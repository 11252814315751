import { Card, CardContent, Typography } from "@material-ui/core";
import { Skeleton, Stack } from "@mui/material";
import { useMongoAggregate } from "../../../utils/mongoAggregate/useMongoAggregate";
import ProductsList from "../components/products/ProductsList";
import { useDocs } from "../../../algolia/getObjects";
import { useContext, useEffect, useMemo } from "react";
import { useSearchParams } from "../hooks/useSearchParams";
import { FiltersSection } from "../components/products/FiltersSection";
import { useChangeTitle } from "../../../components/Utils/useChangeTitle";
import { useHistory } from "react-router-dom";
import { MainLayout } from "../layouts/MainLayout";
import { ProviderContext } from "../context/providerContext";

function ProductsRender({ providerData, allProductsData, productsData }) {
  useChangeTitle("Productos");
  const location = useHistory();

  const [allPData, allProductsLoading] = allProductsData;
  const [pData, productsIsLoading] = productsData;

  let allProductsDataFormatted = useMemo(() => {
    if (!allPData) return [];
    return allPData[0]?.totalData.map((p) => p.data);
  }, [allProductsData, location]);

  let productsDataFormatted = useMemo(() => {
    if (!pData) return [];
    return pData?.map((p) => p.data);
  }, [productsData, location]);

  return (
    <MainLayout>
      {(allProductsLoading || allProductsData[1]) && (
        <Skeleton variant="text" width="200px" height="35px"></Skeleton>
      )}
      {!allProductsLoading && !allProductsData[1] && (
        <Typography
          component="h3"
          style={{ fontWeight: 700, fontSize: "24px", color: "#1e293b" }}
        >
          {providerData?.name ? providerData?.name : "Vivet"} (
          {allProductsData[0][0].totalCount[0]?.count || 0})
        </Typography>
      )}
      <Stack
        direction="column"
        gap={2}
        style={{ marginTop: "16px", height: "100%" }}
      >
        <Stack gap={2} sx={{ flexGrow: "1" }}>
          <Stack direction="row" gap={2}>
            <Card
              style={{ borderTop: "6px solid #64748b" }}
              sx={{
                boxShadow:
                  "rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;",
              }}
            >
              <CardContent>
                <Typography fontWeight={700}>Vivet Market</Typography>
              </CardContent>
            </Card>
          </Stack>
          <Stack gap={2} sx={{ marginTop: 2, flexGrow: "1" }}>
            {productsIsLoading ? (
              <Stack gap={1}>
                <Skeleton
                  variant="rounded"
                  animation="wave"
                  height="50px"
                  width="100%"
                  sx={{ fontSize: "1rem" }}
                />
                <Skeleton
                  variant="rounded"
                  height="50px"
                  width="109px"
                  sx={{ fontSize: "1rem" }}
                />
              </Stack>
            ) : (
              <FiltersSection
                isLoading={productsIsLoading || allProductsLoading}
                data={allProductsDataFormatted}
              />
            )}
            <ProductsList
              isLoading={productsIsLoading}
              data={productsDataFormatted}
            />
          </Stack>
        </Stack>
      </Stack>
    </MainLayout>
  );
}
export const DEFAULT_LIMIT = 100;
export default function ProvidersProducts() {
  const { deleteParam, getParam, pushParams, setParam, getAllParams } =
    useSearchParams();
  let seller = getParam("seller");
  let searchQuery = getParam("q");
  let limitQuery = getParam("limit");
  let activeQuery = getAllParams("active");
  let availableQuery = getAllParams("available");
  const sortQuery = getParam("sort");
  const includesParam = (query, reg) =>
    query && reg && query.some((e) => new RegExp(reg).test(e));
  const brandQuery = getParam("brandName");
  const providerCompany = getParam("providerCompany");
  const { providerSelected,defaultBrands } = useContext(ProviderContext);
  const specQuery = getAllParams("spec");

  const productsData = useMongoAggregate({
    index: "products",
    refetchOnWindowFocus: false,

    aggregate: [
      {
        $match: {
          "data.brand": { $in: providerSelected?.brands || defaultBrands },
          ...(searchQuery
            && {
                $or: [
                  {
                    "data.name": {
                      $regex: searchQuery && searchQuery,
                      $options: "i",
                    },
                  },
                  {
                    "data.sellerCode": {
                      $regex: searchQuery && searchQuery,
                      $options: "i",
                    },
                  },
                  {
                    "data.taste": {
                      $regex: searchQuery && searchQuery,
                      $options: "i",
                    },
                  },
                  {
                    "data.description": {
                      $regex: searchQuery && searchQuery,
                      $options: "i",
                    },
                  },
                ],
              }
            ),
            
          ...(brandQuery
            && {
                "data.brandName": brandQuery,
              }
            ),

          ...(specQuery.length > 0
            && {
                "data.specs": { $all: specQuery },
              }
            ),

          ...(includesParam(availableQuery, "available") && {
            "data.available": { $gt: 0 },
          }),
          
          ...(includesParam(availableQuery, "unavailable") && {
            "data.available": { $in: [0, undefined] },
          }),
          ...(includesParam(activeQuery, "active") && {
            "data.active": true,
          }),
          ...(includesParam(activeQuery, "inactive") && {
            "data.active": { $in: [false, undefined, ""] },
          }),

          "data.seller": seller || "v",
        },
      },

      { $limit: Number(limitQuery) || DEFAULT_LIMIT },
      {
        $project: {
          "data.iva": 1,
          "data.objectID": 1,
          "data.active": 1,
          "data.brandName": 1,
          "data.brand": 1,
          "data.name": 1,
          "data.thumbnail": 1,
          "data.grossWeight": 1,
          "data.price": { $objectToArray: "$data.price" },
          "data.sellerCode": 1,
          "data.description": 1,
          "data.onbin": 1,
          "data.purchasePrice": 1,
          "data.available": 1,
          "data.image": 1,
          "data.taste": 1,
          "data.specs": 1,
          "data.quantity": 1,
          "data.unit": 1,
        },
      },
      {
        $lookup: {
          from: "ppricelists",
          localField: "data.price.k",
          foreignField: "data.objectID",
          as: "data.pricelists_data",
          pipeline: [
            {
              $project: {
                "data.objectID": 1,
                "data.name": 1,
              },
            },
          ],
        },
      },
      {
        $lookup: {
          from: "pspecs",
          localField: "data.specs",
          foreignField: "data.objectID",
          as: "data.specs_data",
          pipeline: [
            {
              $project: {
                "data.objectID": 1,
                "data.name": 1,
                "data.image": 1,
              },
            },
          ],
        },
      },
      {
        ...(sortQuery
          ? {
              $sort: {
                ...(sortQuery === "alphabetically" && {
                  "data.name": 1,
                }),
                ...(sortQuery === "brand" && {
                  "data.brandName": 1,
                }),
                ...(sortQuery === "priceHigh" && {
                  "data.price.v": -1,
                }),
                ...(sortQuery === "priceLow" && {
                  "data.price.v": 1,
                }),
              },
            }
          : { $match: {} }),
      },
    ],
  });
  const allProductsData = useMongoAggregate({
    index: "products",
    refetchOnWindowFocus: false,
    aggregate: [
      {
        $match: {
          "data.seller":  seller || "v",
          "data.brand": { $in: providerCompany ? providerSelected?.brands : defaultBrands },
          ...(searchQuery
            && {
                $or: [
                  {
                    "data.name": {
                      $regex: searchQuery ? searchQuery : null,
                      $options: "i",
                    },
                  },
                  {
                    "data.description": {
                      $regex: searchQuery ? searchQuery : null,
                      $options: "i",
                    },
                  },
                ],
              }
            ),
          ...(brandQuery
            && {
                "data.brandName": brandQuery,
              }
            ),

          ...(specQuery.length > 0
            && {
                "data.specs": { $all: specQuery },
              }
            ),

          ...(includesParam(availableQuery, "available") && {
            "data.available": { $gt: 0 },
          }),
          ...(includesParam(availableQuery, "unavailable") && {
            "data.available": { $in: [0, undefined] },
          }),
          ...(includesParam(activeQuery, "active") && {
            "data.active": true,
          }),
          ...(includesParam(activeQuery, "inactive") && {
            "data.active": { $in: [false, undefined,""] },
          }),
        },
      },

      {
        $facet: {
          totalData: [
            {
              $project: {
                "data.objectID": 1,
                "data.brandName": 1,
                "data.active": 1,
                "data.available": 1,
                "data.specs": 1,
              },
            },
            {
              $lookup: {
                from: "pspecs",
                localField: "data.specs",
                foreignField: "data.objectID",
                as: "data.specs_data",
                pipeline: [
                  {
                    $project: {
                      "data.objectID": 1,
                      "data.name": 1,
                    },
                  },
                ],
              },
            },
          ],
          totalCount: [{ $count: "count"  }],
        },
      },
    ],
  });
  

  useEffect(() => {
    if(productsData[1]) return
    if(productsData[0].length <= 0){
        // deleteParam("providerCompany");
        // pushParams()
    }
    
  },[productsData])

  //setting total data param or deleting if doesn't exist
  useEffect(() => {
    if (!allProductsData[1]) {
      if (allProductsData[0][0]?.totalCount.length > 0) {
        setParam("total", allProductsData[0][0]?.totalCount[0]?.count);
        return pushParams();
      }
    }
    
    deleteParam("total");
    pushParams();
  }, [allProductsData[1], allProductsData[0]]);

  const providersData = useDocs({
    index: "sellers",
    ids: [seller],
  });

  const providerData = providersData?.[seller];

  useEffect(() => {
   
    deleteParam("limit");
    pushParams();
  }, [allProductsData[0], providersData]);
  return (
    <>
      <ProductsRender
        allProductsData={allProductsData}
        providerData={providerData}
        productsData={productsData}
      />
    </>
  );
}
