import { useGetLibro } from "./useGetLibro";
import { Box, Button, Grid, styled } from "@mui/material";
import { numberWithCommas } from "../../../../../components/Utils/NumberWithCommas";
import {
  gridFilteredDescendantCountLookupSelector,
  useGridApiContext,
  useGridApiRef,
  useGridSelector,
} from "@mui/x-data-grid-premium";
import _ from "lodash";
import { useEffect, useMemo, useState } from "react";
import DataGridPremium from "../../../../../components/DataGrid/DataGrid";
import { useBanksDataContext } from "../../../BanksContext";
import moment from "moment";
import queryString from "qs";
import { useLocation } from "react-router-dom";
import { invoices_types } from "../../Commons";

const replaces = {
  "TR.": "Transferencia",
  Transf: "Transferencia",
  "PAGO CHEQUE": "PAGO CHEQUE",
  Cobranza: "Cobranzas",
  "DEPOSITO CHEQUE": "DEPOSITO CHEQUE",
  "Retencion Ganancias SICORE": "Retencion Ganancias SICORE 3",
  Efectivo: "Cobros Efectivo",
  "G. DE ECHEQ GALICIA": "DEPOSITO ECHEQ",
};

export default function DiarioTable() {
  const { accounts } = useBanksDataContext();
  const accounts_by_id = useMemo(
    () => _.mapValues(_.groupBy(accounts, "id"), ([{ name }]) => name),
    [accounts],
  );
  const [transactions, transactionsLoading] = useGetLibro({});

  const [invoices, invoicesLoading] = useGetLibro({
    index: "invoices",
  });

  const [checks, checksLoading] = useGetLibro({
    index: "checks",
  });

  const location = useLocation();

  const parsedHash = queryString.parse(location.search?.replace("?", ""));

  const [data, setArrayData] = useState([]);
  const apiRef = useGridApiRef();

  const { setData } = useBanksDataContext();

  const handleCellClick = ({ field, row, value }) => {
    if (field === "entity") {
      setData({ entity: value, entity_name: row.entity_name });
    } else {
      setData({ idSelected: row.id, tableType: row.index });
    }
  };

  useEffect(() => {
    setArrayData([]);
  }, [parsedHash.to, parsedHash.from]);

  useEffect(() => {
    if (![checks, invoices, transactions].some((d) => !d)) {
      const data = _.chain([...transactions, ...checks, ...invoices])
        .map((h) => {
          let r =
            h.reason?.replace?.("Cobro", "Cobros") ||
            (h.index === "invoices"
              ? `${h.received ? "Compras" : "Ventas"} - ${
                  invoices_types[h.tipo_numero]
                }`
              : // - ${_.padStart(h.pto_de_venta, 4, "0")}-${_.padStart(
              //     h.numero,
              //     8,
              //     "0",
              //   )}`
              h.index === "checks"
              ? accounts.map((d) => d.k).includes("1-1-1-1-2")
                ? `Cobro con cheque` // ${h.check_number}`
                : `Pago con cheque` // ${h.check_number}`
              : "");
          r =
            _.entries(replaces).find(([k]) =>
              r.toUpperCase().includes(k.toUpperCase()),
            )?.[1] || r;
          return {
            ...h,
            date_reason: `${
              h.date ? moment(h.date).add(3, "hours").format("DD/MM/YYYY") : ""
            } - ${r}`,
            reason: r,
          };
        })
        .groupBy("date_reason")
        .mapValues((values) => ({
          ...values[0],
          accounts: _.chain(values)
            .flatMap((v) => v.accounts)
            .groupBy("k")
            .map((v) => ({
              k: v[0].k,
              v: v.reduce((p, a) => p + (a.v || 0), 0),
            }))
            .value(),
        }))
        .sortBy("date")
        .value();
      let rows = [];
      for (let i in data) {
        const h = data[i];
        const accounts = h.accounts;
        rows.push({
          ...h,
          i,
          row_id: h.id,
        });
        for (let j in accounts) {
          const account = accounts[j];
          const v = account.v;
          rows.push({
            row_id: `${h.id}-${j}`,
            account_code: account.k?.replaceAll("-", "."),
            account_name: accounts_by_id[account.k],
            debit: v > 0 ? v : 0,
            credit: v < 0 ? -v : 0,
          });
        }
      }
      setArrayData(rows);
    }
  }, [checks, invoices, transactions]);

  return (
    <Grid container sx={{ height: 500, paddingBottom: "44px" }}>
      <Grid item xs={12}>
        <StyledDataGrid
          initialState={{
            columns: {
              columnVisibilityModel: {
                // Hide columns status and traderName, the other columns will remain visible
                payment_date: false,
                check: false,
                contabilium_id: false,
                id: false,
                // date: false,
              },
            },
          }}
          apiRef={apiRef}
          fileName={`Diario - (${moment().format("YYYY-MM-DD")})`}
          getRowId={(row) => row.row_id}
          treeData
          getTreeDataPath={(row) =>
            row.account_code
              ? [row.row_id.replace(/-\d+$/, ""), row.row_id]
              : [row.row_id]
          }
          onCellClick={handleCellClick}
          isCellEditable={(params) => !params.row.entity?.length}
          loading={transactionsLoading || invoicesLoading || checksLoading}
          columns={columns}
          rows={data}
          defaultGroupingExpansionDepth={1}
          groupingColDef={groupingColDef}
        />
      </Grid>
    </Grid>
  );
}

const groupingColDef = {
  headerName: "Nro Asiento",
  disableExport: true,
  renderCell: (params) => <CustomGridTreeDataGroupingCell {...params} />,
};
function CustomGridTreeDataGroupingCell(props) {
  const { id, field, rowNode, row } = props;
  const apiRef = useGridApiContext();
  const filteredDescendantCountLookup = useGridSelector(
    apiRef,
    gridFilteredDescendantCountLookupSelector,
  );
  const filteredDescendantCount =
    filteredDescendantCountLookup[rowNode.id] ?? 0;

  const handleClick = (event) => {
    if (rowNode.type !== "group") {
      return;
    }

    apiRef.current.setRowChildrenExpansion(id, !rowNode.childrenExpanded);
    apiRef.current.setCellFocus(id, field);
    event.stopPropagation();
  };

  return (
    <Box sx={{ ml: rowNode.depth * 4 }}>
      <div>
        {filteredDescendantCount > 0 ? (
          <Button onClick={handleClick} tabIndex={-1} size="small">
            {Number(row.i) + 1}
          </Button>
        ) : (
          <span />
        )}
      </div>
    </Box>
  );
}

// const handleEditEntity = () => {
//   // const { id, entity, index } = updatedRow;
//   // // updateDoc({ id: id, indexName: index, hit: { entity } });
//   // return "";
// };

const columns = [
  { field: "id", headerName: "id", disableExport: true },
  {
    field: "date",
    headerName: "Fecha",
    width: 120,
    valueGetter: ({ value }) =>
      value ? moment(value).add(3, "hours").format("DD/MM/YYYY") : "",
  },
  { field: "reason", headerName: "Detalle", width: 250 },
  // {
  //   field: "description",
  //   headerName: "Descripcion",
  // },
  // {
  //   field: "entity",
  //   headerName: "Persona cuit",
  //   renderCell: (params) =>
  //     params.row.editable ? (
  //       <InputChangeEntity handleChange={handleEditEntity} row={params.row} />
  //     ) : (
  //       params.row.entity
  //     ),
  // },
  // {
  //   field: "entity_name",
  //   headerName: "Persona",
  // },
  // {
  //   field: "index",
  //   headerName: "Tipo",
  //   valueGetter: ({ value }) =>
  //     ({ checks: "Cheque", invoices: "Factura", transactions: "Transacción" }[
  //       value
  //     ]),
  // },
  {
    field: "account_code",
    headerName: "Codigo",
  },
  {
    field: "account_name",
    headerName: "Cuenta",
  },
  {
    field: "debit",
    headerName: "Debito",
    width: 120,
    type: "number",
    renderCell: ({ value }) =>
      _.isUndefined(value) ? "" : "$ " + numberWithCommas(value, 2),
  },
  {
    field: "credit",
    headerName: "Credito",
    width: 120,
    type: "number",
    renderCell: ({ value }) =>
      _.isUndefined(value) ? "" : "$ " + numberWithCommas(value, 2),
  },
];

const StyledDataGrid = styled(DataGridPremium)(({ theme }) => ({
  border: 0,
  color:
    theme.palette.mode === "light"
      ? "rgba(0,0,0,.85)"
      : "rgba(255,255,255,0.85)",
  WebkitFontSmoothing: "auto",
  letterSpacing: "normal",
  "& .MuiDataGrid-columnsContainer": {
    backgroundColor: theme.palette.mode === "light" ? "#fafafa" : "#1d1d1d",
    whiteSpace: "pre-wrap",
  },
  "& .MuiDataGrid-columnHeader, .MuiDataGrid-cell": {
    borderRight: `1px solid ${
      theme.palette.mode === "light" ? "#f0f0f0" : "#303030"
    }`,
    whiteSpace: "pre-wrap",
  },
  "& .MuiDataGrid-columnsContainer, .MuiDataGrid-cell": {
    borderBottom: `1px solid ${
      theme.palette.mode === "light" ? "#f0f0f0" : "#303030"
    }`,
    whiteSpace: "pre-wrap",
  },
  "& .MuiDataGrid-cell": {
    color:
      theme.palette.mode === "light"
        ? "rgba(0,0,0,.85)"
        : "rgba(255,255,255,0.65)",
    whiteSpace: "pre-wrap",
  },
  "& .MuiPaginationItem-root": {
    borderRadius: 0,
    whiteSpace: "pre-wrap",
  },
}));
