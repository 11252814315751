import _ from "lodash";
import { useEffect, useState } from "react";
import { useMongoAggregate } from "../../utils/mongoAggregate/useMongoAggregate";

const facetsToAggregate = (facets, match) => {
  return _.chain({
    ...facets,
    ..._.chain(facets)
      .pickBy((v) => v.multiple && match[v.field])
      .mapKeys((v, k) => `${k}_multiple`)
      .value(),
  })
    .mapValues((v, k) => [
      ...matchArray(match, !k.includes("_multiple") && v.field, facets),
      {
        $unwind: `$${v.field}`,
      },
      {
        $group: {
          _id: `$${v.field}`,
          count: { $sum: 1 },
        },
      },
      ...(match[k]
        ? [
            {
              $project: {
                count: 1,
                selected: {
                  $cond: [{ $in: ["$_id", [match[k]].flat()] }, 1, 0],
                },
              },
            },
          ]
        : []),
      {
        $sort: { selected: -1, count: -1, _id: -1 },
      },
      ...(v?.lookup
        ? [
            {
              $lookup: {
                localField: "_id",
                from: `p${v?.field?.toLowerCase()}s`,
                foreignField: "data.objectID",
                as: k,
                pipeline: [
                  {
                    $project: {
                      [v?.lookup?.field]: `$data.${v?.lookup?.field}`,
                    },
                  },
                ],
              },
            },
          ]
        : []),
      ...(v.limit
        ? [
            {
              $limit: _.max([v.limit, match[k]?.length || 0]),
            },
          ]
        : []),
      ...(v.index
        ? [
            {
              $lookup: {
                localField: "_id",
                from: `p${v.index}`,
                foreignField: "data.objectID",
                as: k,
                pipeline: [
                  {
                    $project: projectToAggregate(v.project),
                  },
                ],
              },
            },
            {
              $project: {
                _id: 1,
                count: 1,
                ...v.project.reduce(
                  (p, a) => ({ ...p, [a]: { $first: `$${v.field}.${a}` } }),
                  {},
                ),
              },
            },
          ]
        : []),
    ])
    .value();
};

const projectToAggregate = (project) =>
  _.chain(project)
    .uniq()
    .reduce((p, a) => ({ ...p, [a]: `$data.${a}` }), {
      _id: 0,
      objectID: "$data.objectID",
    })
    .value();

const matchArray = (match, omit, facets) => {
  return _.isEmpty(_.omit(match, [omit]))
    ? []
    : [
        {
          $match: _.chain(match)
            .omit([omit])
            .mapValues((v, k) =>
              _.isArray(v) && !k.match(/^\$/)
                ? { [facets[k]?.multiple ? "$all" : "$in"]: v }
                : v,
            )
            .value(),
        },
      ];
};

export default function useData({
  index,
  match = {},
  facets = {},
  project = [],
  sort = {},
  size = 100,
  limit = 100,
  lookup = [],
  defaultDateFilters = {},
  defaultFilters = {},
}) {
  const [items, setItems] = useState([]);

  const mongoProject = projectToAggregate(
    _.concat(
      project,
      _.values(facets).map((f) => f.field),
    ),
  );

  const [
    [
      {
        hits: data,
        count: [{ count } = { count: 0 }] = [],
        ...responseFacets
      } = {},
    ] = [],
    loading,
    error,
    { isRefetching },
  ] = useMongoAggregate({
    index,
    enabled: !!size,
    refetchOnWindowFocus: false,
    aggregate: [
      ...matchArray(
        _.mapKeys(defaultFilters, (v, k) => (k.match(/^\$/) ? k : `data.${k}`)),
      ),
      {
        $sort: _.mapKeys(sort, (v, k) => `data.${k}`),
      },
      {
        $project: mongoProject,
      },
      {
        $facet: {
          hits: [
            ...matchArray({ ...match, ...defaultDateFilters }, "", facets),
            { $skip: size - limit },
            { $limit: limit },
            ...lookup.map(({ from, localField, as, fields }) => ({
              $lookup: {
                foreignField: "data.objectID",
                pipeline: [
                  {
                    $project: projectToAggregate(fields),
                  },
                ],
                from: `p${from}`,
                localField,
                as,
              },
            })),
          ],
          count: [
            ...matchArray({ ...defaultDateFilters, ...match }, "", facets),
            { $group: { _id: "", count: { $sum: 1 } } },
          ],
          ...facetsToAggregate(facets, { ...defaultDateFilters, ...match }),
        },
      },
    ].filter((d) => _.reduce(d, (p, v) => p || !_.isEmpty(v), false)),
  });

  useEffect(() => {
    data &&
      setItems((items) => {
        let it = size - limit === 0 ? [] : [...items];
        data.forEach((d, i) => _.set(it, Number(i) + size - limit, d));
        return it;
      });
  }, [data]);

  return [items, loading || isRefetching, error, count, responseFacets];
}
