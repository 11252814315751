/* eslint-disable react/prop-types */

import React, { useState } from "react";
import { Button } from "@mui/material";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import AddNew from "../../../components/CardFirestore/DataCard/components/AddNew";

export default function ButtonAddNew({ index }) {
  const [addNew, setAddNew] = useState(false);
  const [open, setOpen] = useState(false);

  const defaultValues = {};

  return (
    <>
      <Button style={{ display: "contents" }}>
        <AddCircleIcon
          onClick={() => {
            setAddNew(true);
            setOpen(true);
          }}
        />
      </Button>
      {addNew && (
        <AddNew
          index={index}
          hit={defaultValues}
          open={open}
          setOpen={setOpen}
        />
      )}
    </>
  );
}
