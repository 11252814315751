/* eslint-disable react/prop-types */

import React from "react";
import { Dialog, Box } from "@mui/material";

export function GenericModal({ open, handleClose, children }) {
  return (
    <Dialog open={open} onClose={handleClose}>
      <Box sx={{ padding: 2 }}>{children}</Box>
    </Dialog>
  );
}
