const filterByBrands = (data,brands) => {
    if(brands.length <= 0) return data
    return data?.filter(d => brands?.find(brand => d.brandName?.includes(brand)))
}
const filterBySeller = (data,sellers) => {
  if(sellers.length <= 0) return data
  return data?.filter(d => sellers?.find(seller => d.seller?.includes(seller)))
}
const filterBySpec = (data,specs) => {
  if(specs.length <= 0) return data;
  return data.filter(product => product.specs_data?.map(spec => spec.data)?.find(spec => specs.includes(spec?.name)))
}

const filterByActive = (data,active) => {
    if(active.length <= 0) return data
    if(active.includes("active") && active.includes("inactive")) return data
    if(active.includes("active")) return data.filter(product => Boolean(product.active))
    if(active.includes("inactive")) return data.filter(product => Boolean(!product.active))
}


  const filterByAvailable = (data,available) => {
    if(available.length <= 0) return data
    if(available.includes("available") && available.includes("unavailable")) data
    if(available.includes("available")) return data.filter(product => product.available >= 1) 
    if(available.includes("unavailable")) return  data.filter(product => product.available <= 0 || !product.available ) 
}

  const orderAlphabetically = (data,sortParam) => (sortParam !== "alphabetically" || !sortParam ) ? data : data.sort((current,next) => current.name < next.name ? -1 : current.name > next.name ? 1 : 0)
  const orderPriceHigh = (data,sortParam) => sortParam !== "priceHigh" || !sortParam  ? data : data.sort((current,next) => {
    const priceObj = current.price?.find(price => price.k === "d")
    const nextPriceObj = next.price?.find(price => price.k === "d")
    return Math.trunc(priceObj?.v) - Math.trunc(nextPriceObj?.v)
  }).reverse()
  const orderPriceLow = (data,sortParam) => sortParam !== "priceLow" || !sortParam  ? data : data.sort((current,next) => {
    const priceObj = current.price?.find(price => price.k === "d")
    const nextPriceObj = next.price?.find(price => price.k === "d")
    return Math.trunc(priceObj?.v) - Math.trunc(nextPriceObj?.v)
  }) 

  const orderAlphabeticallyBrand = (data,sortParam) => sortParam !== "brand" || !sortParam ? data : data.sort((current,next) => current.brandName < next.brandName ? -1 : current.brandName > next.brandName ? 1 : 0)
  export {
      filterBySpec,
      filterBySeller,
      filterByBrands,
      filterByActive,
      filterByAvailable,
      orderAlphabeticallyBrand,
      orderAlphabetically,
      orderPriceHigh,
      orderPriceLow
    }