
/* eslint-disable react/prop-types */
import {
  Box, Button, Input, Menu, MenuItem,
} from '@mui/material';
import React, { useEffect, useState } from 'react';

export function MenuApp({
  isVisible, toggleVisibily, options, anchorEl, style, ...others
}) {
  const { types, setDataTypes, action } = { ...others };
  const [typeNumber, setTypeNumber] = useState({ ...types });

  const openMenu = Boolean(isVisible);
  const handleClose = () => toggleVisibily(false);

  useEffect(() => {
    let active = true;
    const arrTypes = Object.entries(typeNumber || {})
      .reduce((p, [, value]) => ({
        ...p,
        [value]: Object.entries(typeNumber || {})
          .filter(([, b]) => b === value).map(([x]) => x),
      }), {});

    // eslint-disable-next-line no-unused-expressions
    active && setDataTypes(arrTypes);
    return () => { active = false; };
  }, [typeNumber]);

  return (
    <Menu
      sx={{ ...style }}
      id="basic-menu"
      anchorEl={anchorEl}
      open={openMenu}
      onClose={handleClose}
      MenuListProps={{
        "aria-labelledby": "basic-button",
      }}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "right",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
    >
      {options.map((x, index) => (
        <Box key={index} sx={{ display: "flex", gap: 1, alignItems: "center" }}>
          <MenuItem>
            <Input
              sx={{ width: "30%" }}
              type="number"
              pattern="[*9]"
              name={x}
              onChange={(e) => {
                const condition =
                  Number(e.target.value) !== 0 &&
                  Number(e.target.value) < Object.keys(types || {}).length + 1;
                if (condition) {
                  setTypeNumber({ ...typeNumber, [x]: Number(e.target.value) });
                }
              }}
              value={typeNumber[x]}
              min={0}
              max={3}
            />
            {x}
          </MenuItem>
        </Box>
      ))}
      {action && (
        <Button variant="outlined" fullWidth onClick={action}>
          Confirmar
        </Button>
      )}
    </Menu>
  );
}
