export const rows = ({ order, objectID }) => {
  const ROWS = Object.values(order.items || {})
    // eslint-disable-next-line max-len, no-unsafe-optional-chaining
    .sort(
      (a, b) =>
        order?.brands?.[a.data?.brand]?.ranking -
          order?.brands?.[b.data?.brand]?.ranking ||
        // eslint-disable-next-line no-unsafe-optional-chaining
        a.data?.ranking - b.data?.ranking ||
        a.data?.name.localeCompare(b.data?.name),
    )
    .map((data) => {
      const dataItem = data.data;
      const dataName = `${dataItem.name} - ${dataItem.taste} - ${dataItem.quantity}${dataItem.unit}`;
      const boxes = Object.keys(data?.shippingBox || {}).length
        ? Object.keys(data?.shippingBox || {}).join(",")
        : "";
      const basket = order.assemblyState !== "inBoxes" ? data?.basket : "";
      const dataDiscount = data.discount;
      // eslint-disable-next-line max-len
      const dataSubTotal =
        (data.q || 0) *
        (data.price || 0) *
        (1 - (data?.discount || 0)) *
        (1 + (data?.iva ?? 0.21));
      const dataBrand = dataItem.brandName;
      const expDate = Object.entries(order.inventorynumbers || {})
        .filter(([lotID]) =>
          Object.keys(data.lot || {}).some((x) => x === lotID),
        )
        ?.map(([, d]) => d?.expirationdate);
      const purchasePriceData = data?.purchasePrice;
      return {
        ...data,
        id: dataItem.objectID,
        iva: data.iva ?? 0.21,
        EAN: dataItem.EAN,
        product: dataName,
        image: dataItem.image,
        q: data.q || 0,
        // eslint-disable-next-line no-nested-ternary
        assembly:
          !!basket?.length && !boxes.length
            ? basket
            : boxes.length
            ? boxes
            : "",
        price: data.price,
        discount: dataDiscount,
        subTotal: dataSubTotal,
        brand: dataBrand,
        expDate,
        purchasePrice: purchasePriceData,
        oq: data.oq || data.q || 0,
        orderId: objectID,
        didLastDiscount: order?.editedUsers?.[data.didLastDiscount]?.name,
        quitItem: order?.deletedUsers?.[data.quitItem]?.name,
        truckerEdit: data?.truckerEdit,
        quantityEditedByAssembly: data?.quantityEditedByAssembly,
        editLastQuantity:
          order?.editedQuantityUsers?.[data.lastUserEditQuantity]?.name ||
          data.editLastQuantity,
        invoiceDriveId: order?.invoiceDriveId,
      };
    });

  return ROWS;
};
