import React from "react";
import { Box, Button, Container, Paper, Typography } from "@mui/material";
import { Link } from "react-router-dom";
import { signOut } from "../context/UserContext";

const AccessDeniedPage = ({
  error = "acceso a esta área",
  go_home = false,
}) => {
  return (
    <Container maxWidth="sm" sx={{ mt: 8 }}>
      <Paper sx={{ p: 4 }}>
        <Typography variant="h4" gutterBottom>
          Acceso denegado
        </Typography>
        <Typography variant="body1">Lo siento, no tienes {error}.</Typography>
        <Typography variant="body2" sx={{ mt: 2 }}>
          Si crees que esto es un error, por favor contacta al administrador del
          sistema.
        </Typography>
        <Box sx={{ mt: 4 }}>
          {go_home && (
            <Button variant="contained" color="primary" component={Link} to="/">
              Ir a la página de inicio
            </Button>
          )}
          <Button
            onClick={() => signOut()}
            variant="outlined"
            color="primary"
            sx={{ ml: 2 }}
          >
            Cerrar Sesión
          </Button>
        </Box>
      </Paper>
    </Container>
  );
};

export default AccessDeniedPage;
