/* eslint-disable no-restricted-syntax */
/* eslint-disable no-unused-expressions */
/* eslint-disable react/prop-types */
/* eslint-disable no-unsafe-optional-chaining */

import { Avatar, Box } from "@material-ui/core";
import * as React from "react";
import { useEffect, useMemo } from "react";
import { numberWithCommas } from "../../../components/Utils/NumberWithCommas";
import { FieldValue, updateDoc } from "../../../firebase";
import DataGridPremium from "../../../components/DataGrid/DataGrid";
import _ from "lodash";

const columns = (purchaseOrder, purchaseOrderId) => [
  {
    field: "other",
    headerName: "Otro",
    width: 150,
    renderCell: (params) => (
      <p>
        {params.value?.split("|")?.[0] || ""}
        <br />
        {params.value?.split("|")?.[1] || ""}
      </p>
    ),
  },
  {
    field: "image",
    headerName: "Imagen",
    width: 80,
    renderCell: (params) => <Avatar src={params.value} />,
  },
  { field: "sellerCode", headerName: "Código", width: 80 },
  { field: "name", headerName: "Producto", width: 150 },
  { field: "taste", headerName: "Sabor", width: 120 },
  {
    field: "gramaje",
    headerName: "Gramaje",
    width: 80,
    valueGetter: (params) =>
      `${params.row.quantity || ""}${params.row.unit || ""}`,
  },
  { field: "available", headerName: "Disponibilidad", width: 120 },
  {
    field: "q",
    headerName: "Cantidad",
    width: 80,
    editable: !purchaseOrder?.received?.s,
    valueSetter: (params) => {
      const row = params.row;
      const q = _.round(params.value, 0) || 0;
      if (q === (row?.q || 0)) return params.row;
      if (!params.row.other) {
        updateDoc(
          "purchaseOrders",
          params.row.orderId,
          !parseInt(params.value, 10)
            ? { [`items.${params.row.id}`]: FieldValue.delete() }
            : {
              [`items.${params.row.id}`]: {
                q,
                purchasePrice: row?.purchasePrice ?? 10000000,
                iva: row?.iva ?? 0.21,
                title: `${row?.brandName} ${row?.name} - ${row?.taste} ${row?.quantity ? row?.quantity + row?.unit : ""
                  }`.trim(),
              },
            },
        );
      } else {
        const otherItems = purchaseOrder?.otherItems;
        // eslint-disable-next-line max-len
        otherItems[
          purchaseOrder?.otherItems?.findIndex((e) => e.id === params.row.id)
        ].q = parseInt(params.value, 10);
        const itemsFiltered = otherItems.filter((x) => x.id !== params.row.id);
        if (params.value === 0) {
          updateDoc({
            indexName: "purchaseOrders",
            id: purchaseOrderId,
            hit: { otherItems: [...itemsFiltered] },
          });
        } else {
          updateDoc({
            indexName: "purchaseOrders",
            id: purchaseOrderId,
            hit: {
              otherItems: [...otherItems],
            },
          });
        }
      }
      return params.row;
    },
  },
  {
    field: "received",
    headerName: "Recibido",
    width: 120,
  },
  { field: "pack", headerName: "Pack", width: 50 },
  {
    field: "bultos",
    headerName: "Bultos",
    width: 60,
    editable: true,
    type: "number",
    valueGetter: (params) =>
      numberWithCommas(params?.row?.q / params?.row?.pack, 1) !== "NaN"
        ? params?.row?.q / params?.row?.pack
        : "",
  },
  {
    field: "iva",
    headerName: "Iva",
    width: 60,
    valueGetter: (params) =>
      params?.row?.iva ? `${params?.row?.iva * 100}%` : "",
  },
  {
    field: "sales",
    headerName: "Un. vendidas",
    width: 100,
    type: "number",
    valueGetter: (params) => params?.row?.sales,
  },

  {
    field: "purchasePrice",
    headerName: "Precio de Compra",
    width: 140,
    type: "number",
    renderCell: ({ value, ...props }) => { return `$${numberWithCommas(purchaseOrder?.items?.[props.row.objectID]?.purchasePrice || value, 2)}` },
  },
  {
    field: "subTotal",
    headerName: "Sub Total",
    width: 100,
    type: "number",
    renderCell: ({ value }) => `$${numberWithCommas(value, 2)}`,
    valueGetter: (params) =>
      purchaseOrder?.items?.[params.row.objectID]?.purchasePrice > 0
        ? params?.row?.q * purchaseOrder?.items?.[params.row.objectID]?.purchasePrice || params.row.purchasePrice
        : 0,
  },
  {
    field: "active",
    headerName: "Activo",
    width: 120,
    type: "boolean",
    hide: true,
  },
];

export default function TableProducts({
  products,
  loading = false,
  classes,
  purchaseOrder,
  purchaseOrderId,
}) {
  useEffect(() => {
    try {
      !loading &&
        setTimeout(() => {
          for (const e of document.querySelectorAll(".MuiDataGrid-main > div"))
            e.textContent === "MUI X: Missing license key" &&
              (e.style.display = "none");
        }, 1000);
    } catch (e) {
      e;
    }
  }, [loading]);

  const productQ = (product) =>
    purchaseOrder?.items?.[product.objectID]?.q || 0;

  let rows = products
    ?.filter((product) => !purchaseOrder?.received?.s || productQ(product))
    .map((product) => ({
      ...product,
      q: productQ(product),
      iva: purchaseOrder?.items?.[product.objectID]?.iva,
      id: product.objectID,
      orderId: purchaseOrder?.objectID,
      available: product?.available || "0",
      received: product?.received,
    }));
  if (purchaseOrder?.otherItems?.length) {
    rows = [...rows, ...purchaseOrder?.otherItems];
  }
  const columnsMemo = useMemo(() => {
    return columns(purchaseOrder, purchaseOrderId).filter((a) =>
      purchaseOrder?.received?.s
        ? a.field !== "available"
        : a.field !== "received",
    );
  }, [purchaseOrder, purchaseOrderId]);

  return (
    <Box style={{ height: 400, width: "100%" }}>
      <DataGridPremium
        disableSelectionOnClick
        rows={rows.length === 0 ? [] : rows}
        columns={columnsMemo}
        className={classes.containerDataGrid}
        // components={{ Toolbar: GridToolbar }}
        // componentsProps={{
        //   toolbar: {
        //     showQuickFilter: true,
        //     quickFilterProps: { debounceMs: 200 },
        //   },
        // }}
        getRowClassName={(el) => {
          console.log(classes);
          if (!purchaseOrder?.received?.s)
            return el?.row?.active === true
              ? el?.row?.q && classes.rowActive
              : classes.rowInActive;

          const q = el?.row?.received;

          return el?.row?.q === q
            ? classes.rowActive
            : !q
              ? classes.rowInActive
              : classes.orangeRow;
        }}
        isCellEditable={() => !purchaseOrder?.received?.t}
      />
    </Box>
  );
}
