import { useEntityDocs } from "./useEntityDocs";
import { Box, Grid, styled } from "@mui/material";
import { numberWithCommas } from "../../../components/Utils/NumberWithCommas";
import { useGridApiRef } from "@mui/x-data-grid-premium";
import _ from "lodash";
import { useEffect, useState } from "react";
import DataGridPremium from "../../../components/DataGrid/DataGrid";
import { Asynchronous } from "../../../components/CardFirestore/DataCard/components/EditForm";
import { useBanksDataContext } from "../BanksContext";
import { useDocumentData } from "../../../firebase";
import moment from "moment";
import EntitiesTable from "./EntitiesTable";

export default function Entities_balance() {
  const { setData, data } = useBanksDataContext();
  const entity = data?.entity;
  const [entity_data] = useDocumentData("entities", entity);

  return (
    <Grid
      container
      p={1}
      sx={{
        marginTop: 2,
        overflow: "hidden",
      }}
    >
      <Grid item xs={12}>
        <Box>
          <EntitiesTable />
        </Box>
        <Asynchronous
          values={entity_data}
          index={"entities"}
          customText={"{{objectID}} - {{name}}"}
          onChange={(d, hit) => {
            setData({ entity: d, entity_name: hit.name });
          }}
        />
      </Grid>
      {entity && (
        <Grid item xs={12} sx={{ height: 600 }}>
          <EntityBalanceTable entity={entity} entity_data={entity_data} />
        </Grid>
      )}
    </Grid>
  );
}
function EntityBalanceTable({ entity, entity_data }) {
  const { setData } = useBanksDataContext();

  const [transactions, transactionsLoading] = useEntityDocs({
    entity: entity,
  });

  const [invoices, invoicesLoading] = useEntityDocs({
    entity: entity,
    index: "invoices",
  });

  const [checks, checksLoading] = useEntityDocs({
    entity: entity,
    index: "checks",
  });

  const [arrayData, setArrayData] = useState([]);
  const apiRef = useGridApiRef();

  useEffect(() => {
    setArrayData([]);
  }, [entity]);

  useEffect(() => {
    if (![checks, invoices, transactions].some((d) => !d)) {
      const data = _.sortBy([...checks, ...invoices, ...transactions], "date");
      let tempBalance = 0;
      for (let i in data) {
        const h = data[i];
        const v =
          (h.entity === entity
            ? h.amount
            : h.amounts?.[h.entity?.indexOf?.(entity)]) || 0;
        h.debit = v > 0 ? v : 0;
        h.credit = v < 0 ? -v : 0;
        h.balance = tempBalance = tempBalance + v;
      }
      setArrayData(data.map((v) => ({ ...v })).reverse());
    }
  }, [checks, invoices, transactions]);

  return (
    <StyledDataGrid
      fileName={`${entity_data?.name} (${moment().format("YYYY-MM-DD")})`}
      apiRef={apiRef}
      onCellClick={({ row, id }) =>
        setData({ idSelected: id, tableType: row.index })
      }
      getRowId={(row) => row.id}
      loading={transactionsLoading || invoicesLoading || checksLoading}
      columns={columns}
      rows={arrayData}
    />
  );
}

const columns = [
  {
    field: "index",
    headerName: "Tipo",
    valueGetter: ({ value }) =>
      ({ checks: "Cheque", invoices: "Factura", transactions: "Transacción" }[
        value
      ]),
  },
  { field: "id", headerName: "id" },
  { field: "date", headerName: "Fecha" },
  { field: "reason", headerName: "Detalle" },
  {
    field: "debit",
    headerName: "Debito",
    width: 180,
    type: "number",
    renderCell: ({ value }) => (value ? "$ " + numberWithCommas(value, 2) : ""),
  },
  {
    field: "credit",
    headerName: "Credito",
    width: 180,
    type: "number",
    renderCell: ({ value }) => (value ? "$ " + numberWithCommas(value, 2) : ""),
  },
  {
    field: "balance",
    headerName: "Saldo",
    type: "number",
    renderCell: ({ value }) => "$ " + numberWithCommas(value, 2),
  },
  {
    field: "entity",
    headerName: "Persona cuit",
  },
  {
    field: "entity_name",
    headerName: "Persona",
  },
  {
    field: "bank",
    headerName: "Banco",
  },
  {
    field: "description",
    headerName: "Descripcion",
  },
];

const StyledDataGrid = styled(DataGridPremium)(({ theme }) => ({
  border: 0,
  color:
    theme.palette.mode === "light"
      ? "rgba(0,0,0,.85)"
      : "rgba(255,255,255,0.85)",
  WebkitFontSmoothing: "auto",
  letterSpacing: "normal",
  "& .MuiDataGrid-columnsContainer": {
    backgroundColor: theme.palette.mode === "light" ? "#fafafa" : "#1d1d1d",
    whiteSpace: "pre-wrap",
  },
  "& .MuiDataGrid-columnHeader, .MuiDataGrid-cell": {
    borderRight: `1px solid ${
      theme.palette.mode === "light" ? "#f0f0f0" : "#303030"
    }`,
    whiteSpace: "pre-wrap",
  },
  "& .MuiDataGrid-columnsContainer, .MuiDataGrid-cell": {
    borderBottom: `1px solid ${
      theme.palette.mode === "light" ? "#f0f0f0" : "#303030"
    }`,
    whiteSpace: "pre-wrap",
  },
  "& .MuiDataGrid-cell": {
    color:
      theme.palette.mode === "light"
        ? "rgba(0,0,0,.85)"
        : "rgba(255,255,255,0.65)",
    whiteSpace: "pre-wrap",
  },
  "& .MuiPaginationItem-root": {
    borderRadius: 0,
    whiteSpace: "pre-wrap",
  },
}));
