import { Box, Container } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { DataGridPremium, GridToolbar } from "@mui/x-data-grid-premium";
import { useGetStoresExcel } from "./useGetStoresExcel";

const useStyles = makeStyles(() => ({
  container: {
    width: "100%",
    height: "1000px",
    display: "flex",
    //justifyContent:'space-around',
    marginTop: 15,
    border: "1px solid #ccc",
    borderRadius: 10,
  },
  containerCheckbox: {
    borderRight: "1px solid #ccc",
  },
}));

const StoresExcel = () => {
  const classes = useStyles();

  const [stores, loading] = useGetStoresExcel();
  console.log("stores", stores, loading);

  // const responsablesById = stores?.responsables?.reduce((p,a)=>({...p,[a._id]:a.user}),{});

  const responsables = [
    {
      _id: "D6zYHHi2GBe2VKWAI8pEui4M5UF2",
      count: 380,
      total: 0,
      user: "Dulcinea",
      code: "DGU",
    },
    {
      _id: "5V2EcX8102QE55FXtzRi3TPmYgJ2",
      count: 238,
      total: 0,
      user: "Ale🌱",
      code: "MAD",
    },
    {
      _id: "lOjT5MNwS6SBsVASpf1bt6Gngok2",
      count: 32,
      total: 0,
      user: "Dulci",
      code: "DGU",
    },
    {
      _id: null,
      count: 8,
      total: 0,
    },
    {
      _id: "ykhNbYGGqMbRaDOpiFhwCGPWwK53",
      count: 1,
      total: 0,
      user: "Juan Busso",
    },
    {
      _id: "hpcavNuLj2VzsRcP2e8VMYsY63d2",
      count: 7,
      total: 0,
      user: "Pablo",
    },
    {
      _id: "",
      count: 123,
      total: 0,
    },
    {
      _id: "CaLdqXeOPBg4m1KXHwt0yethnAw2",
      count: 1,
      total: 0,
      user: "Karen",
      code: "KSS",
    },
    {
      _id: "E5uE2lx83HQfTvNUfELR3ke3agB3",
      count: 1,
      total: 0,
      user: "Nury Rodriguez",
    },
    {
      _id: "vweq64ZE1UcrcAnXVjafXf8MUZo2",
      count: 5,
      total: 0,
      user: "Sebastian Gasaro",
    },
    {
      _id: "1ZrW1Ycj2ZWARVGsLxm6C2NGfYI3",
      count: 368,
      total: 0,
      user: "Karen Responsable Ventas ",
      code: "KSS",
    },
  ];

  const responsablesById = responsables?.reduce(
    (p, a) => ({ ...p, [a._id]: a.code }),
    {}
  );

  let rows = stores?.hits?.map((p) => {
    return {
      id: p?._id,
      sellerCode: p?.store?.sellerCode?.v,
      count: p?.count,
      total: p?.total,
      address: p.store.address.formatted_address,
      responsableSales: responsablesById[p.store.responsableSales] || "",
    };
  });
  console.log(rows);

  const columns = [
    // { field: 'thumbnail', headerName: 'Imagen', width: 80, renderCell: (params) => <Avatar src={params.value} /> },
    { field: "sellerCode", headerName: "Codigo" },
    { field: "count", headerName: "Ordenes" },
    { field: "total", headerName: "Total $" },
    { field: "address", headerName: "Direccion" },
    { field: "responsableSales", headerName: "Responsable" },
  ];

  return (
    <>
      <Container className={classes.container}>
        {/*<Box className={classes.containerCheckbox}>*/}
        {/*    <FormGroup>*/}
        {/*            <FormControlLabel onChange={(e) => handleChangeGeneral(e)} control={<Checkbox  />} label="General" />*/}
        {/*            <FormControlLabel onChange={(e) => handleChangeDietetica(e)} control={<Checkbox />} label="Dietética" />*/}
        {/*    </FormGroup>*/}
        {/*</Box>*/}

        <Box style={{ width: "100%" }}>
          {rows?.length && (
            <DataGridPremium
              rows={rows}
              // getRowId={(row) => console.log('row', row)}
              // loading={loading}
              columns={columns}
              components={{ Toolbar: GridToolbar }}
              // pageSize={50}
              // rowsPerPageOptions={[3]}
              // pagination
            />
          )}
        </Box>
      </Container>
    </>
  );

  // return <></>
};

export default StoresExcel;
