import { Box, Breadcrumbs, Typography } from "@material-ui/core";
import { NavLink, useLocation } from "react-router-dom";
export const Breadcrumb = ({ routes }) => {
  const locaton = useLocation();
  const findInRoutes = (location) => {
    const result = routes?.find((e) => e.path === location);
    if (!result) {
      const locationSplit = location.split("/");
      let find = routes
      ?.find((e) => location.includes(e.path))
      ?.children?.find(
        (child) => child?.name.length === location.split("/").length - 1,
      );
      find.name[find?.name.length - 1] = locationSplit[find?.name.length];
      const replaced = find.path.replace(
        ":id",
        locationSplit[find.name.length],
      );
      const copy = { ...find };
      copy.path = replaced;

      return copy;
    }
    if (Object.hasOwn(result, "children"))
      return result.children.find((child) => child.path === location);
    if (result) return result;
  };
  const findRoute = findInRoutes(locaton.pathname);
  return (
    <Box
      component="header"
      style={{
        position: "fixed",
        width: "100%",
        zIndex: 3,
        padding: "16px 24px",
        backgroundColor: "#f8fafc",
        borderBottom: "1.5px solid #cbd5e1",
      }}
    >
      <Breadcrumbs separator="›" aria-label="breadcrumb">
        {findRoute?.name.map((name, index) => (
          <div key={name}>
            {index === findRoute?.name.length - 1 ? (
              <Typography>{name}</Typography>
            ) : (
              <NavLink
                underline="hover"
                color="inherit"
                to={`/${findRoute.path
                  .split("/")
                  .slice(1)
                  .slice(0, index + 1)
                  .join("/")}`}
              >
                {name}
              </NavLink>
            )}
          </div>
        ))}
      </Breadcrumbs>
    </Box>
  );
};
