/* eslint-disable react/prop-types */
import React, { useEffect, useState } from "react";
import classNames from "classnames";
import { faPlusCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import { db, updateDoc } from "../../firebase";
import NewAddress from "./NewAddress";
import useStyles from "./styles";
import ConfirmNewAddress from "./NewAddress/ConfirmNewAddress";

export function AddressesTemplate({
  classes,
  title,
  address,
  details,
  onClick,
}) {
  return (
    // eslint-disable-next-line max-len
    <div
      onClick={onClick}
      // eslint-disable-next-line max-len
      className={classNames([
        classes.location_list__location,
        classes.location_list__location__selected,
      ])}
    >
      {/* location_list__location__selected"> */}
      <div className={classes.location_list__location__flag}>
        {/* <img src={collection==="addresses"?flag:shop} */}
        {/*     alt="Location Marker"/> */}
      </div>
      <div className={classes.location_list__location__address}>
        <div className={classes.location_list__location__address__title}>
          {title}
        </div>
        <div className={classes.location_list__location__address__text}>
          {address}
        </div>
        <div className={classes.location_list__location__address__details}>
          {details}
        </div>
      </div>
    </div>
  );
}

export default function SelectAddress({ orderID, userID, storeID, onUpdate }) {
  // userID = 'ykhNbYGGqMbRaDOpiFhwCGPWwK53';
  // storeID = '0E2z1lYaNj2Tne8u03Np';
  // orderID = 'MTaYA5REMof7vUNg5jdo';

  const classes = useStyles();
  const [newAddress, setNewAddress] = useState(false);
  const [address, setAddress] = useState(false);
  const [store, setStore] = useState(false);

  useEffect(() => {
    let active = true;
    if (storeID) {
      db.collection("stores")
        .doc(storeID)
        .get()
        .then((doc) => active && setStore(doc.data()));
    }
    // eslint-disable-next-line no-return-assign
    return () => (active = false);
  }, [storeID]);

  const addressDispatch = (add, newAdd) => {
    setAddress(add);
    setNewAddress(!!newAdd);
  };

  const confirmAddress = async (details) => {
    if (!storeID) {
      if (address.id) {
        db.collection("addresses")
          .doc(address.id)
          .update({ details, last_used: new Date() });
        if (orderID) {
          updateDoc({
            indexName: "orders",
            id: orderID,
            hit: { address: address.id, "data.address": {...address, details} },
          });
        }
      } else {
        const addressID = await db
          .collection("addresses")
          .add({
            ...address,
            details,
            last_used: new Date(),
            users: [userID],
          })
          .then((doc) => doc.id);
        if (orderID) {
          updateDoc({
            indexName: "orders",
            id: orderID,
            hit: {
              address: addressID,
              "data.address": { ...address, details },
            },
          });
        }
      }
    } else {
      updateDoc({
        indexName: "stores",
        id: storeID,
        hit: { address: { ...address, details } },
      });
      if (orderID) {
        updateDoc({
          indexName: "orders",
          id: orderID,
          hit: { "data.address": { ...address, details } },
        });
      }
    }
    onUpdate({ ...address, details });
  };

  return (
    <div className={classes.root}>
      <div className={classes.title}>
        <Typography variant="h2" align="center">
          Confirmar Dirección
        </Typography>
      </div>
      {address ? (
        <ConfirmNewAddress
          address={address}
          cancel={() => setAddress(false)}
          confirmAddress={confirmAddress}
        />
      ) : (
        // eslint-disable-next-line react/jsx-no-useless-fragment
        <>
          {!newAddress ? (
            <div className={classes.location_list__add}>
              <Button
                startIcon={<FontAwesomeIcon icon={faPlusCircle} />}
                onClick={() => setNewAddress(true)}
                variant="contained"
                color="secondary"
              >
                Introducir una nueva dirección de envío.
              </Button>
            </div>
          ) : (
            <NewAddress
              setNewAddressValue={(a) => addressDispatch(a, true)}
              Template={(props) => AddressesTemplate({ classes, ...props })}
            />
          )}
        </>
      )}
      <hr />

      <div className={classes.location_list}>
        {store && (
          <AddressesTemplate
            classes={classes}
            onClick={() => addressDispatch(store.address)}
            title={store.storeName}
            address={store.address?.formatted_address}
            details={store.address?.details}
          />
        )}
        <Addresses
          userUid={userID}
          Template={(props) =>
            AddressesTemplate({
              classes,
              onClick: () => addressDispatch(props.hit),
              ...props,
            })
          }
        />
      </div>
    </div>
  );
}

function Addresses({ Template, userUid }) {
  const [addresses, setAddresses] = useState([]);

  useEffect(() => {
    let active = true;
    if (userUid) {
      db.collection("addresses")
        .where("users", "array-contains", userUid)
        .orderBy("last_used", "desc")
        .limit(5)
        .get()
        .then((addr) => {
          const naddresses = [];
          addr.forEach((doc) => {
            const address = doc.data();
            naddresses.push({
              ...address,
              address: address ? address.formatted_address : "",
              title: "",
              id: doc.id,
              details: address.details,
            });
          });
          if (active) setAddresses(naddresses);
        });
    }
    // eslint-disable-next-line no-return-assign
    return () => (active = false);
  }, [userUid]);

  return (
    <>
      {addresses.map((a) => (
        <Template
          key={a.id}
          collection="addresses"
          hit={a}
          title={a.title}
          address={a.address}
          details={a.details}
          id={a.id}
        />
      ))}
    </>
  );
}

// function useMaps() {
//      const processDispatch = useProcessDispatch();
//     const  google  = useGoogleMapsApi(
//         process.env.REACT_APP_MAPS_API+'&libraries=places,geometry,drawings'
//     );
//     useEffect(()=>{
//         google && processDispatch({field:'mapsLoaded',value:true});
//     },[google,processDispatch]);
//      // window.initAutocomplete = () => {
//      //     processDispatch({field:'mapsLoaded',value:true});
//      // };
//     return google;
//  }
//
// export function checkContainsLocation(point,shippings){
//     const google = window.google;
//     //clean map
//     let matchShippings = [];
//     for (let i in shippings) {
//         let ship = shippings[i];
//         if (ship.coordinates) {
//             //  console.log(ship);
// oint, bermudaTriangle);
//             contains && matchShippings.push(ship);
// //  map.data.add({geometry: new google.maps.Data.Polygon([polygo])})
//         }}
//     return matchShippings;
// }
