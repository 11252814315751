import {
  Box,
  Button,
  createSvgIcon,
  LinearProgress,
  Menu,
  MenuItem,
} from "@mui/material";
import {
  DataGridPremium as MuiDataGridPremium,
  gridSortedRowIdsSelector,
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarDensitySelector,
  GridToolbarFilterButton,
  GridToolbarQuickFilter,
  useGridApiContext,
  esES,
  GridLogicOperator,
} from "@mui/x-data-grid-premium";
import { useEffect, useMemo, useState } from "react";

export default function DataGridPremium({ columns, fileName, ...props }) {
  useEffect(() => {
    let active = true;
    try {
      setTimeout(() => {
        if (active)
          for (const e of document.querySelectorAll(".MuiDataGrid-main > div"))
            e.textContent === "MUI X Missing license key" &&
              (e.style.display = "none");
      }, 100);
    } catch (e) {
      console.log(e);
    }
    return () => (active = false);
  }, []);

  const customColumns = useMemo(
    () =>
      columns?.map((c) => ({
        ...c,
        getApplyQuickFilterFn: removeAccents,
      })),
    [columns],
  );

  return (
    <MuiDataGridPremium
      density="compact"
      initialState={{
        filter: {
          filterModel: {
            items: [],
            quickFilterLogicOperator: GridLogicOperator.Or,
          },
        },
      }}
      slots={{
        toolbar: CustomToolbar,
        loadingOverlay: LinearProgress,
      }}
      slotProps={{
        toolbar: { fileName },
      }}
      columns={customColumns}
      localeText={{
        ...esES.components.MuiDataGrid.defaultProps.localeText,
        toolbarDensity: "",
        toolbarFilters: "",
        toolbarColumns: "",
      }}
      {...props}
    />
  );
}

const getUnfilteredRows = ({ apiRef }) => gridSortedRowIdsSelector(apiRef);

function CustomToolbar({ fileName }) {
  const apiRef = useGridApiContext();
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const ExportIcon = createSvgIcon(
    <path d="M19 12v7H5v-7H3v7c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2v-7h-2zm-6 .67l2.59-2.58L17 11.5l-5 5-5-5 1.41-1.41L11 12.67V3h2z" />,
    "SaveAlt",
  );

  const handleExportCsv = (options) =>
    apiRef.current.exportDataAsCsv({ ...options, fileName });

  const handleExportExcel = (options) =>
    apiRef.current.exportDataAsExcel({ ...options, fileName });

  const buttonBaseProps = {
    color: "primary",
    size: "small",
    startIcon: <ExportIcon />,
  };

  return (
    <GridToolbarContainer>
      <Box
        sx={{ display: "flex", justifyContent: "space-between", width: "100%" }}
      >
        <Box sx={{ display: "flex", gap: 3 }}>
          <GridToolbarColumnsButton />
          <GridToolbarFilterButton />
          <GridToolbarDensitySelector />
          <Button
            onClick={(e) => setAnchorEl(e.currentTarget)}
            {...buttonBaseProps}
          ></Button>
          <Menu
            anchorEl={anchorEl}
            open={open}
            onClose={() => setAnchorEl(null)}
            MenuListProps={{
              "aria-labelledby": "basic-button",
            }}
          >
            <MenuItem
              onClick={() =>
                handleExportExcel({ getRowsToExport: getUnfilteredRows })
              }
            >
              Exportar Excel
            </MenuItem>
            <MenuItem
              onClick={() =>
                handleExportCsv({ getRowsToExport: getUnfilteredRows })
              }
            >
              Exportar csv
            </MenuItem>
          </Menu>
        </Box>
        <GridToolbarQuickFilter variant="outlined" />
      </Box>
    </GridToolbarContainer>
  );
}

const removeAccents = (value) => {
  return (params) => {
    const normalizeValue = (val) =>
      val
        ?.toLowerCase()
        ?.normalize("NFD")
        ?.replace(/[\u0300-\u036f]/g, "");

    if (params?.value && typeof params?.value === "string") {
      const updatedValue = normalizeValue(
        params?.row?.brand?.[0] || params?.row?.store?.[0] || params?.value,
      );

      return (
        updatedValue.startsWith(normalizeValue(value)) ||
        updatedValue.includes(normalizeValue(value))
      );
    }
    return undefined;
  };
};
