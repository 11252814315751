import { IconButton, Menu, MenuItem } from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";

const OPTIONS = [{ label: "Editar fechas de lotes", action: "editLotDates" }];

export const MenuOptionsEdit = ({
  selectedOption,
  handleClick,
  anchorEl,
  handleAction,
  handleClose,
  open,
}) => {
  return (
    <>
      <IconButton
        aria-label="more"
        id="long-button"
        aria-controls={open ? "long-menu" : undefined}
        aria-expanded={open ? "true" : undefined}
        aria-haspopup="true"
        sx={{justifyContent: 'flex-end', alignSelf: 'flex-start', marginLeft: "50px"}}
        onClick={handleClick}
      >
        <MoreVertIcon />
      </IconButton>
      <Menu
        id="long-menu"
        MenuListProps={{
          "aria-labelledby": "long-button",
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        {OPTIONS.map((option) => (
          <MenuItem
            key={option.label}
            selected={option === selectedOption}
            onClick={() => handleAction(option.action)}
          >
            {option.label}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};
