/* eslint-disable no-restricted-syntax */
/* eslint-disable no-unused-expressions */
/* eslint-disable react/prop-types */

// REACT && HOOKS
import React, { useEffect, useState } from "react";

// MUI
import { Box, LinearProgress, Paper } from "@mui/material";
import {
  DataGridPremium,
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarDensitySelector,
  GridToolbarExport,
  GridToolbarFilterButton,
  GridToolbarQuickFilter,
} from "@mui/x-data-grid-premium";

// OTHER
import Lightbox from "react-image-lightbox";
import _ from "lodash";
import { useDocumentData } from "../../../firebase/database";
import { populateOrder } from "./utils/populateOrder";
import { Header } from "./components/header/Header";
import { Footer } from "./components/footer/footer";
import LANGUAGE_FORMATTED from "./utils/LANGUAGE_FORMATTED.js";
import { rows } from "./Datagrid/ROWS";
import { columns } from "./Datagrid/COLUMNS";
import useStyles from "./styles";
import AddItem from "./components/addItem/addItem";
import { create } from "zustand";

export const useOrder = create((set) => ({
  order: {},
  orderId: "",
  setOrder: (order) => set(() => ({ order })),
}));

export default function OrdenesKoco({ objectID }) {
  // STATES
  const [orderDoc] = useDocumentData("orders", objectID);
  const [lightboxImage, setLightboxImage] = useState("");
  const [order, setOrder] = useState({});
  const setOrderZus = useOrder((s) => s.setOrder);

  // DATAFETCH
  useEffect(() => {

    let active = true;
    const editedUsers =
      orderDoc &&
      _.uniq(
        Object.values(orderDoc?.items)
          .map((d) => d.didLastDiscount)
          .filter((d) => d),
      );
    const deletedUsers =
      orderDoc &&
      _.uniq(
        Object.values(orderDoc?.items)
          .map((d) => d.deletedItemBy)
          .filter((d) => d),
      );
    const editedQuantityUsers =
      orderDoc &&
      _.uniq(
        Object.values(orderDoc?.items)
          .map((d) => d.lastUserEditQuantity)
          .filter((d) => d),
      );
    orderDoc &&
      populateOrder({
        ...orderDoc,
        editedUsers,
        deletedUsers,
        editedQuantityUsers,
      }).then((ord) => {
        if (active) {
          setOrder(ord);
          orderDoc && setOrderZus({ ...orderDoc, store: ord?.store });
          try {
            setTimeout(() => {
              for (const e of document.querySelectorAll(
                ".MuiDataGrid-main > div",
              ))
                e.textContent === "MUI X Missing license key" &&
                  (e.style.display = "none");
            }, 1000);
          } catch (e) {
            e;
          }
        }
      });
    // eslint-disable-next-line no-return-assign
    return () => (active = false);
  }, [orderDoc]);

  // Datagrid
  const COLUMNS = columns({ setLightboxImage });
  const ROWS = rows({ order, objectID });
  const classes = useStyles();

  return !Object.entries(order || {}).length ? (
    <Box sx={{ width: "100%" }}>
      <LinearProgress />
    </Box>
  ) : (
    <Paper>
      <Paper>
        <Header order={order} />
        <Box
          sx={{ width: "100%", height: 400, maxWidth: "calc(100vw - 120px)" }}
        >
          <DataGridPremium
            getRowClassName={(params) =>
              `${params.row.q === 0 ? classes.deletedItem : classes.normalItem}`
            }
            loading={ROWS.length === 0}
            rows={ROWS}
            localeText={LANGUAGE_FORMATTED}
            columns={COLUMNS}
            pageSize={100}
            rowsPerPageOptions={[5]}
            slots={{
              toolbar: CustomToolbar,
            }}
          />
          <Footer order={order} />
          {lightboxImage && (
            <Lightbox
              animationDuration={0}
              animationDisabled
              mainSrc={lightboxImage}
              onCloseRequest={() => setLightboxImage("")}
            />
          )}
        </Box>
      </Paper>
    </Paper>
  );
}

function CustomToolbar() {
  return (
    <GridToolbarContainer sx={{ justifyContent: "left" }}>
      <GridToolbarQuickFilter
        quickFilterParser={(searchInput) =>
          searchInput.split(",").map((value) => value.trim())
        }
        quickFilterFormatter={(quickFilterValues) =>
          quickFilterValues.join(", ")
        }
      />
      <GridToolbarColumnsButton />
      <GridToolbarFilterButton />
      <GridToolbarDensitySelector />
      <GridToolbarExport
        excelOptions={{
          columnsStyles: {
            discount: {
              numFmt: "0%",
            },
            iva: {
              numFmt: "0%",
            },
            subTotal: {
              numFmt: '"$"#,##0.00;[Red]-"$"#,##0.00',
            },
            EAN: {
              numFmt: "0",
            },
            price: {
              numFmt: '"$"#,##0.00;[Red]-"$"#,##0.00',
            },
          },
        }}
      />
      <AddItem />
    </GridToolbarContainer>
  );
}
