/* eslint-disable react/prop-types */

import React, { useMemo } from "react";
import { Grid } from "@material-ui/core";
// components
import { Redirect } from "react-router-dom";
import PageTitle from "../../../components/PageTitle";
import Card from "../../../components/CardFirestore";
import DataCard from "../../../components/CardFirestore/DataCard/DataCard";
import customField from "../../../components/CustomField";
import { useAreaDataContext } from "../../../context/AreaContext";
import CardElement from "../../../components/Card/CardElement";
import { Stack } from "@mui/material";

export default function Cards({ objectID, indexName }) {
  const areaData = useAreaDataContext();
  const areaCardData = areaData?.panel?.[indexName]?.card || {};

  return (
    <>
      {" "}
      {areaData?.panel?.[indexName] && areaCardData.show !== false && (
        <Card
          index={indexName}
          objectID={String(objectID)}
          relatedIndexes={areaData?.panel?.[indexName]?.relatedIndexes}
          render={({ hit, orginalHit }) => (
            <>
              {hit && !hit.modified_date && (
                <Redirect to={`/app/${indexName}/`} />
              )}
              <Stack direction="column" flexGrow="1">
              <PageTitle
                title={
                  (areaCardData?.title &&
                    customField(hit, areaCardData?.title)) ||
                  indexName
                }
                backButton
              />
              <Grid spacing={0} style={{height:"100%"}}>
                <Grid item xs={12} md={12} lg={8} style={{height:"fit-content"}}>
                  <DataCard
                    hit={hit}
                    index={
                      areaData?.panel?.[indexName]?.collection || indexName
                    }
                    orginalHit={orginalHit}
                  />
                </Grid>
                <ElementsItems hit={hit} areaCardData={areaCardData} />
              </Grid>
              </Stack>
            </>
          )}
        />
      )}
    </>
  );
}

function ElementsItems({ hit, areaCardData }) {
  return useMemo(
    () => (
      // eslint-disable-next-line react/jsx-no-useless-fragment
      <>
        {areaCardData &&
          areaCardData.items &&
          hit &&
          areaCardData.items.map((item, index) => (
            <CardElement key={index} properties={item} hit={hit} />
          ))}
      </>
    ),
    [hit, areaCardData]
  );
}
