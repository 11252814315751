import { useMongoAggregate } from "../../../../../utils/mongoAggregate/useMongoAggregate";
import queryString from "qs";
import moment from "moment";
import { useHistory } from "react-router-dom";
import { useEffect, useState } from "react";
import _ from "lodash";

const date_field = {
  transactions: "date",
  invoices: "accounting_date",
  checks: "dates.issue",
};

export const useGetLibro = ({ accounts, index = "transactions" }) => {
  const history = useHistory();
  const [data, setData] = useState();
  const [skip, setSkip] = useState(0);

  useEffect(() => {
    setSkip(0);
    setData((d) => (d ? false : d));
  }, [accounts]);

  const parsedHash = queryString.parse(
    history.location.search?.replace("?", ""),
  );

  const dateFilter = parsedHash.from
    ? {
        ["data." + date_field[index]]: {
          $lt: moment(parsedHash.to)
            ?.clone()
            .add(1, "day")
            .format("YYYY-MM-DD"),
          $gte: parsedHash.from,
        },
      }
    : {};

  const project = {
    id: "$data.objectID",
    reason: "$data.reason",
    date: "$data." + date_field[index],
    bank: "$data.bank",
    entity: "$data.entity",
    entity_name: "$data.entity_name",
    description: "$data.description",
    accounts: "$data.accounts",
    check: "$data.check",
    payment_date: "$data.dates.payment",
    contabilium_id: "$data.contabilium.id",
    tipo_numero: "$data.tipo_numero",
    numero: "$data.numero",
    pto_de_venta: "$data.pto_de_venta",
    received: "$data.received",
    check_number: "$data.check_number",
  };

  const project_keys = {
    id: 0,
    reason: 1,
    date: 2,
    bank: 3,
    entity: 4,
    entity_name: 5,
    description: 6,
    accounts: 7,
    check: 8,
    payment_date: 9,
    contabilium_id: 10,
    tipo_numero: 11,
    pto_de_venta: 13,
    numero: 12,
    received: 14,
    check_number: 15,
  };

  const [response, isLoading, , { isRefetching }] = useMongoAggregate({
    refetchOnWindowFocus: false,
    index: index,
    aggregate: [
      {
        $match: {
          "data.accounts.k":
            _.isArray(accounts) && accounts.length > 1
              ? { $in: accounts }
              : accounts?.[0] || accounts || { $exists: true },
          ...dateFilter,
        },
      },
      {
        $sort: {
          ["data.date"]: 1,
        },
      },
      {
        $project: {
          _id: 0,
          array: _.values(project),
        },
      },
      ...(skip
        ? [
            {
              $skip: skip,
            },
          ]
        : []),
      {
        $limit: 20000,
      },
    ],
  });

  useEffect(() => {
    if (response) {
      setData((data) => {
        const d = _.uniqBy(
          [
            ...response.map((d) => ({
              ..._.mapValues(project_keys, (v) => d.array[v]),
              index,
            })),
            ...(data || []),
          ],
          "id",
        );
        d?.length &&
          _.isInteger(d?.length / 20000) &&
          setSkip((s) => s + 20000);
        return d;
      });
    }
  }, [response]);

  return [
    data,
    isRefetching || isLoading || (skip && _.isInteger(data?.length / 20000)),
  ];
};
