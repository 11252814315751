/* eslint-disable react/prop-types */

import { Box, InputAdornment, TextField, Typography } from "@mui/material";
import React, { useState } from "react";
import EditIcon from "@mui/icons-material/Edit";
import IconButton from "@mui/material/IconButton";
import { useUserData } from "../../../../context/UserContext";
import { FieldValue, updateDoc } from "../../../../firebase/database";

export default function IvaEdit({ order }) {
  const [newIva, setNewIva] = useState("");
  const [editD, setEditD] = useState(false);
  const { uid } = useUserData();

  const saveIva = () => {

      const hit = Object.entries(order.items)
        .filter(
          ([, v]) =>
            v.q
        )
        .reduce((p, [id, v]) => {
          const restore = Number(newIva / 100) === v.oiva;
          return {
            ...p,
            [`items.${id}.iva`]: Number(newIva / 100),
            [`items.${id}.oiva`]: restore
              ? FieldValue.delete()
              : order.items[id].oiva || order.items[id].iva || 0,
            [`items.${id}.didLastIva`]: restore
              ? FieldValue.delete()
              : uid,
          };
        }, {});

      updateDoc({ indexName: "orders", id: order.objectID, hit }).then(() => {
        setNewIva("");
        setEditD(false);
      });
  };

  return (
    <>
      {!editD && (
        <IconButton onClick={() => setEditD(true)}>
          <EditIcon />
        </IconButton>
      )}
      {editD && (
        <Box sx={{ width: "100%" }}>
          <TextField
            fullWidth
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Typography style={{ fontWeight: "bold" }}>%</Typography>
                </InputAdornment>
              ),
            }}
            onKeyDown={(e) => e.key === "Enter" && saveIva()}
            size="small"
            placeholder="10"
            type="number"
            step={10}
            autoFocus
            value={newIva}
            onChange={(e) =>
              e.target.value?.length < 4 &&
              setNewIva(Number(e.target.value))
            }
            onBlur={e=>e.target.value?.length < 4 && e.target.value  ? saveIva(Number(e.target.value)) : setEditD(false)}
          />
        </Box>
      )}
    </>
  );
}
