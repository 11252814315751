/* eslint-disable */
import getObjects from "../../../../algolia/getObjects";
import Populate from "../../../../algolia/populate";

export function populateOrder(order) {
  let seconds = order?.timestamp._seconds
    ? order.timestamp?._seconds
    : order.timestamp?.seconds;
  let date = new Date(seconds * 1000);
  order.formatDate =
    date.getDate() + "/" + (date.getMonth() + 1) + "/" + date.getFullYear();

  let products = Object.keys(order.items);
  let items_promise = new Promise((resolve) => {
    let items = order.items;
    getObjects("products", products).then(async (pp) => {
      for (let i in items) {
        items[i].data = pp[i];
      }
      resolve(items);
    });
  });

  const populateProps = [
    { index: "users", field: "user" },
    { index: "users", field: "assembledBy" },
    { index: "users", field: "boxedBy" },
    { index: "users", field: "editedUsers" },
    { index: "users", field: "deletedUsers" },
    { index: "users", field: "editedQuantityUsers" },
    { index: "stores", field: "store" },
    { index: "carriers", field: "carriersData" },
    { index: "brands", field: "brands" },
    { index: "paymentOptions", field: "paymentMethod" },
    { index: "inventorynumbers", field: "inventorynumbers" },
    { index: "roadmaps", field: "roadmap" },
    { index: "priceList", field: "priceList" },
    { index: "shippings", field: "shipping" },
    { index: "shipments", field: "shipment" },
  ];

  let pop_promise = new Promise((resolve) => {
    items_promise.then((items) => {
      order.brands = [
        ...new Set(
          Object.keys(items)
            .map((i) => items[i].data?.brand)
            .filter((d) => d)
        ),
      ];
      order.carriersData = order.carriers;

      Populate({ hits: [order], props: populateProps }).then((hp) => {
        resolve(hp[0]);
      });
    });
  });

  return Promise.all([items_promise, pop_promise]).then(([items, order]) => ({
    ...order,
    items,
  }));
}
