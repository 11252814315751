import { useMongoAggregate } from "../../utils/mongoAggregate/useMongoAggregate";

export const useGetProductsExcel = () => {
  const [data, loading] = useMongoAggregate({
    index: "products",
    aggregate: [
      {
        $match: {
          "data.active": true,
          "data.box": 0,
          $or: [{ "data.showOnly": "d" }, { "data.showAll": true }],
        },
      },
      {
        $project: {
          "data.objectID": 1,
          "data.price": 1,
          "data.brandName": 1,
          "data.name": 1,
          "data.taste": 1,
          "data.thumbnail": 1,
          "data.unit": 1,
          "data.quantity": 1,
          "data.sellerCode": 1,
          "data.EAN": 1,
          "data.brand": 1,
          "data.specs": 1,
          "data.discounts": 1,
          "data.ranking": 1,
          "data.brandRanking": 1,
        },
      },
    ],
  });
  return [data, loading];
};
