import pdfFonts from "pdfmake/build/vfs_fonts";
import pdfMake from "pdfmake/build/pdfmake";

pdfMake.vfs = pdfFonts.pdfMake.vfs;

export function pdfCreate(template) {
  pdfMake.createPdf(template).open();
}

export function pdfDownload(template) {
  pdfMake.createPdf(template).download();
}

