import React from "react";
import { numberWithCommas } from "../../../../../components/Utils/NumberWithCommas";
import DiscountsEdit from "../../discountsEdit";
import EditShipping from "../../editShipping";
import { Box, Chip, Tooltip, Typography } from "@mui/material";
import PersonOutlineIcon from "@mui/icons-material/PersonOutline";
import { useDocs } from "../../../../../algolia/getObjects";
import DiscountIcon from "@mui/icons-material/Discount";
import IvaEdit from "../../utils/IvaEdit";

const footerData = ({ order }) => {
  const users = useDocs({
    ids: [order?.lastUserChangeShipping_cost],
    index: "users",
  });

  const userChange = users?.[order?.lastUserChangeShipping_cost];

  return [
    {
      label: "SubTotal con descuento",
      info: <>
     { `$ ${numberWithCommas(order.subTotal - (order.discount || 0), 0)}`}
      {order?.discountCode && (
          <Tooltip
            title={
              <Box sx={{ display: "flex", flexDirection: "column" }}>
                <Typography>{order.discountCode?.name}</Typography>

                <Typography>Código: {order.discountCode?.code}</Typography>
                <Typography>Descuento de: $ {numberWithCommas(order.discountCode?.discount, 2)}</Typography>
              </Box>
            }
          >
            <DiscountIcon sx={{marginLeft: 2, position:'relative', top: 4}} color="primary" />
          </Tooltip>
        )}
      </> ,
    },
    {
      label: "IVA",
      info: <>$ {numberWithCommas(order.iva, 0)}
        {" "}
        {!order.invoiceDriveId && <IvaEdit order={order} />}
      </>,
    },
    {
      label: "Descuentos",
      info: (
        <>
          $ {numberWithCommas(order.discount || 0, 0)}{" "}
          {!order.invoiceDriveId && <DiscountsEdit order={order} />}
        </>
      ),
    },
    {
      label: "Envío",
      info: (
        <>
          $ {numberWithCommas(order.shipping_cost, 0)}{" "}
          {!order?.invoiceDriveId ? <EditShipping order={order} /> : null}
          {order.lastUserChangeShipping_cost ? (
            <Chip
              icon={<PersonOutlineIcon />}
              label={userChange?.name}
              color="primary"
              size="small"
            />
          ) : null}
        </>
      ),
    },
    {
      label: "Total",
      info: `$ ${numberWithCommas(order.total, 0)}`,
    },
    // eslint-disable-next-line no-mixed-operators
    {
      label: "Costo Mercaderia",
      info: `$ ${numberWithCommas(order?.subTotalPurchase - order?.discountPurchase || 0, 0)} --- ${numberWithCommas(
        ((order?.subTotalPurchase - order?.discountPurchase || 0) / (order.subTotal - (order.discount || 0))) * 100,
        0,
      )}%`,
    },
  ];
};

export default footerData;
