import {
  Box,
  Collapse,
  List,
  ListItemIcon,
  ListItemText,
  useMediaQuery,
} from "@material-ui/core";
import { useEffect, useState } from "react";
import { ListItemButton, Stack } from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import { IconButton } from "@mui/material";
import { useDebouncer } from "../../../utils/hooks/useDebouncer";
import CloseIcon from "@mui/icons-material/Close";
import { ExpandLess, ExpandMore } from "@material-ui/icons";
export default function Sidebar({
  childrenOnClosed,
  delayOnOpen = 258,
  top = 0,
  mediaQuery = "600",
  children,
  openOnHover,
  defaultOpen = false,
  onChangeOpenState,
}) {
  const [isOpen, setIsOpen] = useState(defaultOpen);
  const [shouldOpenOnHover, setShouldOpenOnHover] = useState(openOnHover);
  const [isHovering, setIsHovering] = useState(false);
  const [debouncedValue] = useDebouncer(isHovering, delayOnOpen);

  useEffect(() => {
    if (debouncedValue) return setIsOpen(debouncedValue);
  }, [debouncedValue]);

  const matches = useMediaQuery(`(min-width:${mediaQuery}px)`);

  useEffect(() => {
    if (!matches && isOpen) return setIsOpen(false);
  }, [matches]);

  useEffect(() => {
    if (!isOpen && !shouldOpenOnHover) return setShouldOpenOnHover(true);
  }, [isOpen, shouldOpenOnHover]);

  useEffect(() => {
    onChangeOpenState(isOpen);
  }, [isOpen]);

  const closeSidebar = () => setIsOpen(false);
  const openSidebar = () => setIsOpen(true);
  const hovering = () => setIsHovering(true);
  const notHovering = () => setIsHovering(false);
  const onHoverIcons = () => {
    if (!shouldOpenOnHover || !openOnHover) return;
    hovering();
    return setShouldOpenOnHover(true);
  };

  const onMouseLeaveOpenedSidebar = () => {
    if (!shouldOpenOnHover || !matches) return;
    notHovering();
    return closeSidebar();
  };
  const onClickClosedSidebarButton = () => {
    openSidebar();
    return setShouldOpenOnHover(false);
  };
  return (
    <>
      {!matches && (
        <Stack
          position="fixed"
          sx={{
            borderBottom: "1.5px solid #cbd5e1",
            zIndex: 10,
            top: top,
            minHeight: "inherit",
            width: "100%",
            alignItems: "start",
            justifyContent: "center",
            paddingLeft: "16px",
            backgroundColor: "#f1f5f9",
          }}
        >
          <IconButton style={{ height: "fit-content" }} onClick={openSidebar}>
            <MenuIcon />
          </IconButton>
        </Stack>
      )}
      {matches && (
        <Box
          component="nav"
          position="fixed"
          width={"60px"}
          sx={{
            zIndex: 10,
            transition: ".15s ease",
            top,
            overflow: "hidden",
            borderRight: "1.5px solid #cbd5e1",
            height: "100%",
            backgroundColor: "#f1f5f9",
          }}
          style={{
            transform: "translate(0px,0px)",
          }}
        >
          <List>
            <Stack alignItems="center" justifyContent="center">
              <Stack
                alignItems="center"
                justifyContent="center"
                sx={{ marginLeft: 0 }}
              >
                <IconButton onClick={onClickClosedSidebarButton}>
                  <MenuIcon />
                </IconButton>
              </Stack>
              <Stack
                width="100%"
                alignItems="center"
                justifyContent="center"
                gap="2px"
                onMouseLeave={notHovering}
                onMouseOver={onHoverIcons}
              >
                {childrenOnClosed}
              </Stack>
            </Stack>
          </List>
        </Box>
      )}
      <Box
        component="nav"
        position="fixed"
        width={matches ? "inherit" : "230px"}
        sx={{
          zIndex: 10,
          transition: ".15s ease",
          top,
          overflow: "hidden",
          borderRight: "1.5px solid #cbd5e1",
          height: "100%",
          backgroundColor: "#f1f5f9",
        }}
        style={{
          transform: isOpen ? "translate(0px,0px)" : "translate(-260px,0px)",
        }}
      >
        <List>
          <Box onMouseLeave={onMouseLeaveOpenedSidebar}>
            {matches && (
              <Box sx={{ marginLeft: 9 }}>
                <IconButton onClick={closeSidebar}>
                  <CloseIcon />
                </IconButton>
              </Box>
            )}
            {!matches && (
              <Box sx={{ marginLeft: 9 }}>
                <IconButton onClick={closeSidebar}>
                  <CloseIcon />
                </IconButton>
              </Box>
            )}

            {children}
          </Box>
        </List>
      </Box>
    </>
  );
}

export function ListItemButtonCollapse({
  children,
  primary,
  rootIcon,
  defaultOpen = false,
}) {
  const [open, setOpen] = useState(defaultOpen);
  const handleClick = () => {
    setOpen(!open);
  };
  return (
    <>
      <ListItemButton onClick={handleClick}>
        <ListItemIcon>{rootIcon}</ListItemIcon>
        <ListItemText primary={primary} />
        {open ? <ExpandLess /> : <ExpandMore />}
      </ListItemButton>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          {children}
        </List>
      </Collapse>
    </>
  );
}
