// import { Box, useMediaQuery } from "@material-ui/core";
import { BrandCard } from "./BrandCard";
import { NavLink } from "react-router-dom";
import { VirtualList } from "../VirtualList";
import { useMediaQuery } from "@mui/material";
import imgurWebp from "../../../../components/Utils/Imgur/imgurWebp";

export function BrandsList({ data }) {
  const matches = useMediaQuery("(min-width:695px)");
  return (
    <VirtualList data={data}>
      {(brand) => (
        <NavLink
          style={{
            display: "inline-block",
            width: "100%",
            padding: "4px 0px",
            textDecoration: "none",
          }}
          to={`/providers/products?active=${
            brand.active ? "active" : "inactive"
          }&provider=${
            Array.isArray(brand.seller) ? brand.seller[0] : brand.seller
          }&brandName=${brand.name}`}
          key={brand.objectID}
        >
          <BrandCard
            mediaQuery={matches}
            description={brand.description}
            id={brand.objectID}
            imageUrl={imgurWebp(brand.image && brand.image)}
            activeProducts={brand.active_products}
            active={brand.active}
            availableProducts={brand.available_products}
            name={brand.name}
          />
        </NavLink>
      )}
    </VirtualList>
  );
}
