/* eslint-disable react/prop-types */
import TextField from "@material-ui/core/TextField";
import React, { useEffect, useState } from "react";
import { Box, Button, CircularProgress, Grid, List } from "@mui/material";
import { Link, useHistory } from "react-router-dom";
import queryString from "qs";
import _ from "lodash";
import { ProductList } from "./ProductList";
import { CheckboxFilters } from "./CheckboxFilters";
import useMediaQuery from "@mui/material/useMediaQuery";

const filters = [
  {
    field: "seller",
    options: {
      Vivet: "v",
      "Luz Azul": "luz_azul",
    },
    label: "Vendedor",
  },
  {
    field: "nostock",
    options: {
      "En Stock en Market": false,
      "Sin Stock en Market": true,
    },
    label: "Stock",
  },
  {
    field: "inventoryOnbin",
    options: {
      "Agotado en Bins": false,
      "Con Stock en Bins": true,
    },
    label: "Bins",
  },
  {
    field: "inventoryAvailable",
    options: {
      Disponible: true,
      "No Dispnible": false,
    },
    label: "Disponibilidad",
  },
  {
    field: "active",
    options: {
      Activo: true,
      "No Activo": false,
    },
    label: "Activo",
  },
];

export default function ProductsControlSearchBox({ scrollToTop }) {
  const history = useHistory();
  const parsedHash = queryString.parse(
    history.location.search?.replace("?", ""),
  );
  const searchText = parsedHash.search || "";
  const facetFilters = _.mapValues(parsedHash.facetFilters || {}, (a) =>
    a === "false" ? false : a === "true" || a,
  );
  const facetFiltersString = _.isEmpty(facetFilters)
    ? ""
    : JSON.stringify(facetFilters);
  const sort = _.mapValues(parsedHash.sort || {}, (a) => Number(a));
  const sortString = _.isEmpty(sort) ? "" : JSON.stringify(sort);

  const [data, setData] = useState({});
  const items = data?.hits || [];

  const showDownloadCsv = useMediaQuery("(min-width:600px)");

  useEffect(() => {
    // dispara la bandera que ejecuta el scroll To top en el componente padre
    scrollToTop?.();
  }, [searchText]);
  const saveText = (query) => {
    history.replace({
      search: queryString.stringify(
        _.omitBy({ ...parsedHash, ...query }, (a) => !a),
      ),
    });
  };

  const setFacetFilters = (e) => saveText({ facetFilters: e(facetFilters) });
  const setSort = (e) => saveText({ sort: e(sort) });

  useEffect(() => {
    let active = true;
    const inventoryFetch = () =>
      active &&
      fetch(
        `${
          "//stock-p-c059a3e3d873.herokuapp.com/products/inventory" + "?query="
        }${searchText}&facets=${JSON.stringify(filters.map((f) => f.field))}${
          facetFiltersString ? `&facetFilters=${facetFiltersString}` : ""
        }${sortString ? `&sort=${sortString}` : ""}`,
      )
        .then((response) => response.json())
        // eslint-disable-next-line no-shadow
        .then((data) => {
          if (active) setData({ ...data, query: searchText || "" });
        })
        .catch((e) => active && setData({ error: e, query: searchText || "" }));
    if (searchText) {
      setTimeout(inventoryFetch, 1000);
    } else inventoryFetch();
    // eslint-disable-next-line no-return-assign
    return () => (active = false);
  }, [searchText, facetFiltersString, sortString]);

  return (
    <Box>
      <TextField
        style={{ position: "fixed", zIndex: 2, background: "#fff" }}
        onKeyPress={(e) => {
          if (e.key === "Enter") e.target.blur();
        }}
        fullWidth
        label="Buscar producto"
        value={searchText}
        onChange={(e) => saveText({ search: e.target.value })}
      />
      {searchText !== data?.query ? (
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          height="100vh"
          width="100%"
        >
          <CircularProgress />
        </Box>
      ) : (
        <Grid container direction="row">
          <CheckboxFilters
            {...{
              filters,
              data,
              setFacetFilters,
              facetFilters,
              sort,
              setSort,
            }}
          />
          {showDownloadCsv ? (
            <Button
              sx={(theme) => ({
                position: "absolute",
                right: 10,
                top: 130,
                zIndex: 2,
                [theme.breakpoints.down(663)]: {
                  display: "none",
                },
                [theme.breakpoints.down(1110)]: {
                  top: "250",
                },
              })}
              href={
                '//stock-p-c059a3e3d873.herokuapp.com/products/inventory?sort={"expirationdate":1}&retriveAll=true&type=csv'
              }
              variant="outlined"
            >
              Descargar CSV
            </Button>
          ) : null}
          <Grid item xs={12} style={{ marginTop: "50px" }}>
            <List
              style={{
                width: "fit-content",
                margin: "auto",
                minWidth: "min( 100%, 450px )",
              }}
            >
              {items?.map((product) => (
                <Box
                  style={{ textDecoration: "unset", color: "black" }}
                  component={Link}
                  to={`productsCheck/${product.objectID}`}
                  key={product.objectID}
                >
                  <ProductList product={product} />
                </Box>
              ))}
            </List>
          </Grid>
        </Grid>
      )}
    </Box>
  );
}
