/* eslint-disable no-unsafe-optional-chaining */
/* eslint-disable react/prop-types */

import { useDocumentData } from "../../../firebase/database";
import * as React from "react";
import { useState } from "react";

// Grafico
import {
  CartesianGrid,
  Legend,
  Line,
  LineChart,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import _ from "lodash";
import moment from "moment";
import {
  Box,
  Checkbox,
  Container,
  FormControlLabel,
  FormGroup,
  Grid,
  Typography,
} from "@mui/material";
import { DataGridPremium, GridToolbar } from "@mui/x-data-grid-premium";
import { numberWithCommas } from "../../../components/Utils/NumberWithCommas";
import useSalesByProduct from "../../../components/Utils/salesByProduct";
import SalesByCustomers from "./salesByCustomers";
import useStyles from "./styles";
import brandColumns from "./brandColumns";
import { MarkdownEditor } from "../products/utils/MarkdownEditor";
import { MuiCalendar } from "../../../utils/MuiCalendar";

// eslint-disable-next-line no-undef
require("moment/locale/es");

moment.locale("es");

function BrandPage({ objectID }) {
  const classes = useStyles();
  const [brandsDoc] = useDocumentData("brands", objectID);

  // Gráfico
  const [selectionModel, setSelectionModel] = React.useState([]);
  const [date, setDate] = useState([moment(), moment().add(1, "day")]);
  const [priceLists, setPriceLists] = useState([]);
  const stepsInHours = 24;
  const from = date?.[0]?.format("YYYY-MM-DD");
  const to = date?.[1]?.clone()?.add(1, "day")?.format("YYYY-MM-DD");
  const [rows, loading] = useSalesByProduct({
    from,
    to,
    brands: [objectID],
    priceLists,
    populate: true,
  });

  const rowsFilter = rows?.filter?.(
    (a) => !selectionModel.length || selectionModel.includes(a.objectID)
  );

  return (
    <>
      <Container>
        <Box
          display="flex"
          alignItems="center"
          gap={10}
          marginLeft={0}
          className={classes.productDetail}
        >
          <Typography>
            <h2>{brandsDoc?.name}</h2>
          </Typography>
        </Box>

        <Box className={classes.containerInfo}>
          <Box className={classes.containerImage}>
            <img
              src={brandsDoc?.image}
              className={classes.image}
              alt={brandsDoc?.name}
            />
            <Box className={classes.containerMasInfo}>
              <Box />
            </Box>
          </Box>

          <Box className={classes.description}>
            <Box>
              <Typography className={classes.typography}>
                Descripción:
              </Typography>
              <MarkdownEditor
                indexName={"brands"}
                defaultData={brandsDoc?.description}
                fieldToUpdate={"description"}
                idToUpdate={objectID}
              />
            </Box>
          </Box>
        </Box>
      </Container>

      <Container>
        <Typography>
          <h3 className={classes.titleH3}>Unidades vendidas</h3>
        </Typography>

        <Box className={classes.containerOrders}>
          <Box>
            <Grid container>
              <Grid item xs={12}>
                <MuiCalendar direction="row" date={date} action={setDate} />
              </Grid>
              {console.log(date)}
              <Grid item xs={8}>
                <Box className={classes.containerTypographyOrders}>
                  <Typography className={classes.typographyOrders}>
                    Unidades Vendidas:
                  </Typography>{" "}
                  {numberWithCommas(
                    rowsFilter?.reduce((p, a) => p + a?.sales, 0),
                    0
                  )}
                </Box>
                <Box className={classes.containerTypographyOrders}>
                  <Typography className={classes.typographyOrders}>
                    Lista General: $
                  </Typography>{" "}
                  {numberWithCommas(
                    rowsFilter?.reduce(
                      (p, a) => p + (a.priceLists?.g?.total || 0),
                      0
                    ),
                    0
                  )}
                </Box>
                <Box className={classes.containerTypographyOrders}>
                  <Typography className={classes.typographyOrders}>
                    Lista Dietética: $
                  </Typography>{" "}
                  {numberWithCommas(
                    rowsFilter?.reduce(
                      (p, a) => p + (a.priceLists?.d?.total || 0),
                      0
                    ),
                    0
                  )}
                </Box>
                <Box className={classes.containerTypographyOrders}>
                  <Typography className={classes.typographyOrders}>
                    Total: $
                  </Typography>{" "}
                  {numberWithCommas(
                    rowsFilter?.reduce((p, a) => p + a?.total, 0),
                    0
                  )}
                </Box>
              </Grid>
            </Grid>
            <Box>
              <FormGroup style={{ display: "flex", flexDirection: "inherit" }}>
                <FormControlLabel
                  control={
                    <Checkbox
                      onClick={(e) =>
                        setPriceLists((a) =>
                          !e.target.checked ? _.without(a, "g") : [...a, "g"]
                        )
                      }
                    />
                  }
                  label="Lista General"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      onClick={(e) =>
                        setPriceLists((a) =>
                          !e.target.checked ? _.without(a, "d") : [...a, "d"]
                        )
                      }
                    />
                  }
                  label="Lista Dieteticas"
                />
              </FormGroup>
            </Box>
          </Box>
          <Box className={classes.containerGraphic}>
            <Typography>
              <h4 className={classes.typographyGraphic}>Gráfico de ventas</h4>
            </Typography>
            <Grid>
              {rowsFilter?.length && (
                <ChartTime
                  data={rowsFilter
                    .map((a) => a.steps)
                    .reduce((p, a) =>
                      p.map((c, i) => _.mapValues(c, (d, k) => d + a[i][k]))
                    )
                    .map((a, i) => ({
                      ..._.mapValues(a, _.toInteger),
                      name: moment(from)
                        .add(stepsInHours * i, "hours")
                        .format(`DD/MM${stepsInHours !== 24 ? " HH:mm" : ""}`),
                    }))}
                />
              )}
            </Grid>
            <div>
              <div style={{ height: 400, width: "100%" }}>
                <DataGridPremium
                  loading={loading}
                  getRowId={(row) => row.objectID}
                  rows={rows}
                  columns={brandColumns}
                  pageSize={5}
                  components={{ Toolbar: GridToolbar }}
                  rowsPerPageOptions={[200, 100, 50, 20, 10]}
                  checkboxSelection
                  onSelectionModelChange={(newSelectionModel) => {
                    setSelectionModel(newSelectionModel);
                  }}
                  selectionModel={selectionModel}
                />
              </div>
            </div>
          </Box>
        </Box>
        <SalesByCustomers objectID={objectID} />
      </Container>
    </>
  );
}

// Funcion gráfico
export function ChartTime({ data }) {
  return (
    <LineChart
      width={730}
      height={250}
      data={data}
      margin={{
        top: 5,
        right: 30,
        left: 20,
        bottom: 5,
      }}
    >
      <CartesianGrid strokeDasharray="3 3" />
      <XAxis dataKey="name" />
      <YAxis tickFormatter={(a) => `$${numberWithCommas(a, 0)}`} />
      <Tooltip />
      <Legend />
      <Line type="monotone" dataKey="total" stroke="#8884d8" />
      {/* <Line type="monotone" dataKey="uv" stroke="#82ca9d" /> */}
    </LineChart>
  );
}

export default BrandPage;
