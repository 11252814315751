import {
  Box,
  Breadcrumbs,
  Button,
  Divider,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Stack,
  Typography,
} from "@mui/material";
import { useMongoAggregate } from "../mongoAggregate/useMongoAggregate";
import { Fragment, useState } from "react";
import { updateDoc } from "../../firebase/database";
import _ from "lodash";
import { useEffect } from "react";
import CloseIcon from "@mui/icons-material/Close";
import { useMemo } from "react";

export const CategorysEditor = ({
  productID,
  onSuccess = () => {},
  defaultSpecs = [],
  showDefaults = false,
  openEditor = false,
}) => {
  const [codeSelected, setCodeSelected] = useState();

  const [googleSpecs] = useMongoAggregate({
    index: "googleProductCategory",
    aggregate: [
      {
        $match: { "data.code": { $in: defaultSpecs } },
      },
      {
        $project: { name: "$data.name", code: "$data.code", _id: 0 },
      },
    ],
  });

  const formattedGoogleCategories = useMemo(
    () =>
      googleSpecs?.length
        ? defaultSpecs?.map((specCode) =>
            googleSpecs?.find((s) => s?.code == specCode),
          )
        : [],
    [googleSpecs],
  );
  const [categoriesSelected, setCategoriesSelected] = useState(
    formattedGoogleCategories?.length ? formattedGoogleCategories : [],
  );

  useEffect(() => {
    setCategoriesSelected(formattedGoogleCategories || []);
    formattedGoogleCategories?.length &&
      setCodeSelected(
        formattedGoogleCategories?.[formattedGoogleCategories?.length - 1]
          ?.code,
      );
  }, [formattedGoogleCategories, openEditor]);

  const handleSpec = (data) => {
    const { code, name } = data || {};
    setCodeSelected(code);

    if (categoriesSelected.find((a) => a.code === code)) {
      const index = categoriesSelected.findIndex((a) => a.code === code);
      setCategoriesSelected((prev) =>
        prev.map((b, indx) => (indx <= index ? b : null)).filter((a) => a),
      );
    }

    setCategoriesSelected((prev) =>
      _.uniqBy([...prev, { code, name }], "code"),
    );
  };

  const handleConfirmCategories = async () => {
    if (categoriesSelected?.length && productID) {
      await updateDoc({
        indexName: "products",
        id: productID,
        hit: {
          ["googleProductCategory"]: categoriesSelected.map((a) => a.code),
        },
      });
      onSuccess();
    }
  };

  const [categories] = useMongoAggregate({
    index: "googleProductCategory",
    aggregate: [
      ...(codeSelected
        ? [
            {
              $match: {
                "data.mother": codeSelected,
              },
            },
          ]
        : [
            {
              $match: { "data.level": 1 },
            },
          ]),
      {
        $project: {
          level: "$data.level",
          code: "$data.code",
          objectID: "$data.objectID",
          mother: "$data.mother",
          name: "$data.name",
          _id: 0,
        },
      },
    ],
  });

  return (
    <Grid sx={{ minHeight: openEditor ? 400 : "inherit" }} item xs={12}>
      {openEditor && (
        <>
          <Breadcrumbs sx={{ maxWidth: 500 }} aria-label="breadcrumb">
            {categoriesSelected?.length ? (
              <IconButton>
                <CloseIcon
                  onClick={() => {
                    setCodeSelected(null);
                    setCategoriesSelected([]);
                  }}
                  style={{ cursor: "pointer" }}
                  color="secondary"
                />
              </IconButton>
            ) : null}
            {categoriesSelected.map((cat) => (
              <Typography
                onClick={() => handleSpec({ code: cat.code, name: cat.name })}
                sx={{
                  "&:hover": {
                    textDecoration:
                      codeSelected !== cat.code ? "underline" : "none",
                  },
                  cursor: codeSelected !== cat.code ? "pointer" : "auto",
                }}
                color={codeSelected !== cat.code ? "primary" : "black"}
                key={cat.code}
              >
                {cat.name}
              </Typography>
            ))}
          </Breadcrumbs>
          <Box
            sx={{
              minWidth: 400,
              maxWidth: 400,
              maxHeight: 300,
              overflowY: "auto",
            }}
          >
            <List dense={true}>
              {(categories || [])?.map((cat, index) => (
                <Fragment key={cat.objectID}>
                  <ListItem
                    sx={{
                      "&:hover": {
                        backgroundColor: "rgba(0,0,0,0.1)",
                      },
                      cursor: "pointer",
                    }}
                    onClick={() =>
                      handleSpec({ code: cat.code, name: cat.name })
                    }
                  >
                    <ListItemText primary={cat.name} />
                  </ListItem>
                  {index !== categories?.length - 1 && (
                    <Divider component="li" />
                  )}
                </Fragment>
              ))}
            </List>
          </Box>
          {categoriesSelected?.length ? (
            <Button
              onClick={handleConfirmCategories}
              sx={{ margin: 1 }}
              variant="outlined"
              color="primary"
            >
              Confirmar categorias
            </Button>
          ) : null}
        </>
      )}
      {showDefaults && formattedGoogleCategories?.length ? (
        <Stack
          sx={{ marginY: 1 }}
          spacing={1}
          direction="row"
          useFlexGap
          flexWrap="wrap"
        >
          <Breadcrumbs>
            {formattedGoogleCategories.map((spec) => (
              <Typography color={"primary"} key={spec.code}>
                {spec.name}
              </Typography>
            ))}
          </Breadcrumbs>
        </Stack>
      ) : null}
    </Grid>
  );
};
