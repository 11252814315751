import { useState } from "react";
import { LoadingButton } from "@mui/lab";
import {
  Box,
  Button,
  Checkbox,
  Chip,
  Grid,
  List,
  ListItem,
  ListItemText,
  Stack,
  TextField,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import CloseIcon from "@mui/icons-material/Close";
import AddIcon from "@mui/icons-material/Add";
import { useCallback } from "react";
import { useEffect } from "react";
import _ from "lodash";
import { useMemo } from "react";
import { updateDoc } from "../../../../firebase/database";

const containsQuery = (spec, query) =>
  spec?.name?.toLowerCase().includes(query?.toLowerCase()) || "";

const startsWithQuery = (spec, query) =>
  spec?.name?.toLowerCase().startsWith(query?.toLowerCase()) || "";

const ChipSelector = ({
  defaultChips,
  label,
  save = () => {},
  defaultCheckedChips = [],
  addNew = {},
  searchChips = false,
}) => {
  const [open, setOpen] = useState(false);
  const [checked, setChecked] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [openAddNew, setOpenAddNew] = useState(false);
  const [query, setQuery] = useState("");
  const [newChip, setNewChip] = useState("");

  const { key, indexName, objectID } = addNew || {};

  const handleToggle = useCallback(
    (value) => {
      if (Object.keys(checked || {})?.includes(value.objectID)) {
        setChecked(_.omit(checked, value.objectID));
        return;
      }

      setChecked((prev) => ({ ...prev, [value.objectID]: value }));
    },
    [checked],
  );

  useEffect(() => {
    if (isLoading || !defaultChips?.length) return;

    if (defaultChips?.length) {
      const defaultChecked = defaultCheckedChips
        ?.filter((a) => a?.objectID?.length)
        ?.reduce((p, acc) => ({ ...p, [acc?.objectID]: acc }), {});

      setChecked(defaultChecked);
    }
  }, [defaultChips, defaultCheckedChips, open]);

  const saveChanges = async () => {
    setIsLoading(true);
    await save(checked);
    setIsLoading(false);
    setOpen(false);
  };

  const filteredChips = useMemo(() => {
    if (query?.length) {
      const filtered = defaultChips.filter(
        (spec) => containsQuery(spec, query) || startsWithQuery(spec, query),
      );

      return _.orderBy(
        filtered,
        [(spec) => (checked?.[spec?.objectID] ? 0 : 1)],
        ["asc"],
      );
    } else {
      return _.orderBy(
        defaultChips,
        [(spec) => (checked?.[spec?.objectID] ? 0 : 1)],
        ["asc"],
      );
    }
  }, [query, defaultChips, checked]);

  const addNewChip = async (e) => {
    if (indexName?.length) {
      await updateDoc({
        indexName,
        id: objectID,
        hit: {
          [key]: [
            ...defaultCheckedChips.map((a) => a.objectID),
            e.target.value,
          ],
        },
      });
      setOpenAddNew(false);
    }
  };

  return (
    <Box>
      <Stack
        direction={"row"}
        alignItems={"center"}
        style={{ marginTop: 10 }}
        spacing={2}
      >
        <Button
          onClick={() => setOpen(!open)}
          variant={open ? "outlined" : "contained"}
          color={open ? "secondary" : "primary"}
          style={{ marginTop: 10, marginBottom: 10 }}
          startIcon={open ? <CloseIcon /> : <EditIcon />}
        >
          {open ? "Cancelar" : label}
        </Button>

        {open ? (
          <LoadingButton
            loading={isLoading}
            onClick={() => saveChanges()}
            variant="contained"
            color="primary"
            style={{ marginTop: 10, marginBottom: 10 }}
          >
            Confirmar
          </LoadingButton>
        ) : null}
      </Stack>
      {indexName?.length && open && (
        <Button
          sx={{ margin: 2 }}
          onClick={() => setOpenAddNew(true)}
          variant="outlined"
          startIcon={<AddIcon />}
        >
          Agregar
        </Button>
      )}
      {openAddNew && (
        <TextField
          sx={{ marginY: 2 }}
          size="small"
          value={newChip}
          onChange={(e) => setNewChip(e.target.value)}
          onBlur={addNewChip}
        />
      )}
      {open ? (
        <Grid container>
          {searchChips && (
            <Grid item xs={6}>
              <TextField
                size="small"
                placeholder="Buscar..."
                value={query}
                onChange={(e) => setQuery(e.target.value)}
              />
              <List
                dense
                sx={{
                  width: "100%",
                  maxWidth: 360,
                  bgcolor: "background.paper",
                  maxHeight: 400,
                  overflowY: "auto",
                }}
              >
                {(filteredChips || [])?.map((value) => {
                  const labelId = `checkbox-list-label-${value}`;
                  const isChecked = Object.keys(checked || {})?.includes(
                    value.objectID,
                  );

                  return (
                    <ListItem
                      key={value.objectID}
                      secondaryAction={
                        <Checkbox
                          edge="start"
                          checked={isChecked}
                          disabled={value?.auto}
                          onChange={() => !value?.auto && handleToggle(value)}
                          tabIndex={-1}
                          disableRipple
                          inputProps={{ "aria-labelledby": labelId }}
                        />
                      }
                      disablePadding
                    >
                      <ListItemText id={labelId} primary={`${value.name}`} />
                    </ListItem>
                  );
                })}
              </List>
            </Grid>
          )}
          <Grid item xs={6}>
            <Stack spacing={1} direction="row" useFlexGap flexWrap="wrap">
              {Object.values(checked || {})?.map((item) => (
                <Chip
                  onDelete={() => !item.auto && handleToggle(item)}
                  key={item?.objectID}
                  label={item?.name}
                  disabled={item?.auto}
                  variant="outlined"
                  color="secondary"
                />
              ))}
            </Stack>
          </Grid>
        </Grid>
      ) : (
        <Grid item xs={6}>
          <Stack spacing={1} direction="row" useFlexGap flexWrap="wrap">
            {Object.values(checked || {})?.map((item) => (
              <Chip
                variant="outlined"
                key={item?.objectID}
                label={item?.name}
                color="primary"
              />
            ))}
          </Stack>
        </Grid>
      )}
    </Box>
  );
};

export default ChipSelector;
