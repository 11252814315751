import { updateDoc } from "../../../../firebase/database";
import _ from "lodash";
import { Box } from "@mui/material";
import { useDocs } from "../../../../algolia/getObjects";
import ChipSelector from "./ChipSelector";

export const ChangeCategory = ({ defaultSpecs, productID }) => {
  const specsObj = useDocs({
    index: "specs",
  });
  const specs = _.map(specsObj, (v, k) => ({ ...v, objectID: k }));

  const defaultCheckedSpecs = defaultSpecs?.map((specID) =>
    specs?.find((a) => a.objectID === specID),
  );

  const saveChanges = async (checked) => {
    return await updateDoc({
      indexName: "products",
      id: productID,
      hit: { ["specs"]: Object.keys(checked || {}) },
    });
  };

  return (
    <Box>
      <ChipSelector
        defaultChips={specs}
        label={"Categorias"}
        save={saveChanges}
        defaultCheckedChips={defaultCheckedSpecs}
        searchChips
      />
    </Box>
  );
};
