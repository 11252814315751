/* eslint-disable no-undef */
/* eslint-disable react/prop-types */
import React, { useEffect, useState } from "react";
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import CircularProgress from "@material-ui/core/CircularProgress";
import { useGoogleMapsApi } from "react-hook-google-maps";
import useStyles from "./styles";

// eslint-disable-next-line max-len
const displaySuggestions = function displaySuggestionsfn(
  predictions,
  status,
  google,
  setLoading,
  setAddresses
) {
  if (status !== google?.maps.places.PlacesServiceStatus.OK) {
    return;
  }
  setLoading(false);
  setAddresses(predictions);
};

export default function NewAddress({
  Template,
  setNewAddressValue,
  autoFocus = true,
  label = "Nueva Direccion",
}) {
  const classes = useStyles();
  const [inpMapValue, setInpMapValue] = useState("");
  const [addresses, setAddresses] = useState([]);
  const [loading, setLoading] = useState(false);
  const google = useGoogleMapsApi(
    `${process.env.REACT_APP_MAPS_API}&libraries=places,geometry,drawings`
  );

  function geocoderF(place) {
    if (!window.geocoder) window.geocoder = new window.google.maps.Geocoder();
    return new Promise((resolve, error) => {
      window.geocoder.geocode({ placeId: place }, (results, status) => {
        if (status !== "OK") {
          // window.alert('Geocoder failed due to: ' + status);
          error();
          return;
        }
        // console.log(results[0]);
        resolve(results[0]);
      });
    });
  }

  useEffect(() => {
    let active = true;
    if (window.google && inpMapValue.length > 2 && !/^\d+$/.test(inpMapValue)) {
      if (!window.AutocompleteSessionToken) {
        window.AutocompleteSessionToken =
          new window.google.maps.places.AutocompleteSessionToken();
      }
      if (!window.AutocompleteService) {
        window.AutocompleteService =
          new window.google.maps.places.AutocompleteService();
      }
      setLoading(true);
      setTimeout(
        () =>
          active &&
          window.AutocompleteService.getPlacePredictions(
            {
              input: `argentina ${inpMapValue}`,
              sessionToken: window.AutocompleteSessionToken,
            },
            (predictions, status) =>
              displaySuggestions(
                predictions,
                status,
                google,
                setLoading,
                setAddresses
              )
          ),
        500
      );
    }
    return () => {
      active = false;
      setLoading(false);
    };
  }, [inpMapValue, google]);

  async function AddNewAddressHandle(placeId, setNewAddressValue2) {
    const place = await geocoderF(placeId);
    // const shippingsContains = checkContainsLocation(place.geometry.location,shippings);

    const nnaa = JSON.parse(JSON.stringify(place));

    const newAddress = {
      geometry: nnaa.geometry,
      name: nnaa.name || "",
      formatted_address: nnaa.formatted_address,
      place_id: nnaa.place_id,
      address_components: nnaa.address_components,
    };

    setNewAddressValue2(newAddress);
  }

  return (
    <>
      {/* {!showMap && <img src={mapEmptyImg} alt={"map"}/>} */}
      {/* {GoogleMap} */}
      {/* <MapLeaf/> */}

      <TextField
        label={label}
        className={classes.inpMap}
        value={inpMapValue}
        onChange={(e) => setInpMapValue(e.target.value)}
        id="inpMap"
        onKeyPress={(e) => e.key === "Enter" && e.target.blur()}
        //         rowsMax="1"
        autoFocus={autoFocus}
        variant="outlined"
        InputProps={
          loading
            ? {
                endAdornment: (
                  <InputAdornment position="end">
                    <CircularProgress color="inherit" size={20} />
                  </InputAdornment>
                ),
              }
            : {}
        }
      />

      {addresses.map((a) => (
        <Template
          key={a.place_id}
          onClick={() => AddNewAddressHandle(a.place_id, setNewAddressValue)}
          collection="addresses"
          title=""
          address={a.structured_formatting.main_text}
          details={a.structured_formatting.secondary_text}
          id={a.place_id}
        />
      ))}
    </>
  );
}
