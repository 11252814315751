import { useEffect, useState } from "react";

export default function useSalesByProduct({
  populate = false,
  from = "2022-10-01",
  to = "2022-10-06",
  stepsInHours = 24,
  enabled = true,
  priceLists = [],
  items = [],
  brands = [],
  preloading = false,
  seller = "v",
} = {}) {
  const [rows, setRows] = useState(false);
  const [loading, setLoading] = useState(false);
  const bodyString = JSON.stringify({
    from,
    to,
    brands,
    stepsInHours,
    priceLists,
    items,
    populate,
    seller,
  });

  useEffect(() => {
    let active = true;
    if(enabled) {

    setLoading(true);
    if (!preloading) {
      fetch("https://us-central1-vivet-web.cloudfunctions.net/salesByProduct", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: bodyString,
        redirect: "follow",
      })
        .then((res) => res.json())
        .then((data) => {
          if (active) setRows(data);
          if (active) setLoading(false);
        });
    }

    }
    // eslint-disable-next-line no-return-assign
    return () => (active = false);
  }, [bodyString, preloading, enabled]);

  return [rows, loading];
}
