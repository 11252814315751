/* eslint-disable guard-for-in */
/* eslint-disable no-restricted-syntax */
/* eslint-disable no-nested-ternary */
/* eslint-disable no-param-reassign */
/* eslint-disable prefer-destructuring */
/* eslint-disable react/prop-types */
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/styles";
import IconButton from "@material-ui/core/IconButton";
import { useSnackbar } from "notistack";
import { Modal } from "@material-ui/core";
import Widget from "../../Widget";
import EditForm from "../../CardFirestore/DataCard/components/EditForm";
import { Button } from "../../Wrappers";
import Populate from "../../../algolia/populate";
import { updateDoc } from "../../../firebase";
import { useUserData } from "../../../context/UserContext";

const useStyles = makeStyles((theme) => ({
  modal: {
    position: "absolute",
    width: "80%",
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 2, 2),
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    maxHeight: "90%",
    overflowY: "scroll",
  },
  iframe: {
    height: "-webkit-fill-available",
    width: "100%",
  },
}));

export default function UserUpdateModal({ closeModal }) {
  const getuser = useUserData();
  const [open, setOpen] = useState(true);
  const [fields, setFields] = useState([]);
  const [values, setValues] = useState({});
  const [error, setError] = useState({});
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    (async () => {
      const user = JSON.parse(JSON.stringify(getuser));
      const pUser = await Populate({
        hits: [user],
        props: [
          { index: "roles", field: "roles" },
          { index: "areas", field: "areas" },
          {
            index: "roles",
            field: "currentRole",
          },
          { index: "areas", field: "currentArea" },
        ],
      }).then((a) => a[0]);

      setValues(pUser);

      const ff = [
        {
          name: "Area",
          field: "currentArea",
          options: {
            options: Object.keys(pUser.areas || {}).map((r) => pUser.areas[r]),
            type: "autocomplete",
            field: "name",
          },
        },
        {
          name: "Role",
          field: "currentRole",
          options: {
            options: Object.keys(pUser.roles || {}).map((r) => pUser.roles[r]),
            type: "autocomplete",
            field: "title",
          },
        },
      ];
      setFields(ff);
    })();
  }, [getuser]);

  const update = ({ field, value, ...r }) => {

    const hit = getuser;
    const nhit = r.hit;
    if (!field) field = Object.keys(nhit)[0];
    const ovalue = field.includes(".")
      ? field.split(".").reduce((p, a) => p[a] || "", hit)
      : hit[field];
    const ohit = {
      [field]:
        ovalue === undefined || ovalue === null
          ? ""
          : typeof ovalue !== "object"
          ? ovalue
          : ovalue.objectID || Object.keys(ovalue),
    };
    if (nhit)
      for (const f in nhit)
        ohit[f] =
          hit[f] === undefined
            ? ""
            : typeof hit[f] !== "object"
            ? hit[f]
            : hit[f].objectID || Object.keys(hit[f]);
    updateDoc({
      indexName: "users",
      id: getuser.uid,
      hit: nhit || { [field]: value },
    }).then(() =>
      enqueueSnackbar(
        field === "delete"
          ? "Documento Borrado"
          : `${
              fields.find((f) => f.field === field).name || field
            } actualizado.`,
        {
          action: (
            <>
              {field !== "delete" && (
                <Button
                  color="secondary"
                  size="small"
                  onClick={() =>
                    updateDoc({
                      indexName: "users",
                      id: getuser.uid,
                      hit: ohit,
                    })
                  }
                >
                  DESHACER
                </Button>
              )}
              <IconButton size="small" aria-label="close" color="inherit">
                {/* <CloseIcon fontSize="small" /> */}
              </IconButton>
            </>
          ),
        }
      )
    );
  };

  return (
    <Modal
      open={open}
      onClose={() => {
        setOpen(false);
        closeModal();
      }}
    >
      <div className={classes.modal}>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <Widget header={<>Actualizar Usuario</>}>
            <div>
              <EditForm 
                 error={error}
                 setError={setError}
              hit={values} fields={fields} update={update} />
            </div>
          </Widget>
        </MuiPickersUtilsProvider>
      </div>
    </Modal>
  );
}
