import { Box, Button } from "@mui/material";
import ChipSelector from "./ChipSelector";
import { updateDoc } from "../../../../firebase/database";

export const ChangeTags = ({ defaultTags = [], productID }) => {
  const tags = defaultTags?.map((tag) => ({ objectID: tag, name: tag }));

  const saveChanges = async (checked) => {
    return await updateDoc({
      indexName: "products",
      id: productID,
      hit: { ["tags"]: Object.keys(checked || {}) },
    });
  };

  const generateWithAI = async () => {
    await updateDoc({
      indexName: "products",
      id: productID,
      hit: { createTags: true, algoliaUpdated: false },
    });
  };

  return (
    <Box sx={{ marginTop: 4 }}>
      <ChipSelector
        label="Tags"
        filteredChips={tags}
        defaultChips={tags}
        defaultCheckedChips={tags}
        save={saveChanges}
        addNew={{ indexName: "products", key: "tags", objectID: productID }}
      />
      {!tags?.length && (
        <Button
          onClick={generateWithAI}
          startIcon={
            <img
              alt="gemini ai icon"
              src="https://www.gstatic.com/lamda/images/gemini_sparkle_v002_d4735304ff6292a690345.svg"
            />
          }
        >
          Generar con IA
        </Button>
      )}
    </Box>
  );
};
