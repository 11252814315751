import { useRef, memo } from "react";
import { useWindowVirtualizer } from "@tanstack/react-virtual";

/* eslint-disable react/display-name */
export const VirtualList = memo(({ data, estimateSize = 100, children }) => {
  const parentRef = useRef(null);
  const virtualizedList = useWindowVirtualizer({
    count: data.length,
    estimateSize: () => estimateSize,
    overscan: 0,
    scrollMargin: parentRef.current?.offsetTop ?? 0,
  });
  const virtualizedItems = virtualizedList.getVirtualItems();
  return (
    <>
      <div ref={parentRef} className="List">
        <div
          style={{
            height: virtualizedList.getTotalSize(),
            width: "100%",
            position: "relative",
          }}
        >
          {virtualizedItems.map((item) => {
            const props = data[item.index];
            return (
              <div
                key={item.key}
                data-index={item.index}
                className={item.index % 2 ? "ListItemOdd" : "ListItemEven"}
                ref={virtualizedList.measureElement}
                style={{
                  position: "absolute",
                  top: 0,
                  left: 0,
                  width: "100%",
                  transform: `translateY(${
                    item.start - virtualizedList.options.scrollMargin
                  }px)`,
                }}
              >
                {children(props, item.index)}
              </div>
            );
          })}
        </div>
      </div>
    </>
  );
});
