/* eslint-disable no-nested-ternary */
/* eslint-disable guard-for-in */
/* eslint-disable no-param-reassign */
/* eslint-disable react/prop-types */
import React, { useState } from "react";
// components
import Avatar from "@material-ui/core/Avatar";
import * as Icons from "@material-ui/icons";
import { makeStyles } from "@material-ui/styles";
import IconButton from "@material-ui/core/IconButton";
import { useSnackbar } from "notistack";
import { green } from "@material-ui/core/colors";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
// import DateFnsUtils from '@date-io/date-fns'
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useHistory } from "react-router-dom";
import moment from "moment";
import MomentUtils from "@date-io/moment";
import FieldRender from "./components/FieldRender";
import EditForm from "./components/EditForm";
import { useRoleDataContext } from "../../../context/RoleContext";
import AlertDialog from "../../AlertDialog";
import customField from "../../CustomField";

import { useAreaDataContext } from "../../../context/AreaContext";
import { Button } from "../../Wrappers";
import { updateDoc } from "../../../firebase";
import Widget from "../../Widget";
import "moment/locale/es";

moment.locale("es");

const useStyles = makeStyles((theme) => ({
  form: {
    "& .MuiTextField-root": {
      margin: theme.spacing(1),
      width: "100%",
    },
  },
  text: {
    marginBottom: theme.spacing(1),
  },
  title: {
    textDecoration: "underline",
  },
  toggleContainer: {
    margin: theme.spacing(1),
  },
  fileInput: {
    display: "none",
  },
  buttonProgress: {
    color: green[500],
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
  buttonSuccess: {
    backgroundColor: green[500],
    "&:hover": {
      backgroundColor: green[700],
    },
  },
}));

export function filterToDisplay(fields, hit) {
  return fields.filter(
    (f) =>
      !f.options ||
      f.options.display === undefined ||
      (typeof f.options.display === "string" &&
        customField(hit, f.options.display)) ||
      f.options.display === true ||
      (typeof f.options.cardDisplay === "string" &&
        customField(hit, f.options.cardDisplay)) ||
      f.options.cardDisplay === true
  );
}

export default function DataCard({
  index,
  hit,
  fields,
  orginalHit,
  transformBody,
}) {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();

  const [edit, setEdit] = useState(false);
  const [deleteDialog, setDeleteDialog] = useState(false);
  const [error, setError] = useState({});

  const areaData = useAreaDataContext();
  // eslint-disable-next-line no-param-reassign
  fields = fields || areaData.panel[index].fields;

  const roleData = useRoleDataContext();

  const update = ({ field, value, ...r }) => {
    const oldValue = field && field.split(".").reduce((p, a) => p && p[a], hit);
    if (
      !field ||
      (oldValue !== value &&
        (!oldValue || !oldValue.objectID || oldValue.objectID !== value))
    ) {
      const nhit = r.hit;
      // eslint-disable-next-line prefer-destructuring
      if (!field) field = Object.keys(nhit)[0];
      const ovalue = hit[field.split(".")[0]];
      // eslint-disable-next-line no-nested-ternary
      const ohit = {
        [field.split(".")[0]]:
          ovalue === undefined || ovalue === null
            ? ""
            : typeof ovalue !== "object" || field?.includes(".")
            ? ovalue
            : ovalue.objectID || Object.keys(ovalue),
      };
      if (nhit) {
        // eslint-disable-next-line no-restricted-syntax
        for (const f in nhit)
          ohit[f] =
            hit[f] === undefined
              ? ""
              : typeof hit[f] !== "object"
              ? hit[f]
              : hit[f].objectID || Object.keys(hit[f]);
        // checkHit(nhit);
      }
      // function checkHit(innerHit) {
      //     for (let f in innerHit) {
      //         let fs = f.split('.');
      //         if (/^[0-9]+$/.test(fs[fs.length-1]))
      //             }
      // }

      updateDoc({
        indexName: index,
        id: hit.objectID,
        hit: nhit || { [field]: value },
      }).then(() =>
        enqueueSnackbar(
          field === "delete"
            ? "Documento Borrado"
            : `${
                (fields.find((f) => f.field === field) || {}).name || field
              } actualizado.`,
          {
            action: (
              <>
                {field !== "delete" && (
                  <Button
                    color="secondary"
                    size="small"
                    onClick={() =>
                      updateDoc({
                        indexName: index,
                        id: hit.objectID,
                        hit: ohit,
                      })
                    }
                  >
                    DESHACER
                  </Button>
                )}
                <IconButton size="small" aria-label="close" color="inherit">
                  {/* <CloseIcon fontSize="small" /> */}
                </IconButton>
              </>
            ),
          }
        )
      );
    }
  };

  const menuItems = [];
  if (roleData.edit && roleData.edit.includes(index)) {
    menuItems.push({
      name: (
        <>
          Editar
          {edit ? <Icons.Check /> : ""}
        </>
      ),
      onclick: () => setEdit((e) => !e),
    });
  }
  if (roleData.deleteElement && roleData.deleteElement.includes(index)) {
    menuItems.push({
      name: (
        <>
          Borrar <FontAwesomeIcon icon={faTrash} />
        </>
      ),
      onclick: () => setDeleteDialog(true),
    });
  }

  return (
    <>
      {deleteDialog && (
        <AlertDialog
          agreeAction={() => {
            update({ field: "delete", value: true });
            history.push(`/app/${index}`);
          }}
          handleClose={() => setDeleteDialog(false)}
          message="Seguro/a que quiere Borrar el documento?"
        />
      )}
      <MuiPickersUtilsProvider
        libInstance={moment}
        utils={MomentUtils}
        locale="es"
      >
        <Widget
        
          header={
            // eslint-disable-next-line react/jsx-no-useless-fragment
            <>
              {edit ? (
                <IconButton onClick={() => setEdit(false)}>
                  <Icons.ArrowBack />
                </IconButton>
              ) : (
                <Avatar
                  aria-label="recipe"
                  alt={hit.razonsocial}
                  src={hit.img || ""}
                >
                  {hit.razonsocial
                    ? hit.razonsocial.substring(0, 1).toUpperCase()
                    : ""}
                </Avatar>
              )}
            </>
          }
          menuItems={menuItems}
        >
          <div>
            {!edit ? (
              filterToDisplay(fields, hit).map((f) => (
                <div key={f.field} className={classes.text}>
                  {f.name && !(f.options && f.options.nested) && (
                    <>
                      <span className={classes.name}>{f.name}</span>:{" "}
                    </>
                  )}
                  <FieldRender
                    f={f}
                    hit={hit}
                    transformBody={transformBody}
                    index={index}
                    classes={classes}
                  />
                </div>
              ))
            ) : (
              <EditForm
                error={error}
                setError={setError}
                hit={hit}
                fields={fields}
                update={update}
                transformBody={transformBody}
                orginalHit={orginalHit}
              />
            )}
          </div>
        </Widget>
      </MuiPickersUtilsProvider>
    </>
  );
}
