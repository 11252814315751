import { InputAdornment, TextField } from "@material-ui/core";
import SearchIcon from "@mui/icons-material/Search";
import { useSearchByText } from "../../../../utils/hooks/useSearchByText";
import { useEffect, useMemo, useState } from "react";
import { Sorting } from "../Sorting";
import { Stack } from "@mui/material";
import SortByAlphaIcon from "@mui/icons-material/SortByAlpha";
import { useSortFilter } from "../../hooks/useSortFilter";
import { useLocation } from "react-router-dom";
import { filterBySeller, orderAlphabetically } from "../../utils/filters";
import Filters from "../Filters";
import { useFilter } from "../../hooks/useFilter";
import { useSearchParams } from "../../hooks/useSearchParams";
import { extractSeller } from "../../utils/extractFilters";

const sortOptions = [
  {
    param: "sort",
    value: "alphabetically",
    name: "Alfabeticamente",
    icon: <SortByAlphaIcon />,
  },
];
export function FiltersSection({ data, onChange }) {
  const {
    clearAllFiltersFromUrl,
    handleDeleteUrlFilter,
    handleOnUrlFilterChange,
  } = useFilter();
  const [inputText, setInputText] = useState("");
  const { sortParam, handleUrlSort } = useSortFilter("sort");
  const { getAllParams } = useSearchParams();
  const location = useLocation();
  const seller = getAllParams("seller");
  const copy = [...data];
  const handleInputText = (e) => setInputText(e.target.value);

  const brandsFiltered = useMemo(
    () => filterBySeller(orderAlphabetically(copy, sortParam), seller),
    [location],
  );
  const filterByText = useSearchByText(brandsFiltered, inputText, ["name"]);

  useEffect(() => {
    onChange(filterByText);
  }, [brandsFiltered, filterByText.dataFiltered, location]);

  const filters = [
    {
      category: "Vendedores",
      multiple: true,
      elements: extractSeller(filterByText.dataFiltered).map((el) => ({
        length: el.length,
        param: "seller",
        label:
          el.label === "v"
            ? "Vívet"
            : el.label === "luz_azul"
            ? "Luz Azul"
            : el.label,
        value: el.value,
      })),
    },
  ];
  return (
    <Stack gap={1}>
      <TextField
        style={{
          alignSelf: "stretch",
          backgroundColor: "white",
          borderRadius: 24,
          border: "1px solid #cbd5e1",
        }}
        placeholder="Veganitas, SOYLAND, etc..."
        variant="outlined"
        // disabled={isPending}
        onChange={handleInputText}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <SearchIcon />
            </InputAdornment>
          ),
        }}
      />
      <Stack direction="row" justifyContent="space-between">
        <Filters
          onApply={(el, close) => close()}
          onDeleteFilter={handleDeleteUrlFilter}
          onChange={handleOnUrlFilterChange}
          onClear={() => clearAllFiltersFromUrl(["seller"])}
          filters={filters}
        />
        <Sorting onChange={handleUrlSort} options={sortOptions} />
      </Stack>
    </Stack>
  );
}
