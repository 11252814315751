import { Skeleton, Stack, Typography } from "@mui/material";
import { useChangeTitle } from "../../../components/Utils/useChangeTitle";
import {
  // LargeCard,
  // ListCard,
  RowCard,
  // SimpleCard
} from "../components/shipment-managment/[id]/card";
import { formatCountryCurrency } from "../../../utils/formatCountryCurrency";
import { useMongoAggregate } from "../../../utils/mongoAggregate/useMongoAggregate";
import Divider from "@mui/material/Divider";
import { useSearchParams } from "../hooks/useSearchParams";
import { MainLayout } from "../layouts/MainLayout";
import { ProviderContext } from "../context/providerContext";
import { useContext } from "react";
export default function ProvidersHome() {
  useChangeTitle();
  const { getParam } = useSearchParams();
  const providerCompany = getParam("providerCompany");
  let providerQuery = getParam("seller");
  const { providerSelected,defaultBrands } = useContext(ProviderContext);

  const [allProductsData, allProductsLoading] = useMongoAggregate({
    index: "products",
    refetchOnWindowFocus: false,
    aggregate: [
      {
        $facet: {
          available: [
            {
              $match: {
                "data.brand": { $in: providerCompany ? providerSelected?.brands : defaultBrands },
                "data.seller": providerQuery ? providerQuery : "v",
              },
            },
            {
              $project: {
                "data.brand":1,
                "data.available": 1,
              },
            },
          ],
          totalPrice: [
            {
              $match: {
                "data.brand": { $in: providerCompany ? providerSelected?.brands : defaultBrands },
                "data.available": { $gt: 0 },
                "data.seller": providerQuery || "v",
                
              },
            },
            {
              $project: {
                "data.available": 1,
                "data.seller": 1,
                "data.brand":1,
                "data.price": 1,
                "data.purchasePrice": 1,
              },
            },
          ],
          totalAvailable: [
            {
              $match: {
                "data.brand": { $in: providerCompany ? providerSelected?.brands : defaultBrands },
                "data.available": { $gt: 0 },
                "data.seller": providerQuery ? providerQuery : "v",
              },
            },

            { $count: "count" },
          ],
          totalUnavailable: [
            {
              $match: {
                "data.brand": { $in: providerCompany ? providerSelected?.brands : defaultBrands },
                "data.seller": providerQuery ? providerQuery : "v",
                "data.available": { $in: [0, undefined] },
              },
            },

            { $count: "count" },
          ],
          totalActive: [
            {
              $match: {
                "data.brand": { $in: providerCompany ? providerSelected?.brands : defaultBrands },
                "data.seller": providerQuery ? providerQuery : "v",
                "data.active": true,
              },
            },
            { $count: "count" },
          ],
          totalCount: [
            { $match: {  
              "data.brand": { $in: providerCompany ? providerSelected?.brands : defaultBrands },
              "data.seller": providerQuery ? providerQuery : "v" } },
            { $count: "count" },
          ],
        },
      },
    ],
  });

  const ifUndefinedZero = (val) => (val ? val : "0");

  const totalAvailable =
    !allProductsLoading &&
    allProductsData[0]?.available
      .map((val) => val.data)
      .filter((val) => Object.hasOwn(val, "available") && val.available)
      .map((e) => e.available)
      .reduce((a, b) => a + b,0);

  const productsPriceEquivalent =
    !allProductsLoading &&
    allProductsData[0]?.totalPrice
      .map((val) => {
        if (
          !Object.hasOwn(val.data, "price") ||
          Object.values(Object.values(val.data.price)).length <= 0
        )
          return;
        if (val.data.seller !== "v" && Object.hasOwn(val.data, "price"))
          return (
            Object.values(Object.values(val.data.price))[0] * val.data.available
          );
        return val.data.purchasePrice * val.data.available;
      })
      .filter((val) => val || (!isNaN(val) && val))
      .reduce((a, b) => a + b, 0);

  return (
    <MainLayout>
      <Typography
        component="h3"
        style={{ fontWeight: 700, fontSize: "24px", color: "#1e293b" }}
      >
        Resumen general
      </Typography>
      <Stack marginTop={2} gap={1}>
        <Typography
          sx={{ fontSize: 20, fontWeight: 700 }}
          component="h4"
          color="text.secondary"
        >
          Productos
        </Typography>
        {allProductsLoading ? (
          <Skeleton height={150} variant="rectangular" />
        ) : (
          <RowCard
            rows={[
              {
                header: "Totales",
                content: ifUndefinedZero(
                  allProductsData[0].totalCount[0]?.count,
                ),
              },
              {
                header: "Activos",
                content: ifUndefinedZero(
                  allProductsData[0].totalActive[0]?.count,
                ),
              },
              {
                header: "Disponibles para venta",
                content: ifUndefinedZero(
                  allProductsData[0].totalAvailable[0]?.count,
                ),
              },
              {
                header: "No disponibles",
                content: ifUndefinedZero(
                  allProductsData[0].totalUnavailable[0]?.count,
                ),
              },
            ]}
          />
        )}
        {allProductsLoading ? (
          <Skeleton height={150} variant="rectangular" />
        ) : (
          <RowCard
            rows={[
              {
                header: "Precio de la mercadería disponible",
                content: formatCountryCurrency(productsPriceEquivalent),
              },
              {
                header: "Unidades disponibles totales",
                content: totalAvailable.toLocaleString(),
              },
            ]}
          />
        )}
      </Stack>
      <Divider style={{ margin: "16px 0" }} orientation="horizontal" flexItem />
    </MainLayout>
  );
}
