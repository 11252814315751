/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/prop-types */
import React, { useEffect, useState } from "react";
import {
  AppBar,
  Fab,
  IconButton,
  Menu,
  MenuItem,
  Toolbar,
} from "@material-ui/core";
import {
  ArrowBack as ArrowBackIcon,
  Business as BusinessIcon,
  Menu as MenuIcon,
  Person as AccountIcon,
  Send as SendIcon,
} from "@material-ui/icons";
import classNames from "classnames";
// styles
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import useStyles from "./styles";
// components
import { Typography } from "../Wrappers/Wrappers";
import Notification from "../Notification/Notification";
import UserAvatar from "../UserAvatar/UserAvatar";
// context
import {
  toggleSidebar,
  useLayoutDispatch,
  useLayoutState,
} from "../../context/LayoutContext";
import { signOut, useUserData } from "../../context/UserContext";
import {
  useAreaDataContext,
  useAreaSetContext,
} from "../../context/AreaContext";
import {
  useRoleDataContext,
  useRoleSetContext,
} from "../../context/RoleContext";
import UserUpdateModal from "../User/components/UserUpdateModal";
import Populate from "../../algolia/populate";
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import drive from '../../images/google-drive-brands.svg';
// import { faGoogleDrive } from "@fortawesome/free-brands-svg-icons";

const messages = [
  {
    id: 0,
    variant: "warning",
    name: "Jane Hew",
    message: "Hey! How is it going?",
    time: "9:32",
  },
  {
    id: 1,
    variant: "success",
    name: "Lloyd Brown",
    message: "Check out my new Dashboard",
    time: "9:18",
  },
  {
    id: 2,
    variant: "primary",
    name: "Mark Winstein",
    message: "I want rearrange the appointment",
    time: "9:15",
  },
  {
    id: 3,
    variant: "secondary",
    name: "Liana Dutti",
    message: "Good news from sale department",
    time: "9:09",
  },
];

const notifications = [
  { id: 0, color: "warning", message: "Check out this awesome ticket" },
  {
    id: 1,
    color: "success",
    type: "info",
    message: "What is the best way to get ...",
  },
  {
    id: 2,
    color: "secondary",
    type: "notification",
    message: "This is just a simple notification",
  },
  {
    id: 3,
    color: "primary",
    type: "e-commerce",
    message: "12 new orders has arrived today",
  },
];

export default function Header({ history = {} }) {
  const classes = useStyles();

  // global
  const layoutState = useLayoutState();
  const layoutDispatch = useLayoutDispatch();
  const panelName = "Vívet";
  const HeaderComment = "";

  // local
  const [mailMenu, setMailMenu] = useState(null);
  // var [isMailsUnread, setIsMailsUnread] = useState(true);
  const [notificationsMenu, setNotificationsMenu] = useState(null);
  // var [isNotificationsUnread, setIsNotificationsUnread] = useState(true);
  const [profileMenu, setProfileMenu] = useState(null);
  // var [isSearchOpen, setSearchOpen] = useState(false);
  const [editUser, setEditUser] = useState(false);

  const { name, firstName, lastName } = useUserData();

  const area = useAreaDataContext();
  const role = useRoleDataContext();

  return (
    <>
      {editUser && <UserUpdateModal closeModal={() => setEditUser(false)} />}
      <AppBar position="relative" className={classes.appBar}>
        <Toolbar className={classes.toolbar}>
          <IconButton
            color="inherit"
            onClick={() => toggleSidebar(layoutDispatch)}
            className={classNames(
              classes.headerMenuButton,
              classes.headerMenuButtonCollapse
            )}
          >
            {layoutState.isSidebarOpened ? (
              <ArrowBackIcon
                classes={{
                  root: classNames(
                    classes.headerIcon,
                    classes.headerIconCollapse
                  ),
                }}
              />
            ) : (
              <MenuIcon
                classes={{
                  root: classNames(
                    classes.headerIcon,
                    classes.headerIconCollapse
                  ),
                }}
              />
            )}
          </IconButton>
          <Typography variant="h6" weight="medium" className={classes.logotype}>
            {panelName}
          </Typography>
          <div className={classes.grow} />
          {/* <div */}
          {/*  className={classNames(classes.search, { */}
          {/*    [classes.searchFocused]: isSearchOpen */}
          {/*  })} */}
          {/* > */}
          {/*  <div */}
          {/*    className={classNames(classes.searchIcon, { */}
          {/*      [classes.searchIconOpened]: isSearchOpen */}
          {/*    })} */}
          {/*    onClick={() => setSearchOpen(!isSearchOpen)} */}
          {/*  > */}
          {/*    <SearchIcon classes={{ root: classes.headerIcon }} /> */}
          {/*  </div> */}
          {/*  <InputBase */}
          {/*    placeholder="Search…" */}
          {/*    classes={{ */}
          {/*      root: classes.inputRoot, */}
          {/*      input: classes.inputInput */}
          {/*    }} */}
          {/*  /> */}
          {/* </div> */}
          {/* <IconButton */}
          {/*  color="inherit" */}
          {/*  aria-haspopup="true" */}
          {/*  aria-controls="mail-menu" */}
          {/*  onClick={e => { */}
          {/*    setNotificationsMenu(e.currentTarget); */}
          {/*    setIsNotificationsUnread(false); */}
          {/*  }} */}
          {/*  className={classes.headerMenuButton} */}
          {/* > */}
          {/*  <Badge */}
          {/*    badgeContent={isNotificationsUnread ? notifications.length : null} */}
          {/*    color="warning" */}
          {/*  > */}
          {/*    <NotificationsIcon classes={{ root: classes.headerIcon }} /> */}
          {/*  </Badge> */}
          {/* </IconButton> */}
          {/* <IconButton */}
          {/*  color="inherit" */}
          {/*  aria-haspopup="true" */}
          {/*  aria-controls="mail-menu" */}
          {/*  onClick={e => { */}
          {/*    setMailMenu(e.currentTarget); */}
          {/*    setIsMailsUnread(false); */}
          {/*  }} */}
          {/*  className={classes.headerMenuButton} */}
          {/* > */}
          {/*  <Badge */}
          {/*    badgeContent={isMailsUnread ? messages.length : null} */}
          {/*    color="secondary" */}
          {/*  > */}
          {/*    <MailIcon classes={{ root: classes.headerIcon }} /> */}
          {/*  </Badge> */}
          {/* </IconButton> */}
          {HeaderComment && <HeaderComment {...{ classes }} />}
          <SelectArea classes={classes} />
          <IconButton
            aria-haspopup="true"
            color="inherit"
            className={classes.headerMenuButton}
            aria-controls="profile-menu"
            onClick={(e) => setProfileMenu(e.currentTarget)}
          >
            <AccountIcon classes={{ root: classes.headerIcon }} />
          </IconButton>
          <Menu
            id="mail-menu"
            open={Boolean(mailMenu)}
            anchorEl={mailMenu}
            onClose={() => setMailMenu(null)}
            MenuListProps={{ className: classes.headerMenuList }}
            className={classes.headerMenu}
            classes={{ paper: classes.profileMenu }}
            disableAutoFocusItem
          >
            <div className={classes.profileMenuUser}>
              <Typography variant="h4" weight="medium">
                New Messages
              </Typography>
              <Typography
                className={classes.profileMenuLink}
                component="a"
                color="secondary"
              >
                {messages.length} New Messages
              </Typography>
            </div>
            {messages.map((message) => (
              <MenuItem
                key={message.id}
                className={classes.messageNotification}
              >
                <div className={classes.messageNotificationSide}>
                  <UserAvatar color={message.variant} name={message.name} />
                  <Typography
                    size="sm"
                    color="text"
                    colorBrightness="secondary"
                  >
                    {message.time}
                  </Typography>
                </div>
                <div
                  className={classNames(
                    classes.messageNotificationSide,
                    classes.messageNotificationBodySide
                  )}
                >
                  <Typography weight="medium" gutterBottom>
                    {message.name}
                  </Typography>
                  <Typography color="text" colorBrightness="secondary">
                    {message.message}
                  </Typography>
                </div>
              </MenuItem>
            ))}
            <Fab
              variant="extended"
              color="primary"
              aria-label="Add"
              className={classes.sendMessageButton}
            >
              Send New Message
              <SendIcon className={classes.sendButtonIcon} />
            </Fab>
          </Menu>
          <Menu
            id="notifications-menu"
            open={Boolean(notificationsMenu)}
            anchorEl={notificationsMenu}
            onClose={() => setNotificationsMenu(null)}
            className={classes.headerMenu}
            disableAutoFocusItem
          >
            {notifications.map((notification) => (
              <MenuItem
                key={notification.id}
                onClick={() => {
                  // eslint-disable-next-line no-alert
                  alert(notification.message);
                  setNotificationsMenu(null);
                }}
                className={classes.headerMenuItem}
              >
                <Notification {...notification} typographyVariant="inherit" />
              </MenuItem>
            ))}
          </Menu>
          <Menu
            id="profile-menu"
            open={Boolean(profileMenu)}
            anchorEl={profileMenu}
            onClose={() => setProfileMenu(null)}
            className={classes.headerMenu}
            classes={{ paper: classes.profileMenu }}
            disableAutoFocusItem
          >
            <div className={classes.profileMenuUser}>
              <Typography variant="h4" weight="medium">
                {name || `${firstName} ${lastName}`}
              </Typography>
            </div>
            <MenuItem
              className={classNames(
                classes.profileMenuItem,
                classes.headerMenuItem
              )}
              onClick={() => history.push("/app/account")}
            >
              <BusinessIcon className={classes.profileMenuIcon} /> Mi Cuenta
            </MenuItem>
            <MenuItem
              className={classNames(
                classes.profileMenuItem,
                classes.headerMenuItem
              )}
              onClick={() => setEditUser(true)}
            >
              <BusinessIcon className={classes.profileMenuIcon} />{" "}
              {area.description}
            </MenuItem>
            <MenuItem
              className={classNames(
                classes.profileMenuItem,
                classes.headerMenuItem
              )}
              onClick={() => setEditUser(true)}
            >
              <BusinessIcon className={classes.profileMenuIcon} /> {role.title}
            </MenuItem>
            {/* <MenuItem */}
            {/*  className={classNames( */}
            {/*    classes.profileMenuItem, */}
            {/*    classes.headerMenuItem */}
            {/*  )} */}
            {/* > */}
            {/*  <AccountIcon className={classes.profileMenuIcon} /> Tasks */}
            {/* </MenuItem> */}
            {/* <MenuItem */}
            {/*  className={classNames( */}
            {/*    classes.profileMenuItem, */}
            {/*    classes.headerMenuItem */}
            {/*  )} */}
            {/* > */}
            {/*  <AccountIcon className={classes.profileMenuIcon} /> Messages */}
            {/* </MenuItem> */}
            <div className={classes.profileMenuUser}>
              <Typography
                className={classes.profileMenuLink}
                color="primary"
                onClick={() => signOut(history)}
              >
                Cerrar Sesión
              </Typography>
            </div>
          </Menu>
        </Toolbar>
      </AppBar>
    </>
  );
}

function SelectArea({ classes = {} }) {
  const getuser = useUserData();

  const area = useAreaDataContext();
  const setArea = useAreaSetContext();
  const setRole = useRoleSetContext();
  const [{ areas, roles, currentRole }, setAreasRoles] = useState({});

  useEffect(() => {
    let load = true;
    const a = async () => {
      const user = JSON.parse(JSON.stringify(getuser));
      const pUser = await Populate({
        hits: [user],
        props: [
          { index: "roles", field: "roles" },
          { index: "areas", field: "areas" },
          {
            index: "roles",
            field: "currentRole",
          },
          { index: "areas", field: "currentArea" },
        ],
      }).then((res) => res[0]);
      const ar = Object.keys(pUser.areas || {}).map((r) => pUser.areas[r]);
      const r = Object.keys(pUser.roles || {}).map((t) => pUser.roles[t]);
      const c = pUser.currentRole || roles[0];
      if (load) setAreasRoles({ areas: ar, roles: r, currentRole: c });
    };
    a();
    // eslint-disable-next-line no-return-assign
    return () => (load = false);
  }, [getuser.areas]);

  return areas ? (
    <>
      <div className={classes.formControl}>
        <FormControl>
          <InputLabel htmlFor="area-select">Area</InputLabel>
          <Select
            native
            value={area.objectID}
            onChange={(e) => setArea(e.target.value)}
            label="Area"
            inputProps={{
              name: "area",
              id: "area-select",
            }}
          >
            {areas.map((a, i) => (
              <option value={a.objectID} key={a.objectID || i}>
                {a.name}
              </option>
            ))}
          </Select>
        </FormControl>
      </div>
      {roles?.length > 1 && (
        <div className={classes.formControl}>
          <FormControl>
            <InputLabel htmlFor="role-select">Role</InputLabel>
            <Select
              native
              value={currentRole.objectID}
              onChange={(e) => setRole(e.target.value)}
              label="Role"
              inputProps={{
                name: "role",
                id: "role-select",
              }}
            >
              {roles
                .filter(
                  (r) => r.view?.length || r.objectID === currentRole.objectID
                )
                .map((a, i) => (
                  <option value={a.objectID} key={a.objectID || i}>
                    {a.name || a.title}
                  </option>
                ))}
            </Select>
          </FormControl>
        </div>
      )}
    </>
  ) : null;
}
