import * as React from "react";
import { useState } from "react";
import EditIcon from "@mui/icons-material/Edit";
import {
  Box,
  Button,
  Container,
  Dialog,
  FormControl,
  IconButton,
  Menu,
  MenuItem,
  TextField,
  Typography,
} from "@mui/material";
import LocalPrintshopIcon from "@mui/icons-material/LocalPrintshop";
import DepartureBoardIcon from "@mui/icons-material/DepartureBoard";
import _ from "lodash";
import { numberWithCommas } from "../../../components/Utils/NumberWithCommas";
import { Asynchronous } from "../../../components/CardFirestore/DataCard/components/EditForm";
import useSalesByProduct from "../../../components/Utils/salesByProduct";
import { useMongoAggregate } from "../../../utils/mongoAggregate/useMongoAggregate";
import {
  db,
  updateDoc,
  useCollection,
  useDocumentData,
} from "../../../firebase";
import { MuiDateRangePicker } from "../../../utils/MuiCalendar";
import useStyles from "./styles";

import "moment/locale/es";
import TableProducts from "./TableProducts";
import { AddNewExtraItem } from "./AddNewExtraItem";
import PasteTextContent from "./PasteTextContent";
import Moment from "moment";
import { extendMoment } from "moment-range";
import { LoadingButton } from "@mui/lab";
import DetailReceived from "./DetailReceived";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { useModal } from "../utils/useModal";
import { useHistory } from "react-router-dom";
import { pdfCreate } from "../../../utils/pdfMaker/pdfCreate";
import { templatePdf } from "./templatePdf";
import templateGenerator from "../../../utils/pdfMaker/templateGenerator";

const moment = extendMoment(Moment);

moment.locale("es");

export default function PurchaseOrderCard({ objectID }) {
  const classes = useStyles();

  const [purchaseOrder] = useDocumentData("purchaseOrders", objectID);
  const [inventoryAdjustment] = useMongoAggregate({
    index: "inventoryAdjustment",
    aggregate: [
      {
        $match: {
          "data.objectID": purchaseOrder?.received?.inventoryAdjustment,
        },
      },
      { $project: { items: "$data.items", "data.controlReason": 1 } },
    ],
  });

  const isOrderReceived = purchaseOrder?.received?.s;
  const [isOpen, toggleModal] = useModal();

  const [range, setRange] = useState([
    moment().startOf("day").subtract(3, "days"),
    moment(),
  ]);

  const [edit, setEdit] = useState(false);
  const [enabled, setEnabled] = useState(false);
  const [openDates, setOpenDates] = useState(false);

  const providerID = purchaseOrder?.provider || "";
  const setProvider = (provider) =>
    updateDoc("purchaseOrders", objectID, { provider });

  const [providerData] = useDocumentData("providers", providerID);
  const [sellerData] = useDocumentData("sellers", purchaseOrder?.seller);


  const dateOrder = purchaseOrder?.date;
  const expirationOrder = purchaseOrder?.expirationDate;

  const from = range?.[0].format("YYYY-MM-DD");
  const to = range?.[1].clone().add(1, "day").format("YYYY-MM-DD");

  const [salesByProduct, loading] = useSalesByProduct({
    enabled,
    from,
    to,
    populate: true,
    brands: providerData?.brands,
    preloading: !providerData?.brands,
    seller: purchaseOrder?.seller,
  });

  const [mongoDataProducts, loadingProducts] = useMongoAggregate({
    index: "products",
    aggregate: [
      {
        $match: {
          "data.brand": { $in: providerData?.brands },
          "data.seller": purchaseOrder?.seller,
          "data.discontinued": { $ne: true },
        },
      },
      {
        $project: {
          brand: "$data.brand",
          name: "$data.name",
          objectID: "$data.objectID",
          taste: "$data.taste",
          unit: "$data.unit",
          quantity: "$data.quantity",
          image: "$data.image",
          sellerCode: "$data.sellerCode",
          active: "$data.active",
          purchasePrice: "$data.purchasePrice",
          price: "$data.price",
          pack: "$data.pack",
          available: "$data.available",
          iva: "$data.iva",
        },
      },
    ],
  });

  const products = _.uniqBy(
    [
      ...(mongoDataProducts?.length
        ? mongoDataProducts?.map((a) => ({
          ...a,
          q: purchaseOrder?.items?.[a.objectID]?.q || 0,
          received: _.chain(inventoryAdjustment?.[0]?.items?.[a?.objectID])
            .values()
            .flatten()
            .map((a) => _.values(a))
            .flatten()
            .reduce((p, a) => (p || 0) + (a || 0), 0)
            .value(),
        }))
        : []),
      ...(salesByProduct?.length ? salesByProduct : []),
    ],
    "objectID",
  );

  const productsToPdf = _.chain(
    [...products, ...(purchaseOrder?.otherItems ? purchaseOrder?.otherItems : [])].map((product) => ({
      ...product,
      ..._.find(purchaseOrder?.items, (_, k) => k === product?.objectID),
    })),
  )
    .filter((a) => a?.q)
    .value();

  const withPrices = productsToPdf.some(v=>v.purchasePrice && v.purchasePrice < 1000000);

  const columns = [
    { key: "q", label: "Cant" },
    { key: "sellerCode", label: "Código" },
    {
      key: "name",
      format: (data) =>
        `${data?.other || ''}${data?.name || ""} ${data?.brandName || ""}${data?.taste || ""} ${data?.quantity || ""
        }${data?.unit || ""}`,
      label: "Descripción",
    },
    ...(withPrices ? [
    {
      key: "purchasePrice",
      format: (data) => `$${numberWithCommas(data.purchasePrice, 2)}`,
      label: "Precio unitario",
    },
    {
      key: "iva",
      format: (data) => `${numberWithCommas((data.iva ?? 0.21) * 100, 2)}%`,
      label: "Iva",
    },
    {
      key: "total",
      label: "Total",
      format: (price) => `$${numberWithCommas(price, 2)}`,
    },
    ] : []),
  ];

  const generatePdf = () => {
    pdfCreate(
      templateGenerator({
        items: productsToPdf,
        columns,
        columnsWidths: withPrices ? [20, 45, "*", 60, 30, 60] :  [30, 70, "*"],
        template: (props) => templatePdf({
          ...props,
          providerData,
          purchaseOrder,
          sellerData,
          withPrices
        })
      }),
    );
  };

  function handleDateChange(date) {
    updateDoc({
      indexName: "purchaseOrders",
      id: objectID,
      hit: {
        date: date.target.value,
      },
    });

    setEdit(false);
  }

  function handleExpirationChange(date) {
    updateDoc({
      indexName: "purchaseOrders",
      id: objectID,
      hit: {
        expirationDate: date.target.value,
      },
    });
    setEdit(false);
  }

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const productsWithMissingItems = products
    .filter((p) => {
      const receivedQ = p?.received;
      const productQ = purchaseOrder?.items?.[p.objectID]?.q;
      return productQ - receivedQ > 0 && !isNaN(productQ);
    })
    .reduce(
      (p, a) => ({
        ...p,
        [a.objectID]: {
          iva: purchaseOrder?.items?.[a.objectID]?.iva || 0.21,
          purchasePrice: a.purchasePrice || 0,
          q: purchaseOrder?.items?.[a.objectID]?.q - a?.received,
          title: `${a.name} ${a.taste} - ${a.quantity}${a.unit}`,
        },
      }),
      {},
    );
  const router = useHistory();

  const [childrenPurchaseOrders] = useCollection({
    collection: objectID && "purchaseOrders",
    where: [["originalPurchaseOrder", "==", objectID]],
  });
  const generateOrderMissingItems = async () => {
    const hit = {
      ..._.pick(purchaseOrder, ["expirationDate", "provider", "seller"]),
      items: productsWithMissingItems,
      date: moment().format("YYYY-MM-DD"),
      originalPurchaseOrder: purchaseOrder.objectID,
      algoliaUpdated: false,
    };
    await db.collection("purchaseOrders").add({ ...hit });
  };

  return (
    <>
      <Dialog onClose={() => setOpenDates(false)} open={openDates}>
        <Typography>Modificar días de unidades vendidas</Typography>
        <MuiDateRangePicker
          localeText={{ start: "Desde", end: "Hasta" }}
          calendars={2}
          direction={"row"}
          date={range}
          onChange={(dates) => setRange(dates)}
        />
      </Dialog>
      (
      <Container className={classes.container}>
        {/* Tabla */}
        <Box
          style={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
            gap: 5,
            alignItems: "center",
            marginBottom: 4,
          }}
        >
          <IconButton sx={{ alignSelf: "flex-end" }} onClick={handleClick}>
            <MoreVertIcon />
          </IconButton>
          <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            MenuListProps={{
              "aria-labelledby": "basic-button",
            }}
          >
            {[
              {
                label: "Ver Recepción",
                key: "showDetail",
                action: toggleModal,
                hide: !purchaseOrder?.received?.s,
              },
              {
                label: "Ver unidades Vendidas",
                key: "showSelledUnits",
                action: () => (enabled ? setOpenDates(true) : setEnabled(true)),
              },
              {
                label: "Generar Orden con Faltantes",
                key: "generateOrderMissingItems",
                hide:
                  _.isEmpty(productsWithMissingItems) ||
                  !purchaseOrder?.received?.s ||
                  childrenPurchaseOrders?.length,
                action: generateOrderMissingItems,
              },
              {
                label: "Ver Orden Generada",
                key: "showGeneratedOrder",
                hide: !childrenPurchaseOrders?.length,
                action: () =>
                  router.push(
                    `/app/purchaseOrders/${childrenPurchaseOrders?.[0]?.objectID}`,
                  ),
              },
              {
                label: "Ver Orden Madre",
                key: "showMother",
                hide: !purchaseOrder?.originalPurchaseOrder,
                action: () =>
                  router.push(
                    `/app/purchaseOrders/${purchaseOrder?.originalPurchaseOrder}`,
                  ),
              },
            ]
              .filter((v) => !v.hide)
              .map(({ label, key, action }) => (
                <MenuItem onClick={action} key={key}>
                  {label}
                </MenuItem>
              ))}
          </Menu>
          {enabled && (
            <LoadingButton
              loading={loading}
              variant="outlined"
              color={enabled ? "secondary" : "primary"}
            >
              {moment(range?.[0])?.format("DD/MM/YYYY")} a
              {moment(range?.[1])?.format("DD/MM/YYYY")}
            </LoadingButton>
          )}
        </Box>
        <Box
          style={{
            display: "flex",
            justifyContent: "space-between",
            marginBottom: "2em",
          }}
        >
          {edit && _.isEmpty(purchaseOrder?.items) ? (
            <FormControl>
              <Box style={{ width: "200px" }}>
                <Asynchronous
                  title="Comprador *"
                  index="sellers"
                  field="name"
                  values={
                    purchaseOrder?.seller
                      ? {
                        objectID: purchaseOrder?.seller,
                        name: purchaseOrder?.seller,
                      }
                      : ""
                  }
                  onChange={(seller) => {
                    updateDoc("purchaseOrders", objectID, { seller });
                  }}
                />
              </Box>

              <Box style={{ width: "200px" }}>
                <Asynchronous
                  title="Proveedor"
                  index="providers"
                  field="name"
                  filters={`sellers:${purchaseOrder?.seller}`}
                  values={providerData || false}
                  onChange={(e) => {
                    setProvider(e);
                    setEdit(false);
                  }}
                />
              </Box>
            </FormControl>
          ) : (
            <>
              <Box>
                <div>
                  <b>
                    {purchaseOrder?.seller === "v"
                      ? "Vívet"
                      : purchaseOrder?.seller}
                  </b>
                </div>
                <Typography className={classes.thypographyBrandName}>
                  {" "}
                  {providerData?.name}
                </Typography>
                {_.isEmpty(purchaseOrder?.items) && (
                  <EditIcon
                    style={{ cursor: "pointer" }}
                    onClick={() => setEdit(true)}
                  />
                )}
                {!isOrderReceived && (
                  <AddNewExtraItem purchaseOrder={purchaseOrder} />
                )}
                {isOrderReceived && (
                  <DetailReceived
                    toggleModal={toggleModal}
                    isOpen={isOpen}
                    inventoryAdjustment={inventoryAdjustment}
                    data={purchaseOrder?.received}
                    productsInfo={products}
                  />
                )}
              </Box>
              <Box
                style={{
                  border: "1px solid #ccc",
                  borderRadius: "10px",
                  padding: "10px",
                  width: "50%",
                  display: "flex",
                  justifyContent: "space-around",
                  textAlign: "center",
                  height: "max-content",
                }}
              >
                <Box>
                  <span
                    style={{
                      fontWeight: 600,
                      display: "block",
                      paddingBottom: 3,
                    }}
                  >
                    Razon Social:{" "}
                  </span>{" "}
                  {providerData?.razonsocial}
                </Box>
                <Box>
                  <span
                    style={{
                      fontWeight: 600,
                      display: "block",
                      paddingBottom: 3,
                    }}
                  >
                    Cuit:
                  </span>{" "}
                  {providerData?.cuit}
                </Box>
                <Box style={{ display: "inline" }}>
                  {" "}
                  <DepartureBoardIcon />{" "}
                  <span style={{ display: "block" }}>
                    {providerData?.lead_time} días
                  </span>{" "}
                </Box>
              </Box>
            </>
          )}

          {edit ? (
            <FormControl>
              <Box style={{ display: "flex", alignItems: "center" }}>
                <TextField
                  label="Fecha"
                  type="date"
                  defaultValue={dateOrder}
                  className={classes.textField}
                  // eslint-disable-next-line react/jsx-no-bind
                  onChange={handleDateChange}
                  style={{ width: 150, margin: "1em" }}
                />
              </Box>

              <Box style={{ display: "flex", alignItems: "center" }}>
                <TextField
                  label="Vencimiento OC"
                  type="date"
                  defaultValue={expirationOrder}
                  className={classes.textField}
                  // eslint-disable-next-line react/jsx-no-bind
                  onChange={handleExpirationChange}
                  style={{ width: 150, margin: "1em" }}
                />
              </Box>
            </FormControl>
          ) : (
            <FormControl>
              <Typography style={{ fontSize: 12 }}>Fecha</Typography>
              <Box
                style={{
                  alignItems: "center",
                  display: "flex",
                  cursor: "pointer",
                }}
                onClick={() => !isOrderReceived && setEdit(true)}
              >
                <Typography>{purchaseOrder?.date}</Typography>
                {!isOrderReceived && <EditIcon />}
              </Box>

              <Typography style={{ fontSize: 12, marginTop: 15 }}>
                Vencimiento OC
              </Typography>

              <Box
                style={{
                  alignItems: "center",
                  display: "flex",
                  cursor: "pointer",
                }}
                onClick={() => !isOrderReceived && setEdit(true)}
              >
                <Typography>{purchaseOrder?.expirationDate}</Typography>
                {!isOrderReceived && <EditIcon />}
              </Box>
            </FormControl>
          )}
        </Box>

        {!loadingProducts && products && (
          <TableProducts
            products={products}
            classes={classes}
            purchaseOrder={purchaseOrder}
            purchaseOrderId={objectID}
          />
        )}

        {/* Footer Card orden de compra */}
        <Box
          sx={{ minWidth: 275 }}
          style={{ marginTop: 20, background: "#fff" }}
        >
          {products && (
            <Box
              component="form"
              sx={{
                "& .MuiTextField-root": { m: 10, width: "200" },
              }}
              noValidate
              autoComplete="off"
              style={{ display: "flex", justifyContent: "space-between" }}
            >
              <Box>
                <Box>
                  <Typography className={classes.TypographyPrice}>
                    Sub-Total
                  </Typography>
                  : ${" "}
                  {numberWithCommas(
                    _.reduce(
                      purchaseOrder?.items,
                      (p, a) => p + a?.purchasePrice * a?.q,

                      0,
                    ) +
                    _.reduce(
                      purchaseOrder?.otherItems,
                      (p, a) => p + a?.purchasePrice * a?.q,
                      0,
                    ),
                  )}
                </Box>

                <Box>
                  <Typography className={classes.TypographyPrice}>
                    IVA
                  </Typography>
                  : ${" "}
                  {numberWithCommas(
                    _.reduce(
                      purchaseOrder?.items,
                      (p, a) => p + a?.iva * a?.purchasePrice * a?.q,

                      0,
                    ),
                  )}
                </Box>

                <Box>
                  <Typography
                    className={classes.TypographyPrice}
                    style={{ fontWeight: "bold" }}
                  >
                    Total
                  </Typography>
                  : ${" "}
                  {numberWithCommas(
                    _.reduce(
                      purchaseOrder?.items,
                      (p, a) => p + (a?.iva + 1) * a?.purchasePrice * a?.q,

                      0,
                    ) +
                    _.reduce(
                      purchaseOrder?.otherItems,
                      (p, a) => p + a?.purchasePrice * a?.q,

                      0,
                    ),
                  )}
                </Box>
                {!!purchaseOrder?.received?.s && (
                  <Box>
                    <Typography
                      className={classes.TypographyPrice}
                      style={{ fontWeight: "bold" }}
                    >
                      Total Recibido
                    </Typography>
                    : ${" "}
                    {numberWithCommas(
                      _.reduce(
                        products,
                        (p, a) =>
                          p +
                          ((purchaseOrder?.items?.[a?.objectID]?.iva || 0.21) +
                            1) *
                          (purchaseOrder?.items?.[a?.objectID]
                            ?.purchasePrice || 0) *
                          (a?.received || 0),

                        0,
                      ) +
                      _.reduce(
                        purchaseOrder?.otherItems,
                        (p, a) => p + a?.purchasePrice * a?.q,

                        0,
                      ),
                    )}
                  </Box>
                )}
              </Box>

              {!isOrderReceived && (
                <PasteTextContent products={products} id={objectID} />
              )}
              {!_.isEmpty(purchaseOrder?.items) && (
                <Box>
                  <Button
                    style={{
                      cursor: "pointer",
                      color: "#4fc3f7",
                      fontWeight: 600,
                      textTransform: "capitalize",
                    }}
                    onClick={generatePdf}
                  >
                    Imprimir PDF <LocalPrintshopIcon />
                  </Button>
                </Box>
              )}
            </Box>
          )}
        </Box>
      </Container>
      )
    </>
  );
}
