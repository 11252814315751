import {useState} from 'react';
import { styled, alpha } from '@mui/material/styles';
import {Button} from "@material-ui/core"
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import SwapVertIcon from '@mui/icons-material/SwapVert';
import { useSearchParams } from '../hooks/useSearchParams';
export function Sorting({options,onChange}){
  const {getAllParams} = useSearchParams()
    // const [defaultSelected,setDefaultSelected] = useState(defaultValue)
    const [selectedSort,setSelectedSorts] = useState([])

  const StyledMenu = styled((props) => (
    <Menu
      elevation={0}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      {...props}
    />
  ))(({ theme }) => ({
    '& .MuiPaper-root': {
      borderRadius: 6,
      marginTop: theme.spacing(1),
      minWidth: 180,
      color:
        theme.palette.mode === 'light' ? 'rgb(55, 65, 81)' : theme.palette.grey[300],
      boxShadow:
        'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
      '& .MuiMenu-list': {
        padding: '4px 0',
      },
      '& .MuiMenuItem-root': {
        '& .MuiSvgIcon-root': {
          fontSize: 18,
          color: theme.palette.text.secondary,
          marginRight: theme.spacing(1.5),
        },
        '&:active': {
          backgroundColor: alpha(
            theme.palette.primary.main,
            theme.palette.action.selectedOpacity,
          ),
        },
      },
    },
  }));
  
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
      setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
      setAnchorEl(null);
    };
  
    return (
      <div>
        <Button
          id="demo-customized-button"
          aria-controls={open ? 'demo-customized-menu' : undefined}
          aria-haspopup="true"
          style={{fontWeight:700}}
          aria-expanded={open ? 'true' : undefined}
          variant="outlined"
          disableElevation
          startIcon={<SwapVertIcon />}
          onClick={handleClick}
          endIcon={<KeyboardArrowDownIcon />}
        >
          Ordenar por
        </Button>
        <StyledMenu
          id="demo-customized-menu"
          MenuListProps={{
            'aria-labelledby': 'demo-customized-button',
          }}
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
        >
          {options.map(op => <MenuItem 
            key={op.value}
            selected={selectedSort.includes(op) || getAllParams(op.param).includes(op.value)} 
            onClick={() => {
                if(selectedSort.find(sort => sort.value === op.value )){
                  let option = op
                  option.active = true
                  setSelectedSorts([option])
                  onChange(option)
                  return handleClose()
                }
                else {
                    let option = op
                    option.active = false
                    setSelectedSorts([])
                    onChange(option)
                    return handleClose()
                }

          }} disableRipple>
            {op.icon}
                {op.name}
          </MenuItem>) }
        </StyledMenu>
      </div>
    ); }