import { useEffect, useMemo, useState } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/DeleteOutlined";
import SaveIcon from "@mui/icons-material/Save";
import CancelIcon from "@mui/icons-material/Close";
import {
  GridActionsCellItem,
  GridRowEditStopReasons,
  GridRowModes,
  GridToolbarContainer,
} from "@mui/x-data-grid-premium";
import { randomId } from "@mui/x-data-grid-generator";
import { useBanksDataContext } from "../../BanksContext";
import { useUserData } from "../../../../context/UserContext";
import DataGridPremium from "../../../../components/DataGrid/DataGrid";
import _ from "lodash";

function EditToolbar(props) {
  const { setRows, setRowModesModel } = props;

  const handleClick = () => {
    const id = randomId();
    setRows((oldRows) => [
      ...oldRows,
      { id, k: "", credit: 0, debit: 0, isNew: true },
    ]);
    setRowModesModel((oldModel) => ({
      ...oldModel,
      [id]: { mode: GridRowModes.Edit, fieldToFocus: "k" },
    }));
  };

  return (
    <GridToolbarContainer>
      <Button color="primary" startIcon={<AddIcon />} onClick={handleClick}>
        Agregar Línea
      </Button>
    </GridToolbarContainer>
  );
}

function getInitialRows(data) {
  return (
    data?.map(({ v, k, edited }, i) =>
      _.omitBy(
        {
          id: i,
          k,
          edited,
          debit: v > 0 ? v : 0,
          credit: v < 0 ? -v : 0,
        },
        (v) => v === undefined,
      ),
    ) || []
  );
}

export default function AccountDetailsEdit({ data_accounts, setDataAccounts }) {
  // const rows = getInitialRows(data_accounts);
  const [rows, setRows] = useState(getInitialRows(data_accounts));
  const [rowModesModel, setRowModesModel] = useState({});
  const { accounts } = useBanksDataContext();
  const { uid } = useUserData();

  useEffect(() => {
    setRowModesModel({});
    setRows(getInitialRows(data_accounts));
  }, [data_accounts]);

  const handleRows = (rows) => {
    // setRows(rows);
    setDataAccounts?.(
      _.isEqual(data_accounts, rows)
        ? data_accounts
        : rows
            .map(({ k, credit, debit, edited }) =>
              _.omitBy(
                {
                  k,
                  v: debit - credit,
                  edited,
                },
                (v) => !v,
              ),
            )
            .filter(({ v }) => v),
    );
  };

  const handleRowEditStop = (params, event) => {
    if (params.reason === GridRowEditStopReasons.rowFocusOut) {
      event.defaultMuiPrevented = true;
    }
  };

  const handleEditClick = (id) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } });
  };

  const handleSaveClick = (id) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });
  };

  const handleDeleteClick = (id) => () => {
    handleRows(rows.filter((row) => row.id !== id));
  };

  const handleCancelClick = (id) => () => {
    setRowModesModel({
      ...rowModesModel,
      [id]: { mode: GridRowModes.View, ignoreModifications: true },
    });

    const editedRow = rows.find((row) => row.id === id);
    if (editedRow.isNew) {
      handleRows(rows.filter((row) => row.id !== id));
    }
  };

  const processRowUpdate = (newRow, oldRow) => {
    if (_.isEqual(newRow, oldRow)) return oldRow;
    const updatedRow = {
      ...newRow,
      isNew: false,
      edited: { by: uid, t: (new Date() / 1000) | 0 },
    };
    handleRows(rows.map((row) => (row.id === newRow.id ? updatedRow : row)));
    handleRows(rows.map((row) => (row.id === newRow.id ? updatedRow : row)));
    return updatedRow;
  };

  const handleRowModesModelChange = (newRowModesModel) => {
    setRowModesModel(newRowModesModel);
  };

  const columns = useMemo(
    () => [
      {
        field: "k",
        headerName: "Cuenta",
        width: 220,
        editable: true,
        type: "singleSelect",
        valueOptions: accounts,
        getOptionValue: (value) => value.id,
        getOptionLabel: (value) => `${value.code} - ${value.name}`,
      },
      {
        field: "debit",
        headerName: "Debito",
        type: "number",
        width: 120,
        align: "left",
        headerAlign: "left",
        editable: true,
      },
      {
        field: "credit",
        headerName: "Credito",
        type: "number",
        width: 120,
        align: "left",
        headerAlign: "left",
        editable: true,
      },
      {
        field: "actions",
        type: "actions",
        headerName: "Actions",
        width: 100,
        cellClassName: "actions",
        getActions: ({ id, row }) => {
          if (accounts.find((d) => d.id === row.k)?.editable === false)
            return [];
          const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;

          if (isInEditMode) {
            return [
              <GridActionsCellItem
                key={0}
                icon={<SaveIcon />}
                label="Save"
                sx={{
                  color: "primary.main",
                }}
                onClick={handleSaveClick(id)}
              />,
              <GridActionsCellItem
                key={1}
                icon={<CancelIcon />}
                label="Cancel"
                className="textPrimary"
                onClick={handleCancelClick(id)}
                color="inherit"
              />,
            ];
          }

          return [
            <GridActionsCellItem
              key={2}
              icon={<EditIcon />}
              label="Edit"
              className="textPrimary"
              onClick={handleEditClick(id)}
              color="inherit"
            />,
            <GridActionsCellItem
              key={3}
              icon={<DeleteIcon />}
              label="Delete"
              onClick={handleDeleteClick(id)}
              color="inherit"
            />,
          ];
        },
      },
    ],
    [accounts, rowModesModel],
  );

  return (
    <Box
      sx={{
        height: 300,
        background: "#fff",
        width: "100%",
        "& .actions": {
          color: "text.secondary",
        },
        "& .textPrimary": {
          color: "text.primary",
        },
      }}
    >
      <DataGridPremium
        rows={rows}
        columns={columns}
        editMode="row"
        rowModesModel={rowModesModel}
        onRowModesModelChange={handleRowModesModelChange}
        onRowEditStop={handleRowEditStop}
        processRowUpdate={processRowUpdate}
        hideFooterPagination
        isCellEditable={(params) =>
          accounts.find((d) => d.id === params.row.k)?.editable !== false
        }
        slots={{
          toolbar: EditToolbar,
        }}
        slotProps={{
          toolbar: { setRows, setRowModesModel },
        }}
      />
    </Box>
  );
}
