import { matchSorter } from "match-sorter";
import { useEffect, useState,useTransition } from "react";

export const useSearchByText = (data,inputText,search=[],limit) => {
    const [isPending,startTransition] = useTransition() 
    const [dataFiltered,setDataFiltered] = useState(data?.slice(0,limit))
    useEffect(() =>{
        setDataFiltered(data)
    },[data])
    useEffect(() => {
        if(!inputText || inputText === "") return setDataFiltered(data?.slice(0,limit));
        startTransition(() => {
            const results = matchSorter(data,inputText,{keys: search})
            return setDataFiltered(results.slice(0,limit))
        })
    },[inputText,limit])

    return {dataFiltered,isPending}
}