/* eslint-disable react/prop-types */
import { Box, Divider, Typography } from "@mui/material";
import React from "react";
import footerData from "./footerData";
import { numberWithCommas } from "../../../../../components/Utils/NumberWithCommas";

export function Footer({ order }) {
  const data = footerData({ order });

  const mercadoPagoRefund =
    order?.refunded || order?.mercadopagoData?.transaction_amount_refunded;

  const mercadoPagoTransaction = order?.mercadopagoData?.transaction_amount;

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "flex-start",
        padding: ".2rem 1rem",
        backgroundColor: "#fff",
        border: "1px solid #e0e0e0",
        borderRadius: 2,
        marginTop: "3px",
      }}
    >
      <Box sx={{ display: "flex", gap: 12 }}>
        <Box sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
          {data.map(({ label, info }, index) => (
            <span
              key={index}
              style={{
                borderBottom:
                  index !== footerData.length - 1 &&
                  "1px solid rgb(0 0 0 / 10%)",
              }}
            >
              <Typography
                sx={{ display: "inline", fontSize: "1rem", fontWeight: "bold" }}
              >
                {label}:
              </Typography>
              {info}
            </span>
          ))}
        </Box>
        {/* mercado pago refunds */}
        {mercadoPagoRefund ? (
          <Box sx={{ display: "flex", gap: 4, alignItems: "center" }}>
            <Typography>{`Valor pagado: $${numberWithCommas(
              mercadoPagoTransaction,
            )}`}</Typography>
            <Divider sx={{ height: "50%" }} orientation="vertical" />
            <Typography>{`Reintegro de mercado pago: $${
              numberWithCommas(mercadoPagoRefund) || 0
            }`}</Typography>
          </Box>
        ) : null}
      </Box>
    </Box>
  );
}
