/* eslint-disable react/prop-types */

import {
  Grid,
  AccordionSummary,
  Accordion,
  AccordionDetails,
  Typography,
  Box,
} from "@mui/material";
import React, { useState } from "react";
export function AccordionHeader({
  labelHeader,
  Icon,
  children,
  infoExtra,
  gridXS = 2,
}) {
  const [expanded, setExpanded] = useState(labelHeader);

  const handleChange = (panel) => (newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  return (
    <Grid sx={{ margin: "0 1rem" }} item xs={gridXS}>
      <Accordion>
        <AccordionSummary
          onChange={handleChange(labelHeader)}
          expanded={expanded === labelHeader}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              width: "100%",
            }}
          >
            <span
              style={{ display: "flex", alignItems: "center", marginRight: 2 }}
            >
              {Icon}
              <Typography sx={{ display: "inline", fontWeight: "bold" }}>
                {labelHeader}
              </Typography>
            </span>
            {infoExtra}
          </Box>
        </AccordionSummary>
        <AccordionDetails>{children && children}</AccordionDetails>
      </Accordion>
    </Grid>
  );
}
