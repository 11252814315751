import { useEffect, useState } from "react";
import { Link, withRouter } from "react-router-dom";
import {
  DataGridPremium,
  GridActionsCellItem,
  GridToolbar,
} from "@mui/x-data-grid-premium";
import _ from "lodash";
import { Box } from "@material-ui/core";
import VisibilityIcon from "@mui/icons-material/Visibility";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import { Button, Divider, Stack, TextField } from "@mui/material";
import TextRotateVerticalIcon from "@material-ui/icons/TextRotateVertical";
import SearchIcon from "@material-ui/icons/Search";
import FieldRender from "../CardFirestore/DataCard/components/FieldRender";
import { useAreaDataContext } from "../../context/AreaContext";
import { useSearch } from "../../algolia";
import { customFieldRender } from "../CustomField";
import { numberWithCommas } from "../Utils/NumberWithCommas";
// import moment from "moment";
import moment from "moment";
import { MuiDateRangePicker } from "../../utils/MuiCalendar";

function CommonRefinementList({ items, refine, title, column, index = null }) {
  const [showMore, setShowMore] = useState(false);
  const [input, setInput] = useState(false);
  const [iconAlf, setIconAlf] = useState(true);

  const [search, setSearch] = useState("");
  const itemsList =
    title === "Armado" || title === "Entrega"
      ? Object.keys(items || {})
      : Object.entries(items || {})
          ?.flatMap(
            ([, item]) =>
              item?.name?.toLowerCase() || item?.title?.toLowerCase(),
          )
          .filter((x) => x);

  const [itemsListState, setItemsListState] = useState(itemsList);

  function handleClick() {
    if (iconAlf) {
      setIconAlf(false);
    } else {
      setIconAlf(true);
    }
  }

  const searcher = (e) => {
    if (!e.target.value.length) {
      setItemsListState(
        itemsListState?.sort() || column.filter((c) => c.options?.filter),
      );
    } else {
      setItemsListState(
        itemsListState.filter((c) =>
          c.toLowerCase().includes(search.toLowerCase()),
        ),
      );
    }
    setSearch(e.target.value);
  };

  return (
    <Box style={{ maxWidth: 300, marginTop: "15px" }}>
      <Box style={{ background: "#fff", paddingLeft: 5 }}>
        <Box
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          {!input && (
            <Box
              onClick={() =>
                Object.keys(items || {}).length > 4 && setInput(true)
              }
              aria-hidden
              style={{
                cursor: Object.keys(items || {}).length > 4 ? "pointer" : "",
                display: "flex",
                alignItems: "center",
              }}
            >
              <span style={{ textDecoration: "underline", paddingLeft: 5 }}>
                {title}
              </span>
              {Object.keys(items || {}).length > 4 && <SearchIcon />}
            </Box>
          )}
          {input && (
            <div style={{ background: "#fff", paddingLeft: 5 }}>
              <TextField
                autoFocus
                value={search}
                placeholder="Buscar.."
                onChange={searcher}
                fullWidth
                size="small"
                onBlur={() => !search && setInput(false)}
              />
            </div>
          )}

          {Object.keys(items || {}).length > 4 && (
            <Box
              style={{
                marginRight: 10,
                color: iconAlf ? "#536DFE" : "#333",
                cursor: "pointer",
              }}
            >
              <TextRotateVerticalIcon onClick={() => handleClick()} />
            </Box>
          )}
        </Box>

        <ul style={{ listStyleType: "none", padding: "0px", margin: 0 }}>
          {iconAlf
            ? Object.entries(items || {})
                ?.filter(([k, v]) =>
                  search
                    ? v?.name
                        ?.toLowerCase()
                        ?.startsWith(search.toLowerCase()) ||
                      v?.title
                        ?.toLowerCase()
                        ?.startsWith(search.toLowerCase()) ||
                      k?.toLowerCase()?.startsWith(search.toLowerCase())
                    : v,
                )
                .map(([value, c]) => ({
                  ...c,
                  value,
                }))
                .sort(
                  (c, d) =>
                    c.title?.localeCompare(d?.title) ||
                    c.name?.localeCompare(d?.name) ||
                    c.value?.localeCompare(d?.value),
                )

                .slice(0, showMore ? 100 : 4)
                .map((item) => (
                  <li key={item?.objectID || item.value}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          style={{ padding: 3, paddingLeft: 9 }}
                          checked={item.isRefined}
                          onClick={() => refine(item.value)}
                        />
                      }
                      label={
                        <>
                          <FieldRender
                            f={column}
                            hit={{
                              [column.field]: item?.objectID
                                ? item
                                : item.value === "false"
                                ? false
                                : item.value,
                            }}
                            index={index}
                            link={false}
                          />
                          ({item.count})
                        </>
                      }
                    />
                    {search && (
                      <HighlightedText
                        text={item.name || item.title || item.value}
                        highlight={search}
                      />
                    )}
                  </li>
                ))
            : Object.entries(items || {})
                ?.filter(([, v]) =>
                  search
                    ? v?.name
                        ?.toLowerCase()
                        ?.startsWith(search.toLowerCase()) ||
                      v?.title?.startsWith(search.toLowerCase())
                    : v,
                )
                .map(([value, c]) => ({
                  ...c,
                  value,
                }))
                .slice(0, showMore ? 100 : 4)
                .map((item) => (
                  <li key={item?.objectID || item.value}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          style={{ padding: 3, paddingLeft: 9 }}
                          checked={item.isRefined}
                          onClick={() => refine(item.value)}
                        />
                      }
                      label={
                        <>
                          <FieldRender
                            f={column}
                            /* eslint-disable-next-line no-nested-ternary */
                            hit={{
                              [column.field]: item?.objectID
                                ? item
                                : item.value === "false"
                                ? false
                                : item.value,
                            }}
                            index={index}
                            link={false}
                          />
                          ({item.count})
                        </>
                      }
                    />
                  </li>
                ))}
        </ul>
        {Object.keys(items || {}).length > 4 && (
          <Button onClick={() => setShowMore((d) => !d)}>
            Mostrar {showMore ? "Menos" : "Mas"}
          </Button>
        )}
      </Box>
    </Box>
  );
}

function HighlightedText({ text = "", highlight = "" }) {
  const parts = text.split(new RegExp(`(${highlight})`, "gi"));
  return (
    <span>
      {" "}
      {parts.map((part, i) => (
        <span
          key={i}
          style={
            part?.toLowerCase() === highlight?.toLowerCase()
              ? {
                  fontWeight: "bold",
                  background: "#69f0ae",
                }
              : {}
          }
        >
          {part}
        </span>
      ))}{" "}
    </span>
  );
}

function InstantSearchTable({ index, filters, columns, facetFilters }) {
  const area = useAreaDataContext();
  const [selectedDate, setSelectedDate] = useState([
    moment().subtract(30, "day"),
    moment(),
  ]);
  const [facetFiltersState, setFacetFilters] = useState(
    facetFilters?.sort() || columns.filter((c) => c.options?.filter),
  );
  const timestampField = facetFiltersState.filter(
    (d) => d.field === "timestamp",
  )[0];
  let facetIncludesDate = timestampField
    ? timestampField?.options?.filter &&
      timestampField?.options?.type === "date"
    : false;
  const [requests, setRequests] = useState([
    {
      indexName: index,
      params: {
        filters: !timestampField
          ? filters
          : [
              ...filters.split(" AND ").filter((d) => d),
              `timestamp_timestamp>${selectedDate[0]?.unix()} AND timestamp_timestamp<${selectedDate[1]
                ?.endOf("d")
                ?.unix()}`,
            ].join(" AND "),
        facets: facetFiltersState.map((d) => d.field),
      },
    },
  ]);
  useEffect(() => {}, [requests]);
  facetFilters = facetFilters || columns.filter((c) => c.options?.filter);
  const [loading, setLoading] = useState(true);
  const { results } = useSearch({
    area: area?.objectID,
    requests,
    updates: true,
  });

  const [paginationModel, setPaginationModel] = useState({
    page: _.get(requests, [0, "params", "page"], 0),
    pageSize: _.get(requests, [0, "params", "hitsPerPage"], 20),
  });

  useEffect(() => {
    setLoading(true);
  }, [requests]);
  useEffect(() => {
    setLoading(false);
  }, [results]);

  const onFilterModelChange = (e) => {
    setRequests((d) =>
      _.set(
        _.clone(d),
        [0, "params", "query"],
        e?.quickFilterValues?.join(" ") || "",
      ),
    );
  };
  const handlePaginationModelChange = (props) => {
    setPaginationModel(props);
    return setRequests((d) => {
      const dd = _.clone(d);
      _.set(dd, [0, "params", "page"], props.page);
      _.set(dd, [0, "params", "hitsPerPage"], props.pageSize);
      return dd;
    });
  };

  const handleOnApplyDate = (date) => {
    setSelectedDate(date);
    setPaginationModel((d) => ({ ...d, page: 0 }));
    date?.length > 1 &&
      setRequests((d) => {
        _.set(d, [0, "params", "page"], 0);
        _.set(
          d,
          [0, "params", "filters"],
          [
            ...filters.split(" AND ").filter((d) => d),
            `timestamp_timestamp>${date[0]?.unix()} AND timestamp_timestamp<${date[1]
              ?.endOf("d")
              ?.unix()}`,
          ].join(" AND "),
        );
        return [...d];
      });
  };

  const isRefined = (field, value) =>
    !!_.get(requests, [0, "params", "facetFilters"], []).filter((a) =>
      a.includes(`${field}:${value}`),
    ).length;

  const onFacetFilterChange = (field, value) => {
    setPaginationModel((d) => ({ ...d, page: 0 }));
    setRequests((d) => {
      const newVal = `${field}:${value}`;
      const prevVal = _.get(d, [0, "params", "facetFilters"], []);
      const includes = prevVal.filter((a) => a.includes(newVal)).length;
      _.set(d, [0, "params", "page"], 0);
      _.set(
        d,
        [0, "params", "facetFilters"],
        !includes ? _.concat(prevVal, newVal) : _.pull(prevVal, newVal),
      );
      return [...d];
    });
  };

  const { hits, facets, nbHits } = results?.[0] || {};

  useEffect(() => {
    try {
      if (hits) {
        setTimeout(() => {
          for (const e of document.querySelectorAll(".MuiDataGrid-main > div"))
            e.textContent === "MUI X Missing license key" &&
              (e.style.display = "none");
        }, 1000);
      }
    } catch (e) {
      console.log(e);
    }
  }, [hits]);

  const [checked, setChecked] = useState(true);

  const [orderAlphabetic, setOrderAlphabetic] = useState(false);
  //titulos
  const orderAlf = Object.entries(facetFiltersState || {})
    .filter(([, a]) => a.name)
    .sort((c, d) => c[1]?.name?.localeCompare(d?.[1].name))
    .filter(([, v]) => facets?.[v?.field]);

  //input general busqueda titulos
  const [search, setSearch] = useState("");

  const searcher = (e) => {
    if (!e.target.value.length) {
      setFacetFilters(
        facetFilters?.sort() || columns.filter((c) => c.options?.filter),
      );
    } else {
      setFacetFilters(
        facetFiltersState.filter((column) =>
          column.name.toLowerCase().includes(search.toLowerCase()),
        ),
      );
    }
    setSearch(e.target.value);
  };

  let orderAlfComponent = orderAlf;
  let facetStateComponent = [];

  if (!search) {
    facetStateComponent = facetFiltersState;
  } else {
    facetFiltersState.filter((column) =>
      column.name.toLowerCase().includes(search.toLowerCase()),
    );
  }

  const [columnsAll] = useState([
    ...columns
      .filter((c) => c.options?.display ?? true)
      .map((c) => ({
        ...c,
        headerName: c.name,
        ...(c.options?.type === "number" && !c.field?.includes(".")
          ? {
              type: "number",
              groupable: false,
              valueFormatter: ({ value }) =>
                c.customField
                  ? customFieldRender({ [c.field]: value }, c.customField)
                  : numberWithCommas(value, c.options?.decimals || 0),
            }
          : {
              groupable: false,
              renderCell: (params) => (
                <FieldRender f={c} hit={params.row} index={index} />
              ),
            }),
      })),
    {
      field: "actions",
      type: "actions",
      headerName: "Abrir",
      width: 50,
      renderCell: (params) => (
        <Link to={`/app/${index}/${params.row?.objectID}`}>
          <GridActionsCellItem icon={<VisibilityIcon />} label="Abir" />
        </Link>
      ),
    },
  ]);

  return (
    <>
      <Stack direction="row">
        <Box style={{ marginTop: 15, width: "240px" }}>
          {facets && (
            <div style={{ width: "100%", background: "#fff", paddingLeft: 5 }}>
              <div
                style={{
                  alignItems: "center",
                  display: "flex",
                  background: "#333",
                }}
              >
                <SearchIcon style={{ color: "#fff" }} />
                <input
                  value={search}
                  type="text"
                  placeholder="Buscar Filtros..."
                  onChange={searcher}
                  onBlur={() =>
                    !search &&
                    setFacetFilters(
                      facetFilters?.sort() ||
                        columns.filter((c) => c.options?.filter),
                    )
                  }
                  style={{ padding: 5, width: "100%" }}
                />
              </div>
              <div style={{ marginBottom: 8, marginTop: 10, display: "flex" }}>
                <input
                  onClick={() => setOrderAlphabetic(true)}
                  onChange={() => setChecked(!checked)}
                  defaultChecked={checked}
                  type="checkbox"
                  id="ordenAlfabetico"
                  name="Ordenar Alfabéticamente"
                  value="Orden Alfabético"
                />
                <label htmlFor="ordenAlfabetico" style={{ fontSize: 12 }}>
                  Ordenar Alfabéticamente
                </label>
              </div>
              <Divider sx={{ marginBottom: "12px" }} />
              {facetIncludesDate && (
                <Box sx={{ marginTop: "0px" }}>
                  <MuiDateRangePicker
                    localeText={{ start: "Desde", end: "Hasta" }}
                    calendars={3}
                    direction={"row"}
                    date={selectedDate}
                    onChange={handleOnApplyDate}
                  />
                </Box>
              )}
              {!checked && orderAlphabetic ? (
                <div>
                  {facetStateComponent
                    .filter((v) => facets?.[v?.field])
                    .map((ff) => (
                      <CommonRefinementList
                        key={ff.field}
                        column={ff}
                        index={index}
                        title={
                          search ? (
                            <HighlightedText text={ff.name} highlight={search}>
                              {ff.name}
                            </HighlightedText>
                          ) : (
                            ff.name
                          )
                        }
                        attribute={ff.field}
                        refine={(v) => onFacetFilterChange(ff.field, v)}
                        items={_.mapValues(facets[ff.field], (v, k) => ({
                          ...v,
                          isRefined: isRefined(ff.field, k),
                        }))}
                      />
                    ))}
                </div>
              ) : (
                <div>
                  {orderAlfComponent.map(([id, x]) => (
                    <CommonRefinementList
                      key={id}
                      column={x}
                      index={index}
                      title={
                        search ? (
                          <HighlightedText text={x.name} highlight={search}>
                            {x.name}
                          </HighlightedText>
                        ) : (
                          x.name
                        )
                      }
                      attribute={x.field}
                      refine={(v) => onFacetFilterChange(x.field, v)}
                      items={_.mapValues(facets[x.field], (v, k) => ({
                        ...v,
                        isRefined: isRefined(x.field, k),
                      }))}
                    />
                  ))}
                </div>
              )}
            </div>
          )}
        </Box>

        <Box display="flex" flexGrow="1">
          {hits && (
            <DataGridPremium
              style={{ width: 0 }}
              sx={{
                width: 0,
                background: "#fff",
                marginTop: "15px",
              }}
              autoHeight
              getRowId={(row) => row.objectID}
              rows={hits}
              loading={loading}
              columns={columnsAll}
              pagination
              paginationMode="server"
              paginationModel={paginationModel}
              rowCount={nbHits}
              onPaginationModelChange={(props) =>
                handlePaginationModelChange(props)
              }
              pageSizeOptions={[20, 40, 100, 200]}
              density="compact"
              components={{ Toolbar: GridToolbar }}
              onFilterModelChange={onFilterModelChange}
              filterMode="server"
              initialState={{ pinnedColumns: { right: ["actions"] } }}
              disableColumnFilter
              componentsProps={{
                toolbar: {
                  showQuickFilter: true,
                  quickFilterProps: { debounceMs: 50 },
                },
              }}
            />
          )}
        </Box>
      </Stack>
    </>
  );
}

export default withRouter(InstantSearchTable);
