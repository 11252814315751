import { makeStyles } from "@material-ui/styles";

export default makeStyles(() => ({
  deletedItem: {
    background: "#FE7070",
    "&:hover": {
      background: "#FF8E8E!important",
    },
  },
  normalItem: {
    background: "#fff",
    "&:hover": {
      background: "rgb(0 0 0 / 15%)!important",
    },
  },
}));
