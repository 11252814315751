import {
  Button,
  ButtonGroup,
  CircularProgress,
  Grid,
  Stack,
} from "@mui/material";
import moment from "moment/moment";
import EditIcon from "@mui/icons-material/Edit";
import { useEffect, useReducer, useState } from "react";
import { AccountDetailsView } from "./DocAccountsView";
import AccountDetailsEdit from "../AccountsDetailsEdit";
import {
  newDoc,
  updateDoc,
  useDocumentData,
} from "../../../../../firebase/database";
import { DatePicker } from "@mui/x-date-pickers-pro";
import {
  Asynchronous,
  AutocompleteWhithOptions,
  FieldTextField,
} from "../../../../../components/CardFirestore/DataCard/components/EditForm";
import _ from "lodash";
import { useBanksDataContext } from "../../../BanksContext";
import {
  columnsChecks,
  columnsInvoices,
  columnsNetsuite,
  columnsTransactions,
} from "../../Commons";
import CloseIcon from "@mui/icons-material/Close";

const columns = [
  {
    field: "date",
    name: "Fecha",
    width: 120,
    options: {
      type: "date",
      disabled: ({ hit }) => !["manual", "cash"].includes(hit.bank),
    },
  },
  {
    field: "bank",
    name: "Banco / Tipo",
    width: 300,
    options: {
      options: {
        manual: "Manual",
        cash: "Efectivo",
        bbva: "bbva",
        icbc: "icbc",
        galicia: "galicia",
        visa_bbva: "visa_bbva",
        mp: "mp",
        itau: "itau",
      },
      disabled_options: ["bbva", "icbc", "galicia", "visa_bbva", "mp", "itau"],
      type: "autocomplete",
    },
  },
  { field: "reason", name: "Concepto", width: 300 },
  {
    field: "entity",
    name: "Persona",
    width: 300,
    options: {
      index: {
        field: "name",
        multiple: true,
        name: "entities",
        customText: "{{objectID}} - {{name}}",
      },
    },
  },

  { field: "notes", name: "Notas", width: 200 },
];

export default function TransactionDetailEdit({
  transactionId = "",
  tableType,
  isCreateAsiento,
}) {
  const [data, isLoading] = useDocumentData(tableType, transactionId);
  const [edit, setEdit] = useState(false);
  const [editedData, setFormData] = useReducer(
    (p, a) => _.omitBy({ ...p, ...a }, (v) => v === undefined),
    {},
    () => {},
  );
  const [dateError, setDateError] = useState(false);
  const { setData } = useBanksDataContext();

  const formData = { ...data, ...editedData };

  const resetValues = () => {
    setFormData(_.mapValues(editedData, () => undefined));
  };
  const detailInfo = {
    transactions: columnsTransactions,
    cobranzas_netsuite: columnsNetsuite,
    invoices: columnsInvoices,
    checks: columnsChecks,
  };

  useEffect(() => {
    setEdit(false);
  }, [data]);

  useEffect(() => {
    resetValues();
  }, [edit]);

  // el total de la cuenta tiene que dar 0 para ser valido
  const accountSum = _.round(
    (formData?.accounts || [])?.reduce((a, b) => a + (b?.v || 0), 0),
    2,
  );

  // no puede haber solo 1 fila que no tenga valores
  const isAccountValid =
    (formData?.accounts || [])?.every((a) => a?.v) &&
    accountSum === 0 &&
    (formData?.accounts || [])?.every((a) => a?.k);

  const isButtonDisabled = isCreateAsiento
    ? !formData.bank ||
      !formData.date ||
      dateError ||
      !isAccountValid ||
      !formData.reason
    : _.isEqual(data, formData) ||
      (!_.isEqual(data?.accounts, formData?.accounts) && !isAccountValid);

  const updateTransaction = async ({
    id = `manual-${formData.date}-${moment().unix()}`,
  }) => {
    const hit = editedData;

    if (isCreateAsiento) {
      await newDoc({
        indexName: "transactions",
        id,
        hit,
      });

      setData({
        isCreateAsiento: false,
        idSelected: id,
        tableType: "transactions",
      });
      console.log({
        id,
        hit: _.pickBy(hit, (v) => v),
      });
      setEdit(false);
    } else {
      await updateDoc({
        indexName: tableType,
        id,
        hit,
      });

      setData({
        isCreateAsiento: false,
        idSelected: id,
        tableType: tableType,
      });
      console.log({
        id,
        hit: _.pickBy(hit, (v) => v),
      });
      setEdit(false);
    }
  };

  return (
    <div
      style={{
        height: "auto",
        width: "100%",
        marginTop: 50,
        position: "sticky",
        top: "15px",
      }}
    >
      {isLoading ? (
        <CircularProgress />
      ) : (
        <div>
          {!isCreateAsiento ? (
            <Button
              sx={{ float: "right", marginRight: 1 }}
              onClick={() => {
                setEdit(!edit);
                resetValues();
              }}
              variant="outlined"
              color={edit ? "secondary" : "primary"}
              startIcon={edit ? <CloseIcon /> : <EditIcon />}
            >
              {edit ? "Cancelar" : "Editar"}
            </Button>
          ) : null}
          {isCreateAsiento || edit ? (
            <>
              {/* Fields form */}

              <Grid
                container
                justifyItems={"center"}
                justifyContent={"center"}
                spacing={2}
                sx={{
                  background: "#ffff",
                  marginY: 5,
                }}
              >
                <Stack spacing={1} sx={{ width: 300 }}>
                  {columns.map((field) =>
                    field?.options?.type === "autocomplete" ? (
                      <AutocompleteWhithOptions
                        key={field.field}
                        field={field}
                        values={formData?.[field.field]}
                        onChange={(value) => {
                          setFormData({
                            [field.field]: value,
                          });
                        }}
                      />
                    ) : field?.options?.index ? (
                      <Asynchronous
                        key={field.field}
                        title={field.name}
                        customText={field.options.index.customText}
                        index={field.options.index.name}
                        values={{
                          objectID: formData?.[field.field],
                        }}
                        onChange={(value) => {
                          setFormData({
                            [field.field]: value,
                          });
                        }}
                      />
                    ) : field.options?.type === "date" ? (
                      <DatePicker
                        key={field.field}
                        size="small"
                        disabled={
                          _.isFunction(field.options?.disabled) &&
                          field.options?.disabled({ hit: formData })
                        }
                        defaultValue={
                          formData[field.field] && moment(formData[field.field])
                        }
                        onError={(e) => setDateError(e)}
                        sx={{ width: "100%" }}
                        onChange={(date) =>
                          setFormData({
                            [field.field]: date.format("YYYY-MM-DD"),
                          })
                        }
                      />
                    ) : (
                      <FieldTextField
                        f={field}
                        values={formData}
                        update={({ value }) =>
                          setFormData({ [field.field]: value })
                        }
                        key={field.field}
                      />
                    ),
                  )}
                </Stack>
              </Grid>
              {/*  accounts form  */}

              <AccountDetailsEdit
                data_accounts={formData?.accounts}
                setDataAccounts={(accounts) =>
                  setFormData({
                    accounts,
                  })
                }
              />
              <ButtonGroup
                sx={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "space-around",
                  alignItems: "center",
                }}
              >
                <Button
                  sx={{
                    marginTop: 2,
                    marginBottom: 2,
                  }}
                  variant="outlined"
                  disabled={isButtonDisabled}
                  fullWidth
                  onClick={() => updateTransaction({ id: data?.objectID })}
                >
                  Confirmar
                </Button>
                {data && (
                  <Button
                    sx={{
                      marginTop: 2,
                      marginBottom: 2,
                    }}
                    variant="outlined"
                    color="secondary"
                    disabled={_.isEqual(data, formData)}
                    fullWidth
                    onClick={resetValues}
                  >
                    Reiniciar
                  </Button>
                )}
              </ButtonGroup>
            </>
          ) : (
            <AccountDetailsView
              data={{
                ...data,
                date: moment(data?.date).format("DD/MM/YYYY"),
              }}
              columns={detailInfo[tableType] || {}}
            />
          )}
        </div>
      )}
    </div>
  );
}
