import { createContext, useEffect, useState } from "react";
import { useSearchParams } from "../hooks/useSearchParams";
import { useMongoAggregate } from "../../../utils/mongoAggregate/useMongoAggregate";
import { useUserData } from "../../../context/UserContext";
export const SellerContext = createContext();

export default function SellerProvider({ children }) {
  const [sellerSelected, setSelectState] = useState();
  const { setParam, deleteParam, pushParams, getParam } = useSearchParams();
  const { uid } = useUserData();
  const [sellersData, sellersLoading] = useMongoAggregate({
    index: "sellers",
    refetchOnWindowFocus: false,
    aggregate: [
      {
        $match: {
          "data.users": { $in: [uid] },
        },
      },
      {
        $project: {
          "data.name": 1,
          "data.objectID": 1,
          "data.users": 1,
        },
      },
    ],
  });

  const [sellersState, setSellers] = useState([
    { label: "Luz Azul", value: "luz_azul" },
    { label: "Vivet", value: "v" },
    { label: "Ninguno", value: "" },
  ]);
  let providerName = getParam("seller");

  useEffect(() => {
    if (sellersLoading) return;
    if (sellersData && sellersData.length > 0) {
      const sellersFormatted = sellersData.map((s) => ({
        label: s.data.name[0].toUpperCase() + s.data.name.slice(1),
        value: s.data.objectID,
      }))
      setSellers(sellersFormatted);
      return handleSelectSeller(sellersData[0].data.objectID);
    }
  }, [sellersData]);

  const handleSelectSeller = (val) => {
    console.log(val)
    setSelectState(val);
    setParam("seller", val);
    return pushParams();
  };
  const handleDeleteSeller = () => {
    setSelectState();
    deleteParam("seller");
    return pushParams();
  };

  return (
    <SellerContext.Provider
      value={{
        providerName,
        sellerSelected,
        handleDeleteSeller,
        handleSelectSeller,
        sellersState,
        setSellers,
      }}
    >
      {children}
    </SellerContext.Provider>
  );
}
