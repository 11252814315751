import { useMongoAggregate } from "../../../utils/mongoAggregate/useMongoAggregate";
import queryString from "qs";
import moment from "moment";
import { useHistory } from "react-router-dom";
import { useEffect, useState } from "react";
import _ from "lodash";

const date_field = {
  transactions: "date",
  invoices: "date",
  checks: "dates.issue",
};

export const useEntityDocs = ({ entity, index = "transactions" }) => {
  const history = useHistory();
  const [data, setData] = useState();
  const [skip, setSkip] = useState(0);

  useEffect(() => {
    setSkip(0);
    setData((d) => (d ? false : d));
  }, [entity]);

  const parsedHash = queryString.parse(
    history.location.search?.replace("?", ""),
  );

  const dateFilter = parsedHash.from
    ? {
        ["data." + date_field[index]]: {
          $lt: moment(parsedHash.to)
            ?.clone()
            .add(1, "day")
            .format("YYYY-MM-DD"),
        },
      }
    : {};

  const [response, isLoading, , { isRefetching }] = useMongoAggregate({
    refetchOnWindowFocus: false,
    // keepPreviousData: false,
    index: index,
    aggregate: [
      {
        $match: {
          "data.entity": entity,
          ...dateFilter,
          "data.canceled": { $ne: true },
        },
      },
      {
        $sort: {
          ["data." + date_field[index]]: 1,
        },
      },
      {
        $project: {
          id: "$data.objectID",
          reason: "$data.reason",
          date: "$data." + date_field[index],
          bank: "$data.bank",
          entity: "$data.entity",
          entity_name: "$data.entity_name",
          description: "$data.description",
          amount: "$data.amount",
          amounts: "$data.amounts",
          index: index,
        },
      },
      ...(skip
        ? [
            {
              $skip: skip,
            },
          ]
        : []),
      {
        $limit: 20000,
      },
    ],
  });

  useEffect(() => {
    if (response) {
      setData((data) => {
        const d = _.uniqBy([...response, ...(data || [])], "id");
        d?.length &&
          _.isInteger(d?.length / 20000) &&
          setSkip((s) => s + 20000);
        return d;
      });
    }
  }, [response]);

  return [
    data,
    isRefetching || isLoading || (skip && _.isInteger(data?.length / 20000)),
  ];
};
