/* eslint-disable react/jsx-key */
/* eslint-disable no-nested-ternary */

import React from "react";
import { faBox, faShoppingBasket } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Avatar, Box, IconButton, Tooltip, Typography } from "@mui/material";
import { GridActionsCellItem } from "@mui/x-data-grid";
import { Link } from "react-router-dom";
import ImageIcon from "@mui/icons-material/Image";
import { numberWithCommas } from "../../../../components/Utils/NumberWithCommas";
import { Sf } from "../../../../components/Widget/SnowFlakes";
import { EditDiscount } from "./EditDiscount";
import { EditQuantity } from "./EditQuantity";
import { InfoOutlined } from "@mui/icons-material";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import moment from "moment";
import scan from "../../../../images/barcode-scan.png";
import _ from "lodash";

export const columns = ({ setLightboxImage }) => {
  return [
    {
      field: "EAN",
      headerName: "EAN",
      width: 100,
      hide: true,
    },
    {
      field: "brand",
      headerName: "Marca",
      width: 100,
      type: "actions",
      getActions: (params) => [
        <Link
          style={{ cursor: "pointer", textDecoration: "unset", color: "black" }}
          to={`/app/brands/${params.row.data?.brand}`}
        >
          <span>{params.row.brand}</span>
        </Link>,
      ],
    },
    {
      field: "product",
      headerName: "Producto",
      width: 350,
      renderCell: (params) => (
        <Link
          style={{ cursor: "pointer", textDecoration: "unset", color: "black" }}
          to={`/app/products/${params.row?.id}`}
        >
          {params.row?.product}
          <Sf
            q={
              params.row.data.conservation.charAt(0) === "c"
                ? 2
                : params.row.data?.conservation.charAt(0) === "f"
                  ? 1
                  : 0
            }
          />
        </Link>
      ),
      getApplyQuickFilterFn: (value) => (params) =>
        params.row.product
          .toLowerCase()
          .normalize("NFD")
          .replace(/[\u0300-\u036f]/g, "")
          .includes(
            value
              .normalize("NFD")
              .replace(/[\u0300-\u036f]/g, "")
              .toLowerCase(),
          ),
      valueGetter: ({ value }) => value && value,
    },
    {
      field: "expDate",
      headerName: "Fecha de vencimiento",
      width: 200,
      type: "actions",
      getActions: (params) => [
        <Box sx={{ display: "flex", flexDirection: "column" }}>
          {params.row.expDate.map((e) => (
            <Typography> {e}</Typography>
          ))}
        </Box>,
      ],
    },
    // {
    //   field: "purchasePrice",
    //   headerName: "Precio de compra [ $ ]",
    //   width: 170,
    //   valueFormatter: (params) => `$ ${numberWithCommas(params.value, 2)}`,
    // },
    {
      field: "image",
      headerName: "Imagen",
      width: 200,
      type: "actions",
      getActions: (params) => [
        <GridActionsCellItem
          key={params.row.id}
          onClick={() => params.row.image && setLightboxImage(params.row.image)}
          icon={
            <Avatar src={params.row.image}>
              {" "}
              <ImageIcon />
            </Avatar>
          }
          label="Delete"
        />,
      ],
    },
    {
      field: "q",
      headerName: "Cantidad",
      width: 220,
      renderCell: (params) => <EditQuantity params={params} />,
      valueFormatter: (params) => _.round(params.value, 2),
    },
    {
      field: "assembly",
      headerName: "Cajas / Canastos",
      width: 130,
      type: "actions",
      renderCell: (params) => (
        <span>
          <Typography sx={{ display: "inline" }}>
            {params.row?.assembly || params.row?.basket}
          </Typography>
          {Object.keys(params.row.shippingBox || {}).length ? (
            <>
              <FontAwesomeIcon icon={faBox} />
              <Sf
                q={
                  Object.keys(params.row.shippingBox || {})?.[0]?.charAt(0) ===
                  "C"
                    ? 2
                    : Object.keys(params.row.shippingBox || {})?.[0]?.charAt(
                      0,
                    ) === "F"
                      ? 1
                      : 0
                }
              />
              <Tooltip
                sx={{ bottom: 2, color: "primary" }}
                title={
                  <>
                    Se agregó items del canasto:
                    <>
                      <Typography style={{ fontSize: 20 }}>
                        <FontAwesomeIcon icon={faShoppingBasket} />{" "}
                        {params.row.basket || ""}
                      </Typography>
                    </>
                  </>
                }
              >
                <IconButton>
                  <InfoOutlined />
                </IconButton>
              </Tooltip>
            </>
          ) : (
            <>
              <FontAwesomeIcon icon={faShoppingBasket} />
              {params.row.oBasket ? (
                <Tooltip
                  sx={{ bottom: 2, color: "primary" }}
                  title={<>Se dividió del canasto: {params.row.oBasket}</>}
                >
                  <IconButton>
                    <InfoOutlined />
                  </IconButton>
                </Tooltip>
              ) : null}
            </>
          )}
          <Sf
            q={
              params.row.basket?.charAt(0) === "c"
                ? 2
                : params.row.basket?.charAt(0) === "f"
                  ? 1
                  : 0
            }
          />
          {params.row.pickedTimestamp || params.row.checkedTimestamp ? (
            <Tooltip
              title={
                <Box display={"flex"} flexDirection={"column"}>
                  {params.row.pickedTimestamp
                    ? "Armado: " +
                    moment(
                      params.row?.pickedTimestamp?.seconds * 1000,
                    ).format("DD/MM/YYYY HH:mm")
                    : null}
                  {params.row.checkedTimestamp
                    ? "Controlado: " +
                    moment(
                      params.row?.checkedTimestamp?.seconds * 1000,
                    ).format("DD/MM/YYYY HH:mm")
                    : null}
                </Box>
              }
            >
              <IconButton>
                <CalendarMonthIcon
                  style={{ fontSize: 20, position: "relative", bottom: 3 }}
                />
              </IconButton>
            </Tooltip>
          ) : null}
          {params.row?.scannedTimestamp && <Tooltip title='Escaneado'>
            <img src={scan} width={20} height={20} />
          </Tooltip>}
        </span>
      ),
      getApplyQuickFilterFn: (value) => (params) =>
        params.row?.assembly
          .toLowerCase()
          .normalize("NFD")
          .replace(/[\u0300-\u036f]/g, "")
          .includes(
            value
              .normalize("NFD")
              .replace(/[\u0300-\u036f]/g, "")
              .toLowerCase(),
          ),
    },
    {
      field: "price",
      headerName: "Precio / u [$]",
      width: 130,
      renderCell: (params) => `$ ${numberWithCommas(params.value, 0)}`,
      valueFormatter: (params) => _.round(params.value, 2),
    },

    {
      field: "discount",
      headerName: "Descuento",
      width: 130,
      valueFormatter: (params) => _.round(params.value, 2),
      renderCell: (params) => <EditDiscount params={params} />,
    },
    {
      field: "iva",
      headerName: "IVA",
      width: 100,
    },
    {
      field: "subTotal",
      headerName: "Sub Total c/IVA [$]",
      width: 200,
      renderCell: (params) => `$ ${numberWithCommas(params.value, 0)}`,
      valueFormatter: (params) => _.round(params.value, 2),
    },
  ];
};
