import {
  Box,
  Button,
  Checkbox,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { useState } from "react";
import DeleteIcon from "@mui/icons-material/Delete";
import { updateDoc } from "../../../../../firebase/database";
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import { pdfCreate } from "../../../../../utils/pdfMaker/pdfCreate";
import { templateRemito } from "../../../../remitosPDF/templateRemito.js";
import templateGenerator from "../../../../../utils/pdfMaker/templateGenerator";
import { numberWithCommas } from "../../../../../components/Utils/NumberWithCommas.js";
import { keys, pickBy, sortBy } from "lodash";
pdfMake.vfs = pdfFonts.pdfMake.vfs;

const MODEL = {
  title: "",
  details: "",
  view: [],
};

const OPTIONS = [
  { value: "picking", label: "Picking" },
  { value: "driver", label: "Chofer" },
  { value: "remito", label: "Remito" },
  { value: "roadmap", label: "Roadmap" },
];

export const EditObservations = ({
  observations,
  orderID,
  toggleVisibility,
  order,
}) => {
  const [newObservations, setNewObservations] = useState(
    observations || [{ ...MODEL }],
  );
  const addObservation = () => {
    setNewObservations((prev) => [...prev, { ...MODEL }]);
  };
  const deleteObservation = (index) => {
    const updatedObservations = newObservations.filter(
      (elemento, indice) => index !== indice,
    );
    setNewObservations(updatedObservations);
  };

  const saveChanges = async () => {
    await updateDoc({
      indexName: "orders",
      id: orderID,
      hit: { ["observations"]: newObservations },
    }).then(() => toggleVisibility());
  };

  const showBoxes = (product) => {
    if (!product?.shippingBox) return "";

    return Object.keys(product.shippingBox).join(",");
  };

  const testPrint = () => {
    const columns = [
      { label: "Cant.", key: "q" },
      { label: "Código", format: (v) => v?.data?.sellerCode || "" },
      {
        label: "Descripción",
        format: (product) =>
          `${product.data?.brandName} - ${product.data?.name}  ${
            product.data?.quantity
              ? product.data?.quantity + product.data?.unit
              : ""
          }`,
      },
      {
        label: "Sabor",
        format: (product) => product?.data?.taste || "",
      },
      {
        label: "Caja/s",
        format: (product) => showBoxes(product),
      },
      {
        label: "Precio",
        format: (product) => {
          const price =
            Math.round(
              (product.price || 0) *
                (1 + (order.priceList === "g" ? product.iva ?? 0.21 : 0)) *
                (1 - (product.discount || 0)) *
                100 || 0,
            ) / 100;

          return `$ ${numberWithCommas(price)}`;
        },
      },
      {
        label: "Subtotal",
        format: (product) => {
          const price =
            Math.round(
              (product.price || 0) *
                (1 + (order.priceList === "g" ? product.iva ?? 0.21 : 0)) *
                (1 - (product.discount || 0)) *
                100 || 0,
            ) / 100;

          const q = product.tq || product.q || 0;

          return `$ ${numberWithCommas(q * price)}`;
        },
      },
    ];

    const sortedItems = sortBy(
      order?.items.map((v) => ({ ...v, cajas: keys(v.shippingBox).join(",") })),
      [
        "cajas",
        "basket",
        "shippingBox",
        "data.brandRanking",
        "data.ranking",
        "data.name",
        "data.taste",
      ],
    );

    const columnsWidth = ["*", "auto"];

    const itemsWithBox = Object.entries(
      pickBy(sortedItems, (v) => v?.items) || {},
    )?.map(([key, v]) => ({
      ...v,
      objectID: key,
    }));

    const itemsWithoutBox = Object.entries(
      pickBy(sortedItems, (v) => !v?.items) || {},
    )?.map(([key, v]) => ({
      ...v,
      objectID: key,
    }));

    pdfCreate(
      templateGenerator({
        items: [...itemsWithBox, ...itemsWithoutBox],
        columns,
        columnsWidth,
        template: (props) => templateRemito({ ...props, order }),
      }),
    );
  };

  return (
    <Box width="fit-content">
      <Typography sx={{ fontWeight: "bold" }}>Observaciones</Typography>
      <Stack direction="column" gap={2} flexWrap="wrap" marginTop={2}>
        {(newObservations || []).map((observation, index) => (
          <Stack direction="row" spacing={1} key={index}>
            <Stack alignItems="center" justifyContent="center">
              <DeleteIcon
                onClick={() => deleteObservation(index)}
                color="secondary"
              />
            </Stack>
            <Stack>
              <Typography sx={{ fontWeight: "bold", mb: 1 }}>Título</Typography>
              <TextField
                value={observation.title}
                onChange={(e) => {
                  const updatedObservations = [...newObservations];
                  updatedObservations[index].title = e.target.value;
                  setNewObservations(updatedObservations);
                }}
                label="titulo"
              />
            </Stack>
            <Stack direction="column">
              <Typography sx={{ fontWeight: "bold", mb: 1 }}>
                Detalle
              </Typography>
              <TextField
                label="Detalle"
                value={observation.details}
                onChange={(e) => {
                  const updatedObservations = [...newObservations];
                  updatedObservations[index].details = e.target.value;
                  setNewObservations(updatedObservations);
                }}
              />
            </Stack>
            <Stack direction="row" w="100%" spacing={1}>
              {OPTIONS.map((option) => (
                <Stack key={option}>
                  <Typography sx={{ fontWeight: "bold" }}>
                    {option.label}
                  </Typography>
                  <Checkbox
                    checked={newObservations[index]?.view?.includes(
                      option.value,
                    )}
                    onChange={(e) => {
                      const checked = e.target.checked;
                      let updatedObservations = [...newObservations];
                      if (checked) {
                        let viewAdded = updatedObservations[index].view.concat(
                          option.value,
                        );
                        updatedObservations[index].view = viewAdded;
                      }
                      if (!checked) {
                        let viewFiltered = updatedObservations[
                          index
                        ].view.filter((op) => op !== option.value);
                        updatedObservations[index].view = viewFiltered;
                      }
                      setNewObservations(updatedObservations);
                    }}
                  />
                </Stack>
              ))}
            </Stack>
          </Stack>
        ))}
      </Stack>
      {/* ITEMS */}

      <Button
        sx={{ marginY: 2, w: "100%" }}
        onClick={addObservation}
        fullWidth
        variant="outlined"
        color="primary"
      >
        agregar
      </Button>
      <Button
        onClick={() => saveChanges()}
        variant="contained"
        color="primary"
        fullWidth
      >
        CONFIRMAR
      </Button>
      <Stack alignItem="start">
        <Button onClick={testPrint}>prueba</Button>
      </Stack>
    </Box>
  );
};
