// import {useDocumentData} from "../../firebase";
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import { populateOrder } from "../app/orders/utils/populateOrder";
import _, { keys, sortBy } from "lodash";
import { pdfCreate } from "../../utils/pdfMaker/pdfCreate";
import templateGenerator from "../../utils/pdfMaker/templateGenerator";
import { numberWithCommas } from "../../components/Utils/NumberWithCommas";
import { templateRemito } from "./templateRemito";
// import {Button} from "@mui/material";
pdfMake.vfs = pdfFonts.pdfMake.vfs;

// function downloadPDF(orderDoc) {
//     populateOrder(orderDoc).then(orderData => {
//         const pdfDoc = remitoTemplate(orderData);
//         pdfMake.createPdf(pdfDoc).download("remito.pdf");
//     });
// }

const conds = [
  (v) =>
    ["frio", "congelado"].includes(v.data.conservation) &&
    ![
      "xuabpCgck1CmbGRl6PGO",
      "Lyr51nVaNxVsMGpsLOkI",
      "zw5AKTsPxEj8HZk2r53f",
      "g1T8ooUaciaatbxPZqxq",
      "Psia1wxJdNS7gOL29phc",
      "4e9kkpcoRJDJjFqJgUSP", 
      "S6Vc8GTWvxfjNPB6o7QS",
    ].includes(v.data.brand),
  (v) =>
    !["frio", "congelado"].includes(v.data.conservation) &&
    ![
      "xuabpCgck1CmbGRl6PGO",
      "Lyr51nVaNxVsMGpsLOkI",
      "zw5AKTsPxEj8HZk2r53f",
      "g1T8ooUaciaatbxPZqxq",
      "Psia1wxJdNS7gOL29phc",
      "4e9kkpcoRJDJjFqJgUSP",
      "S6Vc8GTWvxfjNPB6o7QS",
    ].includes(v.data.brand),
  (v) =>
    [
      "xuabpCgck1CmbGRl6PGO",
      "Lyr51nVaNxVsMGpsLOkI",
      "zw5AKTsPxEj8HZk2r53f",
      "g1T8ooUaciaatbxPZqxq",
      "Psia1wxJdNS7gOL29phc",
      "4e9kkpcoRJDJjFqJgUSP",
      "S6Vc8GTWvxfjNPB6o7QS",
    ].includes(v.data.brand),
];

export const columnsWidths = [20, 45, "*", "*", 28, 50, 50];

const formatQ = (data) => {
  return `${data.q || 0} ${
    data.tq >= 0 && data.q != data.tq ? `(${data.tq})` : "" || ""
  }`;
};

export function printPDF({ orderDoc, printAll }) {
  const showBoxes = (product) => {
    if (!product?.shippingBox) return "";

    return Object.keys(product.shippingBox).join(",");
  };

  const columns = [
    { label: "Cant", format: (v) => formatQ(v) },
    { label: "Código", format: (v) => v?.data?.sellerCode || "" },
    {
      label: "Descripción",
      format: (product) =>
        `${product.data?.brandName} - ${product.data?.name} ${
          product.data?.quantity
            ? product.data?.quantity + product.data?.unit
            : ""
        }`,
    },
    {
      label: "Sabor",
      format: (product) => product?.data?.taste || "",
    },
    {
      label: "Caja/s",
      format: (product) => showBoxes(product),
    },
    {
      label: "Precio",
      format: (product) => {
        const price =
          Math.round(
            (product.price || 0) *
              (1 + (orderDoc.priceList === "g" ? product.iva ?? 0.21 : 0)) *
              (1 - (product.discount || 0)) *
              100 || 0,
          ) / 100;

        return `$ ${numberWithCommas(price)}`;
      },
    },
    {
      label: "Subtotal",
      format: (product) => {
        const price =
          Math.round(
            (product.price || 0) *
              (1 + (orderDoc.priceList === "g" ? product.iva ?? 0.21 : 0)) *
              (1 - (product.discount || 0)) *
              100 || 0,
          ) / 100;

        const q = product.tq || product.q || 0;

        return `$ ${numberWithCommas(q * price)}`;
      },
    },
  ];

  populateOrder(orderDoc).then((orderData) => {
    if (printAll) {
      const data = {
        ...orderData,
        items: orderData.items,
      };
      const shippingBoxes = _.pickBy(
        data.shippingBoxes,
        (a, k) =>
          Object.values(data.items).filter((d) =>
            Object.keys(d.shippingBox || {}).includes(k),
          ).length,
      );

      const sortedItems = sortBy(
        _.map(orderData?.items, (v) => ({
          ...v,
          cajas: keys(v.shippingBox).join(","),
        })),
        [
          "cajas",
          "basket",
          "shippingBox",
          "data.brandRanking",
          "data.ranking",
          "data.name",
          "data.taste",
        ],
      );
      const itemsWithBox = Object.entries(
        _.pickBy(sortedItems, (v) => v?.items) || {},
      )?.map(([key, v]) => ({
        ...v,
        objectID: key,
      }));

      const itemsWithoutBox = Object.entries(
        _.pickBy(sortedItems, (v) => !v?.items) || {},
      )?.map(([key, v]) => ({
        ...v,
        objectID: key,
      }));

      pdfCreate(
        templateGenerator({
          items: [...itemsWithBox, ...itemsWithoutBox],
          columns,
          columnsWidths,
          template: (props) =>
            templateRemito({
              ...props,
              order: { ...orderData, shippingBoxes },
            }),
        }),
      );
    } else {
      // imprime por conservacion
      for (let i in conds) {
        const sortedItems = sortBy(
          _.map(orderData?.items, (v) => ({
            ...v,
            cajas: keys(v.shippingBox).join(","),
          })),
          [
            "cajas",
            "basket",
            "shippingBox",
            "data.brandRanking",
            "data.ranking",
            "data.name",
            "data.taste",
          ],
        );
        const itemsWithBox = Object.entries(
          _.pickBy(sortedItems, (v) => v?.items && conds[i](v)) || {},
        )?.map(([key, v]) => ({
          ...v,
          objectID: key,
        }));

        const itemsWithoutBox = Object.entries(
          _.pickBy(sortedItems, (v) => !v?.items && conds[i](v)) || {},
        )?.map(([key, v]) => ({
          ...v,
          objectID: key,
        }));

        const data = {
          ...orderData,
          items: printAll
            ? orderData.items
            : _.pickBy(orderData.items, conds[i]),
        };
        const shippingBoxes = _.pickBy(
          data.shippingBoxes,
          (a, k) =>
            Object.values(data.items).filter((d) =>
              Object.keys(d.shippingBox || {}).includes(k),
            ).length,
        );

        pdfCreate(
          templateGenerator({
            items: printAll
              ? Object.entries(orderData.items || {})?.map(([key, v]) => ({
                  ...v,
                  objectID: key,
                }))
              : [...itemsWithBox, ...itemsWithoutBox],
            columns,
            columnsWidths,
            template: (props) =>
              templateRemito({
                ...props,
                order: {
                  ...orderData,
                  ...calcNewTotal({ hit: data }),
                  shippingBoxes,
                },
              }),
          }),
        );
      }
    }
  });
}

function calcNewTotal({ hit }) {
  let subTotal = 0;
  let discount = 0;
  let iva = 0;
  let shipping_cost = hit.shipping_cost || 0;
  for (let i in hit.items)
    if (hit.items[i].q) {
      let it = hit.items[i];
      subTotal += round(it.q * it.price, 2);
      discount += round(
        (it.discount || hit.percentDiscount || 0) * (it.q * it.price),
        2,
      );
      iva +=
        (it.iva ?? 0.21) * round(it.q * it.price * (1 - (it.discount || 0)), 2);
    }

  // discount += ( hit.percentDiscount || hit.porcentDiscount || 0 ) * subTotal;
  let mp_comission =
    (hit.percent_mp_comission || 0) *
    (subTotal - discount + iva + shipping_cost);

  return {
    mp_comission,
    discount,
    subTotal,
    iva,
    ...(!hit.ototal
      ? { ototal: Number(hit.total.toFixed(2)), osubTotal: hit.subTotal }
      : {}),
    total: round(subTotal + mp_comission - discount + iva + shipping_cost, 2),
  };
}

function round(num, n) {
  n = Math.pow(10, n);
  return Math.round(num * n) / n;
}
