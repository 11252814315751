//si pasas por parametros de resolucion un 1 se genera con la mejor resolucion

export default function imgurWebp(url, maxwidth = 520) {
  return url?.includes("imgur")
    ? url
        .replace(
          /(\.png)|(\.jpg)/,
          `_d.webp?maxwidth=${maxwidth}&shape=thumb&fidelity=high`,
        )
        .replace(/.*imgur.com/, "https://i.imgur.com")
    : url;
}
