/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable no-nested-ternary */

import React from "react";
import LinearProgress from "@material-ui/core/LinearProgress";
import Box from "@material-ui/core/Box";
import { withStyles } from "@material-ui/styles";

const BorderLinearProgressGrey = withStyles(() => ({
  root: {
    height: 3,
    borderRadius: 5,
  },
  colorPrimary: {
    backgroundColor: "#ccc",
  },
  bar: {
    borderRadius: 5,
    backgroundColor: "rgba(0,0,0,0.3)",
  },
}))(LinearProgress);
const BorderLinearProgressGreen = withStyles(() => ({
  root: {
    height: 3,
    borderRadius: 5,
  },
  colorPrimary: {
    backgroundColor: "#ccc",
  },
  bar: {
    borderRadius: 5,
    backgroundColor: "#74c871",
  },
}))(LinearProgress);
const BorderLinearProgressRed = withStyles(() => ({
  root: {
    height: 3,
    borderRadius: 5,
  },
  colorPrimary: {
    backgroundColor: "#ccc",
  },
  bar: {
    borderRadius: 3,
    backgroundColor: "#FF5C93",
  },
}))(LinearProgress);

const BorderLinearProgressBlue = withStyles(() => ({
  root: {
    height: 4,
    borderRadius: 5,
  },
  colorPrimary: {
    backgroundColor: "#ccc",
  },
  bar: {
    borderRadius: 5,
    backgroundColor: "#536dfe",
  },
}))(LinearProgress);

const BorderLinearProgressYellow = withStyles(() => ({
  root: {
    height: 4,
    borderRadius: 5,
  },
  colorPrimary: {
    backgroundColor: "#ccc",
  },
  bar: {
    borderRadius: 5,
    backgroundColor: "#fec853",
  },
}))(LinearProgress);

export function LinearProgressLabel(value) {
  return (
    <Box width="100%" display="flex" alignItems="center">
      <Box width="100%" mr={1}>
        {value.label === "Total" ? (
          <BorderLinearProgressGrey variant="determinate" {...value} />
        ) : value.label === "Reservado" ? (
          <BorderLinearProgressRed variant="determinate" {...value} />
        ) : value.label === "Disponible" ? (
          <BorderLinearProgressGreen variant="determinate" {...value} />
        ) : value.label === "En picking" ? (
          <BorderLinearProgressBlue variant="determinate" {...value} />
        ) : (
          <BorderLinearProgressYellow variant="determinate" {...value} />
        )}
      </Box>
      <Box minWidth={35}>
        {value.label === "Total" ? (
          <p
            style={{
              margin: 0,
              whiteSpace: "nowrap",
              fontSize: ".8rem",
              lineHeight: "1",
              width: 60,
            }}
          >
            {value?.label} {value?.info}
          </p>
        ) : value.label === "Reservado" ? (
          <p
            style={{
              margin: 0,
              whiteSpace: "nowrap",
              color: "#FF5C93",
              fontSize: ".8rem",
              lineHeight: "1",
              width: 60,
            }}
          >
            {value?.label} {value?.info}
          </p>
        ) : value.label === "Disponible" ? (
          <p
            style={{
              margin: 0,
              whiteSpace: "nowrap",
              color: "#74c871",
              fontSize: ".8rem",
              lineHeight: "1",
              width: 60,
            }}
          >
            {value?.label} {value?.info}
          </p>
        ) : value.label === "En picking" ? (
          <p
            style={{
              margin: 0,
              whiteSpace: "nowrap",
              color: "#536dfe",
              fontSize: ".8rem",
              lineHeight: "1",
              width: 60,
            }}
          >
            {value?.label} {value?.info}
          </p>
        ) : (
          <p
            style={{
              margin: 0,
              whiteSpace: "nowrap",
              color: "#feb453",
              fontSize: ".8rem",
              lineHeight: "1",
              width: 60,
            }}
          >
            {value?.label} {value?.info}
          </p>
        )}
      </Box>
    </Box>
  );
}
