import { Box, Typography } from "@material-ui/core";
import { Skeleton, Stack } from "@mui/material";
import { BrandsList } from "../components/brands/BrandsList";
import { FiltersSection } from "../components/brands/FiltersSection";
import { useContext, useEffect, useState } from "react";
import { BrandsCardsSkeleton } from "../components/CardsSkeleton";
import { useMongoAggregate } from "../../../utils/mongoAggregate/useMongoAggregate";
import { MainLayout } from "../layouts/MainLayout";
import _ from "lodash";
import { useSearchParams } from "../hooks/useSearchParams";
import { ProviderContext } from "../context/providerContext";

export default function Brands() {
  const {getParam} = useSearchParams()
  const providerCompany = getParam("providerCompany");
  const seller = getParam("seller");
  const { providerSelected,defaultBrands } = useContext(ProviderContext);

  const [data, loading, error] = useMongoAggregate({
    index: "brands",
    refetchOnWindowFocus: false,
    aggregate: [
      {$match:{
          "data.objectID": { $in: providerCompany ? providerSelected?.brands : defaultBrands },
          "data.seller":seller ,
        
      }},
      {
        $project: {
          "data.active": 1,
          "data.objectID": 1,
          "data.description": 1,
          "data.name": 1,
          "data.active_products": 1,
          "data.seller": 1,
          "data.image": 1,
          "data.available_products": 1,
        },
      },
    ],
  });

  const brandsFormatted = _.uniqBy(data?.map((brand) => brand.data));
  const [brands, setBrands] = useState(brandsFormatted ? brandsFormatted : []);

  useEffect(() => {
    setBrands(_.uniqBy(data?.map((brand) => brand.data)))
  },[providerCompany,data])
  if (error) return null;

  return (
    <MainLayout>
      <Box marginBottom={2}>
        {loading && (
          <Skeleton variant="text" width="200px" height="35px"></Skeleton>
        )}
        {!loading && data && (
          <Typography
            component="h3"
            style={{ fontWeight: 700, fontSize: "24px", color: "#1e293b" }}
          >
            Marcas ({data.length})
          </Typography>
        )}
      </Box>
      {loading && (
        <Stack marginBottom={2} gap={1}>
          <Skeleton
            variant="rounded"
            animation="wave"
            height="50px"
            width="100%"
            sx={{ fontSize: "1rem" }}
          />
          <Skeleton
            variant="rounded"
            height="30px"
            width="140px"
            sx={{ fontSize: "1rem" }}
          />
        </Stack>
      )}

      {loading && <BrandsCardsSkeleton />}

      {!loading && (
        <Stack gap={2} height="100%">
          <FiltersSection
            data={brandsFormatted}
            onChange={(filteredData) => setBrands(filteredData.dataFiltered)}
          />
          <BrandsList data={brands} />
        </Stack>
      )}
    </MainLayout>
  );
}
