const indexNames = {
  users: "Usuarios",
  stores: "Tiendas",
  roles: "Roles",
  brands: "Marcas",
  priceList: "Lista de precios",
  products: "Productos",
  specs: "Categorías",
  paymentOptions: "Opciones de pago",
  carriers: "Transportistas",
  providers: "Proveedores",
  purchaseOrders: "Ordenes de compra",
};

const paymentOptions = {
  cash: 'Efectivo',
  check: 'Cheque',
  mp: 'Mercado pago',
  mpDebito: 'Debito',
  mpQr: 'Con QR',
  wireTransfer: 'Transferencia bancaria',
}

const paymentStates = {
 pending: 'Pendiente',
 payed: 'Pagado',
}

export { indexNames, paymentOptions, paymentStates };
