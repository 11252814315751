export const ProductsControlSearchBoxFilters = [
  {
    field: "seller",
    defaultValue: "",
    visible: false,
    options: {
      Vivet: "v",
      "Luz Azul": "luz_azul",
    },
    label: "Vendedor",
  },
  {
    field: "nostock",
    visible: true,
    options: {
      "En Stock en Market": false,
      "Sin Stock en Market": true,
    },
    label: "Stock",
  },
  {
    field: "inventoryOnbin",
    visible: true,
    options: {
      "Agotado en Bins": false,
      "Con Stock en Bins": true,
    },
    label: "Bins",
  },
  {
    field: "inventoryAvailable",
    visible: true,
    options: {
      Disponible: true,
      "No Dispnible": false,
    },
    label: "Disponibilidad",
  },
  {
    field: "active",
    visible: true,
    options: {
      Activo: true,
      "No Activo": false,
    },
    label: "Activo",
  },
];
