import { Card, CardActions,Button, CardContent, Divider, Icon, Stack, Typography } from "@mui/material";
import LocationOnIcon from '@mui/icons-material/LocationOn';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { useState } from "react";

export function TaskCard({title,direction,disableButtonText,defaultFinished,onClickPrimary,onClickDisabled,activedButtonText}){
    const [isFinished,setIsFinished] = useState(defaultFinished);
    const [isLoading,setIsLoading] = useState(false)

    const handleClickPrimaryButton = async () => {
      setIsLoading(true)
      setIsFinished(prev => !prev)
      if(onClickDisabled && isFinished) {
        await onClickDisabled()
        return setIsLoading(false)
      }
      if(onClickPrimary && !isFinished) {
        await onClickPrimary()
        return setIsLoading(false)
      }
    }
    return <Card sx={{
      ":hover":{
        backgroundColor:"#f3f4f6"
      }
    }} style={{outline:isFinished ? "2px solid #637763b5":null,backgroundColor:isFinished ? "#ebffe9":"#f9fafb",boxShadow:isFinished ? null:"rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px",overflow:"hidden",borderRadius:"4px"}} >
  
  
      <CardContent sx={{padding:0}} >
        <Stack sx={{padding:2}} direction="row" alignItems="start">
        <Typography sx={{lineHeight:"20px",fontFamily:"system-ui",fontWeight:600}} color={isFinished ? "#303d2f":"#030712"}>
          {title}
        </Typography>
        {isFinished && <Icon>
            <CheckCircleIcon color="red" style={{color:"#344c34"}}></CheckCircleIcon>
        </Icon>}
        </Stack>
        <Divider sx={{paddingLeft:1,paddingRight:1}}/>
  
      <Stack direction="row" sx={{padding:2,paddingTop:1,paddingBottom:1,marginTop:"8px"}} alignItems="center" gap={1}>
        <Icon color="#4b5563" style={{color:"#4b5563",fontSize:"18px"}} small="small">
          <LocationOnIcon style={{color:isFinished? "#545e53":"#4b5563"}} />  
          </Icon> 
      
      <Typography as="small" fontSize="14px" fontWeight={500} lineHeight="16px"color={isFinished ? "#545e53" : "#4b5563"}>
        {direction}
      </Typography>
      </Stack>
      
      </CardContent>
      <CardActions alignItems="end" sx={{padding:2}}>
        <Button 
          loading={isLoading}
          onClick={handleClickPrimaryButton} sx={{marginLeft:"auto"}} color={isFinished?"error":"primary"} variant={isFinished?"outlined":"contained"}>
          {isFinished && disableButtonText}
          {!isFinished && activedButtonText }
        </Button>
      </CardActions>
      
    </Card>
  }