import { useCallback, useEffect, useState } from "react";
import { useMongoAggregate } from "../../../utils/mongoAggregate/useMongoAggregate";
import { useSearchParams } from "./useSearchParams";

export const useProductsObj = () => {
    const [productsObj, setProductsObj] = useState({})
    const [productsData, productsDataLoading] = useMongoAggregate({
        index: "products",
        refetchOnWindowFocus: false,
        aggregate: [
          // {$match:{
          //   //   "data.objectID": { $in: providerCompany ? providerSelected?.brands : defaultBrands },
          //     "data.seller":seller ,
            
          // }},
          {
            $project: {
            "data.sellerCode": 1,
              "data.thumbnail":1,
              "data.image":1,
              "data.objectID": 1,
              "data.name": 1,
              "data.description": 1,
            },
          },
        ],
    });

    const formatProducts = useCallback(()=> {
        
        const pObj = {}
        !productsDataLoading && productsData.forEach(p => {
          pObj[p?.data?.objectID] = {
            id: p?.data?.objectID,
            name: p?.data?.name,
            image: p?.data?.image,
            thumbnail: p?.data?.thumnail,
            description: p?.data?.description,
          }
        })
    
        setProductsObj(pObj)
        
    }, [productsData, productsDataLoading])

    useEffect(() => {
        formatProducts()
    },[productsData])

    return [productsObj, productsDataLoading] 
}

export const useBrandsObj = () => {
    const [brandsObj, setBrandsObj] = useState({})
    const { getParam } = useSearchParams();
    const seller = getParam("seller");
    const [data, loading] = useMongoAggregate({
        index: "brands",
        refetchOnWindowFocus: false,
        aggregate: [
          {$match:{
            //   "data.objectID": { $in: providerCompany ? providerSelected?.brands : defaultBrands },
              "data.seller": seller ,
            
          }},
          {
            $project: {
              "data.objectID": 1,
              "data.name": 1,
            },
          },
        ],
    });

    const formatBrands = useCallback(()=> {
        const bObj = {}
        !loading && data.forEach(b => bObj[b?.data?.objectID] = b?.data?.name)
        setBrandsObj(bObj)
    }, [data, loading])

    useEffect(() => {
        formatBrands()
    },[data, loading])
    
    return [brandsObj, loading] 
}