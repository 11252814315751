import { useHistory } from "react-router-dom"
import { useSearchParams } from "./useSearchParams"
import { useTransition } from "react"

export const useSortFilter = (param) => {
    const [isPending,startTransition] = useTransition()
    const history = useHistory()
    const { 
        deleteParam,
        setParam,
        getParam,
        searchParams
      } = useSearchParams()
    let sortParam = getParam(param)

    const handleUrlSort = (selectedSort) => {
        sortParam === selectedSort.value
        ? deleteParam(selectedSort.param,selectedSort.value) 
        : setParam(selectedSort.param, selectedSort.value);
    
        startTransition(() => {
          history.replace(location.pathname+"?"+searchParams.toString())
        })
    }
    return { sortParam, handleUrlSort,isPending }
}