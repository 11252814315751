import useEntitiesBalance from "./useEntitiesBalance";
import * as React from "react";
import { useEffect, useState } from "react";
import { FormControlLabel, Grid, styled, Typography } from "@mui/material";
import DataGridPremium from "../../../components/DataGrid/DataGrid";
import Switch from "@mui/material/Switch";
import _ from "lodash";
import { numberWithCommas } from "../../../components/Utils/NumberWithCommas";
import { useMongoAggregate } from "../../../utils/mongoAggregate/useMongoAggregate";
import { useBanksDataContext } from "../BanksContext";
import queryString from "qs";
import { useLocation } from "react-router-dom";
import { batchUpdate } from "../../../firebase";
import { matchSorter } from "match-sorter";
import { entities_tags } from "../consolidations/Commons";

const columnEntities = [
  {
    field: "cuit",
    headerName: "Cuit",
    width: 115,
    renderCell: ({ value }) => (
      <Typography sx={{ cursor: "pointer" }}>{value}</Typography>
    ),
  },
  {
    field: "name",
    headerName: "Nombre",
    width: 225,
    renderCell: ({ value }) => (
      <Typography sx={{ cursor: "pointer" }}>{value}</Typography>
    ),
  },
  {
    field: "initial",
    headerName: "Saldo Inicial",
    valueGetter: ({ row }) => row.accumulated - row.debit + row.credit,
    type: "number",
    renderCell: ({ value }) =>
      _.isNumber(value) && !isNaN(value) && `$${numberWithCommas(value, 0)}`,
  },
  {
    field: "transactions",
    headerName: "Transacciones",
    type: "number",
    renderCell: ({ value }) =>
      _.isNumber(value) && `$${numberWithCommas(value, 0)}`,
  },
  {
    field: "invoices",
    headerName: "Facturas",
    type: "number",
    renderCell: ({ value }) =>
      _.isNumber(value) && `$${numberWithCommas(value, 0)}`,
  },
  {
    field: "checks",
    headerName: "Cheques",
    type: "number",
    renderCell: ({ value }) =>
      _.isNumber(value) && `$${numberWithCommas(value, 0)}`,
  },
  {
    field: "debit",
    headerName: "Debito",
    type: "number",
    renderCell: ({ value }) =>
      _.isNumber(value) && `$${numberWithCommas(value, 0)}`,
  },
  {
    field: "credit",
    headerName: "Credito",
    type: "number",
    renderCell: ({ value }) =>
      _.isNumber(value) && `$${numberWithCommas(value, 0)}`,
  },
  {
    field: "accumulated",
    headerName: "Acumulado",
    type: "number",
    renderCell: ({ value }) =>
      _.isNumber(value) && `$${numberWithCommas(value, 0)}`,
  },
  {
    field: "tags",
    headerName: "Tags",
    renderCell: ({ value }) => value?.join(" "),
  },
];

export default function EntitiesTable() {
  const { setData: setContextData } = useBanksDataContext();
  const [rowSelectionModel, setRowSelectionModel] = useState([]);

  const [showBalance, setShowBalance] = useState(false);
  const [accumulated] = useState(true);

  const location = useLocation();
  const parsedHash = queryString.parse(location.search?.replace("?", ""));
  const date_to = parsedHash.to;

  const [entitiesBalancesUpdated, setEntitiesBalancesUpdated] = useState([]);
  const [{ quickFilterValues }, setQuickFilterValues] = useState({});
  const [allEntities, isLoadingEntities] = useMongoAggregate({
    refetchOnWindowFocus: false,
    index: "entities",
    aggregate: [
      {
        $project: [
          "objectID",
          "name",
          "cuit",
          "tags",
          "balances." + date_to,
        ].reduce((p, a) => ({ ...p, [a]: "$data." + a }), {}),
      },
    ],
  });
  const [entities, setEntities] = useState(allEntities);
  useEffect(() => {
    allEntities &&
      setEntities((ent) => {
        const entities_by_id = _.groupBy(ent, "objectID");
        return fuzzySearchMutipleWords(
          allEntities.map((d) => ({
            ...d,
            tags: d.tags?.map((d) => entities_tags[d] || d),
            balances: {
              ...(entities_by_id[d.objectID]?.[0]?.balances || {}),
              ...d.balances,
            },
          })),
          ["name", "cuit", "tags"],
          quickFilterValues,
        );
      });
  }, [allEntities, quickFilterValues]);
  const [entitiesBalance, isLoading] = useEntitiesBalance({
    enable: showBalance && !!entities?.length,
    ids: rowSelectionModel.length
      ? rowSelectionModel
      : entities?.map((d) => d.objectID),
    accumulated,
    limit: rowSelectionModel.length || entities?.length || 30,
  });

  const [rows, setRows] = useState(
    JSON.parse(localStorage?.getItem("entities_rows") || "[]"),
  );
  useEffect(() => {
    rows.length &&
      localStorage?.setItem(
        "entities_rows",
        JSON.stringify(
          _.uniqBy(
            [
              ...JSON.parse(localStorage?.getItem("entities_rows") || "[]"),
              ...rows,
            ],
            "id",
          ),
        ),
      );
  }, [rows]);

  useEffect(() => {
    if (rows.length && date_to === "2022-12-31" && accumulated && showBalance) {
      const entities_by_id = _.groupBy(entities, "objectID");
      let hits_to_update = {};
      rows
        .filter(
          ({ id, accumulated }) =>
            _.isNumber(accumulated) &&
            (entitiesBalancesUpdated?.[id]?.["balances." + date_to] ??
              _.get(entities_by_id, [id, 0, "balances", date_to])) !==
              _.round(accumulated, 2),
        )
        .forEach(({ id, accumulated }) => {
          hits_to_update[id] = {
            ["balances." + date_to]: _.round(accumulated, 2),
          };
        });
      setEntitiesBalancesUpdated((h) => ({ ...h, ...hits_to_update }));
      console.log({ hits_to_update });
      !_.isEmpty(hits_to_update) &&
        batchUpdate({ indexName: "entities", hits: hits_to_update });
    }
  }, [rows, entities, date_to]);

  useEffect(() => {
    if (entitiesBalance?.length || entities?.length) {
      const entitiesBalance_by_id = _.groupBy(entitiesBalance, "id");
      setRows((rows) => {
        const currentRows = _.groupBy(rows, "id");
        return (entities || []).map((d) => {
          const row = entitiesBalance_by_id?.[d.objectID]?.[0];
          const balanceData = row && {
            ..._.pick(row, ["id", ...columnEntities.map((c) => c.field)]),
            debit: _.sum(
              ["transactions", "checks", "invoices"].map(
                (d) => row[d]?.[1] || 0,
              ),
            ),
            credit: _.sum(
              ["transactions", "checks", "invoices"].map(
                (d) => row[d]?.[2] || 0,
              ),
            ),
            accumulated: _.sum(
              ["transactions", "checks", "invoices"].map(
                (d) => row[d]?.[0] || 0,
              ),
            ),
            ..._.mapValues(
              _.pick(row, ["transactions", "checks", "invoices"]),
              (v) => v[1] - v[2],
            ),
          };
          return {
            ...d,
            id: d.objectID,
            accumulated: d.balances?.[date_to],
            ...(currentRows[d.objectID]?.[0] || {}),
            ...(balanceData || {}),
          };
        });
      });
    }
  }, [entitiesBalance, entities]);

  return (
    <Grid container>
      <Grid item xs={12}>
        <FormControlLabel
          control={
            <Switch
              checked={showBalance}
              onChange={(event) => setShowBalance(event.target.checked)}
            />
          }
          label="Mostrar Balances"
        />
      </Grid>
      <Grid item xs={12} sx={{ height: 600 }}>
        <StyledDataGrid
          columns={columnEntities}
          initialState={{
            columns: {
              columnVisibilityModel: {
                // Hide columns status and traderName, the other columns will remain visible
                transactions: false,
                checks: false,
                invoices: false,
              },
            },
          }}
          filterMode="server"
          onFilterModelChange={({
            quickFilterValues,
            items,
            logicOperator,
          }) => {
            setQuickFilterValues({ quickFilterValues, items, logicOperator });
          }}
          rows={rows || []}
          onCellClick={({ row, id, colDef }) => {
            ["cuit", "name"].includes(colDef.field) &&
              setContextData({
                entity: id,
                entity_name: row.name,
              });
          }}
          loading={isLoading || isLoadingEntities}
          checkboxSelection
          disableRowSelectionOnClick
          onRowSelectionModelChange={(newRowSelectionModel) => {
            setRowSelectionModel(newRowSelectionModel);
          }}
        />
      </Grid>
    </Grid>
  );
}

function fuzzySearchMutipleWords(
  rows, // array of data [{a: "a", b: "b"}, {a: "c", b: "d"}]
  keys, // keys to search ["a", "b"]
  quickFilterValues = [], // potentially multi-word search string "two words"
) {
  if (!quickFilterValues?.length) {
    return rows;
  }

  // reduceRight will mean sorting is done by score for the _first_ entered word.
  return quickFilterValues.reduceRight(
    (results, term) => matchSorter(results, term, { keys }),
    rows,
  );
}

const StyledDataGrid = styled(DataGridPremium)(({ theme }) => ({
  border: 0,
  color:
    theme.palette.mode === "light"
      ? "rgba(0,0,0,.85)"
      : "rgba(255,255,255,0.85)",
  WebkitFontSmoothing: "auto",
  letterSpacing: "normal",
  "& .MuiDataGrid-columnsContainer": {
    backgroundColor: theme.palette.mode === "light" ? "#fafafa" : "#1d1d1d",
    whiteSpace: "pre-wrap",
  },
  "& .MuiDataGrid-columnHeader, .MuiDataGrid-cell": {
    borderRight: `1px solid ${
      theme.palette.mode === "light" ? "#f0f0f0" : "#303030"
    }`,
    whiteSpace: "pre-wrap",
  },
  "& .MuiDataGrid-columnsContainer, .MuiDataGrid-cell": {
    borderBottom: `1px solid ${
      theme.palette.mode === "light" ? "#f0f0f0" : "#303030"
    }`,
    whiteSpace: "pre-wrap",
  },
  "& .MuiDataGrid-cell": {
    color:
      theme.palette.mode === "light"
        ? "rgba(0,0,0,.85)"
        : "rgba(255,255,255,0.65)",
    whiteSpace: "pre-wrap",
  },
  "& .MuiPaginationItem-root": {
    borderRadius: 0,
    whiteSpace: "pre-wrap",
  },
}));
