import {
  Box,
  Chip,
  Grid,
  Input,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import useStyles from "../styles";
import { useUpdateField } from "./useUpdateField";
import DoneIcon from "@mui/icons-material/Done";
import { numberWithCommas } from "../../../../components/Utils/NumberWithCommas";
import { FieldValue, updateDoc } from "../../../../firebase/database";
import AddIcon from "@mui/icons-material/Add";
import { useState } from "react";
import { Asynchronous } from "../../../../components/CardFirestore/DataCard/components/EditForm";
import _ from "lodash";

export const EditableLabel = ({
  label,
  field,
  actualValue,
  isArray,
  productID,
  type,
  labels,
  types,
  withComma,
}) => {
  const classes = useStyles();

  const { updateField, inputOpen, toggleInput } = useUpdateField({
    indexName: "products",
    id: productID,
  });
  const formatValue = (customType, e) => {
    const { value } = e.target;
    if (customType === "number") {
      return Number(value);
    }
    return value;
  };

  const [asynchron, toggleAync] = useState(false);

  return (
    <Grid item xs={4}>
      {inputOpen && !isArray && !asynchron ? (
        <Box display="flex" gap={2}>
          {field.map((f, index) => (
            <Box key={index} display="flex" alignItems="center" gap={1}>
              <Typography
                onClick={(e) => {
                  e.stopPropagation();
                  e.preventDefault();
                  toggleInput();
                }}
                sx={{ fontWeight: "bold", margin: 0, whiteSpace: "nowrap" }}
              >
                {labels?.[index] || label}
                {":"}
              </Typography>

              <Input
                autoFocus={true}
                defaultValue={actualValue(f) ?? actualValue() ?? ""}
                type={types?.[index] || type || "text"}
                onBlur={(e) => {
                  updateField({
                    key: f,
                    value: formatValue(types?.[index] || type, e),
                  });
                  if (label !== "Gramaje") {
                    toggleInput();
                  }
                }}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    updateField({
                      key: f,
                      value: formatValue(types?.[index] || type, e),
                    });
                    toggleInput();
                  }
                }}
                name={label}
                className={classes.input}
              />
              {index === 1 && label === "Gramaje" ? (
                <DoneIcon
                  onClick={() => {
                    toggleInput();
                  }}
                />
              ) : null}
            </Box>
          ))}
        </Box>
      ) : (
        <span
          onClick={(e) => {
            if (field?.[0] === "brand") {
              toggleAync(true);
            }
            e.stopPropagation();
            e.preventDefault();
            !isArray && field?.[0] !== "brand" ? toggleInput() : null;
          }}
        >
          <p className={classes.typographyOrders}>{label}: </p>
          {isArray ? (
            <Chips
              productID={productID}
              field={field?.[0]}
              data={actualValue()}
            />
          ) : null}
          {asynchron ? (
            <Asynchronous
              title="Marca"
              index="brands"
              customText={"{{name}}"}
              field="name"
              onChange={(id) =>
                updateDoc({
                  indexName: "products",
                  id: productID,
                  hit: { ["brand"]: id },
                }).then(() => toggleAync(false))
              }
            />
          ) : null}
          {withComma
            ? "$" + numberWithCommas(actualValue(), 2)
            : !isArray
            ? actualValue()
            : ""}
        </span>
      )}
    </Grid>
  );
};

const Chips = ({ data, productID, field }) => {
  const [addNew, setAddNew] = useState(false);

  const handleDelete = (label) => {
    updateDoc({
      indexName: "products",
      id: productID,
      hit: {
        [field]: data.filter((l) => l !== label)?.length
          ? data.filter((l) => l !== label)
          : FieldValue.delete(),
      },
    });
  };

  const addNewCategory = (e) => {
    const { value } = e.target;

    if (value?.length) {
      updateDoc({
        indexName: "products",
        id: productID,
        hit: { [field]: [...(data || []), value] },
      });
    }
    setAddNew(false);
  };

  return (
    <Stack spacing={1} direction="row" useFlexGap flexWrap="wrap">
      {addNew ? (
        <TextField onBlur={addNewCategory} />
      ) : (
        <>
          {_.map(data || [], (f, index) => (
            <Chip
              key={index}
              onDelete={() => handleDelete(f)}
              color="primary"
              size="small"
              variant="outlined"
              label={f}
            />
          ))}
          <Chip
            onClick={() => setAddNew(true)}
            color="primary"
            size="small"
            icon={<AddIcon />}
            variant="filled"
            label={"Agregar"}
          />
        </>
      )}
    </Stack>
  );
};
