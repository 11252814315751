import { useSearchParams } from "./useSearchParams"
import { useTransition } from "react"
import { useHistory } from "react-router-dom"
export const useFilter = () => {
    const {searchParams,deleteParam,addParam} = useSearchParams()
    const [isPending,startTransition] = useTransition()
    const history = useHistory()

    const handleOnUrlFilterChange = (element,val) => {
        if(!val) deleteParam(element.param, element.value);
        if(val) addParam(element.param, element.value);
        startTransition(() => {
            history.replace(location.pathname+"?"+searchParams.toString())
        }) 
    }
    const clearAllFiltersFromUrl = (filters) => {
        filters.forEach(filter => deleteParam(filter))
        startTransition(() => {
            history.replace(location.pathname+"?"+searchParams.toString())
        }) 
      }
    
    const handleDeleteUrlFilter = (filter) => {
        deleteParam(filter.param, filter.value);
        startTransition(() => {
          history.replace(location.pathname+"?"+searchParams.toString())
        })
    }
    return {isPending,clearAllFiltersFromUrl,handleOnUrlFilterChange,handleDeleteUrlFilter}
}