import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/styles";
import Widget from "../../../Widget";
import EditForm from "./EditForm";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import { useAreaDataContext } from "../../../../context/AreaContext";
import Populate from "../../../../algolia/populate";
import { newDoc } from "../../../../firebase";
import IconButton from "@material-ui/core/IconButton";
import { useSnackbar } from "notistack";
import { useHistory } from "react-router-dom";
import { setObjects } from "../../../../algolia/getObjects";
import { Button, Modal } from "@material-ui/core";
import moment from "moment";
import MomentUtils from "@date-io/moment";
import "moment/locale/es";
import _ from "lodash";

const useStyles = makeStyles((theme) => ({
  modal: {
    position: "absolute",
    width: "80%",
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 2, 2),
    top: "50%",
    left: "50%",
    transform: `translate(-50%, -50%)`,
    maxHeight: `90%`,
    overflowY: `scroll`,
  },
  iframe: {
    height: "-webkit-fill-available",
    width: "100%",
  },
}));

export default function AddNew({ hit, index, onAdd, open, setOpen }) {
  const classes = useStyles();
  const [anopen, setanOpen] = React.useState(open !== false);
  const [values, setValues] = useState(hit);
  const [error, setError] = useState({});

  let history = useHistory();

  useEffect(() => {
    setanOpen(open !== false);
  }, [open]);
  useEffect(() => {
    setOpen && setOpen(anopen);
  }, [anopen]);

  const { enqueueSnackbar } = useSnackbar();

  const areaData = useAreaDataContext();
  const fields = areaData.panel[index].fields;

  useEffect(() => {
    let active = true;
    !hit && (hit = {});
    let filters = areaData.panel[index].filters;
    filters &&
      (() => {
        let fs = filters.split(" AND ");
        for (let i in fs)
          if (!hit[fs[i]?.split(":")[0]])
            hit[fs[i].split(":")[0]] = fs[i].split(":")[1];
      })();
    for (let i in fields)
      if (
        !hit[fields[i].field] &&
        fields[i].options &&
        fields[i].options.default
      )
        hit[fields[i].field] = fields[i].options.default;
    if (Object.keys(hit)) {
      let populateOptions = fields
        .filter((f) => f.options && f.options.index)
        .map((f) => ({
          index: f.options.index.name,
          field: f.field,
        }));
      Populate({ hits: [hit], props: populateOptions }).then(
        (a) => active && setValues({ ...a[0] }),
      );
    }
    return () => {
      active = false;
    };
  }, [hit, fields, areaData]);

  const update = ({ field, value, hit }) => {
    const updateField = async (field, value) => {
      let c = fields.find((f) => f.field === field);
      value &&
        c &&
        c.options &&
        c.options.index &&
        (value = await Populate({
          hits: [{ [field]: value }],
          props: [{ index: c.options.index.name, field }],
        }).then((a) => a[0][field]));
      let f = field.split(".");
      setValues((v) => ({
        ...v,
        [f[0]]: f[1]
          ? { ...(typeof v[f[0]] === "object" ? v[f[0]] : {}), [f[1]]: value }
          : value,
      }));
    };

    if (hit) for (let f in hit) updateField(f, hit[f]);
    else updateField(field, value);
  };

  const handleAddButton = () => {
    const hit = convertObject(_.cloneDeep(values));
    for (let i in hit) {
      // let c = fields.find(f=>f.field===i);
      hit[i] &&
        (hit[i] =
          hit[i].objectID ||
          (typeof hit[i] === "object" && !hit[i][0] && Object.keys(hit[i])) ||
          hit[i]);
    }
    setanOpen(false);
    newDoc({ indexName: index, hit, id: hit.id }).then((newID) => {
      setObjects(index, { [newID]: hit });
      EnqueueSnackbar(newID);
      onAdd && onAdd(newID, hit);
    });
  };

  function EnqueueSnackbar(id) {
    enqueueSnackbar("Documento Creado.", {
      autoHideDuration: 10000,
      anchorOrigin: { horizontal: "right", vertical: "bottom" },
      action: (
        <React.Fragment>
          {/*<Button color="secondary" size="small" onClick={()=>updateDoc({indexName:index,id:hit.objectID,hit:{*/}
          {/*    }})}>*/}
          {/*    DESHACER*/}
          {/*</Button>*/}
          <Button
            color="secondary"
            size="small"
            onClick={() => history.push(`/app/${index}/${id}`)}
          >
            ABRIR
          </Button>
          <IconButton size="small" aria-label="close" color="inherit">
            {/*<CloseIcon fontSize="small" />*/}
          </IconButton>
        </React.Fragment>
      ),
    });
  }
  console.log("error", error);
  return (
    <>
      <Modal open={anopen} onClose={() => setanOpen(false)}>
        <div className={classes.modal}>
          <MuiPickersUtilsProvider
            libInstance={moment}
            utils={MomentUtils}
            locale={"es"}
          >
            <Widget
              header={<>Nuevo en {areaData.panel[index].title || index}</>}
            >
              <div>
                <EditForm
                  error={error}
                  setError={setError}
                  hit={values}
                  fields={fields.filter(
                    (f) =>
                      !f.options || (!f.options.nested && !f.options.multiple),
                  )}
                  update={update}
                />
              </div>
              <Button
                disabled={_.values(error).some((a) => a)}
                variant="contained"
                color="primary"
                onClick={handleAddButton}
              >
                Agregar Nuevo
              </Button>
            </Widget>
          </MuiPickersUtilsProvider>
        </div>
      </Modal>
    </>
  );
}

function convertObject(obj) {
  return _.transform(
    obj,
    (result, value, key) => {
      const parts = key.split(".");
      _.set(result, parts, value);
    },
    {},
  );
}
