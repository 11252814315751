import { Box } from "@mui/material";
import React from "react";

export default function UpperNav({ children }) {
  return (
    <Box
      as="nav"
      style={{
        position: "fixed",
        zIndex: 100,
        height: "80px",
        top: 0,
        width: "100%",
        borderBottom: "1px solid #9ca3af",
        backgroundColor: "#d1d5db",
      }}
    >
      {children}
    </Box>
  );
}
