import { Chip, Skeleton, Stack, Typography } from "@mui/material";
import { useState } from "react";
// eslint-disable-next-line react/display-name
export const TableProductCard = ({
  imageSrc,
  name,
  tags,
  measurement,
  id,
  code,
  brandName,
  measurementUnit,
}) => {
  const [imageHasLoaded, setImageHasLoaded] = useState(false);
  const [error, setError] = useState(false);
  const errorImage = "/Image-not-found-removebg-preview.png";
  return (
    <Stack direction="row" alignItems="start" padding={1} gap={2}>
      {!imageHasLoaded && (
        <Skeleton
          variant="rectangular"
          style={{ minWidth: "80px" }}
          height="80px"
        />
      )}
      <img
        onLoad={() => setImageHasLoaded(true)}
        onError={() => {
          setImageHasLoaded(true);
          return setError(true);
        }}
        style={{
          objectFit: "contain",
          display: imageHasLoaded ? "block" : "none",
        }}
        width="84px"
        height="84px"
        src={error ? errorImage : imageSrc}
      ></img>
      <Stack>
        {" "}
        {id && code && <Typography
          component="span"
          style={{ fontWeight: 500, color: "#64748b" }}
        >
          {id} | {code}
        </Typography>}
        <Typography
          component="h4"
          style={{ fontWeight: 700, color: "#1f2937", fontSize: 19 }}
        >
          {name}
        </Typography>
        <Stack direction="row" flexWrap="wrap" marginTop={1} gap={1}>
          {tags &&
            tags?.map((tag) => (
              <Chip
                color="warning"
                style={{ fontWeight: 600 }}
                label={tag.name}
                key={tag.name}
              />
            ))}
          {measurement && measurementUnit && (
            <Chip
              style={{ fontWeight: 600, color: "#3c4048" }}
              label={measurement + " " + measurementUnit}
            />
          )}
          <Chip
            style={{ fontWeight: 600, color: "#3c4048" }}
            label={brandName}
          />
        </Stack>
      </Stack>
    </Stack>
  );
};
