/* eslint-disable no-restricted-syntax */
/* eslint-disable no-param-reassign */
import slice from "lodash/slice";
import last from "lodash/last";
import { numberWithCommas } from "../Utils/NumberWithCommas";

const customFieldFunctions = {
  pad: (num, size) => {
    let s = `${num}`;
    while (s.length < size) s = `0${s}`;
    return s;
  },
  textBefore: (v, text) => v && text + v,
  textAfter: (v, text) => v && v + text,
  ifEmpty: (v, text) => v || text,
  if: (v, text) => {
    text = text.split(",");
    return text[0] === String(v) || (text[0] === "false" && !v)
      ? text[1]
      : text[2];
  },
  date: () => new Date(),
  in: (v, text) => {
    const array = text.replace(/[[\]]/g, "").split(",");
    return slice(array, 0, array.length - 2).includes(String(v))
      ? array[array.length - 2]
      : last(array);
  },
  includes: (v, text) => {
    const array = text.split(",");
    return array[v?.includes(array[0]) ? 1 : 2];
  },
  numberWithCommas: (v, decimals) => v && numberWithCommas(Number(v), decimals),
};
export function customFieldRender(hit = {}, custom = "") {
  if (typeof hit !== "object") hit = {};
  if (typeof custom !== "string") return custom;
  const a = hit;
  const b = custom;
  let c = 0;
  let n = "";
  while (b.indexOf("{{", c) > -1) {
    const f = b.indexOf("{{", c);
    n += b.substring(c, f);
    let t = b.indexOf("}}", f + 2);
    (() => {
      let nf = f;
      while (
        b.indexOf("{{", nf + 2) > 0 &&
        b.indexOf("{{", nf + 2) < b.indexOf("}}", nf + 2)
      ) {
        nf = b.indexOf("{{", nf + 2);
        t = b.indexOf("}}", b.indexOf("}}", nf + 2) + 2);
      }
    })();
    let d = b.substring(f + 2, t);
    // console.log(d);
    if (d.indexOf("{{") > -1 && d.indexOf("}}", d.indexOf("{{")) > -1) {
      d = customFieldRender(hit, d);
    }
    d = d.replace(/\\./g, ";;");
    d = d.split(".");
    d = d.map((i) => i.replace(/;;/g, "."));
    let v = a;
    for (const i of d) {
      if (i.substring(0, 1) === "*") {
        v = customFieldFunctions[i.substring(1, i.indexOf("("))](
          v,
          i.substring(i.indexOf("(") + 1, i.indexOf(")")),
          hit
        );
      } else if (v && typeof v === "object") v = v[i];
      else v = "";
    }
    n += v || "";
    c = t + 2;
  }
  n += b.substring(c, b.length);
  return n;
}

const CustomFieldRenderHooks = (hit = {}, custom = "") => {
  if (typeof hit !== "object") hit = {};
  //? REVIEW: dice que no se puede llamar a hooks dentro de una función.
  //? hay que revisar donde se usaba
  // const userData = useUserData();
  // hit.userData = userData;
  return customFieldRender(hit, custom);
};

export default CustomFieldRenderHooks;
