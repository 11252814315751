import * as React from "react";
import { useEffect, useMemo, useState } from "react";
import {
  GRID_CHECKBOX_SELECTION_COL_DEF,
  useGridApiRef,
} from "@mui/x-data-grid-premium";
import _ from "lodash";
import useAccountsData from "./useAccountsData";
import { FormControlLabel } from "@mui/material";
import Switch from "@mui/material/Switch";
import { numberWithCommas } from "../../../../components/Utils/NumberWithCommas";
import { useBanksDataContext } from "../../BanksContext";
import DataGridPremium from "../../../../components/DataGrid/DataGrid";
import moment from "moment";

// function CustomGridTreeDataGroupingCell(props) {
//   const { rowNode, row } = props;
//   const apiRef = useGridApiContext();
//   const filteredDescendantCountLookup = useGridSelector(
//     apiRef,
//     gridFilteredDescendantCountLookupSelector,
//   );
//   const filteredDescendantCount =
//     filteredDescendantCountLookup[rowNode.id] ?? 0;
//
//   const handleClick = () => {
//     // if (rowNode.type !== 'group') {
//     //   return;
//     // }
//     // apiRef.current.setRowChildrenExpansion(id, !rowNode.childrenExpanded);
//     // apiRef.current.setCellFocus(id, field);
//     // event.stopPropagation();
//   };
//
//   return (
//     <Box sx={{ ml: rowNode.depth * 4 }}>
//       <div>
//         {filteredDescendantCount > 0 ? (
//           <Button onClick={handleClick} tabIndex={-1} size="small">
//             {rowNode.childrenExpanded ? <ExpandMore /> : <ChevronRight />}
//             {row.code} - {row.name} ({filteredDescendantCount})
//           </Button>
//         ) : (
//           <span>
//             {row.code} - {row.name}
//           </span>
//         )}
//       </div>
//     </Box>
//   );
// }

const columns = [
  {
    ...{
      ...GRID_CHECKBOX_SELECTION_COL_DEF,
      renderCell: (d) => {
        return GRID_CHECKBOX_SELECTION_COL_DEF.renderCell(d);
      },
    },
    width: 100,
  },
  {
    field: "code",
    headerName: "Codigo",
  },
  {
    field: "name",
    headerName: "Nombre",
    width: 250,
  },
  {
    field: "inicial",
    headerName: "Saldo Inicial",
    width: 120,
    type: "number",
    renderCell: ({ value }) => `$${numberWithCommas(value, 0)}`,
  },
  {
    field: "debe",
    headerName: "Debitos",
    width: 120,
    type: "number",
    renderCell: ({ value }) => `$${numberWithCommas(value, 0)}`,
  },
  {
    field: "haber",
    headerName: "Creditos",
    width: 120,
    type: "number",
    renderCell: ({ value }) => `$${numberWithCommas(value, 0)}`,
  },
  {
    field: "deudor",
    headerName: "Deudor",
    width: 120,
    type: "number",
    valueGetter: ({ row }) => (row.saldo > 0 ? row.saldo : 0),
    renderCell: ({ value }) => `$${numberWithCommas(value, 0)}`,
  },
  {
    field: "acreedor",
    headerName: "Acreedor",
    width: 120,
    type: "number",
    valueGetter: ({ row }) => (row.saldo < 0 ? -row.saldo : 0),
    renderCell: ({ value }) => `$${numberWithCommas(value, 0)}`,
  },
  {
    field: "activo",
    headerName: "Activo",
    width: 120,
    type: "number",
    renderCell: ({ value }) => `$${numberWithCommas(value, 0)}`,
  },
  {
    field: "pasivo",
    headerName: "Pasivo",
    width: 120,
    type: "number",
    renderCell: ({ value }) => `$${numberWithCommas(value, 0)}`,
  },
  {
    field: "pn",
    headerName: "PN",
    width: 120,
    type: "number",
    renderCell: ({ value }) => `$${numberWithCommas(value, 0)}`,
  },
];

// const getTreeDataPath = (row) => row.hierarchy;

// const groupingColDef = {
//   headerName: "Cuenta",
//   width: 300,
//   valueGetter: ({ row }) => `${row.code} - ${row.name}`,
//   renderCell: (params) => <CustomGridTreeDataGroupingCell {...params} />,
// };

export default function Accounts({ dateFilter }) {
  const [hideZeros, setHideZeros] = useState(true);
  const [accumulated, setAccumulated] = useState(true);
  const [accounts, loading, , { isRefetching }] = useAccountsData({
    hideZeros: hideZeros,
    dateFilter,
    accumulated,
  });

  const rows = useMemo(() => {
    if (!accounts)
      return JSON.parse(localStorage?.getItem("accounts_rows") || "[]");
    const accountsH = _.sortBy(accounts.map((d) => d.code)).reverse();
    return (
      _.chain(accounts)
        .sortBy(["code"])
        .reduce((p, a) => {
          const hCode = accountsH.find(
            (d) =>
              d.length < a.code.length &&
              _.isEqual(
                d.split("."),
                a.code.split(".").slice(0, d.split(".").length),
              ),
          );
          const h = hCode && p.find((c) => c.code === hCode);
          const hierarchy = [...(h?.hierarchy || [h?.code]), a.code].filter(
            (d) => d,
          );

          const saldo = a.accumulated;

          return [
            ...p,
            {
              ...a,
              inicial: a.accumulated - (a.debe - a.haber),
              saldo,
              activo: (saldo > 0 && saldo) || 0,
              pasivo: (saldo < 0 && -saldo) || 0,
              hierarchy,
              hCode,
              h,
            },
          ];
        }, [])
        // .reverse()
        // .map((v, i, p) => ({
        //   ...v,
        // ..._.mapValues(
        //   p
        //     .filter((d) => d.code !== v.code && d.hierarchy.includes(v.code))
        //     .reduce((p2, a) => _.mapValues(p2, (v, k) => v + a[k]), {
        //       debe: v.debe,
        //       haber: v.haber,
        //       saldo: v.saldo,
        //     }),
        //   (v) => _.round(v, 2),
        // ),
        // }))
        // .reverse()
        .value()
    );
  }, [accounts]);

  useEffect(() => {
    rows.length && localStorage?.setItem("accounts_rows", JSON.stringify(rows));
  }, [rows]);

  const { setData } = useBanksDataContext();

  const apiRef = useGridApiRef();

  const onRowClick = React.useCallback(
    (params) => {
      const rowNode = apiRef.current.getRowNode(params.id);
      if (rowNode && rowNode.type === "group") {
        apiRef.current.setRowChildrenExpansion(
          params.id,
          !rowNode.childrenExpanded,
        );
      } else {
        setData({
          libroId: params.id,
          libroName: params.row.name,
        });
      }
    },
    [apiRef],
  );

  return (
    <div style={{ height: 400, width: "100%" }}>
      <FormControlLabel
        control={
          <Switch
            checked={hideZeros}
            onChange={(event) => setHideZeros(event.target.checked)}
          />
        }
        label="Ocultar Ceros"
      />
      <FormControlLabel
        control={
          <Switch
            checked={accumulated}
            onChange={(event) => setAccumulated(event.target.checked)}
          />
        }
        label="Acumulado"
      />
      <DataGridPremium
        fileName={`Plan de cuentas - ${moment().format("YYYY-MM-DD")}`}
        apiRef={apiRef}
        checkboxSelection
        // treeData
        rows={rows}
        columns={columns}
        // getTreeDataPath={getTreeDataPath}
        onRowClick={onRowClick}
        // groupingColDef={groupingColDef}
        loading={loading || isRefetching}
        // columnVisibilityModel={{
        //   code: false,
        //   name: false,
        // }}
        onRowSelectionModelChange={(selectedAccounts) =>
          setData({ selectedAccounts })
        }
        disableRowSelectionOnClick
        // defaultGroupingExpansionDepth={-1}
        getRowId={(row) => row._id}
      />
    </div>
  );
}
