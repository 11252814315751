/* eslint-disable no-return-assign */
/* eslint-disable react/prop-types */

import React, { createContext, useEffect, useMemo, useState } from "react";
import { algoliaIndexBrowser } from "../../../algolia";

const BinsContext = createContext();
const { Provider } = BinsContext;

function BinsProvider({ children }) {
  const [bins, setBins] = useState([]);
  const binsById = useMemo(
    () => bins.reduce((p, a) => ({ ...p, [a.objectID]: a }), {}),
    [bins]
  );

  useEffect(() => {
    let hits = [];

    algoliaIndexBrowser("bins")
      .browseObjects({
        // attributesToRetrieve: [
        //     'type', // list of attributes to retrieve
        //     'binnumber',
        //     'l'
        // ],
        batch: (batch) => (hits = [...hits, ...batch]),
      })
      .then(() => setBins(hits));
  }, []);

  return <Provider value={{ binsById, bins }}>{children}</Provider>;
}

function useBins() {
  const context = React.useContext(BinsContext);
  if (context === undefined) {
    throw new Error("useBins must be used within a BinsProvider");
  }
  return context;
}

export { BinsProvider, useBins };
