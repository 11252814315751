import React from "react";
// styles
import { CircularProgress } from "@material-ui/core";
// import { usePanelState } from '../../context/PanelContext'

// components

export default function LoadingPage() {
  // var { logo } = usePanelState()

  return (
    <div
      style={{
        width: "20rem",
        margin: "auto",
        height: "20rem",
        top: "calc(40% - 10rem)",
        left: "calc(50% - 10rem)",
        position: "absolute",
        // eslint-disable-next-line no-useless-concat
        background: "url(" + "logo" + ") no-repeat center center",
        backgroundSize: "contain",
        padding: "18rem 0 0 9rem",
      }}
    >
      <CircularProgress />
    </div>
  );
}
