/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/prop-types */
import React from "react";
import { makeStyles } from "@material-ui/styles";
import Modal from "@material-ui/core/Modal";
import { Button, Link } from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Address from "./Address";

const useStyles = makeStyles((theme) => ({
  modal: {
    position: "fixed",
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    left: "50%",
    transform: "translate(-50%, 0%)",
    maxHeight: "100%",
    overflow: "scroll",
    width: "fit-content",
    maxWidth: "100%",
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
}));

export default function AddressModal({
  text,
  orderID,
  userID,
  storeID,
  onUpdate,
  ...rest
}) {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  const onUpdateModal = (address) => {
    console.log('address', address)
    handleClose();
    if (onUpdate) {
      onUpdate(address);
    }
  };

  return (
    <>
      <Button
        {...rest}
        className={classes.login}
        component={Link}
        onClick={() => setOpen(true)}
        variant="contained"
        color="secondary"
      >
        {text || "Modificar Direccion"}
      </Button>
      <Modal open={open} onClose={handleClose}>
        <div className={classes.modal}>
          <IconButton
            aria-label="close"
            className={classes.closeButton}
            onClick={handleClose}
          >
            <CloseIcon />
          </IconButton>
          <Address
            {...{
              orderID,
              userID,
              storeID,
              onUpdate: onUpdateModal,
            }}
          />
        </div>
      </Modal>
    </>
  );
}
