export default function populate({ hits, props }) {
  const myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/json");
  return fetch("https://stock-p-c059a3e3d873.herokuapp.com/populate", {
    method: "POST",
    headers: myHeaders,
    body: JSON.stringify({ hits, props }),
    redirect: "follow",
  }).then((response) => response.json());
}
