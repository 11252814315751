import { useState } from "react";
import { updateDoc } from "../../../../firebase/database";

export const useUpdateField = ({ indexName, id }) => {
  const [inputOpen, setInputOpen] = useState(false);

  const toggleInput = () => setInputOpen(!inputOpen);

  const updateField = ({ key, value }) => {
    updateDoc({
      indexName: indexName,
      id: id,
      hit: {
        [key]: value,
      },
    });
  };

  return { updateField, toggleInput, inputOpen };
};
