import {
  Button,
  Checkbox,
  Chip,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
} from "@mui/material";
import _ from "lodash";

export default function Filters({
  columns,
  match,
  dispatch,
  facets,
  requestFacets,
}) {
  return (
    <>
      {_.entries(facets)
        .filter(
          ([key, value]) =>
            (value?.length ||
              match[key]?.length ||
              (!_.isArray(match[key]) && match[key])) &&
            !key.includes("_multiple"),
        )
        .map(([key, value]) => (
          <Filter
            key={key}
            k={key}
            value={value}
            columns={columns}
            match={match}
            dispatch={dispatch}
            facets={facets}
            requestFacets={requestFacets}
          />
        ))}
    </>
  );
}

function Filter({
  k: key,
  value,
  match,
  columns,
  dispatch,
  requestFacets,
  facets,
}) {
  const field = requestFacets[key]?.field;
  const limit = requestFacets[key]?.limit;
  const othersFilters = (_.isArray(match[key]) ? match[key] : [match[key]])
    ?.filter((d) => d && !value.map((v) => v._id).includes(d))
    ?.map((d) => ({ _id: d, count: 0, selected: 1 }));

  return (
    <FormGroup>
      <FormLabel component="legend">
        {columns.find((c) => c.field === field)?.headerName || field}
      </FormLabel>
      {_.chain([...othersFilters, ...value])
        .map((v) => ({
          ...v,
          multiple:
            facets[`${key}_multiple`]?.find((d) => d._id === v._id)?.count || 0,
          checked: _.isObject(match[key])
            ? match[key].includes(v._id)
            : match[key] === v._id,
        }))
        .sortBy(["checked", "multiple", "count"])
        .reverse()
        .value()
        .map((v) => (
          <FormControlLabel
            key={v._id}
            onChange={() =>
              dispatch({
                [`match.${key}`]: match[key]
                  ? v.checked
                    ? _.pull([match[key]].flat(), v._id)
                    : [match[key], v._id].flat()
                  : v._id,
              })
            }
            control={<Checkbox checked={v.checked} />}
            label={
              <>
                {_.isBoolean(v._id) ? (v._id ? "Si" : "No") : v._id}{" "}
                <Chip label={v.count} />{" "}
                {!v.checked && !!v?.multiple && (
                  <Chip color={"primary"} label={v?.multiple} />
                )}
              </>
            }
          />
        ))}
      <FormControl>
        <Button
          onClick={() => dispatch({ [`facets.${key}.limit`]: !limit ? 5 : 0 })}
        >
          Ver {limit ? "Mas" : "Menos"}
        </Button>
      </FormControl>
    </FormGroup>
  );
}
