/* eslint-disable react/prop-types */


import {
  ListItem, ListItemIcon, ListItemText, MenuItem, Select, Typography,
} from '@mui/material';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import React, { useState } from 'react';
import { updateDoc } from '../../../../../firebase/database';

export function CarrierList({
  c, id, data, carriers, order,
}) {
  const [carrierSelected, setCarrierSelected] = useState({});

  const updateCarrier = (idCarrier, conservation) => {
    setCarrierSelected({ idCarrier, conservation });
  };

  const selectNewCarrier = (e, conservation) => {
    if (e.target.value !== id) {
      updateDoc({
        indexName: 'orders',
        id: order?.objectID,
        hit: {
          carriers: { ...order?.carriers, [conservation]: e.target.value },
        },
      });
    }
    setCarrierSelected({});
  };

  return (
    <ListItem key={data.name}>
      <ListItemIcon>
        {" "}
        <LocalShippingIcon />
      </ListItemIcon>
      {carrierSelected?.idCarrier !== id ? (
        <ListItemText>
          <Typography
            onClick={() => updateCarrier(id, c)}
            sx={{
              cursor: "pointer",
              "&:hover": { textDecoration: "underline" },
            }}
          >
            {data.name}
          </Typography>
        </ListItemText>
      ) : null}
      {carrierSelected.idCarrier === id &&
      carrierSelected.conservation === c ? (
        <Select
          onBlur={() => setCarrierSelected({})}
          onChange={(e) => selectNewCarrier(e, c)}
          autoFocus
          defaultValue={id}
        >
          {carriers
            .filter((x) => x.conservations.some((v) => v === c))
            .map((x, index) => (
              <MenuItem
                key={index}
                sx={{ "&:hover": { background: "rgb(0,0,0,0.2)" } }}
                value={x?.objectID}
              >
                {x?.name}
              </MenuItem>
            ))}
        </Select>
      ) : null}
    </ListItem>
  );
}
