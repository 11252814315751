import React from "react";
import ReactDOM from "react-dom";
import { ThemeProvider } from "@material-ui/styles";
import { CssBaseline } from "@material-ui/core";
import Themes from "./themes";
import App from "./components/App";
import * as serviceWorker from "./serviceWorker";
import { LayoutProvider } from "./context/LayoutContext";
import { UserProvider } from "./context/UserContext";
import "react-image-lightbox/style.css";
import { AreaProvider } from "./context/AreaContext";
import { RoleProvider } from "./context/RoleContext";
import { SnackbarProvider } from "notistack";
import { LocalizationProvider } from "@mui/x-date-pickers-pro";
import { AdapterMoment } from "@mui/x-date-pickers-pro/AdapterMoment";

ReactDOM.render(
  <ThemeProvider theme={Themes.default}>
    <CssBaseline />
    <LayoutProvider>
      <LocalizationProvider dateAdapter={AdapterMoment}>
        <UserProvider>
          <RoleProvider>
            <AreaProvider>
              <SnackbarProvider maxSnack={3}>
                <App />
              </SnackbarProvider>
            </AreaProvider>
          </RoleProvider>
        </UserProvider>
      </LocalizationProvider>
    </LayoutProvider>
  </ThemeProvider>,

  document.getElementById("root"),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
export { fixTimestampFirestore } from "./firebase/utils/fixTimestamp";
