import { Fragment, memo } from "react";
import { useMongoAggregate } from "../../../../utils/mongoAggregate/useMongoAggregate";
import { CommitedOrderCard } from "./CommitedOrderCard";
import { CircularProgress, Divider, List } from "@mui/material";
const assemblyStates = ["assembled", "canceled", "inBoxes", "checked"];

const Component = ({ productID }) => {
  const [orders, isLoading] = useMongoAggregate({
    index: "orders",
    aggregate: [
      {
        $match: {
          "data.itemsArray": productID,
          "data.canceled": { $ne: true },
          "data.assemblyState": { $nin: assemblyStates },
          "data.inventoryAffect": true,
          [`data.items.${productID}.basket`]: { $exists: false },
          $or: [
            { [`data.items.${productID}.q`]: { $gt: 0 } },
            { [`data.items.${productID}.tq`]: { $gt: 0 } },
          ],
        },
      },
      {
        $project: {
          objectID: "$data.objectID",
          internalId_daily: "$data.internalId_daily",
          timestamp: "$data.timestamp_timestamp",
          storeName: "$data.data.store.storeName",
          user: "$data.data.user.name",
          address: "$data.data.address.formatted_address",
          shipping: "$data.shipping",
          shippingDate: "$data.shippingDate",
          roadmap: "$data.roadmap",
          q: `$data.items.${productID}.q`,
          tq: `$data.items.${productID}.tq`,
          _id: 0,
        },
      },
      {
        $lookup: {
          from: "pshippings",
          localField: "shipping",
          foreignField: "data.objectID",
          as: "shipping",
          pipeline: [
            {
              $project: {
                title: "$data.title",
                _id: 0,
              },
            },
          ],
        },
      },
      {
        $sort: { shippingDate: 1, timestamp:1 },
      },
    ],
  });

  if (isLoading) return <CircularProgress />;

  return (
    <List sx={{ maxHeight: '90vh', overflowY: "auto" }}>
      {(orders || [])?.map((order) => (
        <Fragment key={order.objectID}>
          <CommitedOrderCard key={order.objectID} order={order} />
          <Divider sx={{ marginY: 1 }} />
        </Fragment>
      ))}
    </List>
  );
};

export const CommitedOrders = memo(Component);
