import DataGridPremium from "../../../../../components/DataGrid/DataGrid";
import { darken, lighten, styled } from "@mui/material/styles";
import _ from "lodash";
import { useEffect, useMemo, useReducer } from "react";
import { Grid } from "@mui/material";
import useData from "./useData";
import { useGridApiRef } from "@mui/x-data-grid-premium";
import Filters from "./Filters";
import { numberWithCommas } from "../../../../../components/Utils/NumberWithCommas";
import { useBanksDataContext } from "../../../BanksContext";
import moment from "moment/moment";

export default function Table({
  index = "transactions",
  columns = [],
  limit = 50,
  defaultSort = {},
  defaultFilters = {},
}) {
  const [
    { sort, size, match, facets, defaultFilters: defaultFiltersState },
    dispatch,
  ] = useReducer(
    (p, a) =>
      a.size
        ? { ...p, size: a.size }
        : a.sort
        ? {
            ...p,
            ...a,
            size: a.size || p.limit,
            sort: { ...a.sort, ..._.omit(defaultSort, _.keys(a.sort)) },
          }
        : a.defaultFilters
        ? {
            ...p,
            ...a,
            size: p.limit,
          }
        : _.reduce(
            a,
            (r, v, k) => {
              (typeof v === "object" && _.isEmpty(v)) || !v
                ? (r = _.omit(r, k))
                : _.set(r, k, v);
              return r;
            },
            {
              ...p,
              size: a.size || p.limit,
            },
          ),
    {
      sort: defaultSort,
      size: limit,
      match: {},
      limit,
      defaultFilters,
      facets: columns
        .filter((c) => c.options?.filter)
        .reduce(
          (p, a) => ({
            ...p,
            [_.snakeCase(a.field)]: {
              field: a.field,
              limit: a.options?.limit || 5,
              multiple: a.options?.multiple,
            },
          }),
          {},
        ),
    },
    (p) => p,
  );
  const { setData } = useBanksDataContext();

  useEffect(() => {
    !_.isEqual(defaultFiltersState, defaultFilters) &&
      dispatch({ defaultFilters });
  }, [defaultFilters]);

  const [data, loading, , count, responseFacets] = useData({
    index,
    project: columns.map((d) => d.field),
    lookup: columns
      .filter((d) => d.options?.index)
      .map((d) => ({
        localField: d.field,
        from: d.options.index.name,
        as: d.field,
        fields: d.options.index.fields || [d.options.index.field],
      })),
    sort,
    match: _.mapKeys(match, (v, k) => facets[k]?.field),
    facets,
    size,
    limit,
    defaultFilters: defaultFiltersState,
  });
  const apiRef = useGridApiRef();

  const sortMatchStringify = JSON.stringify({ sort, match });

  useEffect(() => {
    apiRef?.current?.scrollToIndexes?.({ rowIndex: 0, colIndex: 0 });
  }, [sortMatchStringify, defaultFiltersState]);

  const handleOnRowsScrollEnd = () => {
    if (!loading && count > size) {
      dispatch({ size: size + limit });
    }
  };

  const handleCellClicked = (transactionData) => {
    const { id, field, value, row } = transactionData;
    if (field === "entity") {
      setData({
        entity: value,
        entity_name: row?.entity_name,
      });
      return;
    }
    setData({ idSelected: id, tableType: index, isCreateAsiento: false });
  };

  const handleSortModelChange = (r) => {
    if (!loading) {
      dispatch({
        sort: r.reduce(
          (p, a) => ({ ...p, [a.field]: a.sort === "asc" ? 1 : -1 }),
          {},
        ),
      });
    }
  };

  const gridColumns = useMemo(() => columnsFix(columns), [columns]);

  return (
    <Grid container sx={{ height: 400, width: "100%" }}>
      <Grid item xs={3} sx={{ maxHeight: 400, overflow: "scroll" }}>
        <Filters
          columns={columns}
          match={match}
          dispatch={dispatch}
          requestFacets={facets}
          facets={responseFacets}
        />
      </Grid>
      <Grid item xs={9}>
        <StyledDataGrid
          fileName={`${index} - ${moment().format("DD/MM/YYYY")}`}
          apiRef={apiRef}
          columns={gridColumns}
          rows={data}
          getRowId={(row) => row?.objectID || row?.tranid}
          rowCount={count}
          loading={loading}
          getRowClassName={(params) =>
            `super-app-theme--${params.row.amount > 0 ? "in" : "out"}`
          }
          processRowUpdate={(updatedRow, oldRow) =>
            console.log({ updatedRow, oldRow }) && updatedRow
          }
          hideFooterPagination
          onRowsScrollEnd={handleOnRowsScrollEnd}
          onCellClick={handleCellClicked}
          sortingMode="server"
          onSortModelChange={handleSortModelChange}
        />
      </Grid>
    </Grid>
  );
}

const columnsFix = (columns) => [
  ...columns?.map((c) => {
    let cc = { ...c };
    c.field?.includes(".") &&
      (cc.valueGetter = ({ row }) => _.get(row, c.field));
    c.options?.index &&
      (cc.valueGetter = ({ value }) =>
        value.map((v) => _.get(v, c.options.index.field, "")).join("; "));
    c.options?.type === "currency" &&
      (cc.renderCell = ({ value }) =>
        `$${numberWithCommas(value, c.options.decimals || 0)}`);
    return cc;
  }),
  // {
  //   field: "actions",
  //   type: "actions",
  //   headerName: "Actions",
  //   width: 100,
  //   cellClassName: "actions",
  //   getActions: ({ id, row }) => [
  //     <GridActionsCellItem
  //       key={0}
  //       icon={<EditIcon />}
  //       label="Edit"
  //       className="textPrimary"
  //       onClick={() => console.log(row)}
  //       color="inherit"
  //     />,
  //   ],
  // },
];

const getBackgroundColor = (color, mode) =>
  mode === "dark" ? darken(color, 0.7) : lighten(color, 0.7);

const getHoverBackgroundColor = (color, mode) =>
  mode === "dark" ? darken(color, 0.6) : lighten(color, 0.6);

const getSelectedBackgroundColor = (color, mode) =>
  mode === "dark" ? darken(color, 0.5) : lighten(color, 0.5);

const getSelectedHoverBackgroundColor = (color, mode) =>
  mode === "dark" ? darken(color, 0.4) : lighten(color, 0.4);

const statesColors = {
  asset: "info",
  assets_outstanding: "info",
  issued_pending: "info",
  deposited: "secondary",
  in: "success",
  custody: "warning",
  out: "error",
  bounced: "error",
};

const StyledDataGrid = styled(DataGridPremium)(({ theme }) =>
  _.mapValues(
    _.mapKeys(statesColors, (v, k) => `& .super-app-theme--${k}`),
    (v) => ({
      backgroundColor: getBackgroundColor(
        theme.palette[v].main,
        theme.palette.mode,
      ),
      "&:hover": {
        backgroundColor: getHoverBackgroundColor(
          theme.palette[v].main,
          theme.palette.mode,
        ),
      },
      "&.Mui-selected": {
        backgroundColor: getSelectedBackgroundColor(
          theme.palette[v].main,
          theme.palette.mode,
        ),
        "&:hover": {
          backgroundColor: getSelectedHoverBackgroundColor(
            theme.palette[v].main,
            theme.palette.mode,
          ),
        },
      },
    }),
  ),
);
