import {
  Box,
  CardContent,
  CardMedia,
  Switch,
  Typography,
} from "@material-ui/core";
import { Skeleton, Stack } from "@mui/material";
import Divider from "@mui/material/Divider";
import { useState, memo } from "react";
import { Card } from "react-bootstrap";
import Lightbox from "react-image-lightbox";

/* eslint-disable react/display-name */
export const ProductCard = memo(
  ({
    mediaQuery,
    brandName,
    name,
    weight,
    prices,
    specs,
    iva,
    taste,
    sellerCode,
    active,
    onbin,
    imageUrl,
    quantity,
    available,
    unit,
  }) => {
    const [imageHasLoaded, setImageHasLoaded] = useState(false);
    const [error, setError] = useState(false);
    const errorImage =
      "https://upload.wikimedia.org/wikipedia/commons/a/a3/Image-not-found.png";
    const [imageIsOpen, setImageIsOpen] = useState(false);

    return (
      <Card
        sx={{ display: "flex" }}
        style={{ borderBottom: "1px solid #cbd5e1" }}
      >
        <Stack gap="4" direction={mediaQuery ? "row" : "column"}>
          {!imageHasLoaded && (
            <Skeleton
              variant="rectangular"
              style={{ minWidth: "150px" }}
              width="150px"
              height="150px"
            />
          )}
          <Box
            style={{
              width: imageHasLoaded ? (mediaQuery ? "150px" : "150px") : 0,
              flexShrink: 0,
              overflow: "hidden",
            }}
          >
            <CardMedia
              onLoad={() => setImageHasLoaded(true)}
              onClick={() => setImageIsOpen((prev) => !prev)}
              onError={() => {
                setImageHasLoaded(true);
                return setError(true);
              }}
              component="img"
              style={{
                cursor: "pointer",
                overflow: "hidden",
                objectFit: "cover",
                width: "150px",
                height: "150px",
                display: imageHasLoaded ? "block" : "none",
              }}
              image={error ? errorImage : imageUrl}
              alt={name}
            />

            {imageIsOpen && (
              <Lightbox
                onImageLoad={() => setImageHasLoaded(true)}
                mainSrcThumbnail={imageUrl}
                onCloseRequest={() => setImageIsOpen(false)}
                onImageLoadError={() => {
                  setImageHasLoaded(true);
                  setError(true);
                }}
                style={{
                  overflow: "hidden",
                  objectFit: "cover",
                  width: "100%",
                  display: imageHasLoaded ? "block" : "none",
                }}
                mainSrc={!error ? imageUrl : errorImage}
                imageTitle={name}
              />
            )}
          </Box>
          <Box sx={{ width: "100%", display: "flex", flexDirection: "column" }}>
            <CardContent>
              <Stack direction={mediaQuery ? "row" : "column"} gap={4}>
                <Stack
                  flexBasis={{ base: 160, sm: 160, md: 360 }}
                  direction="column"
                >
                  <Typography
                    component="div"
                    style={{ color: "#1e293b" }}
                    variant="h5"
                  >
                    {name}
                  </Typography>
                  <Typography
                    variant="subtitle1"
                    style={{ color: "#64748b" }}
                    component="div"
                  >
                    {brandName} #{sellerCode}
                  </Typography>

                  <Stack direction="column">
                    <Stack direction="row">
                      <Stack gap={1} direction="row">
                        {quantity && unit && (
                          <Typography
                            style={{ fontWeight: 700, color: "#334155" }}
                            component="span"
                            variant="subtitle1"
                          >
                            {quantity}
                            {unit}
                          </Typography>
                        )}
                        {available && (
                          <Typography
                            style={{
                              whiteSpace: "nowrap",
                              alignSelf: "start",
                              borderRadius: 16,
                              padding: "0px 6px",
                              backgroundColor: "#cbd5e1",
                              fontWeight: 700,
                              color: "#0f172a",
                            }}
                            component="span"
                            variant="subtitle1"
                          >
                            {available} disponible
                          </Typography>
                        )}
                        {!available && (
                          <Typography
                            style={{
                              whiteSpace: "nowrap",
                              alignSelf: "start",
                              borderRadius: 16,
                              padding: "0px 6px",
                              backgroundColor: "#fca5a5",
                              fontWeight: 700,
                              color: "#450a0a",
                            }}
                            component="span"
                            variant="subtitle1"
                          >
                            Agotado
                          </Typography>
                        )}
                      </Stack>

                      {onbin && (
                        <Typography
                          style={{
                            borderRadius: 16,
                            padding: "0px 6px",
                            backgroundColor: "#cbd5e1",
                            fontWeight: 700,
                            color: "#0f172a",
                          }}
                          component="span"
                          variant="subtitle1"
                        >
                          {onbin} in stock
                        </Typography>
                      )}
                    </Stack>
                    {taste && (
                      <Typography
                        style={{
                          flexWrap: "wrap",
                          fontWeight: 500,
                          color: "#334155",
                        }}
                        component="span"
                        variant="subtitle1"
                      >
                        {taste}
                      </Typography>
                    )}
                  </Stack>
                  {weight && (
                    <Typography
                      style={{
                        flexWrap: "wrap",
                        fontWeight: 500,
                        color: "#334155",
                      }}
                      component="span"
                      variant="subtitle1"
                    >
                      {weight}
                    </Typography>
                  )}
                  <Stack
                    direction="row"
                    marginTop="8"
                    width="100%"
                    flexWrap="wrap"
                    overflow="auto"
                    gap="4px"
                  >
                    {specs &&
                      specs?.map(
                        (spec) =>
                          spec && (
                            <Stack
                              key={spec.objectID}
                              justifyContent="center"
                              alignItems="center"
                              gap={1}
                              direction="row"
                              style={{
                                padding: "2px 8px",
                                backgroundColor: "#e0f2fe",
                                borderRadius: 16,
                              }}
                            >
                              {spec.image !== "" && spec.image && (
                                <img
                                  src={spec.image}
                                  width={20}
                                  height="auto"
                                />
                              )}
                              <Typography
                                style={{
                                  whiteSpace: "nowrap",
                                  fontSize: "13px",
                                  alignSelf: "start",
                                  fontWeight: 700,
                                  color: "#0c4a6e",
                                }}
                                component="span"
                                variant="subtitle1"
                              >
                                {spec.name}
                              </Typography>
                            </Stack>
                          ),
                      )}
                  </Stack>
                </Stack>
                <Stack
                  direction="column"
                  backgroundColor="#e5e7eb"
                  flexGrow={1}
                  borderRadius={1}
                  p={2}
                  gap={0}
                >
                  {iva && (
                    <Typography
                      component="h4"
                      style={{
                        width: "100%",
                        color: "#1e293b",
                        fontSize: "12px",
                      }}
                    >
                      IVA:{" "}
                      <Typography
                        component="strong"
                        style={{ fontSize: "14px", fontWeight: 700 }}
                      >
                        {iva}
                      </Typography>
                    </Typography>
                  )}

                  <Stack
                    gap="4px"
                    marginTop={1}
                    alignItems="start"
                    direction="column"
                  >
                    {prices?.map((price, index) => (
                      <Box key={index} style={{ width: "100%" }}>
                        {price && price.value && (
                          <Typography
                            component="h4"
                            style={{
                              width: "100%",
                              color: "#1e293b",
                              fontSize: "16px",
                            }}
                          >
                            {price.title}:{" "}
                            <Typography
                              component="strong"
                              style={{ fontSize: "17px", fontWeight: 700 }}
                            >
                              {price.value}
                            </Typography>
                          </Typography>
                        )}
                        {index + 1 < prices.length && (
                          <Divider
                            sx={{ my: "6px" }}
                            style={{ width: "100%" }}
                          />
                        )}
                      </Box>
                    ))}
                  </Stack>
                </Stack>
                <Box sx={{ marginLeft: mediaQuery ? "auto" : "0" }}>
                  <Typography>Activada</Typography>
                  <Switch checked={active} />
                </Box>
              </Stack>
            </CardContent>
          </Box>
        </Stack>
      </Card>
    );
  },
);
