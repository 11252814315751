/* eslint-disable react/prop-types */

import {
  Box,
  Chip,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import EditIcon from "@mui/icons-material/Edit";
import { useUserData } from "../../../../context/UserContext";
import { FieldValue, updateDoc } from "../../../../firebase/database";

export function EditDiscount({ params }) {
  const [newDiscount, setNewDiscount] = useState(
    (params.row.discount || 0) * 100
  );
  const [editQ, setEditQ] = useState(false);

  const { uid } = useUserData();

  // FUNCTIONS
  const saveDiscount = (id) => {
    // const editedUsers = orderDoc.editedUsers
    if (
      newDiscount >= 0 &&
      newDiscount < 101 &&
      Number(newDiscount / 100) !== params.row.discount
    ) {
      const restore = Number(newDiscount / 100) === params.row.odiscount;
      updateDoc({
        indexName: "orders",
        id: params.row.orderId,
        hit: {
          [`items.${id}.discount`]: Number(newDiscount / 100),
          [`items.${id}.odiscount`]: restore
            ? FieldValue.delete()
            : params.row.discount || 0,
          [`items.${id}.didLastDiscount`]: restore ? FieldValue.delete() : uid,
          // ['editedUsers']: { ...editedUsers, [uid]: uid }
        },
      });
    }
    setEditQ(false);
  };

  return (
    <Box sx={{ display: "flex", flexDirection: "column" }}>
      {!editQ && (
        <span
          style={{ height: "20px", marginBottom: "8px", cursor: "pointer" }}
          onClick={() => !params.row.invoiceDriveId && setEditQ(true)}
        >
          <Typography style={{ display: "inline" }}>
            {Math.round((params.row.discount || 0) * 100)}%
          </Typography>
          {!params.row.invoiceDriveId && (
            <span>
              <EditIcon />
            </span>
          )}
        </span>
      )}
      {editQ && (
        <Box sx={{ width: "100%" }}>
          <TextField
            fullWidth
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Typography style={{ fontWeight: "bold" }}>%</Typography>
                </InputAdornment>
              ),
            }}
            onKeyDown={(e) => e.key === "Enter" && saveDiscount(params.row.id)}
            size="small"
            placeholder="10"
            type="number"
            step={10}
            autoFocus
            value={newDiscount}
            onChange={(e) =>
              e.target.value.length < 4 &&
              setNewDiscount(Number(e.target.value))
            }
            onBlur={() => saveDiscount(params.row.id)}
          />
        </Box>
      )}
      <div style={{ marginBottom: "5px" }}>
        {" "}
        {!editQ && params.row.didLastDiscount ? (
          <Chip
            icon={<AccountCircleIcon />}
            size="small"
            label={params.row.didLastDiscount}
            color="info"
          />
        ) : (
          ""
        )}
      </div>
    </Box>
  );
}
