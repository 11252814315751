import { useSearchParams } from "../../hooks/useSearchParams";
import Filters from "../Filters";
import SearchIcon from "@mui/icons-material/Search";
import InputAdornment from "@mui/material/InputAdornment";
import { TextField } from "@material-ui/core";
import { Stack } from "@mui/material";
import SortByAlphaIcon from "@mui/icons-material/SortByAlpha";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import ApartmentIcon from "@mui/icons-material/Apartment";
import { Sorting } from "../Sorting";
import { useSortFilter } from "../../hooks/useSortFilter";
import { useFilter } from "../../hooks/useFilter";

import {
  extractActive,
  extractAvailable,
  extractBrands,
  extractCategories,
} from "../../utils/extractFilters";
import { useDebouncer } from "../../../../utils/hooks/useDebouncer";
import { useEffect, useState } from "react";
export function FiltersSection({ data, isLoading }) {
  const searchDelayTime = 350;
  const {
    clearAllFiltersFromUrl,
    handleDeleteUrlFilter,
    handleOnUrlFilterChange,
  } = useFilter();
  const { handleUrlSort } = useSortFilter("sort");

  const { pushParams, deleteParam, setParam } = useSearchParams();

  const [inputText, changeInputText] = useState("");
  const [debouncedValue] = useDebouncer(inputText, searchDelayTime);
  const handleInputText = ({ target: { value } }) => changeInputText(value);
  useEffect(() => {
    if (debouncedValue === "") {
      deleteParam("q");
      return pushParams();
    }
    setParam("q", debouncedValue);
    return pushParams();
  }, [debouncedValue]);

  const filters = [
    {
      category: "Categorias",
      multiple: true,
      elements: extractCategories(data),
    },
    {
      category: "Marcas",
      multiple: true,
      elements: extractBrands(data),
    },
    {
      category: "Activo",
      multiple: true,
      elements: extractActive(data),
    },
    {
      category: "Disponibilidad",
      multiple: true,
      elements: extractAvailable(data),
    },
  ];
  const sortOptions = [
    {
      param: "sort",
      value: "alphabetically",
      name: "Alfabeticamente",
      icon: <SortByAlphaIcon />,
    },
    {
      param: "sort",
      value: "brand",
      name: "Por marca",
      icon: <ApartmentIcon />,
    },
    {
      param: "sort",
      value: "priceHigh",
      name: "Precio mayor",
      icon: <AttachMoneyIcon />,
    },
    {
      param: "sort",
      value: "priceLow",
      name: "Precio menor",
      icon: <AttachMoneyIcon />,
    },
  ];

  return (
    <>
      <Stack
        style={{ width: "100%" }}
        direction="column"
        gap={2}
        alignItems="start"
      >
        <TextField
          style={{
            width: "100%",
            alignSelf: "start",
            backgroundColor: "white",
            borderRadius: 24,
            border: "1px solid #cbd5e1",
          }}
          placeholder="fruits, Luz Azul, etc..."
          variant="outlined"
          onChange={handleInputText}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
        />
        <Stack width="100%" direction="row" justifyContent="space-between">
          <Filters
            isLoading={isLoading}
            onClear={() =>
              clearAllFiltersFromUrl([
                "brandName",
                "available",
                "spec",
                "active",
              ])
            }
            onApply={(el, close) => close()}
            filters={filters}
            onDeleteFilter={(filter) => handleDeleteUrlFilter(filter)}
            onChange={(el, event) => handleOnUrlFilterChange(el, event)}
          />
          <Sorting onChange={handleUrlSort} options={sortOptions} />
        </Stack>
      </Stack>
    </>
  );
}
