const constants = {
  algolia: [
    {
      id: "H0WENDTVNA",
      privatekey: "1da189767c0faf172d27efbba822f527",
      indexes: [
        "products",
        "priceList",
        "shippings",
        "brands",
        "specs",
        "discountCodes",
        "shipments",
        "roles",
        "areas",
        "stores",
      ],
    },
    {
      id: "SZAFCE8K18",
      privatekey: "4a3bdbffeb671b6c590fb2bb2b552e8e",
      indexes: [
        "vehicle_types",
        "vehicles",
        "truckers",
        "roadmaps",
        "users",
        "purchaseOrders",
        "invoices",
        "providers",
        "baskets",
      ],
    },
    {
      id: "2U59NROHY5",
      privatekey: "d9a94d4fd1ff426a2847b923b33413e5",
      indexes: ["orders", "sellers", "campaigns"],
    },
  ],
  seller: "v",
};

const config = (variable) => constants[variable];

export default config;
