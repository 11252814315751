import { Box, InputAdornment, TextField, Typography } from "@mui/material";
import React, { useState } from "react";
import EditIcon from "@mui/icons-material/Edit";
import IconButton from "@mui/material/IconButton";
import { updateDoc } from "../../../firebase/database";
import { useUserData } from "../../../context/UserContext";

export default function editShipping({ order }) {
  const [newShipping, setnewShipping] = useState("");
  const [editD, setEditD] = useState(false);
  const { uid } = useUserData();

  const saveShipping = () => {
    if (newShipping >= 0 && newShipping !== "") {
      const hit = {
        o_shipping_cost: Number(order.shipping_cost),
        shipping_cost: Number(newShipping),
        lastUserChangeShipping_cost: uid,
      };

      updateDoc({ indexName: "orders", id: order.objectID, hit }).then(() => {
        setnewShipping("");
        setEditD(false);
      });
    } else {
      setEditD(false);
      setnewShipping("");
    }
  };

  return (
    <>
      {!editD && (
        <IconButton onClick={() => setEditD(true)}>
          <EditIcon />
        </IconButton>
      )}
      {editD && (
        <Box sx={{ width: "100%" }}>
          <TextField
            fullWidth
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Typography style={{ fontWeight: "bold" }}>$</Typography>
                </InputAdornment>
              ),
            }}
            onKeyDown={(e) => e.key === "Enter" && saveShipping()}
            size="small"
            placeholder="10"
            type="number"
            step={10}
            autoFocus
            value={newShipping}
            onChange={(e) =>
              e.target.value >= 0 ? setnewShipping(e.target.value) : null
            }
            onBlur={saveShipping}
          />
        </Box>
      )}
    </>
  );
}
