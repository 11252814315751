/* eslint-disable no-plusplus */
/* eslint-disable react/no-unstable-nested-components */

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSnowflake } from "@fortawesome/free-solid-svg-icons";
import React from "react";

export function Sf({ color, q }) {
  function SF(i) {
    return <FontAwesomeIcon key={i} icon={faSnowflake} />;
  }
  const sfs = [];
  for (let i = 0; i < q; i++) sfs.push(SF(i));
  return <span style={{ color: color || "#5685ff" }}>{sfs}</span>;
}
