import { makeStyles } from "@material-ui/styles";

export default makeStyles((theme) => ({
  root: {
    // display: "flex",
    width: "fit-content",
    overflow: "auto",
  },
  content: {
    flexGrow: 1,
    width: "100%",
    minHeight: "calc(100vh - 64px)",
    [theme.breakpoints.down("sm")]: {
      minWidth: "100vw",
      marginLeft: "0",
    },
    [theme.breakpoints.down("xs")]: {
      padding: 0,
    },
  },
  contentShift: {
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  fakeToolbar: {
    ...theme.mixins.toolbar,
  },
}));
