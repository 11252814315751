/* eslint-disable react/button-has-type */
/* eslint-disable react/prop-types */

import React, { useEffect, useState } from "react";
import { useDocumentData } from "../firebase";
import { useUserData, useUserDataSet } from "./UserContext";
import LoadingPage from "../pages/loading";
import _ from "lodash";

const AreaDataSetContext = React.createContext();
const AreaDataContext = React.createContext();

function AreaProvider({ children }) {
  const user = useUserData();
  const userDataSet = useUserDataSet();
  const [areaDataOffline] = useState(
    localStorage?.getItem("areaData") || false,
  );

  const [areaDataOnline, loading] = useDocumentData(
    "areas",
    (user.currentArea &&
      user.areas?.includes(user.currentArea) &&
      user.currentArea) ||
      user.areas?.[0] ||
      "general",
  );

  const areaData = loading ? JSON.parse(areaDataOffline) : areaDataOnline;

  useEffect(() => {
    if (areaDataOnline) {
      localStorage?.setItem("areaData", JSON.stringify(areaDataOnline));
    }
  }, [areaDataOnline]);

  // eslint-disable-next-line react/jsx-no-constructed-context-values
  const setArea = (area) => userDataSet({ currentArea: area });

  return _.isEmpty(areaData) && user?.roles?.[0] ? (
    <LoadingPage />
  ) : (
    <AreaDataSetContext.Provider value={setArea}>
      <AreaDataContext.Provider value={areaData}>
        {children}
      </AreaDataContext.Provider>
    </AreaDataSetContext.Provider>
  );
}

function useAreaDataContext() {
  const context = React.useContext(AreaDataContext);
  if (context === undefined) {
    throw new Error("AreaStateContext must be used within a UserProvider");
  }
  return context;
}

function useAreaSetContext() {
  const context = React.useContext(AreaDataSetContext);
  if (context === undefined) {
    throw new Error("AreaDataSetContext must be used within a UserProvider");
  }
  return context;
}

export { AreaProvider, useAreaDataContext, useAreaSetContext };
