import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles(() => ({
  containerOrders: {
    display: "flex",
    justifyContent: "space-between",
    background: "#fff",
    borderRadius: 0,
    padding: "4px",
    boxShadow: "-3px 4px 13px 0px #ccccccd1",
  },
  containerInfo: {
    display: "flex",
    background: "#fff",
    borderRadius: 10,
    boxShadow: "1px 2px 9px #ccc",
  },
  image: {
    width: 200,
    marginTop: 15,
    marginBottom: 15,
  },
  typography: {
    fontWeight: "bold",
    color: "#4caf50",
    marginTop: 10,
  },
  typographyGraphic: {
    fontWeight: "bold",
    color: "#4caf50",
    marginTop: 10,
    display: "flex",
    justifyContent: "center",
  },
  typographyNumber: {
    fontWeight: "bold",
    color: "#4caf50",
    display: "inline",
    marginRight: 3,
  },
  description: {
    marginRight: 10,
    marginBottom: 15,
    marginLeft: 15,
  },
  containerMasInfo: {
    marginLeft: 15,
    marginBottom: 15,
  },
  containerImage: {
    width: 300,
    borderRight: "1px solid #d3d3d3",
    paddingRight: 20,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },

  mainBoxProduct: {
    maxWidth: 1200,
    marginTop: 20,
    margin: "auto",
    boxShadow: "1px 1px 1px 2px rgba(0,0,0,0.1)",
    borderRadius: 20,
    background: "#fff",
  },
  productImage: {
    width: 90,
    height: 90,
    objectFit: "contain",
  },
  productDetail: {
    position: "relative",
    right: 5,
  },
  titleH3: {
    color: "#666",
    marginTop: 40,
  },
  orders: {
    textAlign: "center",
  },
  typographyOrders: {
    fontWeight: "bold",
    display: "inline",
  },
  containerTypographyOrders: {
    marginBottom: 20,
  },
  containerDate: {
    display: "flex",
    justifyContent: "center",
    marginBottom: 50,
    flexDirection: "column",
    maxWidth: "fit-content",
    marginTop: 50,
  },
  containerGraphic: {
    flex: 1,
    marginBottom: 20,
    marginRight: 50,
  },

  containerInfoVentas: {
    borderRight: "1px solid #ccc",
    paddingRight: 20,
  },

  containerInfoGraphic: {
    height: "400",
    textAlign: "center",
  },
  typographyListGraphic: {
    marginTop: 130,
    fontWeight: "bold",
    color: "#4caf50",
    display: "flex",
    justifyContent: "center",
  },
  checkbox: {
    display: "flex",
    flexDirection: "inherit",
  },
  containerResumen: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginBottom: 5,
  },
}));
export default useStyles;
