import TuneIcon from "@mui/icons-material/Tune";
import CloseIcon from "@mui/icons-material/Close";
import IconButton from "@mui/material/IconButton";
import {
  Typography,
  Button,
  Checkbox,
  Chip,
  useMediaQuery,
} from "@material-ui/core";
import React, { useState } from "react";
import FormControlLabel from "@mui/material/FormControlLabel";
import { Box, Skeleton, Stack } from "@mui/material";
import FormGroup from "@mui/material/FormGroup";
import Modal from "@mui/material/Modal";
import { useSearchParams } from "../hooks/useSearchParams";
import { ExpandableList } from "./ExpandableList";
const style = {
  position: "absolute",
  top: "50%",
  borderRadius: 2,
  left: "50%",
  maxHeight: "80%",
  overflow: "auto",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
};
const Filters = ({
  isLoading = false,
  filters = [],
  onChange = ()=> null,
  onDeleteFilter = ()=> null,
  onApply = ()=> null,
  onClear = ()=> null,
}) => {
  let { getAllParams } = useSearchParams();
  const matches = useMediaQuery("(min-width:795px)");
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [selectedFilters, setSelectedFilters] = useState([]);
  const handleFilterChange = (e, el) => {
    if (e.target.checked) {
      setSelectedFilters((prev) => [...prev, el]);
      return onChange(el, e.target.checked);
    }
    setSelectedFilters(
      selectedFilters.filter((filter) => filter.value !== el.value),
    );
    return onChange(el, e.target.checked);
  };
  return (
    <div>
      <Stack gap={1} width="100%">
        <Button
          onClick={handleOpen}
          variant="outlined"
          style={{ alignSelf: "start", fontWeight: 700 }}
          startIcon={<TuneIcon />}
        >
          Filtros
        </Button>
        <Stack gap={1} direction="row" flexWrap="wrap">
          {filters.map((filter) =>
            filter.elements.map(
              (el) =>
                getAllParams(el.param).find(
                  (val) => val?.toString() === el.value?.toString(),
                ) && (
                  <Chip
                    key={el.value}
                    style={{
                      fontSize: 12,
                      backgroundColor: "#e2e8f0",
                      fontWeight: 700,
                      color: "#334155",
                    }}
                    label={el.label}
                    onDelete={() => {
                      setSelectedFilters(
                        selectedFilters.filter((f) => f.value !== el.value),
                      );
                      return onDeleteFilter(el);
                    }}
                  />
                ),
            ),
          )}
        </Stack>
      </Stack>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <>
          <Stack sx={style} style={{ width: matches ? "70%" : "80%" }} gap={1}>
            <Stack
              direction="row"
              style={{
                marginBottom: isLoading ? 0 : 24,
                justifyContent: "space-between",
              }}
            >
              <Typography
                style={{ fontWeight: 700, fontSize: "24px" }}
                id="modal-modal-title"
                variant="h6"
                component="h2"
              >
                Filtrar y ordernar
              </Typography>
              <IconButton onClick={handleClose} aria-label="delete">
                <CloseIcon />
              </IconButton>
            </Stack>
            {isLoading && (
              <Stack>
                <Stack>
                  <Skeleton width={100} height={30} variant="text"></Skeleton>
                  <Skeleton
                    width="100%"
                    height={400}
                    variant="rectangle"
                  ></Skeleton>
                </Stack>
                <Stack>
                  <Skeleton width={100} height={30} variant="text"></Skeleton>
                  <Skeleton
                    width="100%"
                    height={400}
                    variant="rectangle"
                  ></Skeleton>
                </Stack>
              </Stack>
            )}
            {!isLoading && (
              <Stack gap={2} flexWrap="wrap">
                {filters?.map((f, i) => (
                  <Stack
                    flexWrap="wrap"
                    key={i}
                    direction="row"
                    justifyContent="start"
                    style={{ borderBottom: "1px solid lightgray" }}
                  >
                    <Stack
                      justifyContent="start"
                      style={{
                        marginBottom: !matches && "8px",
                        flexBasis: "200px",
                      }}
                      width={200}
                    >
                      <Typography style={{ fontWeight: 700 }}>
                        {f.category}
                      </Typography>
                    </Stack>
                    <FormGroup style={{ flexGrow: 1 }}>
                      <Box
                        maxHeight="200px"
                        overflow="auto"
                        display="grid"
                        sx={{
                          gridTemplateColumns: {
                            base: `repeat(1,minmax(100px,1fr))`,
                            sm: `repeat(2,minmax(100px,1fr))`,
                            md: `repeat(3,minmax(100px,1fr))`,
                          },
                        }}
                      >
                        <ExpandableList limit={9}>
                          {f.elements?.map((el) => (
                            <Box key={el.value}>
                              {f.multiple && (
                                <Stack direction="row" alignItems="center">
                                  <FormControlLabel
                                    sx={{
                                      ".MuiFormControlLabel-label": {
                                        fontSize: "13px",
                                        fontFamily: "system-ui",
                                        fontWeight: 700,
                                      },
                                    }}
                                    style={{ fontSize: 12 }}
                                    onChange={(e) => handleFilterChange(e, el)}
                                    control={
                                      <Checkbox
                                        style={{ fontSize: 12 }}
                                        defaultChecked={getAllParams(
                                          el.param,
                                        ).find(
                                          (val) =>
                                            val?.toString() ===
                                            el.value?.toString(),
                                        )}
                                        checked={getAllParams(el.param).find(
                                          (val) =>
                                            val?.toString() ===
                                            el.value?.toString(),
                                        )}
                                        disabled={
                                          el.length === "0" || el.length === 0
                                        }
                                      />
                                    }
                                    label={el.label}
                                  />

                                  {el.length && (
                                    <FilterCounter
                                      size={"small"}
                                      value={el.length}
                                      variant="outlined"
                                    />
                                  )}
                                </Stack>
                              )}
                            </Box>
                          ))}
                        </ExpandableList>
                      </Box>
                    </FormGroup>
                  </Stack>
                ))}
              </Stack>
            )}

            {!isLoading && (
              <Stack sx={{ marginTop: 2 }} direction="row" gap={2}>
                <Button
                  onClick={() => onApply(selectedFilters, handleClose)}
                  variant="contained"
                >
                  Aplicar filtros
                </Button>
                <Button variant="outlined" onClick={() => onClear()}>
                  Limpiar filtros
                </Button>
              </Stack>
            )}
          </Stack>
        </>
      </Modal>
    </div>
  );
};
export default Filters;

export function FilterCounter({ value, variant, style, size }) {
  const defaultStyle = {
    fontSize: 12,
    backgroundColor: "#e2e8f0",
    height: 20,
    fontWeight: 700,
    color: "#334155",
  };
  return (
    <Chip
      label={String(value)}
      size={size}
      variant={variant}
      sx={{
        ".MuiChip-label": {
          padding: "4px 0",
        },
      }}
      style={style ? style : defaultStyle}
    />
  );
}
