import { TextField } from "@mui/material";
import { styled } from "@mui/styles";
import { useState } from "react";

export const InputChangeEntity = ({ row, handleChange, ...props }) => {
  const { sx, placeholder, persistNoValue = false, disabled } = props;
  const [inputValue, setInputValue] = useState(row?.entity);
  const MASK = "##-########-#";

  // if persistNoValue i don't update in firebase with onBlur, i need to persist the value

  const cleanedInput = inputValue?.trim()?.replace(/\D/g, "");

  const isValidCuit =
    inputValue?.split("")?.some((a) => a === "-") &&
    cleanedInput?.length === 11;

  const isValidDni = cleanedInput?.length >= 7 && cleanedInput?.length < 10;

  return (
    <StyledTextField
      disabled={disabled}
      placeholder={placeholder}
      sx={{ ...sx }}
      value={inputValue}
      onChange={(e) =>
        persistNoValue
          ? setInputValue(applyMask(MASK, e.target.value))
          : !row?.entity && row?.editable
          ? setInputValue(applyMask(MASK, e.target.value))
          : null
      }
      onBlur={() => {
        let formattedEntity = "";

        if (isValidCuit) {
          formattedEntity = "cuit-" + cleanedInput;
        } else if (isValidDni) {
          formattedEntity = "dni-" + cleanedInput;
        }

        if (isValidCuit || isValidDni || persistNoValue) {
          handleChange({
            ...row,
            entity: formattedEntity,
          });
        } else {
          setInputValue(row?.entity || "");
        }
      }}
    />
  );
};

const applyMask = (mask, value) => {
  const cleanedValue = value.replace(/\D/g, "");
  let maskedValue = "";
  let valueIndex = 0;

  for (let i = 0; i < mask.length; i++) {
    if (mask[i] === "#") {
      maskedValue += cleanedValue[valueIndex] || "";
      valueIndex++;
    } else if (value.length > 9 && valueIndex < cleanedValue.length) {
      maskedValue += mask[i];
    }
  }

  return maskedValue;
};
const StyledTextField = styled(TextField)({
  "& .MuiOutlinedInput-root": {
    fontSize: "0.875rem",
    "& fieldset": {
      outline: "none",
      border: "none",
    },
  },
});
