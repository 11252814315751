import { useMongoAggregate } from "../../../utils/mongoAggregate/useMongoAggregate";

export default function useUsersByBrands({ brand, from, to, product, spec }) {
  const [data, loading] = useMongoAggregate({
    index: "products",
    aggregate: [
      {
        $match: {
          ...(brand ? { "data.brand": brand } : {}),
          ...(product ? { "data.objectID": product } : {}),
          ...(spec ? { "data.specs": spec } : {}),
        },
      },
      {
        $project: {
          "data.objectID": 1,
          "data.name": 1,
          "data.brandName": 1,
          "data.brand": 1,
          "data.timestamp_timestamp": 1,
        },
      },
      {
        $lookup: {
          from: "porders",
          localField: "data.objectID",
          foreignField: "data.itemsArray",
          pipeline: [
            {
              $match: {
                "data.timestamp_timestamp": {
                  $gt: from || 1677096141,
                  $lt: to || 1677268941,
                },
              },
            },
          ],
          as: "order",
        },
      },
      {
        $unwind: {
          path: "$order",
          includeArrayIndex: "string",
          preserveNullAndEmptyArrays: false,
        },
      },
      {
        $group: {
          _id: "$order._id",
          orderItems: {
            $first: "$order.data.items",
          },
          user: {
            $first: "$order.data.user",
          },
          store: {
            $first: "$order.data.store",
          },
          lastEdit: {
            $first: "$order.data.timestamp_timestamp",
          },
          items: {
            $push: {
              k: "$data.objectID",
              brand: "$data.brand",
            },
          },
        },
      },
      {
        $project: {
          totalQ: {
            $filter: {
              input: {
                $objectToArray: "$orderItems",
              },
              as: "item",
              cond: {
                $in: ["$$item.k", "$items.k"],
              },
            },
          },
          items: 1,
          orderItems: 1,
          user: 1,
          store: 1,
          lastEdit: 1,
        },
      },
      {
        $project: {
          total: {
            $sum: "$totalQ.v.q",
          },
          user: 1,
          store: 1,
          lastEdit: 1,
          totalprice: {
            $reduce: {
              input: "$totalQ",
              initialValue: 0,
              in: {
                $add: [
                  "$$value",
                  {
                    $multiply: ["$$this.v.q", "$$this.v.price"],
                  },
                ],
              },
            },
          },
        },
      },
      {
        $lookup: {
          from: "pstores",
          localField: "store",
          foreignField: "data.objectID",
          as: "store",
        },
      },
      {
        $sort: {
          totalPrice: -1,
        },
      },
      {
        $match: {
          store: { $size: 1 },
        },
      },
      {
        $facet: {
          old: [],
          dates: [
            {
              $project: {
                lastEdit: {
                  $dateToString: {
                    date: {
                      $toDate: {
                        $multiply: ["$lastEdit", 1000],
                      },
                    },
                    format: "%d-%m",
                  },
                },
                totalprice: 1,
              },
            },
            {
              $group: {
                _id: "$lastEdit",
                totalPrice: {
                  $sum: "$totalprice",
                },
              },
            },
          ],
        },
      },
    ],
  });
  return [data, loading];
}
