import { Box } from "@material-ui/core";

export function MainLayout({ children }) {
  return (
    <Box
      style={{ padding: 24, marginTop: 56, flexGrow: 1 }}
      component="main"
      sx={{ backgroundColor: "#f8fafc" }}
    >
      {children}
    </Box>
  );
}
