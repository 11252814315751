const check_states = {
  paid: "PAGADO",
  deposited: "DEPOSITADO",
  custody: "CUSTODIA",
  asset: "ACTIVO",
  issued_pending: "EMITIDO_PENDIENTE",
  assets_outstanding: "ACTIVO_PENDIENTE",
  pending_return: "DEVOLUCION_PENDIENTE",
  presented: "PRESENTADO",
  bounced: "RECHAZADO",
  rejected: "REPUDIADO",
  expired: "CADUCADO",
  voided: "ANULADO",
};

const entities_tags = {
  supplier: "Proveedor",
  customer: "Cliente",
  store: "Tienda",
};

const invoices_types = {
  1: "Factura A",
  2: "Nota De Debito A",
  3: "Nota De Credito A",
  4: "Recibo A",
  5: "Nota De Venta Al Contado A",
  6: "Factura B",
  7: "Nota De Debito B",
  8: "Nota De Credito B",
  9: "Recibo B",
  10: "Nota De Venta Al Contado B",
  11: "Factura C",
  12: "Nota De Debito C",
  13: "Nota De Credito C",
  15: "Recibo C",
  51: "Factura M",
  52: "Nota De Debito M",
  53: "Nota De Credito M",
  54: "Recibo M",
  201: "Factura De Crédito Electrónica Mipymes (Fce) A",
  202: "Nota De Debito Electrónica Mipymes (Fce) A",
  203: "Nota De Credito Electrónica Mipymes (Fce) A",
};
const columnsTransactions = [
  {
    field: "date",
    headerName: "Fecha",
  },
  { field: "objectID", headerName: "id" },
  {
    field: "bank",
    headerName: "Banco",
    options: {
      filter: true,
    },
  },
  {
    field: "reason",
    headerName: "Concepto",
  },
  {
    field: "description",
    headerName: "Desc",
  },
  {
    field: "entity",
    headerName: "Persona",
    options: {
      filter: true,
    },
  },
  {
    field: "entity_name",
    headerName: "Nombre",
  },
  {
    field: "amount",
    headerName: "Monto",
    options: {
      type: "currency",
    },
  },
  {
    field: "balance",
    headerName: "Balance",
    options: {
      type: "currency",
    },
  },
  {
    field: "cuit",
    headerName: "Cuit",
  },
  {
    field: "razonsocial",
    headerName: "Nombre",
  },
  {
    field: "check",
    headerName: "Cheque",
    options: {
      index: { name: "checks", field: "payee.name" },
    },
  },
  {
    field: "contabilium",
    headerName: "Contabilium Estado",
  },
  {
    field: "tags",
    headerName: "Tags",
    options: {
      filter: true,
      multiple: true,
    },
  },
];

const columnsInvoices = [
  {
    field: "date",
    headerName: "Fecha",
  },
  {
    field: "reason",
    headerName: "Concepto",
  },
  {
    field: "imp_total",
    headerName: "Total",
    options: {
      type: "currency",
    },
  },
  {
    field: "ref",
    headerName: "ref",
  },
  {
    field: "entity",
    headerName: "Persona",
    options: {
      filter: true,
    },
  },
  {
    field: "entity_name",
    headerName: "Nombre",
  },
  {
    field: "tipo_numero",
    headerName: "Tipo",
    options: {
      filter: true,
    },
  },
  {
    field: "received",
    headerName: "Recibida",
    options: {
      filter: true,
    },
  },
  {
    field: "idContabilium",
    headerName: "Contabilium",
  },
  {
    field: "idContabiliumCompra",
    headerName: "Contabilium Compra",
  },
  { field: "objectID", headerName: "id" },
];

const columnsChecks = [
  {
    field: "dates.issue",
    headerName: "Fecha Emision",
  },
  { field: "objectID", headerName: "id" },

  {
    field: "dates.payment",
    headerName: "Fecha Pago",
  },
  {
    field: "dates.transaction",
    headerName: "Fecha Transaccion",
  },
  {
    field: "amount",
    headerName: "Monto",
    options: {
      type: "currency",
    },
  },
  {
    field: "payee.cuit",
    headerName: "Cuit Receptor",
    options: {
      filter: true,
    },
  },
  {
    field: "issuer.cuit",
    headerName: "Cuit Emisor",
    options: {
      filter: true,
    },
  },
  { field: "check_number", headerName: "Cheque" },
  {
    field: "state",
    headerName: "Estado",
    options: {
      filter: true,
    },
    valueFormatter: ({ value }) => check_states[value] || "",
  },
];

const columnsNetsuite = [
  {
    field: "date",
    headerName: "Fecha",
  },
  {
    field: "amount",
    headerName: "Monto",
    options: {
      type: "currency",
    },
    // valueFormatter: ({ value }) => `$${numberWithCommas(value, 2)}` || "",
  },
  { field: "cuit", headerName: "Cuit" },
  {
    field: "account",
    headerName: "Cuenta",
    options: {
      filter: true,
    },
  },
  { field: "tranid", headerName: "ID" },
  {
    field: "name",
    headerName: "Razón social",
    options: {
      filter: true,
    },
  },
];

export {
  check_states,
  columnsTransactions,
  columnsChecks,
  columnsInvoices,
  columnsNetsuite,
  entities_tags,
  invoices_types,
};
