import { makeStyles } from "@material-ui/styles";

export default makeStyles((theme) => ({
  containerOrders: {
    display: "flex",
    justifyContent: "space-around",
    background: "#fff",
    borderRadius: 10,
    boxShadow: "1px 2px 9px #ccc",
  },
  containerInfo: {
    display: "flex",
    background: "#fff",
    borderRadius: 10,
    boxShadow: "1px 2px 9px #ccc",
  },
  image: {
    maxWidth: "100%",
    width: 200,
    marginTop: 15,
    marginBottom: 15,
  },
  imageEdit: {
    width: "100px",
  },
  containerImagesEdit: {
    display: "flex",
    width: 200,
    justifyContent: "space-around",
    marginBottom: "20px",
    marginTop: "25px",
    border: "1px solid #ccc",
    borderRadius: "5px",
  },
  typography: {
    fontWeight: "bold",
    color: "#4caf50",
    marginTop: 10,
  },
  typographyGraphic: {
    fontWeight: "bold",
    color: "#4caf50",
    marginTop: 10,
    display: "flex",
    justifyContent: "center",
  },
  typographyNumber: {
    fontWeight: "bold",
    color: "#4caf50",
    display: "inline",
    marginRight: 3,
  },
  description: {
    marginRight: 10,
    marginBottom: 15,
    marginLeft: 15,
  },
  containerMasInfo: {
    marginLeft: 15,
    marginBottom: 15,
  },
  containerImage: {
    width: 300,
    borderRight: "1px solid #d3d3d3",
    paddingRight: 20,
  },
  titleH3: {
    color: "#666",
    marginTop: 40,
  },
  orders: {
    borderRight: "1px solid #ccc",
    paddingRight: 20,
    borderBottom: "1px solid  #ccc",
    paddingBottom: 50,
  },
  typographyOrders: {
    fontWeight: "bold",
    display: "inline",
  },
  containerTypographyOrders: {
    marginBottom: 20,
  },
  containerDate: {
    display: "flex",
    justifyContent: "center",
    marginBottom: 50,
  },
  containerGraphic: {
    marginBottom: 20,
  },
  containerInfoVentas: {
    borderRight: "1px solid #ccc",
    paddingRight: 20,
  },
  containerInfoGraphic: {
    height: "400",
  },
  typographyListGraphic: {
    marginTop: 130,
    fontWeight: "bold",
    color: "#4caf50",
    display: "flex",
    justifyContent: "center",
  },
  mainBoxProduct: {
    maxWidth: 1200,
    marginTop: 20,
    margin: "auto",
    boxShadow: "1px 1px 1px 2px rgba(0,0,0,0.1)",
    borderRadius: 20,
    background: "#fff",
  },
  productImage: {
    width: 90,
    height: 90,
    objectFit: "contain",
  },
  productDetail: {
    position: "relative",
    right: 5,
  },
  productName: {
    width: "100%",
    fontSize: "1.2rem",
    fontWeight: "bold",
    textDecoration: "underline",
    textOverflow: "ellipsis",
    [theme.breakpoints.down(330)]: {
      maxWidth: 280,
    },
  },
  binBox: {
    backgroundColor: "#eeeeee",
    cursor: "pointer",
    borderRadius: 5,
    border: "1px solid #ccc",
    padding: ".2rem .5rem",
    maxWidth: 320,
    // fontSize: '0.8rem',
  },
  binButton: {
    padding: 20,
    margin: 4,
    background: "#fff",
    boxShadow: "1px 1px 1px 2px rgba(0,0,0,0.1)",
    fontSize: "1rem",
  },
  totalsBox: {
    backgroundColor: "#fff9c4",
    boxShadow: "1px 1px 1px 2px rgba(0,0,0,0.1)",
    padding: ".2rem .5rem",
    borderRadius: 5,
    marginTop: "2rem",
  },
  quantity: {
    fontWeight: "bold",
    fontSize: "1em",
  },
  lotPackQ: {
    fontSize: "0.6em",
    marginLeft: "4px",
    color: "#5c5c5c",
  },
  listAvatar: {
    marginRight: 10,
  },
  badge: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: ".2rem .5rem",
    backgroundColor: theme.palette.secondary.main,
    color: "#fff",
    fontSize: ".6rem",
    fontWeight: "bold",
  },
  badgeNoStock: {
    backgroundColor: theme.palette.secondary.main,
  },
  badgeStock: {
    backgroundColor: theme.palette.success.main,
  },
  badgeActive: {
    backgroundColor: "#ffb74d",
  },
  form: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 1200,
    bgcolor: "background.paper",
    backgroundColor: "#fff",
    borderRadius: 20,
    boxShadow: 24,
    p: 4,
  },
  titleForm: {
    margin: 40,
    textAlign: "center",
  },
  containerForm: {
    display: "flex",
    textAlign: "center",
    flexDirection: "column",
  },
  label: {
    margin: "10px 0",
    display: "grid",
    gridAutoFlow: "column",
    alignItems: "center",
  },
  input: {
    width: "90%",
  },
  buttonInput: {
    borderRadius: 10,
    border: "1px solid #4caf50",
    color: "#4caf50",
    backgroundColor: "#fff",
    margin: "20 auto",
    padding: "20 60",
    cursor: "pointer",
    fontSize: 20,
  },
  typographyLabel: {
    maxWidth: 70,
    marginLeft: 100,
  },
}));
