import { makeStyles } from "@material-ui/styles";

export default makeStyles(() => ({
  root: {
    // "width": "75%",
    // "maxWidth": "390px",
    margin: "auto",
    marginBottom: "70px",
    WebkitBoxShadow: "0 11px 18px -4px #777",
    MozBoxShadow: "0 11px 18px -4px #777",
    boxShadow: "0 11px 18px -4px #777",
    padding: "1rem",
    // [theme.breakpoints.down('sm')]: {
    //     "width": "95%",
    // }
  },
  title: {
    margin: "1rem 0",
  },
  location_list: {
    // "height": "260px",
    // "overflowY": "scroll"
  },
  location_list__location__selected: {
    border: "2px solid #ffc244",
  },
  location_list__location: {
    // "height": "80px",
    // "width": "70%",
    // "maxWidth": "360px",
    border: "1px solid #e4e4e4",
    borderRadius: "10px",
    margin: "0 auto 10px",
    transition: ".3s",
    cursor: "pointer",
    display: "flex",
    flexWrap: "wrap",
    boxShadow: "0 2px 20px 0 rgba(0,0,0,.1)",
    "&:not(:hover)": {
      boxShadow: "none",
      borderColor: "#cacaca",
    },
  },
  location_list__location__flag: {
    display: "flex",
    width: "70px",
    lineHeight: "80px",
    height: "80px",
    color: "#2abb9b",
    fontSize: "24px",
    justifyContent: "start",
    boxSizing: "border-box",
    "&> img": {
      marginTop: "13px",
      marginLeft: "10px",
      height: "50px",
      opacity: "0.6",
    },
  },
  location_list__location__checkbox: {
    display: "flex",
    width: "70px",
    lineHeight: "80px",
    height: "80px",
    color: "#2abb9b",
    fontSize: "24px",
  },
  location_list__location__address: {
    width: "calc(100% - 70px)",
    padding: "8px 8px 8px 0",
    textAlign: "left",
  },
  location_list__location__address__details: {
    color: "#9b9b9b",
    margin: "5px 0",
  },
  location_list__location__address__title: {
    fontWeight: "600",
    fontSize: "1rem",
  },
  location_list__location__address__text: {
    overflow: "hidden",
    // "textOverflow": "ellipsis",
    // "whiteSpace": "nowrap",
    fontSize: "1rem",
  },
  location_list__add: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    fontSize: "16px",
    lineHeight: "1.33",
    margin: "20px auto 20px",
    cursor: "pointer",
    color: "#2abb9b",
    fontWeight: "500",
    // "maxWidth": "80%",
    // "&:hover":{
    //     "opacity": "0.8"
    // }
  },
}));
