import firebase from "firebase/app";
import "firebase/storage";

export default async function UploadToFirebase({ file, fullPath }) {
  const storageRef = firebase.storage().ref();
  const ref = storageRef.child(fullPath);

  const canvas = document.createElement('canvas');
  const ctx = canvas.getContext('2d');

  const img = new Image();
  img.src = URL.createObjectURL(file);

  return new Promise((resolve, reject) => {
    img.onload = () => {
      canvas.width = img.width;
      canvas.height = img.height;

      ctx.drawImage(img, 0, 0);
      
      canvas.toBlob(async (blob) => {
        if (!blob) {
          reject(new Error('Failed to create blob'));
          return;
        }
        
        const snapshot = await ref.put(blob);
        const downloadURL = await snapshot.ref.getDownloadURL();
        resolve(downloadURL);
      }, 'image/jpeg', 0.4); // Change the quality (0.4 = 40%)
    };
    
    img.onerror = (error) => {
      reject(error);
    };
  });
}
