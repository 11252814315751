import moment from "moment/moment";

export default function validReception(expirationDate, shelflife) {
  return moment(expirationDate).isSameOrAfter(minDateForReception(shelflife));
}

export function minDateForReception(shelflife) {
  const min = conditions.find(
    (a) => a.from <= shelflife && a.to > shelflife
  )?.min;
  return moment()
    .add(min * shelflife, "days")
    .startOf("day");
}

const conditions = [
  {
    from: 0,
    to: 30,
    min: 0.85,
  },
  {
    from: 30,
    to: 90,
    min: 0.8,
  },
  {
    from: 90,
    to: 365,
    min: 0.7,
  },
  {
    from: 365,
    to: 1000000,
    min: 0.65,
  },
];
