import {
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  Typography,
} from "@mui/material";
import moment from "moment";
import { useState } from "react";
import { numberWithCommas } from "../../../../../components/Utils/NumberWithCommas";
import { useDocs } from "../../../../../algolia/getObjects";
import { map } from "lodash";
import { updateDoc } from "../../../../../firebase/database";
import { useUserData } from "../../../../../context/UserContext";

export const PaymentsCard = ({ order }) => {
  const date = order?.payment_timestamp
    ? moment(order?.payment_timestamp).format("DD/MM HH:mm")
    : "";

  const { uid } = useUserData();

  const paymentOptions = useDocs({ index: "paymentOptions" });

  const totalToPay = numberWithCommas(order?.ototal || 0, 2);

  const [paymentValues, setPaymentValues] = useState({
    paymentMethod: order?.paymentMethod?.objectID || null,
  });

  const handleChangeData = async (e) => {
    const { value, name } = e.target;

    await updateDoc({
      indexName: "orders",
      id: order?.objectID,
      hit: {
        paymentMethod: value,
        paymentChanged: { t: (new Date() / 1000) | 0, by: uid },
      },
    });

    setPaymentValues({ ...paymentValues, [name]: value });
  };

  return (
    <Box display={"flex"} flexDirection={"column"} gap={2}>
      <Stack
        direction={"row"}
        sx={{
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Typography>{date}</Typography>
      </Stack>
      <Stack
        direction={"row"}
        sx={{
          justifyContent: "space-between",
          alignItems: "center",
        }}
        spacing={2}
      >
        <FormControl fullWidth>
          <InputLabel id="demo-changePaymentMethod">Método de pago</InputLabel>
          <Select
            labelId="demo-changePaymentMethod"
            id="changePaymentMethod"
            value={paymentValues.paymentMethod || ""}
            label="Método de pago"
            name={"paymentMethod"}
            onChange={handleChangeData}
          >
            {map(paymentOptions, ({ name: paymentMethod }, id) => (
              <MenuItem key={paymentMethod} value={id}>
                {paymentMethod}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <Typography
          sx={{ fontSize: 18, fontWeight: "bold", whiteSpace: "nowrap" }}
        >
          $ {totalToPay}
        </Typography>
      </Stack>
      <Stack>
        <Stack direction={"row"} sx={{ alignItems: "center" }} spacing={1}>
          <Typography>
            Pagado:{" "}
            <Typography sx={{ color: "green" }}>
              ${numberWithCommas(12000 || 0, 2)}
            </Typography>
          </Typography>
        </Stack>

        <Stack direction={"row"} sx={{ alignItems: "center" }} spacing={1}>
          <Typography>
            Faltan:{" "}
            <Typography sx={{ color: "red" }}>
              {" "}
              ${numberWithCommas(2000 || 0, 2)}
            </Typography>
          </Typography>
        </Stack>
      </Stack>
    </Box>
  );
};
