import {
  DateRangeCalendar,
  SingleInputDateRangeField,
} from "@mui/x-date-pickers-pro";
import { useEffect } from "react";
import { DateRangePicker } from "@mui/x-date-pickers-pro/DateRangePicker";

import "moment/locale/es";
import moment from "moment";

export const MuiCalendar = ({ date, action, direction, ...props }) => {
  const updateDate = (newDate) => {
    action(newDate);
  };
  useEffect(() => {
    try {
      setTimeout(() => {
        for (const e of document.querySelectorAll(
          ".MuiDateRangeCalendar-root > div",
        ))
          e.textContent === "MUI X Missing license key" &&
            (e.style.display = "none");
      }, 1000);
    } catch (e) {
      console.log(e);
    }
  }, []);

  return (
    <DateRangeCalendar
      sx={{
        "&.MuiDateRangeCalendar-root": {
          flexDirection: direction || "column",
        },
      }}
      value={date}
      onChange={updateDate}
      {...props}
    />
  );
};

const clearMissingKey = () => {
  try {
    setTimeout(() => {
      for (const e of document.querySelectorAll(
        ".MuiDateRangeCalendar-root > div",
      ))
        e.textContent === "MUI X Missing license key" &&
          (e.style.display = "none");
    }, 100);
  } catch (e) {
    console.log(e);
  }
};

const shortcutsItems = [
  {
    label: moment().subtract(2, "year").format("YYYY"),
    getValue: () => {
      const prevYear = moment().subtract(2, "year");
      return [prevYear.startOf("year"), prevYear.clone().endOf("year")];
    },
  },
  {
    label: moment().subtract(1, "year").format("YYYY"),
    getValue: () => {
      const prevYear = moment().subtract(1, "year");
      return [prevYear.startOf("year"), prevYear.clone().endOf("year")];
    },
  },
  {
    label: moment().format("YYYY"),
    getValue: () => {
      return [moment().startOf("year"), moment().endOf("year")];
    },
  },
  {
    label: "Esta Semana",
    getValue: () => {
      return [moment().startOf("week"), moment().endOf("week")];
    },
  },
  {
    label: "Últimos 365 Dias",
    getValue: () => {
      return [moment().subtract(365, "day"), moment()];
    },
  },
  {
    label: "Semana Anterior",
    getValue: () => {
      const prevWeek = moment().subtract(7, "day");
      return [prevWeek.startOf("week"), prevWeek.clone().endOf("week")];
    },
  },
  {
    label: "Últimos 7 Dias",
    getValue: () => {
      return [moment().subtract(7, "day"), moment()];
    },
  },
  {
    label: "Mes Anterior",
    getValue: () => {
      const startOfNextMonth = moment()
        .startOf("month")
        .subtract(1, "day")
        .startOf("month");
      return [startOfNextMonth, startOfNextMonth.clone().endOf("month")];
    },
  },
  {
    label: "Mes en Curso",
    getValue: () => {
      return [moment().startOf("month"), moment().endOf("month")];
    },
  },
  {
    label: "Siguiente Mes",
    getValue: () => {
      const startOfNextMonth = moment().endOf("month").add(1, "day");
      return [startOfNextMonth, startOfNextMonth.clone().endOf("month")];
    },
  },
  // { label: "Reset", getValue: () => [null, null] },
];

export const MuiDateRangePicker = ({ date, onChange, ...props }) => {
  const { showSlots = true } = props
  
  return (
    <DateRangePicker
      value={date}
      onChange={onChange}
      onOpen={clearMissingKey}
      sx={{ width: "100%", maxWidth: 240 }}
      slots={{ field: SingleInputDateRangeField }}
      slotProps={showSlots ? {
        shortcuts: {
          items: shortcutsItems,
        },
        actionBar: { actions: [] },
      } : {}}
      {...props}
    />
  );
};
