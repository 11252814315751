import { useState } from "react";
import { useEvent } from "../../../hooks/useEvent";

export const useModal = () => {
  const [modalIsVisible, setModalIsVisible] = useState(false);
  const toggleModalVisibility = useEvent(() =>
    setModalIsVisible(!modalIsVisible),
  );

  return [modalIsVisible, toggleModalVisibility];
};
