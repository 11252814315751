import { Box, CardContent, CardMedia, Typography } from "@material-ui/core";
import { Skeleton, Stack } from "@mui/material";
import { useState } from "react";

import { Card } from "react-bootstrap";
import Chip from "@mui/material/Chip";
export function BrandCard({
  mediaQuery,
  name,
  id,
  imageUrl,
  active,
  activeProducts,
  availableProducts,
  description,
}) {
  const [imageHasLoaded, setImageHasLoaded] = useState(
    imageUrl === "" && imageUrl ? true : false,
  );
  const [error, setError] = useState(false);
  const errorImage =
    "https://upload.wikimedia.org/wikipedia/commons/a/a3/Image-not-found.png";

  return (
    <Card
      sx={{ display: "flex" }}
      style={{
        backgroundColor: "#f8f8f8",
        fontFamily: "system-ui",
        overflow: "hidden",
        borderRadius: 3,
        borderBottom: "none",
        boxShadow:
          "rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px",
      }}
    >
      <Stack
        style={{ height: "100%" }}
        gap="4"
        direction={mediaQuery ? "row" : "column"}
      >
        {!imageHasLoaded && imageUrl && (
          <Skeleton
            variant="rectangular"
            style={{ minWidth: "170px" }}
            height="auto"
          />
        )}
        <Box
          style={{
            padding: 12,
            width: imageHasLoaded ? (mediaQuery ? "170px" : "150px") : 0,
            flexShrink: 0,
            overflow: "hidden",
          }}
        >
          <CardMedia
            onLoad={() => setImageHasLoaded(true)}
            onError={() => {
              setImageHasLoaded(true);
              return setError(true);
            }}
            component="img"
            style={{
              borderRadius: 2,
              cursor: "pointer",
              overflow: "hidden",
              objectFit: "contain",
              width: "100%",
              height: "100px",
              display: imageHasLoaded ? "block" : "none",
            }}
            image={
              error | !imageUrl | (imageUrl === "") ? errorImage : imageUrl
            }
            alt={name}
          />
        </Box>
        <Box sx={{ width: "100%", display: "flex", flexDirection: "column" }}>
          <CardContent>
            <Stack direction={mediaQuery ? "row" : "column"} gap={4}>
              <Stack style={{ maxWidth: "100%" }} direction="column">
                <Typography
                  variant="subtitle1"
                  style={{ color: "#64748b" }}
                  component="div"
                >
                  #{id}
                </Typography>
                <Stack direction="row" alignItems="center" gap={2}>
                  <Typography
                    component="h3"
                    style={{
                      fontSize: "16px",
                      fontWeight: 700,
                      color: "#1e293b",
                    }}
                  >
                    {name}
                  </Typography>
                  <Chip label={active ? "Activo" : "Desactivado"} />
                </Stack>
                <Typography
                  component="p"
                  style={{
                    fontSize: "16px",
                    fontWeight: 400,
                    color: "#9ca3af",
                  }}
                >
                  {description}
                </Typography>
                <Stack direction="row" gap={2} marginTop={1}>
                  <Typography
                    component="span"
                    style={{
                      fontSize: "14px",
                      fontWeight: 400,
                      color: "#6b7280",
                    }}
                  >
                    Activos{" "}
                    <strong style={{ color: "#1f2937" }}>
                      {activeProducts}
                    </strong>
                  </Typography>
                  <Typography
                    component="span"
                    style={{
                      fontSize: "14px",
                      fontWeight: 400,
                      color: "#6b7280",
                    }}
                  >
                    Disponibles{" "}
                    <strong style={{ color: "#1f2937" }}>
                      {availableProducts}
                    </strong>
                  </Typography>
                </Stack>
              </Stack>
            </Stack>
          </CardContent>
        </Box>
      </Stack>
    </Card>
  );
}
