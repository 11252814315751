import { GenericModal } from "../pages/app/utils/GenericModal";

export const withModal = (BaseComponent) => {
  return function Component(props) {
    const { open, handleClose, width = 400 } = props;

    return (
      <GenericModal width={width} open={open} handleClose={handleClose}>
        <BaseComponent {...props} />
      </GenericModal>
    );
  };
};
