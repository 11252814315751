import algoliasearch from "algoliasearch";
import { createNullCache } from "@algolia/cache-common";
import { useEffect, useState } from "react";
import _ from "lodash";
import config from "./config";
import { auth, useCollection } from "../firebase";

import { useQuery } from "react-query";
import { fixTimestampFirestore } from "../firebase/utils/fixTimestamp";

const algoliaconfig = config("algolia");
let indexAlgolia = {};

let initIndex = (index) => {
  if (!indexAlgolia[index]) {
    const app =
      algoliaconfig?.find((d) => d.indexes.includes(index)) ||
      _.last(algoliaconfig);
    indexAlgolia[index] = algoliasearch(app.id, app.privatekey, {
      responsesCache: createNullCache(),
      requestsCache: createNullCache(),
    }).initIndex(index);
  }
};

let algoliaIndex = (index) => {
  initIndex(index);
  return indexAlgolia[index];
};

let algoliaIndexBrowser = algoliaIndex;

const serverUrl = "https://stock-p-c059a3e3d873.herokuapp.com"; // 'http://localhost:5011';

const customSearchClient = (area) => ({
  async search(requests) {
    await new Promise((r) => {
      const t = () => (auth?.currentUser ? r() : setTimeout(t, 500));
      t();
    });
    const idToken = await auth.currentUser.getIdToken();
    return fetch(`${serverUrl}/search`, {
      method: "post",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${idToken}`,
      },
      body: JSON.stringify({ requests, area }),
    }).then((res) => res.json());
  },
  async searchForFacetValues(requests) {
    await new Promise((r) => {
      const t = () => (auth?.currentUser ? r() : setTimeout(t, 500));
      t();
    });
    const idToken = await auth.currentUser.getIdToken();
    return fetch(`${serverUrl}/sffv`, {
      method: "post",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${idToken}`,
      },
      body: JSON.stringify({ requests, area }),
    }).then((res) => res.json());
  },
});

const populateByArea = async (request) => {
  await new Promise((r) => {
    const t = () => (auth?.currentUser ? r() : setTimeout(t, 500));
    t();
  });
  const idToken = await auth.currentUser.getIdToken();
  return fetch(`${serverUrl}/populateHitsByArea`, {
    method: "post",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${idToken}`,
    },
    body: JSON.stringify(request),
  }).then((res) => res.json());
};

export function useFirestoreDocs({ indexName = false, where = [] }) {
  const [lastUpdate, setLastUpdate] = useState((new Date() / 1000) | 0);

  const [fdocs, loading, error] = useCollection({
    collection: indexName,
    where: [["modified_date", ">", lastUpdate], ...where],
    limit: 5,
  });

  useEffect(() => {
    const fl = fdocs?.map?.((d) => d.modified_date).sort((a, b) => a - b)[0];
    if (fl && fl > lastUpdate) setLastUpdate((fl / 1000) | 0);
  }, [fdocs, lastUpdate]);

  return [
    fdocs?.map((d) => fixTimestampFirestore(d)),
    loading,
    error,
    lastUpdate,
  ];
}

const fetcher = (area, req) => customSearchClient(area).search(req);

function useSearch({ area, requests = [], updates = false }) {
  const [response, setResponse] = useState(false);

  // console.log(area);
  // console.log(requests);

  const { data, isLoading } = useQuery(
    [area, requests],
    () => fetcher(area, requests),
    {
      refetchOnWindowFocus: true,
      keepPreviousData: true,
      staleTime: 5000,
    },
  );

  const [fdocs] = useFirestoreDocs({
    indexName:
      data?.results?.[0]?.hits?.length && updates && requests[0]?.indexName,
    // ids: data?.results?.[0]?.hits?.map((d) => d.objectID) || [],
    // lastDocDate:
    //   data?.results?.[0]?.hits
    //     ?.map((d) => d.modified_date)
    //     .sort((a, b) => a - b)[0] || new Date("2022-11-01")
  });
  const idsString = JSON.stringify(
    data?.results?.[0]?.hits?.map((d) => d.objectID) || [],
  );

  useEffect(() => {
    const ids = JSON.parse(idsString);
    fdocs?.filter((d) => ids.includes(d.objectID)).length &&
      populateByArea({
        hits: fdocs?.filter((d) => ids.includes(d.objectID)),
        area,
        indexName: requests[0]?.indexName,
      }).then((p) => {
        const fdocsByIds = p?.reduce((p, a) => ({ ...p, [a.objectID]: a }), {});
        _.set(
          data,
          ["results", 0, "hits"],
          data?.results?.[0]?.hits?.map((d) => fdocsByIds[d.objectID] || d),
        );
        setResponse({ ...data });
      });
  }, [fdocs, idsString, area, data]);

  return isLoading ? {} : response || data;
}

const searchClient = (area) => customSearchClient(area);

let clientsAlgolia = {};
// eslint-disable-next-line no-return-assign
const searchClientLegacy = (indexName) => {
  const app =
    algoliaconfig?.find((d) => d.indexes.includes(indexName)) ||
    _.last(algoliaconfig);
  return (
    clientsAlgolia[indexName] ||
    (clientsAlgolia[indexName] = algoliasearch(app.id, app.privatekey, {
      responsesCache: createNullCache(),
      requestsCache: createNullCache(),
    }))
  );
};

export {
  searchClient,
  searchClientLegacy,
  algoliaIndex,
  algoliaIndexBrowser,
  useSearch,
};
