import { algoliaIndexBrowser } from "./index";
import { db } from "../firebase";
import { useEffect, useState } from "react";

var data = {};

const marketConfigPromise = new Promise((resolve) => {
  db.collection("marketconfig")
    .doc("v")
    .get()
    .then((doc) => {
      let fireData = doc.data();
      data.priceList = fireData.priceList;
      data.brands = fireData.brands;
      data.specs = fireData.specs;
      data.shippings = fireData.shippings;
      data.paymentOptions = fireData.paymentOptions;
      resolve();
    });
});

export function useDocs({ index = "", ids = [] }) {
  const [docs, setDocs] = useState({});
  const idsString = JSON.stringify(ids.sort());
  useEffect(() => {
    let active = true;
    index &&
      idsString &&
      getObjects(index, JSON.parse(idsString)).then(
        (r) => active && setDocs(r),
      );
    return () => (active = false);
  }, [index, idsString]);

  return docs;
}

let running = false;

async function waitFor(someFunction) {
  return new Promise((r) => {
    let counter = 0;
    function w() {
      if (counter++ > 10) r();
      else if (someFunction()) r();
      else setTimeout(w, 250);
    }
    w();
  });
}

const getObjects = async (index, o) => {
  await new Promise((r) => {
    const check = () => (!running ? r() : setTimeout(check, 30));
    setTimeout(check, Math.floor(Math.random() * 50));
  });
  if (
    ["priceList", "brands", "specs", "shippings", "paymentOptions"].includes(
      index,
    )
  )
    return marketConfigPromise.then(() => data[index]);
  running = true;
  if (!data[index]) data[index] = {};
  o = [...new Set(o)];
  let op = [];
  let keys = Object.keys(data[index]);
  await waitFor(
    () =>
      Object.entries(data[index]).filter(([, d]) => d?.updating).length === 0,
  );

  for (let i in o) {
    if (!keys.includes(o[i])) {
      // data[index][o[i]] = {updating: true};
      op.push(o[i]);
    }
  }

  if (!op.length) {
    running = false;
    return JSON.parse(JSON.stringify(data[index]));
  }
  return stockFetch({ indexName: index, ids: op }).then((a) => {
    data[index] = { ...data[index], ...a };
    // console.log(a);
    // let r = a.results;
    // for (let i of r) if (i) data[index][i.objectID] = i;
    running = false;
    return JSON.parse(JSON.stringify(data[index]));
  });
};

function stockFetch({ indexName, ids }) {
  const myHeaders = new Headers();
  // const BASE_URL = 'https://stock-p-c059a3e3d873.herokuapp.com/getObjects'
  const FUNCTIONS_URL =
    "https://us-central1-vivet-web.cloudfunctions.net" + //process.env.FUNCTIONS_URL
    "/getObjects";

  myHeaders.append("Content-Type", "application/json");
  return fetch(FUNCTIONS_URL, {
    method: "POST",
    headers: myHeaders,
    body: JSON.stringify({ indexName, ids }),
    redirect: "follow",
  }).then((response) => response.json());
}

export const AlgBrowser = (index, resolve) => {
  if (!data[index]) data[index] = {};

  algoliaIndexBrowser(index)
    .browseObjects({
      query: "", // Empty query will match all records
      facetFilters: ["seller:v"],
      batch: (batch) => {
        // hits = hits.concat(batch);
        for (const i in batch) {
          data[index][batch[i].objectID] = batch[i];
        }
      },
    })
    .then(() => resolve(data[index]));
};

export const setObjects = (index, objects) => {
  if (!data[index]) data[index] = {};
  for (let i in objects) data[index][i] = objects[i];
};

export var gdata = data;
export default getObjects;
