import { Button } from "@mui/material";
import MDEditor from "@uiw/react-md-editor";
import { useState } from "react";
import { useUpdateField } from "../EditableLabel/useUpdateField";
import CreateIcon from "@material-ui/icons/Create";
import { useCallback } from "react";

export const MarkdownEditor = ({
  defaultData,
  fieldToUpdate,
  idToUpdate,
  indexName,
}) => {
  const [value, setValue] = useState(defaultData ?? "");

  const updateValue = useCallback(() => {
    setValue(defaultData || "");
  }, [defaultData]);

  const { inputOpen, toggleInput, updateField } = useUpdateField({
    indexName: indexName,
    id: idToUpdate,
  });

  return (
    <>
      {inputOpen ? (
        <>
          <div>
            <MDEditor preview="edit" value={value} onChange={setValue} />
          </div>
          <Button
            m={2}
            onClick={() => {
              updateField({ key: fieldToUpdate, value: value });
              toggleInput();
            }}
            variant="outlined"
            color="primary"
          >
            Guardar cambios
          </Button>
          <MDEditor.Markdown
            source={value}
            style={{ whiteSpace: "pre-wrap" }}
          />
        </>
      ) : (
        <>
          <CreateIcon
            onClick={() => {
              toggleInput();
              updateValue();
            }}
          />
          <MDEditor.Markdown
            source={defaultData}
            style={{ whiteSpace: "pre-wrap" }}
          />
        </>
      )}
    </>
  );
};
