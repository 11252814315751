/* eslint-disable react/prop-types */

import {
  Box,
  Button,
  FormControl,
  Input,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";
import React, { useState } from "react";
import { updateDoc } from "../../../firebase/database";
import { GenericModal } from "../utils/GenericModal";
import { useModal } from "../utils/useModal";

export function AddNewExtraItem({ purchaseOrder }) {
  const [addExtraItem, setNewExtraItem] = useState({ iva: 0.21 });
  const [modalIsVisible, toggleVisibility] = useModal();
  const objectID = purchaseOrder?.objectID;

  const form = (
    <>
      <FormControl fullWidth variant="standard">
        <InputLabel>Nombre</InputLabel>
        <Input
          name="other"
          onChange={(e) =>
            setNewExtraItem({
              ...addExtraItem,
              [e.target.name]: e.target.value,
            })
          }
        />
      </FormControl>
      <FormControl sx={{ marginY: 4 }} fullWidth variant="standard">
        <InputLabel>Precio</InputLabel>
        <Input
          name="purchasePrice"
          onChange={(e) =>
            setNewExtraItem({
              ...addExtraItem,
              [e.target.name]: Number(e.target.value),
            })
          }
          type="number"
        />
      </FormControl>
      <FormControl fullWidth>
        <InputLabel id="iva">Iva</InputLabel>
        <Select
          defaultValue={0.21}
          name="iva"
          value={addExtraItem.iva}
          label="Iva"
          onChange={(e) =>
            setNewExtraItem({
              ...addExtraItem,
              [e.target.name]: Number(e.target.value),
            })
          }
        >
          <MenuItem value={0.21}>21%</MenuItem>
          <MenuItem value={0.105}>10,5%</MenuItem>
          <MenuItem value={0.025}>2,5%</MenuItem>
          <MenuItem value={0}>0%</MenuItem>
        </Select>
      </FormControl>
      <FormControl fullWidth variant="standard">
        <InputLabel>Cantidad</InputLabel>
        <Input
          name="q"
          onChange={(e) =>
            setNewExtraItem({
              ...addExtraItem,
              [e.target.name]: Number(e.target.value),
            })
          }
          type="number"
        />
      </FormControl>
    </>
  );

  const addItem = () => {
    const itemFormatted = {
      ...addExtraItem,
      other: addExtraItem.other || "",
      active: true,
      id: purchaseOrder?.otherItems?.length
        ? purchaseOrder?.otherItems?.length
        : 0,
    };

    updateDoc({
      indexName: "purchaseOrders",
      id: objectID,
      hit: {
        otherItems: purchaseOrder?.otherItems?.length
          ? [...(purchaseOrder?.otherItems || []), itemFormatted]
          : [itemFormatted],
      },
    });
    setNewExtraItem({});
    toggleVisibility();
  };

  const modalContent = (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      {form}
      <Button
        disabled={
          !addExtraItem.purchasePrice || !addExtraItem.q || !addExtraItem.other
        }
        onClick={addItem}
        sx={{ marginTop: 2 }}
        variant="outlined"
        color="primary"
      >
        Agregar item
      </Button>
    </Box>
  );
  return (
    <>
      <Button
        onClick={() => {
          toggleVisibility();
        }}
        color="primary"
        variant="contained"
        sx={{ margin: 2 }}
      >
        Agregar item
      </Button>
      <GenericModal open={modalIsVisible} handleClose={toggleVisibility}>
        {modalContent}
      </GenericModal>
    </>
  );
}
