import { useMongoAggregate } from "../../../utils/mongoAggregate/useMongoAggregate";

export default function useProducts(productsIDs) {
  const [data, loading, error] = useMongoAggregate({
    enabled: !!productsIDs?.length,
    index: "products",
    aggregate: [
      {
        $match: {
          ...(productsIDs?.length
            ? { "data.objectID": { $in: productsIDs } }
            : {}),
        },
      },
      {
        $project: {
          "data.objectID": 1,
          "data.brandName": 1,
          "data.name": 1,
          "data.thumbnail": 1,
          "data.taste": 1,
          "data.quantity": 1,
          "data.unit": 1,
        },
      },
      {
        $facet: {
          products: [
            {
              $project: {
                _id: 0,
              },
            },
          ],
        },
      },
    ],
  });
  return [data, loading, error];
}
