import React, { useEffect, useRef, useState } from "react";

export default function Observer({
  callback,
  options = { root: null, rootMargin: "0px", threshold: 0.4 },
  children,
}) {
  const [isVisible, setVisible] = useState(false);
  const containerRef = useRef(null);
  const callbackFn = (entries) => {
    const [entry] = entries;
    setVisible(entry.isIntersecting);
  };
  useEffect(() => {
    const observer = new IntersectionObserver(callbackFn, options);
    if (containerRef.current) observer.observe(containerRef.current);
    return () => {
      if (containerRef.current) observer.unobserve(containerRef.current);
    };
  }, [containerRef, options]);
  useEffect(() => {
    if (isVisible && callback) return callback();
  }, [isVisible]);

  return <div ref={containerRef}>{children}</div>;
}
