import { useMongoAggregate } from "../../../utils/mongoAggregate/useMongoAggregate";
import queryString from "qs";
import moment from "moment";
import { useHistory } from "react-router-dom";
import { useEffect, useState } from "react";
import _ from "lodash";

const date_field = {
  transactions: "date",
  invoices: "date",
  checks: "dates.issue",
};

const pipeline = ({ index, accumulated, date_from, date_to }) => {
  const date_filter = _.omitBy(
    {
      $lt:
        date_to && moment(date_to)?.clone().add(1, "day").format("YYYY-MM-DD"),
      $gte: date_from && !accumulated && moment(date_from).format("YYYY-MM-DD"),
    },
    (v) => !v,
  );

  return [
    ...(_.isEmpty(date_filter)
      ? []
      : [
          {
            $match: {
              ["data." + date_field[index]]: date_filter,
            },
          },
        ]),
    {
      $group: {
        _id: "",
        debit: {
          $sum: {
            $cond: [
              accumulated
                ? {
                    $and: [
                      {
                        $gt: ["$data.amount", 0],
                      },
                      {
                        $gte: [
                          "$data." + date_field[index],
                          moment(date_from).format("YYYY-MM-DD"),
                        ],
                      },
                    ],
                  }
                : {
                    $gt: ["$data.amount", 0],
                  },
              "$data.amount",
              0,
            ],
          },
        },
        credit: {
          $sum: {
            $cond: [
              accumulated
                ? {
                    $and: [
                      {
                        $lt: ["$data.amount", 0],
                      },
                      {
                        $gte: [
                          "$data." + date_field[index],
                          moment(date_from).format("YYYY-MM-DD"),
                        ],
                      },
                    ],
                  }
                : {
                    $lt: ["$data.amount", 0],
                  },
              {
                $abs: "$data.amount",
              },
              0,
            ],
          },
        },
        accumulated: { $sum: "$data.amount" },
      },
    },
  ];
};

export default function useEntitiesBalance({
  ids = ["cuit-30707240063"],
  accumulated = true,
  enable = true,
  limit,
} = {}) {
  const history = useHistory();
  const [data, setData] = useState();
  const [skip, setSkip] = useState(0);
  const data_ids = (data || []).map((d) => d.id);

  const parsedHash = queryString.parse(
    history.location.search?.replace("?", ""),
  );

  const date_from = parsedHash.from,
    date_to = parsedHash.to;

  useEffect(() => {
    setSkip(0);
    setData((d) => (d ? false : d));
  }, [accumulated, date_from]);

  const [response, isLoading, , { isRefetching }] = useMongoAggregate({
    refetchOnWindowFocus: false,
    // keepPreviousData: false,
    enabled:
      enable &&
      !!parsedHash.from &&
      (data_ids.length < limit ||
        !!ids.filter((id) => !data_ids.includes(id)).length),
    index: "entities",
    aggregate: [
      ...(ids?.length
        ? [
            {
              $match: {
                "data.objectID": {
                  $in: ids.filter((id) => !data_ids.includes(id)).slice(0, 10),
                },
              },
            },
          ]
        : [
            {
              $match: {
                "data.objectID": {
                  $nin: (data || []).map((d) => d.id),
                },
              },
            },
          ]),
      // ...(skip
      //   ? [
      //       {
      //         $skip: skip,
      //       },
      //     ]
      //   : []),
      {
        $limit: 50,
      },
      {
        $lookup: {
          from: "ptransactions",
          localField: "data.objectID",
          foreignField: "data.entity",
          as: "transactions",
          pipeline: pipeline({
            index: "transactions",
            accumulated,
            date_from: parsedHash.from,
            date_to: parsedHash.to,
          }),
        },
      },
      {
        $lookup: {
          from: "pchecks",
          localField: "data.objectID",
          foreignField: "data.entity",
          as: "checks",
          pipeline: pipeline({
            index: "checks",
            accumulated,
            date_from,
            date_to,
          }),
        },
      },
      {
        $lookup: {
          from: "pinvoices",
          localField: "data.objectID",
          foreignField: "data.entity",
          as: "invoices",
          pipeline: pipeline({
            index: "invoices",
            accumulated,
            date_from: parsedHash.from,
            date_to: parsedHash.to,
          }),
        },
      },
      {
        $project: {
          name: "$data.name",
          cuit: "$data.cuit",
          id: "$data.objectID",
          transactions: [
            {
              $first: "$transactions.accumulated",
            },
            {
              $first: "$transactions.debit",
            },
            {
              $first: "$transactions.credit",
            },
          ],
          invoices: [
            {
              $first: "$invoices.accumulated",
            },
            {
              $first: "$invoices.debit",
            },
            {
              $first: "$invoices.credit",
            },
          ],
          checks: [
            {
              $first: "$checks.accumulated",
            },
            {
              $first: "$checks.debit",
            },
            {
              $first: "$checks.credit",
            },
          ],
        },
      },
    ],
  });

  useEffect(() => {
    if (response) {
      setData((data) => {
        const d = _.uniqBy([...response, ...(data || [])], "id");
        // d?.length &&
        //   _.isInteger(d?.length / 10) &&
        //   d?.length < limit &&
        //   setSkip((s) => s + 10);
        return d;
      });
    }
  }, [response]);

  return [
    data,
    isRefetching ||
      isLoading ||
      (skip && _.isInteger(data?.length / 10 && data?.length >= limit)),
  ];
}
