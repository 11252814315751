
/* eslint-disable react/prop-types */
import React from 'react';
import {
  Box,
  Grid,
  Typography,
} from '@mui/material';
import { Link } from 'react-router-dom';

export function ItemHeader({
  children, headerLabel, label, link,
}) {
  return (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <>
      {(children || headerLabel || label)
        ? (
          <Grid
            sx={{
              borderRight: '1px solid rgb(0 0 0 / 10%)', padding: '0 .6rem', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', position: 'relative',
            }}
            item
          >
            <Box component={link ? Link : 'div'} to={link || ''} style={{ textDecoration: 'unset', color: 'black' }}>
              <Typography sx={{ fontWeight: 'bold' }}>{headerLabel}</Typography>
              {children && children}
              <Typography sx={{ fontSize: '.9rem' }}>{label}</Typography>
            </Box>
          </Grid>
        )
        : null}
    </>
  );
}
