/* eslint-disable react/jsx-key */
import { Link } from "react-router-dom";
import React from "react";

const customerColumns = [
  {
    field: "storeName",
    headerName: "Tienda",
    width: 120,
    type: "actions",
    getActions: (params) => [
      <Link
        style={{ color: "black", textDecoration: "none" }}
        to={`/app/stores/${params.row.objectID}`}
      >
        {params.row.storeName}
      </Link>,
    ],
  },
  { field: "cuit", headerName: "Cuit", width: 130 },
  { field: "totalprice", headerName: "Total de compras", width: 150 },
  { field: "total", headerName: "Cantidad total", width: 100 },
];

export default customerColumns;
