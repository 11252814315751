/* eslint-disable react/prop-types */
import React from "react";
import ProductDescription from "./ProductDescription";

export default function ProductPage({ objectID }) {
  return (
    <>
      <ProductDescription objectID={objectID} />
      {/* <ProductGraphic objectID={objectID} /> */}
      {/* <ProductStock objectID={objectID} history={history} /> */}
    </>
  );
}
