import { Menu, MenuItem } from "@mui/material";
import React, { useState } from "react";
import { useOrder } from "../../ordenesKoco";
import { printPDF } from "../../../../remitosPDF";
import { Button } from "@material-ui/core";

export default function Remito() {
  const [anchorEl, setAnchorEl] = useState(null);
  const order = useOrder((s) => s.order);

  return (
    <div>
      <Button
        id="basic-button"
        aria-controls={anchorEl ? "basic-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={anchorEl ? "true" : undefined}
        variant="contained"
        color="primary"
        onClick={(e) => setAnchorEl(e.currentTarget)}
      >
        Imprimir
      </Button>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={() => setAnchorEl(null)}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        <MenuItem
          onClick={() => printPDF({ orderDoc: order, printAll: false })}
        >
          Imprimir por conservacion
        </MenuItem>
        <MenuItem onClick={() => printPDF({ orderDoc: order, printAll: true })}>
          imprimir todo en uno
        </MenuItem>
      </Menu>
    </div>
  );
}
