/* eslint-disable no-unsafe-optional-chaining */
/* eslint-disable react/prop-types */

import { Box, CircularProgress, Grid, Typography } from "@mui/material";
import { DataGridPremium, GridToolbar } from "@mui/x-data-grid-premium";
import React, { useState } from "react";
import moment from "moment";
import { ChartTime } from "./BrandPage";
import useStyles from "./styles";
import { numberWithCommas } from "../../../components/Utils/NumberWithCommas";
import useUsersByBrands from "./useUsersByBrands";
import customerColumns from "./customerColumns";
import { MuiCalendar } from "../../../utils/MuiCalendar";

export default function SalesByCustomers({ objectID, product, spec }) {
  const classes = useStyles();
  const [selectionModel, setSelectionModel] = useState([]);
  const [date, setDate] = useState([moment().subtract(3, "days"), moment()]);

  const from = moment(date?.[0]).unix();
  const to = moment(date?.[1]).unix();

  const [rows, loading] = useUsersByBrands({
    brand: objectID,
    from,
    to,
    product,
    spec,
  });
  const rowsFilter = rows?.[0]?.old
    // eslint-disable-next-line no-underscore-dangle
    ?.filter((x) => x._id)
    ?.flatMap((x) => [
      {
        ...x.store?.[0],
        totalprice: x?.totalprice || 0,
        total: x?.total || 0,
        lastEdit: x?.lastEdit || 0,
      },
    ])
    ?.flatMap((v) => ({
      ...v?.data,
      total: v?.total,
      totalprice: `$ ${numberWithCommas(v?.totalprice, 2)}`,
      lastEdit: v?.lastEdit,
      totalPriceNumeric: v?.totalprice,
    }));

  const stepsForChart = rows?.[0]?.dates
    // eslint-disable-next-line no-underscore-dangle
    ?.map((x) => ({ ...x, name: x?._id, total: x?.totalPrice }));

  return (
    <Box className={classes.containerOrders}>
      <Box>
        <Grid container>
          <Grid item xs={12}>
            <MuiCalendar date={date} action={setDate} />
          </Grid>

          <Grid item xs={8}>
            <Box className={classes.containerTypographyOrders}>
              <Typography className={classes.typographyOrders}>
                Unidades Vendidas:
              </Typography>{" "}
              {numberWithCommas(
                rowsFilter?.reduce((p, a) => p + a?.total, 0),
                0
              )}
            </Box>
            <Box className={classes.containerTypographyOrders}>
              <Typography className={classes.typographyOrders}>
                Total: $
              </Typography>{" "}
              {numberWithCommas(
                rowsFilter?.reduce((p, a) => p + a?.totalPriceNumeric, 0),
                0
              )}
            </Box>
          </Grid>
        </Grid>
      </Box>

      <Box className={classes.containerGraphic}>
        <Typography>
          <h4 className={classes.typographyGraphic}>Ventas por tiendas</h4>
        </Typography>
        <Grid>
          {rowsFilter?.length && (
            <ChartTime
              data={stepsForChart
                // eslint-disable-next-line max-len
                .sort(
                  (a, b) =>
                    Number(a.name.slice(0, 2)) - Number(b.name.slice(0, 2))
                )}
            />
          )}
        </Grid>

        <div>
          <div style={{ height: 400, width: "100%", flex: 1 }}>
            {!rows?.[0]?.old ? (
              <CircularProgress />
            ) : (
              <DataGridPremium
                loading={loading}
                getRowId={(row) => row.objectID}
                rows={rowsFilter}
                columns={customerColumns}
                pageSize={5}
                rowsPerPageOptions={[200, 100, 50, 20, 10]}
                components={{ Toolbar: GridToolbar }}
                onSelectionModelChange={(newSelectionModel) => {
                  setSelectionModel(newSelectionModel);
                }}
                selectionModel={selectionModel}
              />
            )}
          </div>
        </div>
      </Box>
    </Box>
  );
}
