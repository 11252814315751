const LANGUAGE_FORMATTED = {
  noRowsLabel: "No hay filas.",
  noResultsOverlayLabel: "No hubo resultados.",
  errorOverlayDefaultLabel: "Ocurrió un error.",

  toolbarDensity: "Densidad",
  toolbarDensityLabel: "Densidad",
  toolbarDensityCompact: "Compacto",
  toolbarDensityStandard: "Standard",
  toolbarDensityComfortable: "Comfortable",

  toolbarColumns: "Columnas",
  toolbarColumnsLabel: "seleccionar columnas",

  toolbarFilters: "Filtros",
  toolbarFiltersLabel: "Mostrar filtros",
  toolbarFiltersTooltipHide: "Esconder filtros",
  toolbarFiltersTooltipShow: "Mostrar filtros",

  toolbarQuickFilterPlaceholder: "Buscar...",
  toolbarQuickFilterLabel: "Buscar",
  toolbarQuickFilterDeleteIconLabel: "Limpiar",

  toolbarExport: "Exportar",
  toolbarExportLabel: "Exportar",
  toolbarExportCSV: "Descargar CSV",
  toolbarExportPrint: "Imprimir",
  toolbarExportExcel: "Descargar Excel",

  columnsPanelTextFieldLabel: "Encontrar columna",
  columnsPanelTextFieldPlaceholder: "Titulo de columna",
  columnsPanelDragIconLabel: "Reordenar columna",
  columnsPanelShowAllButton: "Mostrar todas",
  columnsPanelHideAllButton: "Esconder todas",

  filterPanelAddFilter: "Agregar filtro",
  filterPanelDeleteIconLabel: "Eliminar",
  filterPanelLinkOperator: "Operador lógico",
  filterPanelOperators: "Operador",
  filterPanelOperatorAnd: "y",
  filterPanelOperatorOr: "ó",
  filterPanelColumns: "Columnas",
  filterPanelInputLabel: "Valor",
  filterPanelInputPlaceholder: "Filtrar valor",

  filterOperatorContains: "contiene",
  filterOperatorEquals: "igual",
  filterOperatorStartsWith: "comienza con",
  filterOperatorEndsWith: "termina con",
  filterOperatorIs: "es",
  filterOperatorNot: "no es",
  filterOperatorAfter: "está despues",
  filterOperatorOnOrAfter: "está en o despues",
  filterOperatorBefore: "está antes",
  filterOperatorOnOrBefore: "está en ó antes",
  filterOperatorIsEmpty: "está vacio",
  filterOperatorIsNotEmpty: "no está vacio",
  filterOperatorIsAnyOf: "es alguno de",

  columnMenuLabel: "Menú",
  columnMenuShowColumns: "Mostrar columnas",
  columnMenuFilter: "Filtrar",
  columnMenuHideColumn: "Esconder",
  columnMenuUnsort: "Desordenar",
  columnMenuSortAsc: "Ordenar ascendente",
  columnMenuSortDesc: "Ordenar descendente",

  pinToLeft: "Anclar a la izquierda",
  pinToRight: "Anclar a la derecha",
  unpin: "Desanclar",
};

export default LANGUAGE_FORMATTED;
