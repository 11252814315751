import {
  Avatar,
  Box,
  CircularProgress,
  List,
  MenuItem,
  Modal,
  Stack,
  Typography,
} from "@mui/material";
import { useMongoAggregate } from "../../../utils/mongoAggregate/useMongoAggregate";
import moment from "moment";
import _ from "lodash";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { Comment } from "../../providers/sections/ShipmentManagment/[order]";
import { convertToCelcius } from "../../../utils/convertToCelcius";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  p: 4,
  maxHeight: 500,
  overflowY: "auto",
};

export default function DetailReceived({
  data,
  productsInfo,
  inventoryAdjustment,
  isOpen,
  toggleModal,
}) {
  const [receivedBy, isLoading] = useMongoAggregate({
    index: "users",
    aggregate: [
      {
        $match: { "data.objectID": data?.by },
      },
      { $project: { name: "$data.name" } },
    ],
  });

  const inventoryAdjustmentItems = inventoryAdjustment?.[0]?.items;
  const controlReason = inventoryAdjustment && inventoryAdjustment[0].data?.controlReason
  const allValuesVoid = controlReason && controlReason?.comment !== "" || controlReason?.temperature !== "" || controlReason?.reason !== ""
  const formatDate = (date) => {
    if (!moment(date).isValid()) return "";

    return `${moment(date * 1000).format("DD/MM HH:mm")}  ${
      moment().isSame(date * 1000, "year")
        ? ""
        : moment(date * 1000).format("/YYYY")
    }`;
  };

  const omittedFields = ["inventoryAdjustment", "s"];
  const labels = {
    by: "Quien recibió",
    t: "Fecha y hora",
  };

  return (
    <>
      <Modal open={isOpen} onClose={toggleModal}>
        <Box sx={style}>
          <List>
            {Object.entries(_.omit(data, omittedFields) || {})?.map(
              ([key, value]) => {
                const infoShowed = {
                  by: receivedBy?.[0]?.name,
                  t: formatDate(value),
                };

                return (
                  <MenuItem sx={{ paddingX: 0 }} key={key}>
                    <Typography sx={{ fontWeight: "bold" }}>
                      {labels[key]}:
                    </Typography>
                    {isLoading ? (
                      <CircularProgress />
                    ) : (
                      <Typography sx={{ paddingLeft: 2 }}>
                        {" "}
                        {infoShowed[key]}{" "}
                      </Typography>
                    )}
                  </MenuItem>
                );
              },
            )}
          </List>
          <List
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
            }}
          >
            <Typography sx={{ fontWeight: "bold" }}>Items</Typography>
            {Object.entries(inventoryAdjustmentItems || {})?.map(
              ([productID, inventory]) => {
                const product = productsInfo?.find(
                  (item) => item?.objectID === productID,
                );

                const bins = _.chain(inventory)
                  .values()
                  .reduce()
                  .entries()
                  .value();

                return (
                  <MenuItem
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      padding: 0,
                    }}
                    key={productID}
                  >
                    <Box sx={{ display: "flex", gap: 1, width: "100%" }}>
                      <Avatar
                        src={product?.image}
                        sx={{ width: 35, height: 35 }}
                      />
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                        }}
                      >
                        <Typography fontSize={14}>{product?.name}</Typography>
                        <Typography fontSize={12}>{product?.taste}</Typography>
                      </Box>
                    </Box>
                    {/*  bins */}
                    <List
                      sx={{
                        width: "100%",
                        paddingLeft: 4,
                        display: "flex",
                        flexDirection: "column",
                      }}
                    >
                      {bins?.map(([binID, q]) => (
                        <MenuItem sx={{ alignSelf: "flex-start" }} key={binID}>
                          <Typography sx={{ fontSize: 14 }}> {q} </Typography>
                          <ArrowForwardIcon
                            style={{ fontSize: 14, color: "green" }}
                          />
                          <Typography sx={{ fontSize: 14 }}>{binID}</Typography>
                        </MenuItem>
                      ))}
                    </List>

                  </MenuItem>
                );
              },
            )}
          </List>
          {allValuesVoid && controlReason && <Stack>
            <Typography sx={{ fontWeight: "bold", marginBottom: 2 }}>Comentarios</Typography>
            <Comment 
              comment={controlReason?.comment} 
              reason={controlReason?.reason} 
              temperature={controlReason?.temperature !== "" && convertToCelcius(controlReason?.temperature)}></Comment>
          </Stack>}
        </Box>
      </Modal>
    </>
  );
}
