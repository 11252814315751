/* eslint-disable no-nested-ternary */
/* eslint-disable react/prop-types */

import React, { useEffect, useState } from "react";
import { useDocumentData } from "../firebase";
import { useUserData, useUserDataSet } from "./UserContext";
import LoadingPage from "../pages/loading";
import _ from "lodash";
import AccessDeniedPage from "../components/AccessDeniedPage";

const RoleDataSetContext = React.createContext();
const RoleDataContext = React.createContext();

function RoleProvider({ children }) {
  const user = useUserData();
  const userDataSet = useUserDataSet();
  const [roleDataOffline] = useState(
    localStorage?.getItem("roleData") || false,
  );

  const [roleDataOnline, loading] = useDocumentData(
    "roles",
    (user.currentRole &&
      user.roles?.includes(user.currentRole) &&
      user.currentRole) ||
      user.roles?.[0] ||
      "",
  );

  const roleData = loading ? JSON.parse(roleDataOffline) : roleDataOnline;

  useEffect(() => {
    if (roleDataOnline) {
      localStorage?.setItem("roleData", JSON.stringify(roleDataOnline));
    }
  }, [roleDataOnline]);

  // eslint-disable-next-line react/jsx-no-constructed-context-values
  const setRole = (role) => userDataSet({ currentRole: role });

  return ( [undefined].includes(user.isAnonymous) || user?.roles?.[0] ) && _.isEmpty(roleData) ? (
    <LoadingPage />
  ) : user?.roles?.[0] || !user.uid || user.isAnonymous ? (
    <RoleDataSetContext.Provider value={setRole}>
      <RoleDataContext.Provider value={roleData}>
        {children}
      </RoleDataContext.Provider>
    </RoleDataSetContext.Provider>
  ) : (
    <AccessDeniedPage error={"role asignado"} />
  );
}

function useRoleDataContext() {
  const context = React.useContext(RoleDataContext);
  if (context === undefined) {
    throw new Error("RoleStateContext must be used within a UserProvider");
  }
  return context;
}

function useRoleSetContext() {
  const context = React.useContext(RoleDataSetContext);
  if (context === undefined) {
    throw new Error("RoleDataSetContext must be used within a UserProvider");
  }
  return context;
}

export { RoleProvider, useRoleDataContext, useRoleSetContext };
