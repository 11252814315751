import { useMongoAggregate } from "../../utils/mongoAggregate/useMongoAggregate";
import moment from "moment";

export const useGetStoresExcel = () => {
  const [data, loading] = useMongoAggregate({
    index: "orders",
    aggregate: [
      {
        $match: {
          "data.timestamp_timestamp": {
            $gt: moment().add(-12, "months").startOf("day").unix(),
          },
          "data.store": { $exists: true },
        },
      },
      // {
      //     $limit:100
      // },
      {
        $group: {
          _id: "$data.store",
          count: { $sum: 1 },
          total: { $sum: "$data.total" },
        },
      },
      {
        $sort: { total: -1 },
      },
      {
        $lookup: {
          from: "pstores",
          localField: "_id",
          foreignField: "data.objectID",
          as: "store",
        },
      },
      {
        $project: {
          store: { $first: "$store.data" },
          total: 1,
          count: 1,
        },
      },
      {
        $facet: {
          hits: [],
          responsables: [
            {
              $project: {
                responsableSales: "$store.responsableSales",
                total: "$data.total",
              },
            },
            {
              $group: {
                _id: "$responsableSales",
                count: { $sum: 1 },
                total: { $sum: "$total" },
              },
            },
            {
              $lookup: {
                from: "pusers",
                localField: "_id",
                foreignField: "data.objectID",
                as: "user",
              },
            },
            {
              $project: {
                user: { $first: "$user.data.name" },
                count: 1,
                total: 1,
              },
            },
          ],
        },
      },
    ],
  });
  return [data, loading];
};

// "index": "orders",
// "aggregate": [
//     {
//         '$match': {
//             '$or': [
//                 {
//                     'data.payments.0.method': 'check'
//                 }, {
//                     'data.payments.1.method': 'check'
//                 }, {
//                     'data.payments.2.method': 'check'
//                 }
//             ]
//         }
//     }, {
//         '$project': {
//             'data.payments': 1,
//             'data.store': 1,
//             'data.user': 1,
//             'data.objectID': 1,
//             'data.data.address.formatted_address': 1,
//             'data.shippingDate': 1,
//             'data.total': 1,
//             'data.timestamp_timestamp': 1,
//         }
//     }, {
//         '$lookup': {
//             'from': 'pusers',
//             'localField': 'data.user',
//             'foreignField': 'data.objectID',
//             'pipeline': [
//                 {
//                     '$project': {
//                         'data.name': 1
//                     }
//                 }
//             ],
//             'as': 'user'
//         }
//     }, {
//         '$lookup': {
//             'from': 'pstores',
//             'localField': 'data.store',
//             'foreignField': 'data.objectID',
//             'pipeline': [
//                 {
//                     '$project': {
//                         'data.storeName': 1
//                     }
//                 }
//             ],
//             'as': 'store'
//         }
//     }, {
//         '$project': {
//             'usuario': {
//                 '$first': '$user.data.name'
//             },
//             'IdOrden': '$data.objectID',
//             'tienda': {
//                 '$first': '$store.data.storeName'
//             },
//             'pagos': '$data.payments',
//             'total': '$data.total',
//             'fechaDeCompra': '$data.timestamp_timestamp',
//             'fechaDeEnvio': '$data.shippingDate',
//             'direccion': '$data.data.address.formatted_address',
//             '_id': 0
//         }
//     }, {
//         '$sort': {
//             'fechaDeCompra': -1
//         }
//     }
// ]
