/* eslint-disable react/button-has-type */
/* eslint-disable react/prop-types */

import React, { useReducer } from "react";
import { useMongoAggregate } from "../../utils/mongoAggregate/useMongoAggregate";

const BanksDataContext = React.createContext();

function BanksProvider({ children }) {
  const [data, setData] = useReducer((p, a) => ({ ...p, ...a }), {});
  const [accounts] = useMongoAggregate({
    index: "accounts",
    aggregate: [
      {
        $project: {
          _id: 0,
          id: "$data.objectID",
          code: "$data.code",
          name: "$data.name",
          editable: "$data.editable",
        },
      },
    ],
    refetchOnWindowFocus: false,
  });

  return (
    <BanksDataContext.Provider value={{ data, setData, accounts }}>
      {children}
    </BanksDataContext.Provider>
  );
}

function useBanksDataContext() {
  const context = React.useContext(BanksDataContext);
  if (context === undefined) {
    throw new Error("BanksStateContext must be used within a UserProvider");
  }
  return context;
}

export { BanksProvider, useBanksDataContext };
