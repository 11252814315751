import { memo, Fragment } from "react";
import {
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import { useBanksDataContext } from "../../../BanksContext";
import { numberWithCommas } from "../../../../../components/Utils/NumberWithCommas";
import { check_states } from "../../Commons";
import _ from "lodash";

const DEFAULT_HEADERS = ["ID", "Descripción", "Débito", "Crédito"];

export const AccountDetailsView = memo(
  ({ data, columns, headers = DEFAULT_HEADERS }) => {
    const { accounts } = useBanksDataContext();

    return (
      <Grid
        container
        sx={{
          background: "#d7d7d7",
          border: "1px solid #cbc9c9",
          borderRadius: "8px",
          boxShadow: "2px 2px 6px 2px #cdcdcd",
        }}
      >
        <Grid
          item
          container
          xs={12}
          sx={{
            width: "100%",
            margin: "2px",
            padding: "5px 13px",
          }}
        >
          {(columns || [])?.map(({ field, headerName, options }, index) => {
            const isCurrency = options?.type === "currency";
            const isMultiple = field?.includes(".");

            const value = isMultiple
              ? _.get(data, field)
              : isCurrency
              ? `$${numberWithCommas(data?.[field], 2)}`
              : field === "state"
              ? check_states?.[data?.[field]]
              : data?.[field];

            return (
              <Fragment key={index}>
                {value && typeof value !== "object" ? (
                  <Grid item xs={6}>
                    {/* header */}
                    <p style={{ fontWeight: "bold" }}>{headerName || ""}</p>

                    <p>{value}</p>
                  </Grid>
                ) : null}
              </Fragment>
            );
          })}
        </Grid>
        <Table sx={{ width: "100%" }} aria-label="simple table">
          <TableHead>
            <TableRow>
              {(headers || [])?.map((header, index) => (
                <TableCell align="left" key={index}>
                  {header}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {(data?.accounts || []).map((account, index) => {
              const ID = account.k;
              const amount = account.v;
              const accountName = accounts?.find((acc) => acc.id === ID)?.name;

              return (
                <TableRow key={index}>
                  <TableCell align="left">{ID}</TableCell>
                  <TableCell align="left">{accountName}</TableCell>
                  <TableCell align="left">
                    ${amount > 0 ? numberWithCommas(amount, 2) : 0}
                  </TableCell>
                  <TableCell align="left">
                    ${amount < 0 ? numberWithCommas(amount * -1, 2) : 0}
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </Grid>
    );
  },
);

AccountDetailsView.displayName = "AccountDetailsView";
