/* eslint-disable */
import React, { useEffect, useMemo, useState } from "react";
// components
import PageTitle from "../../components/PageTitle";
// data
import { Redirect } from "react-router-dom";
import { useAreaDataContext } from "../../context/AreaContext";
import { useRoleDataContext } from "../../context/RoleContext";
import CustomFieldRender from "../../components/CustomField";
import InstantSearchTable from "../../components/InstantSearch";
import ButtonAddNew from "../app/cards/ButtonAddNew";
import { Box } from "@material-ui/core";
import { Stack } from "@mui/material";

export default function Tables({ index = "orders", style = {} }) {
  const areaData = useAreaDataContext();
  const indexData = areaData.panel[index];
  const table = useMemo(() => index && <IndexTable index={index} />, [index]);

  return (
    <div {...{ style }} style={{flexGrow:"1",flexDirection:"column"}}>
      <Box style={{ display: "flex", alignItems: "center"}} >
          <PageTitle title={indexData?.title || "Panel"} />
          {indexData && <ButtonAddNew index={index} />}
      </Box>
      {indexData && table}
    </div>
  );
}

export function IndexTable({ index = "", filters = "", hiddenFields = [] }) {
  const areaData = useAreaDataContext();
  let indexData = areaData.panel[index];
  const roleData = useRoleDataContext();

  let tableFilters = {};

  CustomFieldRender({}, indexData.filters || "")
    .split(" AND ")
    .forEach((f) => (tableFilters[f.split(":")[0]] = f.split(":")[1]));

  let columns;
  if (filters) {
    filters = CustomFieldRender({}, filters);
    columns = JSON.parse(JSON.stringify(indexData.fields));
    let f = filters.split(" AND ");
    f.forEach((f) => (tableFilters[f.split(":")[0]] = f.split(":")[1]));
    for (let i in f) {
      let c = columns.find((a) => a.field === f[i].split(":")[0]);
      if (c) {
        c.options || (c.options = {});
        c.options.display = false;
      }
    }
  }
  if (hiddenFields) {
    columns = columns || JSON.parse(JSON.stringify(indexData.fields));
    for (let i in hiddenFields) {
      let c = columns.find((a) => a.field === hiddenFields[i]);
      if (c) {
        c.options || (c.options = {});
        c.options.display = false;
      }
    }
  }

  const [loading, setLoading] = useState(true);
  useEffect(() => {
    let active = true;
    setLoading(true);
    setTimeout(() => active && setLoading(false), 100);
    return () => (active = false);
  }, [index, areaData && areaData.objectID, filters]);
  return (
    <>
      {!loading && (
        <InstantSearchTable
          index={indexData.collection || index}
          columns={columns || indexData.fields}
          addNew={roleData.new && roleData.new.includes(index)}
          filters={Object.keys(tableFilters)
            .filter((f) => f)
            .map((f) => f + ":" + tableFilters[f])
            .join(" AND ")}
          renderExpandableRow={(hit) => (
            <Redirect
              push
              to={{
                pathname:
                  "/app/" +
                  (indexData.collection || index) +
                  "/" +
                  hit.objectID,
                state: {
                  from: window.location.pathname,
                },
              }}
            />
          )}
        />
      )}
    </>
  );
}
