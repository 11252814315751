import React, { useEffect, useState, useMemo } from "react";

export function ExpandableList({ children, limit }) {
  const childrenLength = React.Children.count(children);
  const [internalLimit, setInternalLimit] = useState(limit);
  const [isExpanded, setIsExpanded] = useState(false);
  const exceedsLimit = useMemo(
    () => childrenLength > limit,
    [limit, childrenLength],
  );

  useEffect(() => {
    if (childrenLength > internalLimit) return setIsExpanded(false);
    setIsExpanded(true);
  }, [childrenLength, internalLimit]);

  const handleExpand = () => {
    setInternalLimit(childrenLength);
    return setIsExpanded(true);
  };
  const handleHide = () => {
    setInternalLimit(limit);
    return setIsExpanded(false);
  };
  return (
    <>
      {children?.slice(0, internalLimit)}

      {!isExpanded && exceedsLimit && (
        <span
          style={{ marginTop: 8, cursor: "pointer", color: "#3b82f6" }}
          onClick={handleExpand}
        >
          Ver más
        </span>
      )}

      {isExpanded && exceedsLimit && (
        <span
          style={{ marginTop: 8, cursor: "pointer", color: "#3b82f6" }}
          onClick={handleHide}
        >
          Ver menos
        </span>
      )}
    </>
  );
}
