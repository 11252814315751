export function getProductDiscount({
  product,
  priceLists,
  priceListID,
  brand,
  specs,
  q,
  extraDiscount = 0,
  customDiscounts,
}) {
  const priceList = priceLists?.[priceListID];
  let motherPriceListID = priceList?.mother;
  let packUnitPrice =
    q > 1 &&
    q >= product?.pack &&
    getPrice({ product, priceLists, priceListID, packUnitPrice: true });
  let price = packUnitPrice && getPrice({ product, priceLists, priceListID });
  const packDiscount =
    Math.round(
      (packUnitPrice && price > packUnitPrice
        ? (price - packUnitPrice) / price
        : 0) * 100,
    ) / 100;
  const discounts = {
    motherPercent: !product?.price?.[priceListID]
      ? 1 - (priceList?.motherPercent || 1)
      : 0,
    product: product?.discounts?.[priceListID] || 0,
    motherPriceListProduct: !product?.price?.[priceListID]
      ? product?.discounts?.[motherPriceListID] || 0
      : 0,
    brand: brand?.discounts?.[priceListID] || 0,
    motherPriceListBrand: !product?.price?.[priceListID]
      ? brand?.discounts?.[motherPriceListID] || 0
      : 0,
    ...(specs?.reduce?.(
      (p, s) => ({
        ...p,
        [`spec_${s?.objectID}`]: s?.discounts?.[priceListID] || 0,
      }),
      {},
    ) || {}),
    priceList: priceList?.discount || 0,
    packDiscount: {
      discount: packDiscount || 0,
      plus: priceList?.packDiscountPlus,
    },
    extraDiscount: extraDiscount,
    [`${customDiscounts?.name || "customDiscounts"}_Brand`]:
      customDiscounts?.brands?.[product?.brand] || 0,
    [`${customDiscounts?.name || "customDiscounts"}_Product`]:
      customDiscounts?.products?.[product?.objectID] || 0,
    ...(specs?.reduce?.(
      (p, s) => ({
        ...p,
        [`${customDiscounts?.name || "customDiscounts_Spec"}_${s?.objectID}`]:
          customDiscounts?.specs?.[s?.objectID] || 0,
      }),
      {},
    ) || {}),
    [`${customDiscounts?.name || "customDiscounts"}_All`]:
      customDiscounts?.all || 0,
  };
  const d = Object.entries(discounts)
    .filter((a) => a)
    .sort(
      ([, a], [, b]) =>
        (a.plusOthers === false) - (b.plusOthers === false) ||
        !!b?.plus - !!a?.plus ||
        (a?.discount || a || 0) - (b?.discount || b || 0),
    )
    .reduce(
      (p, [type, discount]) => {
        const plusSum =
          discount?.plusOthers !== false
            ? Object.entries(p.plusDiscounts).reduce((p, [, a]) => p + a, 0)
            : 0;
        return discount?.plus && p.plus
          ? {
              ...p,
              discount: (discount?.discount || 0) + plusSum,
              name: type,
              plusDiscounts: {
                ...p.plusDiscounts,
                [type]: discount?.discount || 0,
              },
            }
          : (discount?.discount || discount) + plusSum > p.discount
          ? {
              ...p,
              discount: (discount?.discount || discount) + plusSum,
              name: type,
              plus: discount?.plusOthers !== false,
              plusDiscounts:
                discount?.plusOthers !== false ? p.plusDiscounts : {},
            }
          : p;
      },
      { discount: 0, plusDiscounts: {}, name: "", plus: true },
    );
  return d;
}

export function getPrice({
  product,
  priceLists,
  priceListID,
  decimals,
  iva = false,
  packUnitPrice = false,
}) {
  let priceList = priceLists[priceListID];
  const custom_iva = product.iva !== undefined ? 1 + product.iva : 1.21;
  let p = product["price"]?.[priceListID];
  let pp = product["packUnitPrice"]?.[priceListID];
  let packDiscount =
    (product.pack &&
      packUnitPrice &&
      ((pp && 1 - pp / p) || priceList?.packDiscount)) ||
    0;
  if (!p && priceListID !== "g")
    return round2decimals(
      getPrice({
        product,
        priceLists,
        priceListID: priceList.mother || "g",
        decimals,
        iva,
      }) *
        (1 - packDiscount),
    );
  if (!p && priceListID === "g") return 0;
  // if ( priceList.mother === priceListID && priceList.motherPercent ) p *= priceList.motherPercent;
  p *= iva ? custom_iva : 1;
  p *= 1 - packDiscount;
  return Number(p.toFixed(decimals !== undefined ? decimals : 2));
}

function round2decimals(num) {
  return Math.round((num + Number.EPSILON) * 100) / 100;
}
