import { Box } from "@material-ui/core";
import { MainLayout } from "../../layouts/MainLayout";
import { ListCard } from "../../components/shipment-managment/[id]/card";
import { Stack, Typography } from "@mui/material";
import { useSearchParams } from "../../hooks/useSearchParams";
import { useMongoAggregate } from "../../../../utils/mongoAggregate/useMongoAggregate";
import { DataGrid } from "@mui/x-data-grid";
import { useProductsObj } from "../../hooks/useProductsObj";
import moment from "moment";
import { TableProductCard } from "../../components/shipment-managment/[id]/table-product-card";
import { DiscountMemoized, fixDiscount, getDiscountAsPercentage } from "./Campaigns";

export default function Campaign(){
    const { getParam } = useSearchParams();
    const id = getParam("id");
    const [productsObj, productsDataLoading] = useProductsObj();
    const createRow = (id, product,discount) => ({
      id,
      product,
      discount
    })
    const columns = [
      { field: "id", headerName: "", width: 1, renderCell: () => null },
      {
        field: "product",
        headerName: "",
        width: 600,
        autoHeight: true,
  
        renderCell: ({ row: { product } }) => (
          <TableProductCard
            imageSrc={product.imageSrc}
            id={product.id}
            tags={product.tags}
            measurement={product.measurement}
            measurementUnit={product.measurementUnit}
            name={product.name}
            brandName={product.brandName}
            code={product.code}
          />
        ),
      },
      {
        field: "discount",
        headerName: "Descuento",
        renderCell: ({row: {discount}}) => <DiscountMemoized discount={discount}/>
      },
    ];
    //Campaign Data
    const [cData, cLoading] = useMongoAggregate({
        index: "campaign",
        refetchOnWindowFocus: false,
        aggregate: [
          {$match:{
              "data.objectID": id,
            //   "data.seller":seller ,
            
          }},
          {
            $project: {
              "data.image":1,
              "data.objectID": 1,
              "data.discount": 1,
              "data.name": 1,
              "data.items": 1,
              "data.brand": 1,
              "data.end_timestamp":1,
              "data.start_timestamp":1,
            },
          },
        ],
    });

    const [bData, bLoading] = useMongoAggregate({
      index: "brands",
      refetchOnWindowFocus: false,
      aggregate: [
        {$match:{
            "data.objectID": !cLoading && cData[0].data.brand,
          //   "data.seller":seller ,
          
        }},
        {
          $project: {
            "data.image":1,
            "data.image_bw": 1,
            "data.name": 1,
            "data.objectID": 1
          },
        },
      ],
    });
    const brandName = !bLoading && bData?.[0]?.data?.name;
    const brandImage = !bLoading && bData?.[0]?.data?.image || bData?.[0]?.data?.image_bw;
    const campaignName = !cLoading && cData?.[0]?.data?.name;
    const campaignDiscount = !cLoading && cData?.[0]?.data?.discount;
    const campaignStartTime = !cLoading && moment(cData?.[0]?.data?.start_timestamp* 1000).format(
      "DD/MM/AA hh:mm",
    );
    const campaignEndTime = !cLoading && moment(cData?.[0]?.data?.end_timestamp* 1000).format(
      "DD/MM/AA hh:mm",
    );
    console.log(productsObj)
    const rows = !productsDataLoading && productsObj && cData?.[0]?.data?.items.map(item => createRow(
    item,
    ({
      code: productsObj[item]?.sellerCode || null,
      imageSrc: productsObj[item]?.image || productsObj[item]?.thumbnail,
      name: productsObj[item]?.name,
      brandName: brandName,
    }),
    campaignDiscount)) || [];
    const fixedDiscount = getDiscountAsPercentage(fixDiscount(campaignDiscount));

    return <MainLayout>
        <Stack flexGrow={1} gap="48px">
            <Stack height="fit-content" maxWidth="800px">
        <Box marginBottom={2} borderRadius={2} overflow="hidden">
            <img style={{maxWidth:90, height:"auto", objectFit:"cover"}} src={brandImage}/>
        </Box>
    <ListCard
      list={[
        {
          header: "Nombre",
          content: campaignName,
        },
        {
          header: "Fecha Comienzo",
          content: campaignStartTime,
        },
        {
          header: "Fecha Final",
          content: campaignEndTime,
        },
        {
          header: "Marca",
          content: brandName,
        },
        {

          header: "Descuento",
          content: `${fixedDiscount}%`,
        },
      ]}
    />
    </Stack>
    <Stack gap="16px">
    <Typography
            sx={{ fontSize: 20, fontWeight: 700 }}
            component="h4"
            color="text.secondary"
          >
            Productos
          </Typography>
          <Box
            style={{ position: "relative", width: "100%", height: "500px" }}
            sx={{
              height: 700,
              overflow: "auto",
              width: "100%",
              backgroundColor: "white",
            }}
          >
          <DataGrid
              rows={rows}
              // className={classes}
              columns={columns}
              getRowHeight={() => "auto"}
              // columnVisibilityModel={colVisibilityModel}
              // getRowClassName={(el) => {
              //   if (!purchaseOrder?.received.s) return;
              //   if (!inventoryAdjustmentData?.data) return;
              //   const quantityRequested = el.row.quantity;
              //   const quantityReceived = inventoryAdjustmentData.data.items[
              //     el.row.id
              //   ]
              //     ? Object.values(
              //         inventoryAdjustmentData?.data.items[el.row.id],
              //       )
              //         .map((e) => Object.values(e)[0])
              //         .reduce((acc, current) => acc + current, 0)
              //     : null;
              //   if (quantityReceived === quantityRequested) {
              //     return classes.greenRow;
              //   }
              //   if (
              //     quantityReceived < quantityRequested &&
              //     quantityReceived &&
              //     quantityReceived > 0
              //   ) {
              //     return classes.yellowRow;
              //   }
              //   if (quantityReceived === 0 || !quantityReceived) {
              //     return classes.redRow;
              //   }
              // }}
              style={{ position: "absolute", height: "100%", width: "100%" }}
              initialState={{
                pagination: {
                  paginationModel: {
                    pageSize: 15,
                  },
                },
              }}
              pageSizeOptions={[5]}
              checkboxSelection
              disableRowSelectionOnClick
            />
            </Box>
            </Stack>
  </Stack>
  </MainLayout>
}