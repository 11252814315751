/* eslint-disable */
import customField, { customFieldRender } from "../../../CustomField";
import * as Icons from "@material-ui/icons";
import DriveFileViewButtonModal, {
  DriveFileDownloadButton,
  DriveFilePrintButton,
} from "../../../Drive/Modal";
import moment from "moment";
import React, { useMemo } from "react";
import { useAreaDataContext } from "../../../../context/AreaContext";
import { Link } from "react-router-dom";
import { Button } from "../../../Wrappers";
import { updateDoc } from "../../../../firebase";
import IconButton from "@material-ui/core/IconButton";
import { useSnackbar } from "notistack";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Typography from "@material-ui/core/Typography";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import get from "lodash/get";
import { filterToDisplay } from "../DataCard";

export default function FieldRender({
  f,
  values,
  hit,
  transformBody,
  index,
  classes,
  link,
}) {
  !hit && (hit = values || {});
  !values && (values = hit || {});
  const value = get(values, f.field);

  return (
    <>
      {" "}
      {f.customField ? (
        customField(hit, f.customField)
      ) : f.options && f.options.multiple && f.options.type !== "autocomplete" ? (
        <Multiple
          field={f}
          hit={hit}
          values={values}
          index={index}
          classes={classes}
        />
      ) : f.options && f.options.nested ? (
        <Nested
          field={f}
          values={values}
          hit={hit}
          index={index}
          classes={classes}
        />
      ) : f.options && f.options.type === "checkbox" ? (
        value ? (
          <Icons.Check />
        ) : (
          <Icons.Clear />
        )
      ) : f.options && f.options.type === "file" ? (
        (() => {
          let fileID = value;
          if (!fileID)
            for (let af in f.alternativeFields) {
              fileID = customField(
                hit,
                f.alternativeFields[af].substring(0, 2) !== "{{"
                  ? "{{" + f.alternativeFields[af] + "}}"
                  : f.alternativeFields[af],
              );
              if (fileID) break;
            }
          return (
            fileID && (
              <>
                <DriveFileViewButtonModal
                  driveFileId={(fileID && fileID.value) || fileID}
                />
                {customField(hit, f.options.print) && (
                  <DriveFilePrintButton driveFileId={fileID} />
                )}
                {customField(hit, f.options.download) && (
                  <DriveFileDownloadButton
                    driveFileId={fileID}
                    title={
                      f.options.title
                        ? customField(hit, f.options.title)
                        : "File"
                    }
                  />
                )}
              </>
            )
          );
        })()
      ) : f.options && f.options.type === "button" && link !== false ? (
        <ButtonField hit={values} f={f} index={index} />
      ) : (
        value &&
        ((f.options &&
          ["select","autocomplete"].includes(f.options.type) &&
          f.options.options[value]) ||
          (f.options && f.options.index ? (
            (value.objectID &&
              transformBodyText(
                f,
                transformBody,
                value,
                f.options.index.field,
                hit,
                values,
                classes,
                link,
              )) ||
            Object.keys(value).map((h, i) => (
              <span key={i}>
                {transformBodyText(
                  f,
                  transformBody,
                  value[h],
                  f.options.index.field,
                  hit,
                  values,
                  classes,
                  link,
                )}
                {i !== Object.keys(value).length - 1 && "; "}
              </span>
            ))
          ) : f.options && ["date", "datetime"].includes(f.options.type) ? (
            moment(
              typeof value === "string" &&
                value.match(
                  /^([12]\d{3}-(0[1-9]|1[0-2])-(0[1-9]|[12]\d|3[01]))$/g,
                )
                ? value + " GMT-0300"
                : String(value || "").length > 11
                ? value
                : value * 1000,
            )
              .format("DD/MM/yyyy HH:mm")
              ?.replace("/" + moment().format("yyyy"), "")
              ?.replace?.(" 00:00", "")
          ) : // f.options && f.options.type === 'image' && f.options.server === 'firebase' && f.options.fullPath ?  <UseFirebaseImage fullPath={value} style={{width:'80px'}}/>
          //   :
          f.options && f.options.type === "image" ? (
            <img src={value} style={{ width: "80px" }} alt={"imagen"}/>
          ) : typeof value === "object" ? (
            Object.keys(value).map((h, i) => (
              <span key={i}>
                {typeof value[h] === "object"
                  ? JSON.stringify(value[h])
                  : value[h]}
                {i < Object.keys(value).length - 1 && "; "}
              </span>
            ))
          ) : typeof value === "string" || typeof value === "number" ? (
            transformBodyText(f, transformBody, value, "", hit, "", {}, link)
          ) : (
            ""
          )))
      )}
      {f.options &&
        f.options.afterText &&
        customField(hit, f.options.afterText)}
    </>
  );
}

const randomColor = () =>
  `hsl(${Math.floor(Math.random() * 360)}, 100%, ${
    Math.floor(Math.random() * 10) + 80
  }%)`; //'#'+Math.floor(Math.random()*16777215).toString(16);

function Nested({ field, hit, index, classes, values }) {
  const color = useMemo(() => randomColor(), []);

  return (
    <>
      <Accordion style={{ backgroundColor: color }}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography>{field.name}</Typography>
          {/*<Typography className={classes.accordionSecondaryHeading}>Tocar para ampliar</Typography>*/}
        </AccordionSummary>
        <AccordionDetails style={{ display: "block" }}>
          {filterToDisplay(field.options.nested, hit).map((f) => (
            <div key={f.field} className={classes && classes.text}>
              {f.name && (!f.options || !f.options.multiple) ? (
                <>
                  <span className={classes && classes.name}>{f.name}</span>:{" "}
                </>
              ) : (
                ""
              )}
              <FieldRender
                f={f}
                values={values[field.field] || {}}
                index={index}
                classes={classes}
                hit={hit}
              />
            </div>
          ))}
        </AccordionDetails>
      </Accordion>
    </>
  );
}

function Multiple({
  field: nestedField,
  hit,
  index,
  classes = {},
  values = {},
}) {
  let fields = [...new Array((values[nestedField.field] || []).length)].map(
    (a, i) => ({
      ...nestedField,
      field: String(i),
      name: nestedField.name + " " + i,
      options: {
        ...nestedField.options,
        multiple: false,
        ...(nestedField.options.index
          ? {
              index: {
                ...nestedField.options.index,
                multiple: false,
              },
            }
          : {}),
      },
    }),
  );

  return !fields ? (
    ""
  ) : fields.length === 1 ? (
    <div className={classes && classes.text}>
      {nestedField.name &&
        !(nestedField.options && nestedField.options.nested) && (
          <>
            <span className={classes?.name}>{nestedField.name}</span>:{" "}
          </>
        )}
      <FieldRender
        f={{ ...fields[0], name: nestedField.name }}
        values={values[nestedField.field] || {}}
        index={index}
        classes={classes}
        hit={hit}
      />
    </div>
  ) : (
    <>
      <Accordion style={{ backgroundColor: randomColor() }}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography>{nestedField.name}</Typography>
          {/*<Typography className={classes.accordionSecondaryHeading}>Tocar para ampliar</Typography>*/}
        </AccordionSummary>
        <AccordionDetails style={{ display: "block" }}>
          {fields.map((f) => (
            <div key={f.field} className={classes && classes.text}>
              <FieldRender
                f={f}
                values={values[nestedField.field] || {}}
                index={index}
                classes={classes}
                hit={hit}
              />
            </div>
          ))}
        </AccordionDetails>
      </Accordion>
    </>
  );
}

function ButtonField({ hit, f, index }) {
  const { enqueueSnackbar } = useSnackbar();

  const update = ({ field, value, ...r }) => {
    const nhit = r.hit;
    !field && (field = Object.keys(nhit)[0]);
    let ohit = {
      [field]:
        hit[field] === undefined
          ? ""
          : typeof hit[field] !== "object"
          ? hit[field]
          : hit[field].objectID || Object.keys(hit[field]),
    };
    if (nhit)
      for (let f in nhit)
        ohit[f] =
          hit[f] === undefined
            ? ""
            : typeof hit[f] !== "object"
            ? hit[f]
            : hit[f].objectID || Object.keys(hit[f]);
    updateDoc({
      indexName: index,
      id: hit.objectID,
      hit: nhit || { [field]: value },
    }).then(() =>
      enqueueSnackbar(
        field === "delete" ? "Documento Borrado" : field + " actualizado.",
        {
          action: (
            <React.Fragment>
              {field !== "delete" && (
                <Button
                  color="secondary"
                  size="small"
                  onClick={() =>
                    updateDoc({ indexName: index, id: hit.objectID, hit: ohit })
                  }
                >
                  DESHACER
                </Button>
              )}
              <IconButton size="small" aria-label="close" color="inherit">
                {/*<CloseIcon fontSize="small" />*/}
              </IconButton>
            </React.Fragment>
          ),
        },
      ),
    );
  };

  const noPointer = { cursor: "default" };

  const ButtonFieldRender = ({ button }) => (
    <Button
      size="small"
      variant="contained"
      color={button.color ? customField(hit, button.color) : ""}
      // disabled={button.edit && !customField(hit,button.edit)}
      style={!button.edit || !customField(hit, button.edit) ? noPointer : {}}
      onClick={(e) => {
        e.stopPropagation();
        e.preventDefault();
        if (button.edit && customFieldRender(hit, button.edit)) {
          if (button.action) {
            if (button.action.index && customFieldRender(hit, button.action.id))
              updateDoc({
                indexName: button.action.index,
                id: customFieldRender(hit, button.action.id),
                hit: {
                  [customFieldRender(hit, button.action.field)]:
                    customFieldRender(hit, button.action.value),
                },
              });
            //updateDoc({indexName:button.action.index,id:customFieldRender(hit, button.action.id),hit:{[button.action.field]:button.action.value}});
            else
              update({
                field: customFieldRender(hit, button.action.field),
                value: customFieldRender(hit, button.action.value),
              });
          } else if (button.actions) {
            let nhit = {};
            for (let i in button.actions)
              nhit[customFieldRender(hit, button.actions[i].field)] =
                customFieldRender(hit, button.actions[i].value);
            update({ hit: nhit });
          }
        }
      }}
    >
      {button.label ? customField(hit, button.label) : f.name}
    </Button>
  );

  if (f.options.button) return <ButtonFieldRender button={f.options.button} />;
  if (f.options.buttons)
    return f.options.buttons
      .filter((b) => !b.display || customField(hit, b.display))
      .map((b, i) => <ButtonFieldRender key={i} button={b} />);
}

function transformBodyText(
  f,
  transformBody,
  value,
  indexField,
  hit,
  values,
  classes,
  link,
) {
  let r =
    f.options && f.options.transformBody
      ? f.options.transformBody(value)
      : transformBody && transformBody[f.field]
      ? transformBody[f.field](value)
      : f && f.customField
      ? customField(hit, f.customField)
      : f.options && f.options.index && f.options.index.customText
      ? customField(value, f.options.index.customText)
      : typeof value === "object"
      ? value[indexField]
      : value;
  return (
    <>
      {f.options && f.options.index && link !== false ? (
        <RedirectToIndexCard f={f} value={value}>
          {r}
        </RedirectToIndexCard>
      ) : (
        <span style={{ whiteSpace: "pre-line" }}>{r}</span>
      )}
      {f.options &&
        f.options.index &&
        f.options.index.secondField &&
        FieldRender({
          f: f.options.index.secondField,
          values: value,
          hit: value,
          index: f.options.index.name,
          classes,
        })}
      {f?.options?.index?.moreFields?.map((mf, i) => (
        <FieldRender
          {...{
            key: i,
            f: mf,
            values: value,
            hit: value,
            index: f.options.index.name,
            classes,
          }}
        />
      ))}
    </>
  );
}

function RedirectToIndexCard({ f, children, value }) {
  const areaData = useAreaDataContext();

  if (areaData.panel[f.options.index.name])
    return (
      <Link
        onClick={(e) => e.stopPropagation()}
        to={`/app/${f.options.index.name}/${value.objectID || value}`}
      >
        {children}
      </Link>
    );
  else return <>{children}</>;
}
