import { ajax } from "can";

export default async function uploadToImgur(files) {
  var url =
    "https://api.imgur.com/3/image?access_token=f274dadc4898261ed5bfc8cbac2434ec7c66849f";
  var file = files[0];
  let params = {};
  var fr = new FileReader();
  return new Promise((resolve) => {
    fr.onload = function (e) {
      params.image = e.target.result.replace(/^.*,/, "");
      ajax({
        crossDomain: true,
        url: url,
        type: "POST",
        beforeSend: function (request) {
          request.setRequestHeader(
            "authorization",
            "Client-ID 2543bd5952d8e23"
          );
        },
        // headers: {
        //   authorization: 'Client-ID 2543bd5952d8e23',
        // },
        data: params,
      })
        .then((r) => resolve(r.data.link))
        .catch((e) => console.log(e));
    };
    fr.readAsDataURL(file);
  });
}
