import {
  Box,
  Grid,
  useMediaQuery,
  ListItemIcon,
  ListItemText,
  MenuItem,
} from "@material-ui/core";
import Select from '@mui/material/Select';
import FormControl from '@mui/material/FormControl';
import Tooltip from "@mui/material/Tooltip";
import Sidebar, { ListItemButtonCollapse } from "../components/Sidebar";
import { NavLink, useLocation } from "react-router-dom";
// import Inventory2Icon from "@mui/icons-material/Inventory2";
import { InputLabel, ListItemButton, Stack } from "@mui/material";

import UpperNav from "../components/UpperNav";
import { SellerContext } from "../context/sellerContext";
import { useContext, useState } from "react";
import { useSearchParams } from "../hooks/useSearchParams";
import { ProviderContext } from "../context/providerContext";
import { ApartmentIcon, ShoppingCartIcon, HomeIcon, PercentIcon, LocalShippingIcon } from "./SidebarIcons";

const getURL = (path) => new URL(path);

export default function ProvidersRootLayout({ children }) {
  const matches = useMediaQuery("(min-width:600px)");
  const location = useLocation();
  const { sellersState, handleDeleteSeller, handleSelectSeller } =
    useContext(SellerContext);
  const { handleDeleteProvider, handleSelectProvider, providersState } =
    useContext(ProviderContext);
  const [sidebarIsClosed, setSidebarClosed] = useState(false);
  const { getParam } = useSearchParams();
  const seller = getParam("seller");
  const providerCompany = getParam("providerCompany");

   // Upper Select component handler
  const handleSeller = ({ target }) => {
    if (target.value === "" || !target.value) return handleDeleteSeller();
    return handleSelectSeller(target.value);
  }

  const handleProvider = ({ target }) => {
    if (target.value === "") return handleDeleteProvider();
    return handleSelectProvider(target.value);
  }

  const UpperNavbarContainerHeight = 80
  const sidebarPaths = [
    
    {
      path: window.location.origin + "/providers",
      icon: <HomeIcon />,
      text: "Home",
      exact: true,
      visible:true,
    },
    {
      path: window.location.origin + "/providers/products?active=active",
      icon: <ShoppingCartIcon />,
      text: "Productos",
      exact: false,
      visible:true,
    },
    // {
    //   path:
    //     seller === "luz_azul"
    //       ? window.location.origin +
    //         `/providers/productsCheck?facetFilters%5Bseller%5D=luz_azul&provider=${seller}`
    //       : seller === "v"
    //       ? window.location.origin +
    //         `/providers/productsCheck?facetFilters%5Bseller%5D=v&provider=${seller}`
    //       : window.location.origin + "/providers/productsCheck",
    //   icon: <Inventory2Icon />,
    //   visible:userHasRoleSeller,
    //   text: "Chequeo productos",
    //   exact: true,
    //   children:
    //     seller === "luz_azul"
    //       ? [
    //           {
    //             path:
    //               window.location.origin +
    //               `/providers/productsCheck?facetFilters%5Bseller%5D=luz_azul&provider=${seller}`,
    //             icon: <Inventory2Icon />,
    //             text: "Luz Azul",
    //             exact: true,
    //           },
    //         ]
    //       : seller === "v"
    //       ? [
    //           {
    //             path:
    //               window.location.origin +
    //               `/providers/productsCheck?facetFilters%5Bseller%5D=v&provider=${seller}`,
    //             icon: <Inventory2Icon />,
    //             text: "Vivet",
    //             exact: true,
    //           },
    //         ]
    //       : null,
    // },
    {
      path: window.location.origin + "/providers/brands",
      icon: <ApartmentIcon />,
      text: "Marcas",
      exact: false,
      visible:true,
    },
    {
      path: window.location.origin + "/providers/purchaseOrders",
      icon: <LocalShippingIcon />,
      text: "Gestión de envíos",
      exact: false,
      visible:true,
    },
    {
      path: window.location.origin + "/providers/campaigns",
      icon: <PercentIcon />,
      text: "Campañas",
      exact: true,
      visible:true,
    },
  ];

  return (
    <Grid container style={{ flexDirection: "column" }}>
      <Grid item>
        <Box
          style={{
            height: UpperNavbarContainerHeight,
            width: "100%",
            backgroundColor: "transparent",
          }}
        ></Box>
        <UpperNav>
          <Stack
            alignItems="end"
            paddingX={4}
            height="100%"
            justifyContent="center"
          >
            <Box
              style={{
                padding: 4,
                borderRadius: 2,
              }}
            >
              <Stack direction="row" gap={4}>
              <FormControl>
              <InputLabel id="demo-select-small-label" style={{fontWeight:600}}>Vendedor</InputLabel>
              <Select
                value={seller}
                labelId="demo-select-small-label"
                id="demo-select-small"
                style={{ fontSize: 12,width:120,backgroundColor:"rgb(248, 250, 252)" }}
                onChange={handleSeller}
              >
                {sellersState.map((s) => (
                  <MenuItem key={s.value} value={s.value}>
                    {s.label}
                  </MenuItem>
                ))}
              </Select>
              </FormControl>
              <FormControl>
              <InputLabel id="demo-select-small-label" style={{fontWeight:600}}>Proveedor</InputLabel>
              <Select
                value={providerCompany}
                labelId="demo-select-small-label"
                id="demo-select-small"
                style={{ fontSize: 12,width:120,backgroundColor:"rgb(248, 250, 252)" }}
                onChange={handleProvider}
              >
                {providersState?.map((s) => (
                  <MenuItem key={s.value} value={s.value}>
                    {s.label}
                  </MenuItem>
                ))}
              </Select>
              </FormControl>
              </Stack>
            </Box>
          </Stack>
        </UpperNav>
      </Grid>
      <Grid
        container
        style={{
          height: "100vh",
          backgroundColor: "#f8fafc",
          flexDirection: matches ? "row" : "column",
          flexWrap: "nowrap",
        }}
      >
        <Grid
          style={{
            minWidth:
              matches && !sidebarIsClosed
                ? "60px"
                : sidebarIsClosed && seller
                ? "255px"
                : sidebarIsClosed
                ? "231px"
                : "100%",
            alignItems: "center",
            minHeight: !matches ? "60px" : "0",
            display: !matches ? "flex" : "block",
          }}
          item
        >
          <Sidebar
            openOnHover={false}
            top={UpperNavbarContainerHeight}
            onChangeOpenState={(state) => setSidebarClosed(state)}
            childrenOnClosed={sidebarPaths.map((path) => {
              if(!path.visible) return null
              const url = new URL(path.path);
              return (
                <Tooltip
                  style={{ minWidth: "100%" }}
                  title={path.text}
                  key={path.path}
                  placement="right"
                >
                  <div>
                    <ListItemButton
                      selected={url.pathname === location.pathname}
                      component={NavLink}
                      to={url.pathname + url.search}
                      style={{
                        pointerEvents:
                          url.pathname === location.pathname ? "none" : "all",
                        padding: "12px 0",
                        width: "100%",
                      }}
                      sx={{
                        ".MuiListItemIcon-root": {
                          justifyContent: "center",
                        },

                        "&.Mui-selected": {
                          backgroundColor: "rgb(0 0 0 / 8%)",
                        },
                        "&.Mui-focusVisible": {
                          backgroundColor: "rgb(0 0 0 / 8%)",
                        },
                      }}
                    >
                      <ListItemIcon>{path.icon}</ListItemIcon>
                    </ListItemButton>
                  </div>
                </Tooltip>
              );
            })}
          >
            {sidebarPaths.map((path) => {
              if(!path.visible) return null
              const url = getURL(path.path);
              if (path.children) {
                return (
                  <ListItemButtonCollapse
                    key={path.path}
                    primary={path.text}
                    rootIcon={path.icon}
                  >
                    <>
                      {path.children.map((child) => {
                        const url = getURL(child.path);

                        return (
                          <ListItemButton
                            key={path.path}
                            selected={
                              url.pathname + url.search ===
                              location.pathname + location.search
                            }
                            component={NavLink}
                            to={url.pathname + url.search}
                            sx={{ pl: 4 }}
                          >
                            <ListItemIcon>{child.icon}</ListItemIcon>
                            <ListItemText primary={child.text} />
                          </ListItemButton>
                        );
                      })}
                    </>
                  </ListItemButtonCollapse>
                );
              }
              return (
                <ListItemButton
                  key={path.path}
                  style={{
                    pointerEvents:
                      url.pathname === location.pathname ? "none" : "all",
                  }}
                  selected={url.pathname === location.pathname}
                  component={NavLink}
                  to={url.pathname + url.search}
                  sx={{
                    "&.Mui-selected": {
                      backgroundColor: "rgb(0 0 0 / 8%)",
                    },
                    "&.Mui-focusVisible": {
                      backgroundColor: "rgb(0 0 0 / 8%)",
                    },
                  }}
                >
                  <ListItemIcon>{path.icon}</ListItemIcon>
                  <ListItemText
                    fontFamily="system-ui"
                    fontWeight="600"
                    style={{
                      fontSize: "14px",
                      fontFamily: "system-ui",
                      color: "#1e293b",
                    }}
                    primary={path.text}
                  />
                </ListItemButton>
              );
            })}
          </Sidebar>
        </Grid>
        <Grid xs item style={{ display: "flex", flexDirection: "column" }}>
          {children}
        </Grid>
      </Grid>
    </Grid>
  );
}
