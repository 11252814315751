import { Skeleton, Stack } from "@mui/material";
import { TaskCard } from "./task-card";
import { updateDoc, useDocumentData } from "../../../../firebase/database";

const handleOnCompleteTask = async (task) => {
  await updateDoc({
    indexName: "tasks",
    id: task.objectID,
    hit: {
      inTruck: true,
      }
  })
}

const handleOnCancellTask = async (task) => {
  await updateDoc({
    indexName: "tasks",
    id: task.objectID,
    hit: {
      inTruck: false,
      }
  })
}
function RoadmapTasksRenderSkeleton(){
  return <Stack direction="column" width="100%" p="10" sx={{alignItems:"center",justifyContent:"center"}}  >
    <Skeleton 
    variant="rectangular"
    width="100%"
    height="200px"/>
    </Stack>
}
export function RoadmapTasksRender({objectID}){
    const [result, loading, error] = useDocumentData("tasks",objectID)
    if(loading)  return <RoadmapTasksRenderSkeleton/>
    if(error) return null
    return <Stack  p={2} style={{paddingTop:0,marginTop:"8px"}}>
        <TaskCard 
          onClickDisabled={()=>handleOnCancellTask(result)} 
          onClickPrimary={()=>handleOnCompleteTask(result)} 
          defaultFinished={result.inTruck} 
          key={result.description} 
          title={result?.description} 
          direction={result.address.formatted_address} 
          activedButtonText="Terminado" 
          disableButtonText="Cancelar"/>   
      </Stack>
  }