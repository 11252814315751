import {
  Box,
  Button,
  Checkbox,
  Chip,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  Typography,
} from "@mui/material";
import _ from "lodash";
import { MuiDateRangePicker } from "../../utils/MuiCalendar";
import { useState } from "react";
import moment from "moment";

export default function Filters({
  columns,
  match,
  dispatch,
  facets,
  requestFacets,
}) {
  const dateFilters = Object.entries(
    _.pickBy(requestFacets, (v) => v?.type === "date") || {},
  );

  return (
    <>
      {/* date filters */}

      {dateFilters.map(([key, filter]) => (
        <DateFilter dispatch={dispatch} key={key} filter={filter} />
      ))}
      {_.entries(facets)
        ?.filter(
          ([key, value]) =>
            (value?.length ||
              match[key]?.length ||
              (!_.isArray(match[key]) && match[key])) &&
            !key.includes("_multiple"),
        )
        ?.map(([key, value]) => (
          <Filter
            key={key}
            k={key}
            value={value}
            columns={columns}
            match={match}
            dispatch={dispatch}
            facets={facets}
            requestFacets={requestFacets}
          />
        ))}
    </>
  );
}

function Filter({
  k: key,
  value,
  match,
  columns,
  dispatch,
  requestFacets,
  facets,
}) {
  const field = requestFacets[key]?.field;
  const limit = requestFacets[key]?.limit;

  const othersFilters = (_.isArray(match[key]) ? match[key] : [match[key]])
    ?.filter((d) => d && !value.map((v) => v._id).includes(d))
    ?.map((d) => ({ _id: d, count: 0, selected: 1 }));

  return (
    <FormGroup>
      <FormLabel component="legend">
        {columns.find((c) => c.field === field)?.headerName || field}
      </FormLabel>

      {/* all filters */}
      {_.chain([...othersFilters, ...value])
        .map((v) => ({
          ...v,
          keyName: Object.keys(v?.[key]?.[0] || {})?.find((a) => a !== "_id"),
          multiple:
            facets[`${key}_multiple`]?.find((d) => d._id === v._id)?.count || 0,
          checked: _.isObject(match[key])
            ? match[key].includes(v._id)
            : match[key] === v._id,
        }))
        .sortBy(["checked", "multiple", "count"])
        .reverse()
        .value()
        .map((v) => (
          <FormControlLabel
            key={v._id}
            onChange={() =>
              dispatch({
                [`match.${key}`]: match[key]
                  ? v.checked
                    ? _.pull([match[key]].flat(), v._id)
                    : [match[key], v._id].flat()
                  : v._id,
              })
            }
            control={<Checkbox checked={v.checked} />}
            label={
              <>
                {_.isBoolean(v._id)
                  ? v._id
                    ? "Si"
                    : "No"
                  : v?.[key]?.[0]?.[v?.keyName] || v._id}{" "}
                <Chip label={v.count} />{" "}
                {!v.checked && !!v?.multiple && (
                  <Chip color={"primary"} label={v?.multiple} />
                )}
              </>
            }
          />
        ))}
      <FormControl>
        <Button
          onClick={() => dispatch({ [`facets.${key}.limit`]: !limit ? 5 : 0 })}
        >
          Ver {limit ? "Mas" : "Menos"}
        </Button>
      </FormControl>
    </FormGroup>
  );
}

const DateFilter = ({ filter, dispatch }) => {
  const startFilter = filter?.defaultFilter?.start;
  const endFilter = filter?.defaultFilter?.end;

  const from = startFilter
    ? moment().add(startFilter?.[0], startFilter?.[1])
    : moment().add(-3, "days");

  const to = endFilter
    ? moment(from).add(endFilter?.[0], endFilter?.[1])
    : moment(from).add(30, "days");

  const [dates, setDates] = useState([from, to]);

  const onChangeDate = (date) => {
    setDates(date);
    const from = date?.[0].unix();
    const to = date?.[1].unix();
    dispatch({ [`match.${filter.field}`]: { $gt: from, $lt: to } });
  };

  return (
    <Box sx={{ marginY: 1 }}>
      <Typography>{filter?.name} </Typography>
      <MuiDateRangePicker
        calendars={2}
        localeText={{ start: "Desde", end: "Hasta" }}
        direction="row"
        onChange={onChangeDate}
        date={dates}
      />
    </Box>
  );
};
