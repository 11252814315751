/* eslint-disable no-nested-ternary */
/* eslint-disable react/prop-types */

import { Box, Chip, TextField, Typography } from "@mui/material";
import React, { useState } from "react";
import EditIcon from "@mui/icons-material/Edit";
import LocalShippingIcon from "@mui/icons-material/LocalShipping";
import ShoppingBasketIcon from "@mui/icons-material/ShoppingBasket";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import { FieldValue, updateDoc } from "../../../../firebase/database";
import { useUserData } from "../../../../context/UserContext";

// eslint-disable-next-line react/prop-types
export function EditQuantity({ params }) {
  const [newQ, setNewQ] = useState(params.row.q || "");
  const [editQ, setEditQ] = useState(false);
  const { uid } = useUserData();
  const editable =
    !params.row.basket &&
    // !params.row.quantityEditedByAssembly &&
    !params.row?.truckerEdit;

  // FUNCTIONS
  const saveNewQ = (id) => {
    // const editedUsers = orderDoc.editedUsers
    if (newQ >= 0) {
      const restore = Number(newQ) === params.row.oq;
      updateDoc({
        indexName: "orders",
        id: params.row.orderId,
        hit: {
          [`items.${id}.q`]: Number(newQ),
          [`items.${id}.oq`]: params.row.oq || params.row.q,
          [`items.${id}.lastUserEditQuantity`]: restore
            ? FieldValue.delete()
            : uid,
          [`items.${id}.editQuantity_timestamp`]: restore
            ? FieldValue.delete()
            : (new Date() / 1000) | 0,
          [`items.${id}.deletedItem`]: restore
            ? FieldValue.delete()
            : newQ === 0
            ? true
            : "",
          [`items.${id}.deletedItemBy`]: restore
            ? FieldValue.delete()
            : newQ === 0
            ? uid
            : "",
        },
      });
    }
    setEditQ(false);
  };

  return (
    <Box sx={{ display: "flex", flexDirection: "column" }}>
      {!editQ && (
        <span
          style={{
            height: "20px",
            marginBottom: "8px",
            cursor: editable ? "pointer" : "default",
          }}
          onClick={() => editable && setEditQ(true)}
        >
          <Typography style={{ display: "inline" }}>
            {params.row?.oq || params.row?.odq
              ? `${params.row?.q || 0}${
                  (
                    params.row?.odq
                      ? params.row?.odq !== params.row.q
                      : params.row?.oq !== params.row.q
                  )
                    ? ` de ${
                        params.row?.odq ? params.row?.odq : params.row?.oq
                      }`
                    : ""
                }`
              : params.row.q}
            {params.row?.tq && params.row?.tq !== params.row.q
              ? ` (${params.row?.tq})`
              : ""}
          </Typography>
          {editable && <EditIcon />}
        </span>
      )}
      {editQ && (
        <Box sx={{ width: "100%" }}>
          <TextField
            fullWidth
            onKeyDown={(e) => e.key === "Enter" && saveNewQ(params.row.id)}
            size="small"
            placeholder="10"
            type="number"
            step={10}
            autoFocus
            value={newQ}
            onChange={(e) => setNewQ(e.target.value)}
            onBlur={() => saveNewQ(params.row.id)}
          />
        </Box>
      )}
      <div style={{ marginBottom: "5px" }}>
        {" "}
        {params.row?.deletedItem || params.row?.editLastQuantity ? (
          <Chip
            icon={
              params.row?.truckerEdit ? (
                <LocalShippingIcon />
              ) : params.row?.quantityEditedByAssembly ? (
                <ShoppingBasketIcon />
              ) : (
                <AccountCircleIcon />
              )
            }
            size="small"
            label={params.row?.quitItem || params.row?.editLastQuantity}
            color={
              params.row.truckerEdit
                ? "success"
                : params.row?.quantityEditedByAssembly
                ? "secondary"
                : "info"
            }
          />
        ) : (
          ""
        )}
      </div>
    </Box>
  );
}
