/* eslint-disable react/prop-types */
import {
  Avatar,
  Box,
  Button,
  FormControl,
  Input,
  InputLabel,
  Typography,
} from "@mui/material";
import React, { useEffect, useMemo, useState } from "react";
import ImageNotSupportedIcon from "@mui/icons-material/ImageNotSupported";
import AddIcon from "@mui/icons-material/Add";
import { FieldValue, updateDoc } from "../../../../firebase/database";
import { useModal } from "../../utils/useModal";
import useProducts from "../useProducts";
import { Asynchronous } from "../../../../components/CardFirestore/DataCard/components/EditForm";

export default function WrapperBoxes({ productDoc }) {
  const [productsBox] = useProducts(productDoc?.boxProducts);
  const [newProduct, setNewProduct] = useState({ q: 1, item: "" });
  const [defaultQValue, setDefaultQValue] = useState({});
  const arrIds = productDoc?.boxProducts?.map((x) => x);
  const arrOfQ = arrIds?.map((x) => ({
    [x]: productDoc?.boxProductsQ?.[x] || 1,
  }));

  const [addNewIsVisible, toggleAddNewVisibility] = useModal();
  const [isVisibleBox, toggleVisibility] = useModal();

  const changeVisibility = () => {
    toggleAddNewVisibility();
    setNewProduct({ q: 1, item: "" });
  };

  const updateItemQBox = (id, quantity) => {
    updateDoc({
      indexName: "products",
      id: productDoc?.objectID,
      hit: {
        boxProducts:
          quantity === 0
            ? FieldValue.arrayRemove(id)
            : FieldValue.arrayUnion(id),
        ...{
          [`boxProductsQ.${id}`]: quantity > 1 ? quantity : FieldValue.delete(),
        },
      },
    });
  };

  const addNewItemToBox = () => {
    updateDoc({
      indexName: "products",
      id: productDoc?.objectID,
      hit: {
        ["boxProducts"]: [...(productDoc?.boxProducts || []), newProduct?.item],
        [`boxProductsQ.${newProduct?.item}`]: newProduct?.q,
      },
    });
    toggleAddNewVisibility();
  };

  useEffect(() => {
    setDefaultQValue(arrOfQ?.reduce((p, acc) => ({ ...p, ...acc }), {}));
  }, [productDoc]);
  const AddNewElementToBox = useMemo(
    () => (
      <Box sx={{ width: "50%", margin: "0 auto" }}>
        <Asynchronous
          title="Producto"
          index="products"
          customText={
            "{{brandName}} {{name}} - {{taste}} - {{quantity}}{{unit}}"
          }
          field="name"
          values={false}
          onChange={(id) => {
            setNewProduct({ ...newProduct, item: id });
          }}
        />
        <FormControl sx={{ marginTop: 5 }} fullWidth variant="standard">
          <InputLabel htmlFor="component-simple">Cantidad</InputLabel>
          <Input
            onChange={(e) =>
              setNewProduct({ ...newProduct, q: Number(e.target.value) })
            }
            type="number"
            id="component-simple"
            defaultValue={1}
          />
        </FormControl>
        <Button
          sx={{ marginTop: 4 }}
          onClick={addNewItemToBox}
          disabled={!newProduct?.item?.length}
          fullWidth
          variant="contained"
          color="primary"
        >
          {" "}
          Agregar producto{" "}
        </Button>
      </Box>
    ),
    [newProduct]
  );
  return (
    <>
      <Box
        sx={{
          display: "grid",
          gridTemplateColumns: "repeat(auto-fill, minmax(164px, 1fr))",
          gap: "10px",
          padding: 4,
        }}
      >
        {productDoc?.boxProducts
          ? (productsBox?.[0]?.products || [])?.map((x) => {
              return (
                <Box
                  key={x.data.objectID}
                  sx={{
                    border: "1px solid #00000024",
                    borderRadius: "4px",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    "&:hover": { background: "rgb(0 0 0 / 5% )" },
                  }}
                >
                  {x?.data?.thumbnail ? (
                    <img
                      alt="imageOfProduct"
                      src={x?.data?.thumbnail}
                      width={40}
                    />
                  ) : (
                    <Avatar size="small" src="">
                      <ImageNotSupportedIcon />
                    </Avatar>
                  )}
                  <Typography
                    style={{
                      textAlign: "center",
                      fontWeight: "bold",
                      fontSize: "14px",
                    }}
                  >
                    {x?.data?.brandName}
                  </Typography>
                  <Typography
                    style={{
                      textAlign: "center",
                      fontWeight: "bold",
                      fontSize: "14px",
                    }}
                  >
                    {x?.data?.name}
                  </Typography>
                  <span style={{ display: "flex", alignItems: "center" }}>
                    <Typography sx={{ display: "inline", fontSize: 12 }}>
                      {x?.data?.taste}
                    </Typography>
                    <Typography
                      sx={{ display: "inline", fontSize: 12, marginLeft: 1 }}
                    >
                      {x?.data?.quantity || "-"}
                      {x?.data?.unit || "-"}
                    </Typography>
                  </span>
                  <FormControl
                    sx={{
                      margin: 0,
                      marginTop: "-6px",
                      padding: "10px",
                      flex: 1,
                      "& .MuiInputBase-root": { flex: 1 },
                    }}
                    fullWidth
                    variant="standard"
                  >
                    <Input
                      onBlur={(e) =>
                        updateItemQBox(
                          x?.data?.objectID,
                          Number(e.target.value)
                        )
                      }
                      onChange={(e) => {
                        setDefaultQValue({
                          ...defaultQValue,
                          [x.data.objectID]: Number(e.target.value),
                        });
                      }}
                      // eslint-disable-next-line no-tabs
                      sx={{ "&	.MuiInput-input": { textAlign: "center" } }}
                      type="number"
                      value={defaultQValue?.[x.data.objectID]}
                    />
                  </FormControl>
                </Box>
              );
            })
          : null}

        {!productsBox?.[0]?.products?.length ? (
          <Button onClick={toggleVisibility} variant="outlined" color="primary">
            {" "}
            {!isVisibleBox ? " Agregar items al box" : "Cancelar"}{" "}
          </Button>
        ) : null}
        {isVisibleBox || productsBox?.[0]?.products?.length ? (
          <Box
            onClick={changeVisibility}
            sx={{
              "&:hover": { background: "rgb(0,0,0,20%)" },
              cursor: "pointer",
              minHeight: 50,
              border: "1px dashed #00000024",
              borderRadius: "4px",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <span style={{ display: "flex", gap: 3 }}>
              <Typography style={{ color: "rgb(0 0 0 / 20%)" }}>
                {addNewIsVisible ? "Cancelar" : "Agregar producto"}
              </Typography>
              {addNewIsVisible ? null : (
                <AddIcon style={{ color: "rgb(0 0 0 / 10%)" }} />
              )}
            </span>
          </Box>
        ) : null}
      </Box>
      {addNewIsVisible ? { ...AddNewElementToBox } : null}
    </>
  );
}
