/* eslint-disable no-param-reassign */
/* eslint-disable camelcase */
/* eslint-disable guard-for-in */
/* eslint-disable no-restricted-syntax */
/* eslint-disable max-len */
/* eslint-disable no-unused-expressions */
/* eslint-disable no-return-assign */
import firebase from "firebase/app";
import "firebase/firestore";
import "firebase/auth";
import {
  useCollection as firestoreUseCollection,
  useDocumentData as firestoreUseDocumentData,
  useDocumentDataOnce as firestoreUseDocumentDataOnce,
} from "react-firebase-hooks/firestore";
import "firebase/storage";
import { useEffect, useState } from "react";
import _ from "lodash";

let db;
let auth;
let uid;
let storage;
const { Timestamp } = firebase.firestore;
export const { FieldValue, FieldPath } = firebase.firestore;

const firebaseConfigDefault = {
  // apiKey: "AIzaSyDvzMDCkUoQG027Mq7-e1maJpCSiB-8_kw",
  apiKey: "AIzaSyD33vLZ3jH04XGM_c-GvqHL98a2DHSgIyI",
  authDomain: "vivet-web.firebaseapp.com",
  databaseURL: "https://vivet-web.firebaseio.com",
  projectId: "vivet-web",
  storageBucket: "vivet-web.appspot.com",
  messagingSenderId: "718089198056",
  appId: "1:718089198056:web:ea66fefa69302a4b9aec5d",
};

export function initializeFirebase(firebaseConfig = firebaseConfigDefault) {
  if (firebase.apps.length === 0) firebase.initializeApp(firebaseConfig);
  firebase.auth().onAuthStateChanged((u) => (uid = u && u.uid));
  db = firebase.firestore();
  auth = firebase.auth();
  storage = firebase.storage();
}

initializeFirebase();

const googleAuthProvider = new firebase.auth.GoogleAuthProvider();

const updateDoc = (collection, id, hit, delete_fields) => {
  let indexName = collection;
  typeof collection === "object" &&
    ({ indexName, id, hit, delete_fields } = collection);
  if (!hit) hit = {};
  if (delete_fields)
    for (const i in delete_fields)
      hit[delete_fields[i]] = firebase.firestore.FieldValue.delete();
  if (uid) hit.lastEditBy = uid;
  return db
    .collection(indexName)
    .doc(id)
    .update({ algoliaUpdated: false, ...hit });
};

const newDoc = async ({ indexName, hit, id }) => {
  if (!hit) hit = {};
  if (uid) hit.lastEditBy = uid;
  const newID = id || db.collection(indexName).doc().id;

  await db
    .collection(indexName)
    .doc(newID)
    .set({
      algoliaUpdated: false,
      modified_date: firebase.firestore.Timestamp.fromDate(new Date()),
      ...hit,
    });
  return newID;
};

export function useCollection({
  collection = "",
  where = [],
  limit = 10,
  orderBy = [],
  localCache = false,
}) {
  const snapshot = firestoreUseCollection(
    collection &&
      orderBy
        .reduce(
          (o, a) => o.orderBy(...a),
          where.reduce((o, a) => o.where(...a), db.collection(collection)),
        )
        .limit(limit),
  );
  const [[result, loading, error], setResults] = useState([
    localCache
      ? JSON.parse(
          localStorage.getItem(
            JSON.stringify({
              collection,
              where,
            }),
          ) || (snapshot[0] ? JSON.stringify(snapshot[0]) : "[]"),
        )
      : snapshot[0],
    snapshot[1],
    snapshot[2],
  ]);

  useEffect(() => {
    const results = snapshot?.[0]?.docs?.map((entity) => ({
      ..._.mapValues(entity.data(), (v) =>
        v instanceof Timestamp ? v.toDate() : v,
      ),
      objectID: entity.id,
    }));

    !(result && !results) && setResults([results, snapshot[1], snapshot[2]]);

    results &&
      localCache &&
      localStorage.setItem(
        JSON.stringify({
          collection,
          where,
        }),
        JSON.stringify(results),
      );
  }, [snapshot]);

  return [result, loading, error];
}

export function useDocumentData(collection, docID) {
  const [entity, loading, error] = firestoreUseDocumentData(
    docID &&
      collection &&
      _.isString(docID) &&
      db.collection(collection).doc(docID),
  );

  const result = entity;

  if (result) {
    result.objectID = docID;
    Object.entries(result).forEach(([field, value]) => {
      if (value instanceof Timestamp) {
        result[field] = value.toDate();
      }
    });
  }

  return [result, loading, error];
}

export function useDocumentDataOnce(collection, docID) {
  const [entity, loading, error] = firestoreUseDocumentDataOnce(
    docID &&
      collection &&
      _.isString(docID) &&
      db.collection(collection).doc(docID),
  );

  const result = entity;

  if (result) {
    result.objectID = docID;
    Object.entries(result).forEach(([field, value]) => {
      if (value instanceof Timestamp) {
        result[field] = value.toDate();
      }
    });
  }

  return [result, loading, error];
}

const batchUpdate = async ({ indexName, hits, lbatch }) => {
  const chunkHits = _.chunk(
    Object.entries(hits || {}).map(([key, value]) => ({ [key]: value })),
    500,
  ).map((f) => f.reduce((p, a) => _.merge(p, a), {}));

  for (let i in chunkHits) {
    console.log("chunkHits", i);
    let hits = chunkHits[i];
    const batch = lbatch || db.batch();

    for (let id in hits) {
      let hit = hits[id] || {};
      let nhit = removeEmpty({ ...hit });
      nhit = removeEmpty(
        { ...nhit },
        { From: "#DELETE#", To: FieldValue.delete() },
      );
      const ref = db.collection(indexName).doc(id);
      batch.update(ref, { algoliaUpdated: false, ...nhit });
    }

    !lbatch && (await batch.commit());
  }
  return true;
};

const removeEmpty = (obj, { From, To } = {}) => {
  Object.keys(obj).forEach((key) => {
    if (obj[key] && typeof obj[key] === "object" && obj[key] !== From)
      removeEmpty(obj[key], { From, To });
    else if (obj[key] === undefined || (From && obj[key] === From))
      To ? (obj[key] = To) : delete obj[key];
  });
  return obj;
};

export function getFirestoreCollection(collection) {
  return db.collection(collection);
}

export {
  db,
  auth,
  updateDoc,
  newDoc,
  googleAuthProvider,
  storage,
  batchUpdate,
};
export default firebase;
