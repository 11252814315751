import { makeStyles } from "@material-ui/styles";

export default makeStyles(() => ({
  list: {
    minHeight: "100vh",
    maxHeight: 200,
    overflowY: "scroll",
    scrollBehavior: "smooth",
  },
  quantity: {
    fontWeight: "bold",
    fontSize: "1.3em",
  },
  quantityNegative: {
    color: "red",
  },
  listAvatar: {
    marginRight: 10,
  },
  tquantity: {
    fontSize: "0.6em",
    marginLeft: "4px",
    color: "#5c5c5c",
  },
  lotPackQ: {
    fontSize: "0.6em",
    marginLeft: "4px",
    color: "#5c5c5c",
  },
  dateUserItem: {
    textAlign: "end",
  },
}));
