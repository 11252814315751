/* eslint-disable react/prop-types */
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import {
  ArrowBack as BackIcon,
  HomeOutlined as HomeOutlinedIcon,
} from "@material-ui/icons";
import Typography from "@material-ui/core/Typography";
import React from "react";
import { makeStyles } from "@material-ui/styles";
import LibraryBooksIcon from "@mui/icons-material/LibraryBooks";
import { useHistory } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
  },
}));

export default function Header({ title, goBack }) {
  const classes = useStyles();
  const history = useHistory();

  return (
    <AppBar position="sticky">
      <Toolbar>
        <IconButton
          className={classes.menuButton}
          edge="start"
          color="inherit"
          aria-label="menu"
          onClick={goBack || history.goBack}
        >
          <BackIcon />
        </IconButton>
        <Typography variant="h6" className={classes.title}>
          {title}
        </Typography>

        {history.location.pathname === "/binsCheck" ? (
          <IconButton
            color="inherit"
            onClick={() => history.push("/binsInventory")}
          >
            <LibraryBooksIcon />
          </IconButton>
        ) : null}
        <IconButton color="inherit" onClick={() => history.push("/home")}>
          <HomeOutlinedIcon />
        </IconButton>
      </Toolbar>
    </AppBar>
  );
}
