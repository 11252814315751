import { useEffect, useState } from "react"

export const useDebouncer = (trigger, time) => {
    const [debouncedValue, setDebouncedValue] = useState(trigger)

    useEffect(() => {
        const timer = setTimeout(() => {
            setDebouncedValue(trigger)
        }, time)

        return () => clearTimeout(timer)
    }, [trigger, time])

    return [debouncedValue]
}