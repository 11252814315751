/* eslint-disable react/prop-types */

import { Box, InputAdornment, TextField, Typography } from "@mui/material";
import React, { useState } from "react";
import EditIcon from "@mui/icons-material/Edit";
import IconButton from "@mui/material/IconButton";
import { useUserData } from "../../../context/UserContext";
import { FieldValue, updateDoc } from "../../../firebase/database";
import _ from "lodash";

export default function DiscountsEdit({ order }) {
  const [newDiscount, setNewDiscount] = useState("");
  const [editD, setEditD] = useState(false);
  const { uid } = useUserData();

  const saveDiscount = () => {
    if (newDiscount) {
      // const editedUsers = orderDoc.editedUsers

      const hit = Object.entries(order.items)
        .filter(
          ([, v]) =>
            v.q &&
            _.min([(v.odiscount ?? 1) - 0.001, v.discount || 0]) <
              Number(newDiscount / 100),
        )
        .reduce((p, [id, v]) => {
          const restore = Number(newDiscount / 100) === v.odiscount;
          return {
            ...p,
            [`items.${id}.discount`]: Number(newDiscount / 100),
            [`items.${id}.odiscount`]: restore
              ? FieldValue.delete()
              : v.odiscount || order.items[id].discount || 0,
            [`items.${id}.didLastDiscount`]: restore
              ? FieldValue.delete()
              : uid,
          };
        }, {});

      updateDoc({ indexName: "orders", id: order.objectID, hit }).then(() => {
        setNewDiscount("");
        setEditD(false);
      });
    } else setEditD(false);
  };

  return (
    <>
      {!editD && (
        <IconButton onClick={() => setEditD(true)}>
          <EditIcon />
        </IconButton>
      )}
      {editD && (
        <Box sx={{ width: "100%" }}>
          <TextField
            fullWidth
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Typography style={{ fontWeight: "bold" }}>%</Typography>
                </InputAdornment>
              ),
            }}
            onKeyDown={(e) => e.key === "Enter" && saveDiscount()}
            size="small"
            placeholder="10"
            type="number"
            step={10}
            autoFocus
            value={newDiscount}
            onChange={(e) =>
              e.target.value.length < 4 &&
              setNewDiscount(Number(e.target.value))
            }
            onBlur={saveDiscount}
          />
        </Box>
      )}
    </>
  );
}
