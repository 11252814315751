/* eslint-disable guard-for-in */
/* eslint-disable no-restricted-syntax */
/* eslint-disable consistent-return */
/* eslint-disable react/prop-types */
import React, { useEffect, useState } from "react";
// components
import Populate from "../../algolia/populate";
import { db } from "../../firebase";
import { useAreaDataContext } from "../../context/AreaContext";
import { setObjects } from "../../algolia/getObjects";
import { fixTimestampFirestore } from "../../firebase/utils/fixTimestamp";

// checkea si hay indices adentro de nested o multiples
function fieldNorMreview(b, f) {
  if (f.options && f.options.multiple) {
    return [
      fieldNorMreview(`${b + f.field}.array.`, {
        ...f,
        options: { ...f.options, multiple: false },
      }),
    ];
  }
  if (f.options && f.options.nested) {
    const nf = f.options.nested;
    const lastArray = b.split(".")[b.split(".").length - 2] === "array";
    return nf.map((nff) =>
      fieldNorMreview(b + (lastArray ? "" : `${f.field}.`), nff),
    );
  }
  if (f.options && f.options.index) {
    return { index: f.options.index.name, field: b + f.field };
  }
  return "";
}

export default function Card({
  // eslint-disable-next-line no-unused-vars
  index,
  objectID,
  populateProps,
  relatedIndexes,
  render,
}) {
  const [hit, setHit] = useState([]);
  const [relatedHits, setRelatedHits] = useState({});
  const [populatePropsState, setPopulatePropsState] = useState(populateProps);

  const areaData = useAreaDataContext();
  useEffect(() => {
    if (!populateProps && areaData.panel[index])
      setPopulatePropsState(
        areaData.panel[index].fields
          .map((f) => fieldNorMreview("", f))
          .flat(10)
          .filter((f) => !!f),
      );
    // areaData.panel[index].fields.filter((f)=>f.options&&f.options.index).map(f=>({index:f.options.index.name,field:f.field})))
    else setPopulatePropsState({});
  }, [areaData.panel, index, populateProps]);

  useEffect(() => {
    let active = true;
    const unsubscribe = db
      .collection(areaData.panel[index]?.collection || index)
      .doc(objectID)
      .onSnapshot(async (doc) => {
        let data = { ...fixTimestampFirestore(doc.data()), objectID };
        await setObjects(areaData.panel[index]?.collection || index, {
          [objectID]: data,
        });
        if (populatePropsState) {
          if (Object.keys(populatePropsState)) {
            data = await Populate({
              hits: [data],
              props: populatePropsState,
            }).then((a) => a[0]);
          }
        }
        if (active) setHit([data, { ...doc.data(), objectID }]);
      });
    if (populatePropsState) {
      return () => {
        active = false;
        unsubscribe();
      };
    }
  }, [index, objectID, populatePropsState]);

  useEffect(() => {
    const snapshots = [];
    let active = true;
    for (const i in relatedIndexes) {
      if (relatedIndexes[i].index) {
        // eslint-disable-next-line no-loop-func
        snapshots.push(
          db
            .collection(relatedIndexes[i].index)
            .where(
              relatedIndexes[i].field,
              relatedIndexes[i].logical || "==",
              objectID,
            )
            .onSnapshot(async (docs) => {
              let hits = [];
              for (const j in docs.docs) {
                hits.push({
                  ...fixTimestampFirestore(docs.docs[j].data()),
                  objectID: docs.docs[j].id,
                });
              }
              const pp = relatedIndexes[i].populateProps;
              if (pp) hits = await Populate({ hits, props: pp });
              if (active) {
                setRelatedHits((h) => ({
                  ...h,
                  [relatedIndexes[i].index]: hits,
                }));
              }
            }),
        );
      }
    }
    return () => {
      active = false;
      for (const k in snapshots) snapshots[k]();
    };
  }, [index, objectID, relatedIndexes]);

  return React.createElement(render, {
    hit: hit[0] || false,
    orginalHit: hit[1] || false,
    relatedHits,
  });
}
