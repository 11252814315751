import { Skeleton, Stack, useMediaQuery } from "@mui/material"
export function BrandsCardsSkeleton(){
  const matches = useMediaQuery('(min-width:600px)')
  return <Stack gap={1}>
        <Stack direction={matches ? "row" : "column"} width="100%" gap={2}>
          <Skeleton variant="rectangular" width="100%" height={118} />
        </Stack>
        <Stack direction={matches ? "row" : "column"} width="100%" gap={2}>
          <Skeleton variant="rectangular" width="100%" height={118} />
        </Stack>
        <Stack direction={matches ? "row" : "column"} width="100%" gap={2}>
          <Skeleton variant="rectangular" width="100%" height={118} />
        </Stack>
        <Stack direction={matches ? "row" : "column"} width="100%" gap={2}>
          <Skeleton variant="rectangular" width="100%" height={118} />  
        </Stack>
        <Stack direction={matches ? "row" : "column"} width="100%" gap={2}>
          <Skeleton variant="rectangular" width="100%" height={118} />  
        </Stack>
        <Stack direction={matches ? "row" : "column"} width="100%" gap={2}>
          <Skeleton variant="rectangular" width="100%" height={118} />  
        </Stack>
        <Stack direction={matches ? "row" : "column"} width="100%" gap={2}>
          <Skeleton variant="rectangular" width="100%" height={118} />  
        </Stack>
        <Stack direction={matches ? "row" : "column"} width="100%" gap={2}>
          <Skeleton variant="rectangular" width="100%" height={118} />  
        </Stack>
     
      </Stack>
}

export function CardsSkeleton(){
    const matches = useMediaQuery('(min-width:600px)')
    return <Stack gap={2} width="100%">
          <Stack direction={matches ? "row" : "column"} width="100%" gap={2}>
            <Skeleton variant="rectangular" width={210} height={118} />
            <Stack width="100%" alignItems="start">
              <Skeleton variatn="text" width="70%" />
              <Skeleton variatn="text" width="60%" />
              <Skeleton variatn="text" width="40px" />
              <Skeleton variatn="text" width="80px" height="50px"/>
            </Stack>
        </Stack>
        <Stack direction={matches ? "row" : "column"} width="100%" gap={2}>
            <Skeleton variant="rectangular" width={210} height={118} />
            <Stack width="100%" alignItems="start">
              <Skeleton variatn="text" width="70%" />
              <Skeleton variatn="text" width="60%" />
              <Skeleton variatn="text" width="40px" />
              <Skeleton variatn="text" width="80px" height="50px"/>
            </Stack>
        </Stack>
        <Stack direction={matches ? "row" : "column"} width="100%" gap={2}>
            <Skeleton variant="rectangular" width={210} height={118} />
            <Stack width="100%" alignItems="start">
              <Skeleton variatn="text" width="70%" />
              <Skeleton variatn="text" width="60%" />
              <Skeleton variatn="text" width="40px" />
              <Skeleton variatn="text" width="80px" height="50px"/>
            </Stack>
        </Stack>
        <Stack direction={matches ? "row" : "column"} width="100%" gap={2}>
            <Skeleton variant="rectangular" width={210} height={118} />
            <Stack width="100%" alignItems="start">
              <Skeleton variatn="text" width="70%" />
              <Skeleton variatn="text" width="60%" />
              <Skeleton variatn="text" width="40px" />
              <Skeleton variatn="text" width="80px" height="50px"/>
            </Stack>
        </Stack>
       
    </Stack>
}