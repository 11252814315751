/* eslint-disable react/prop-types */

import * as React from "react";

// Grafico
import moment from "moment";
import "react-daterange-picker/dist/css/react-calendar.css";
import SalesByCustomers from "../brands/salesByCustomers";

// eslint-disable-next-line no-undef
require("moment/locale/es");

moment.locale("es");

function SpecsPage({ objectID }) {
  // Gráfico
  return <SalesByCustomers spec={objectID} />;
}

export default SpecsPage;
