import React, { useContext, useEffect, useState } from "react";
import deepEqual from "deep-equal";
import { auth, db } from "../firebase";
import "firebase/auth";
import "firebase/firestore";
import {
  phoneNumberFix,
  validatePhoneNumber,
} from "../pages/login/phoneNumber";

const UserDataContext = React.createContext();
const UserDataSetContext = React.createContext();

function UserProvider({ children }) {
  const [userData, setUserData] = React.useState(
    JSON.parse(localStorage?.getItem("userData") || "{}"),
  );
  const [userID, setUserID] = React.useState(userData?.uid || "");
  const workIp = "45.232.164.91";
  const [ip, setIp] = useState(true);
  const only_on_ip = !!userData?.only_on_ip;
  const atWork = !only_on_ip || ip === workIp;

  useEffect(() => {
    only_on_ip &&
      fetch("https://ipapi.co/json/")
        .then((response) => response.json())
        .then((apis) => setIp(apis?.ip));
  }, [only_on_ip]);

  useEffect(
    () =>
      auth.onAuthStateChanged((user) => {
        if (user) {
          setUserID(user.uid);
          db.collection("users")
            .doc(user.uid)
            .set(
              {
                lastPanelSignIn: (new Date() / 1000) | 0,
                isAnonymous: user.isAnonymous,
              },
              { merge: true },
            );
        }
        if (!user) auth.signInAnonymously();
      }),
    [],
  );

  useEffect(() => {
    if (userID) {
      return db
        .collection("users")
        .doc(userID)
        .onSnapshot((uD) => {
          // const data = uD.data();
          if (uD.exists) {
            localStorage?.setItem(
              "userData",
              JSON.stringify({ ...uD.data(), uid: userID }),
            );
            setUserData((pd) => {
              if (
                pd.currentRole !== uD.data().currentRole ||
                pd.currentArea !== uD.data().currentArea ||
                !deepEqual(pd.roles, uD.data().roles) ||
                !deepEqual(pd.areas, uD.data().areas) ||
                uD.data().logintoken ||
                pd.only_on_ip !== uD.data().only_on_ip ||
                pd.isAnonymous !== uD.data().isAnonymous
              ) {
                return {
                  ...(uD.metadata.hasPendingWrites && uD.exists ? pd : {}),
                  ...uD.data(),
                  uid: userID,
                };
              }
              return pd;
            });
          } else {
            localStorage?.clear();
            setUserData({});
          }
        });
    }
  }, [userID]);

  const updateUserData = (data) => {
    const userDoc = db.collection("users").doc(userID);
    Object.keys(userData).length ? userDoc.update(data) : userDoc.set(data);
  };

  useEffect(() => {
    if (userData.logintoken) auth.signInWithCustomToken(userData.logintoken);
  }, [userData]);

  return !userID ? (
    "Cargando datos de Usuario"
  ) : !atWork ? (
    "No se encuentra en el área de trabajo."
  ) : (
    <UserDataContext.Provider value={userData}>
      <UserDataSetContext.Provider value={updateUserData}>
        {children}
      </UserDataSetContext.Provider>
    </UserDataContext.Provider>
  );
}

function useUserData() {
  const context = useContext(UserDataContext);
  if (context === undefined) {
    throw new Error("useUserData must be used within a UserProvider");
  }
  return context;
}

function useUserDataSet() {
  const context = useContext(UserDataSetContext);
  if (context === undefined) {
    throw new Error("useUserDataSet must be used within a UserProvider");
  }
  return context;
}

export { UserProvider, loginUser, signOut, useUserData, useUserDataSet };

// ###########################################################

function loginUser({
  userDispatch,
  phoneNumber,
  setIsLoading,
  setError,
  userDataSet,
  isInternational,
}) {
  setError(false);
  setIsLoading(true);
  if (isInternational) {
    return userDataSet({
      phoneNumber: phoneNumber,
      wcodeSent: false,
      isAnonymous: true,
    });
  }
  if (validatePhoneNumber(phoneNumber) && !isInternational) {
    userDataSet({
      phoneNumber: phoneNumberFix(phoneNumber),
      wcodeSent: false,
      isAnonymous: true,
    });
  } else {
    userDispatch({ type: "LOGIN_FAILURE" });
    setError(true);
    setIsLoading(false);
  }
}

function signOut() {
  localStorage?.clear();
  auth.signOut();
}
