import { useGetLibro } from "./useGetLibro";
import { Grid, styled } from "@mui/material";
import { numberWithCommas } from "../../../../../components/Utils/NumberWithCommas";
import { useGridApiRef } from "@mui/x-data-grid-premium";
import _ from "lodash";
import { useEffect, useMemo, useState } from "react";
import DataGridPremium from "../../../../../components/DataGrid/DataGrid";
import { InputChangeEntity } from "../transactions/InputChangeEntity";
import { useBanksDataContext } from "../../../BanksContext";
import moment from "moment";

export default function LibroTable() {
  const {
    data: { selectedAccounts },
    accounts,
  } = useBanksDataContext();
  const accounts_by_id = useMemo(
    () => _.mapValues(_.groupBy(accounts, "id"), ([{ name }]) => name),
    [accounts],
  );
  const [transactions, transactionsLoading] = useGetLibro({
    accounts: selectedAccounts,
  });

  const [invoices, invoicesLoading] = useGetLibro({
    accounts: selectedAccounts,
    index: "invoices",
  });

  const [checks, checksLoading] = useGetLibro({
    accounts: selectedAccounts,
    index: "checks",
  });

  const [data, setArrayData] = useState([]);
  const apiRef = useGridApiRef();

  const { setData } = useBanksDataContext();

  const handleCellClick = ({ field, row, value }) => {
    if (field === "entity") {
      setData({ entity: value, entity_name: row.entity_name });
    } else {
      setData({ idSelected: row.id, tableType: row.index });
    }
  };

  useEffect(() => {
    setArrayData((d) => (d.length ? [] : d.map((v) => ({ ...v }))));
  }, [selectedAccounts]);

  useEffect(() => {
    if (![checks, invoices, transactions].some((d) => !d)) {
      const data = _.sortBy([...checks, ...invoices, ...transactions], "date");
      let tempBalance = 0;
      let rows = [];
      for (let i in data) {
        const h = data[i];
        const accounts = h.accounts?.filter?.(({ k }) =>
          selectedAccounts.includes(k),
        );
        for (let j in accounts) {
          const account = accounts[j];
          const v = account.v;
          rows.push({
            ...h,
            row_id: `${h.id}-${j}`,
            account_code: account.k?.replaceAll("-", "."),
            account_name: accounts_by_id[account.k],
            debit: v > 0 ? v : 0,
            credit: v < 0 ? -v : 0,
            balance: (tempBalance = tempBalance + v),
          });
        }
      }
      setArrayData(rows.reverse());
    }
  }, [checks, invoices, transactions]);

  return (
    <Grid container sx={{ height: 500, paddingBottom: "44px" }}>
      <Grid item xs={12}>
        <h1 style={{ fontSize: 15 }}>
          {selectedAccounts
            .map((k) => `${k.replaceAll("-", ".")} - ${accounts_by_id[k]}`)
            .join(";")}
        </h1>
      </Grid>
      <Grid item xs={12}>
        <StyledDataGrid
          initialState={{
            columns: {
              columnVisibilityModel: {
                // Hide columns status and traderName, the other columns will remain visible
                payment_date: false,
                check: false,
                contabilium_id: false,
              },
            },
          }}
          apiRef={apiRef}
          fileName={`Mayor - ${selectedAccounts
            .map((k) => k.replaceAll("-", "."))
            .join(" - ")} (${moment().format("YYYY-MM-DD")})`}
          getRowId={(row) => row.row_id}
          onCellClick={handleCellClick}
          isCellEditable={(params) => !params.row.entity?.length}
          loading={transactionsLoading || invoicesLoading || checksLoading}
          columns={columns}
          rows={data}
        />
      </Grid>
    </Grid>
  );
}

const handleEditEntity = () => {
  // const { id, entity, index } = updatedRow;
  // // updateDoc({ id: id, indexName: index, hit: { entity } });
  // return "";
};

const columns = [
  {
    field: "account_code",
    headerName: "Codigo",
  },
  {
    field: "account_name",
    headerName: "Cuenta",
  },
  { field: "id", headerName: "id" },
  { field: "date", headerName: "Fecha", width: 120 },
  { field: "reason", headerName: "Detalle", width: 250 },
  {
    field: "debit",
    headerName: "Debito",
    width: 120,
    type: "number",
    renderCell: ({ value }) => "$ " + numberWithCommas(value, 2),
  },
  {
    field: "credit",
    headerName: "Credito",
    width: 120,
    type: "number",
    renderCell: ({ value }) => "$ " + numberWithCommas(value, 2),
  },
  {
    field: "balance",
    headerName: "Saldo",
    type: "number",
    width: 180,
    renderCell: ({ value }) => "$ " + numberWithCommas(value, 2),
  },
  {
    field: "entity",
    headerName: "Persona cuit",
    renderCell: (params) =>
      params.row.editable ? (
        <InputChangeEntity handleChange={handleEditEntity} row={params.row} />
      ) : (
        params.row.entity
      ),
  },
  {
    field: "entity_name",
    headerName: "Persona",
  },
  {
    field: "index",
    headerName: "Tipo",
    valueGetter: ({ value }) =>
      ({ checks: "Cheque", invoices: "Factura", transactions: "Transacción" }[
        value
      ]),
  },
  {
    field: "bank",
    headerName: "Banco",
  },
  {
    field: "description",
    headerName: "Descripcion",
  },
  {
    field: "check",
    headerName: "cheque",
  },
  {
    field: "payment_date",
    headerName: "Fecha Pago",
  },

  {
    field: "contabilium_id",
    headerName: "contabilium_id",
  },
];

const StyledDataGrid = styled(DataGridPremium)(({ theme }) => ({
  border: 0,
  color:
    theme.palette.mode === "light"
      ? "rgba(0,0,0,.85)"
      : "rgba(255,255,255,0.85)",
  WebkitFontSmoothing: "auto",
  letterSpacing: "normal",
  "& .MuiDataGrid-columnsContainer": {
    backgroundColor: theme.palette.mode === "light" ? "#fafafa" : "#1d1d1d",
    whiteSpace: "pre-wrap",
  },
  "& .MuiDataGrid-columnHeader, .MuiDataGrid-cell": {
    borderRight: `1px solid ${
      theme.palette.mode === "light" ? "#f0f0f0" : "#303030"
    }`,
    whiteSpace: "pre-wrap",
  },
  "& .MuiDataGrid-columnsContainer, .MuiDataGrid-cell": {
    borderBottom: `1px solid ${
      theme.palette.mode === "light" ? "#f0f0f0" : "#303030"
    }`,
    whiteSpace: "pre-wrap",
  },
  "& .MuiDataGrid-cell": {
    color:
      theme.palette.mode === "light"
        ? "rgba(0,0,0,.85)"
        : "rgba(255,255,255,0.65)",
    whiteSpace: "pre-wrap",
  },
  "& .MuiPaginationItem-root": {
    borderRadius: 0,
    whiteSpace: "pre-wrap",
  },
}));
