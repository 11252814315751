
const defaultOptions = {
    dontBreakRows: true
}

export default function templateGenerator({
  items,
  columns,
  columnsWidths,
  template,
}) {
  const columnsFormatted = columns.map((c) => ({
    text: c?.label,
    fillColor: "#eaf2f5",
    border: [false, true, false, true],
    margin: [0, 5, 0, 5],
    fontSize: 9,
    textTransform: "uppercase",
  }));

  const itemsFormatted = items.map((item) =>
    columns.map((c) => {
      const productWithIva = (item.iva ?? 0.21) + 1;
      const price = productWithIva * item?.purchasePrice * item.q || 0;
      const formattedText =
        c?.key === "total"
          ? c.format(price)
          : c?.format
          ? c.format(item)
          : null;
      const text = formattedText ? formattedText : item?.[c.key] || "";

      return {
        text,
        fontSize: 8,
        fillColor: "#fff",
        border: [false, true, false, true],
        margin: [0, 0, 0, 0],
        fillOpacity: 0,
      };
    }),
  );

  return template({
   defaultOptions,
    items: itemsFormatted,
    columns: columnsFormatted,
    columnsWidths,
  });
}
