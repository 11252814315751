import { useMongoAggregate } from "../../../../utils/mongoAggregate/useMongoAggregate";
import _ from "lodash";
import moment from "moment";

const date_field = {
  transactions: "date",
  invoices: "accounting_date",
  checks: "dates.issue",
};

const pipeline = ({ index, accumulated, dateFilter }) => {
  const date_filter = accumulated
    ? {
        ..._.omit(dateFilter, ["$gt"]),
        $gte: moment(dateFilter["$gte"]).format("YYYY"),
      }
    : dateFilter;
  return [
    {
      $match: {
        ["data." + date_field[index]]: date_filter,
        ...(accumulated ? {} : { "data.opening": { $ne: true } }),
      },
    },
    {
      $project: {
        _id: 0,
        opening: "$data.opening",
        values: "$data.accounts",
        ...(accumulated ? { date: "$data." + date_field[index] } : {}),
      },
    },
    {
      $unwind: "$values",
    },
    {
      $group: {
        _id: "$values.k",
        debe: {
          $sum: {
            $cond: [
              accumulated
                ? {
                    $and: [
                      {
                        $gt: ["$values.v", 0],
                      },
                      {
                        $gte: ["$date", dateFilter["$gte"]],
                      },
                      {
                        $ne: ["$opening", true],
                      },
                    ],
                  }
                : {
                    $gt: ["$values.v", 0],
                  },
              "$values.v",
              0,
            ],
          },
        },
        haber: {
          $sum: {
            $cond: [
              accumulated
                ? {
                    $and: [
                      {
                        $lt: ["$values.v", 0],
                      },
                      {
                        $gte: ["$date", dateFilter["$gte"]],
                      },
                      {
                        $ne: ["$opening", true],
                      },
                    ],
                  }
                : {
                    $lt: ["$values.v", 0],
                  },
              {
                $abs: "$values.v",
              },
              0,
            ],
          },
        },
        accumulated: { $sum: "$values.v" },
      },
    },
  ];
};

export default function useAccountsData({
  hideZeros = false,
  dateFilter,
  accumulated = false,
}) {
  const aggregate = [
    // { $match: { "data.objectID": "1-1-1-2-4" } },
    ...["transactions", "invoices", "checks"].map((index) => ({
      $lookup: {
        from: "p" + index,
        localField: "data.objectID",
        foreignField: "data.accounts.k",
        as: index,
        pipeline: pipeline({ index, accumulated, dateFilter }),
      },
    })),
    {
      $addFields: {
        values: {
          $filter: {
            input: {
              $concatArrays: ["$transactions", "$invoices", "$checks"],
            },
            as: "account",
            cond: {
              $eq: ["$$account._id", "$data.objectID"],
            },
          },
        },
      },
    },
    {
      $unwind: {
        path: "$values",
        preserveNullAndEmptyArrays: !hideZeros,
      },
    },
    {
      $group: {
        _id: "$data.objectID",
        debe: { $sum: "$values.debe" },
        haber: { $sum: "$values.haber" },
        accumulated: { $sum: "$values.accumulated" },
        name: { $first: "$data.name" },
        code: { $first: "$data.code" },
      },
    },
  ];

  const data = useMongoAggregate({
    index: "accounts",
    refetchOnWindowFocus: false,
    enabled: !!dateFilter?.["$lt"],
    aggregate,
  });

  return data;
}
