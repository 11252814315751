import {
  FieldValue,
  updateDoc,
  useDocumentData,
  useDocumentDataOnce,
} from "../../../firebase/database";
import { Box, Button, Container, Tooltip, Typography } from "@material-ui/core";
import EditIcon from "@mui/icons-material/Edit";
import useStyles from "./styles";

//Carousel
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";

//Grafico
import * as React from "react";
import { useState } from "react";

//stock
import classnames from "classnames";

//importar fotos carousel - eliminar
import InputUpload from "../../../components/Drive/UploadInput";
import DeleteIcon from "@mui/icons-material/Delete";
import CreateIcon from "@material-ui/icons/Create";
import KeyboardReturnIcon from "@material-ui/icons/KeyboardReturn";

//libreria para arrastrar y soltar las img
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import WrapperBoxes from "./editBoxes/WrapperBoxes";
import { EditableLabel } from "./EditableLabel/EditableLabel";
import { EDITABLE_FIELDS } from "./constants";

import { fieldFormatted } from "./helpers/fieldFormatter";
import { MarkdownEditor } from "./utils/MarkdownEditor";

import { ChangeCategory } from "./EditableLabel/ChangeCategory";
import { CategorysEditor } from "../../../utils/categories/categorysEditor";
import { Grid } from "@mui/material";
import { ChangeTags } from "./EditableLabel/ChangeTags";
import { useModal } from "../utils/useModal";
import _ from "lodash";

const reorder = (list, startIndex, endIndex) => {
  const result = [...list];
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

const ProductDescription = ({ objectID }) => {
  const classes = useStyles();
  const [productDoc] = useDocumentData("products", objectID);
  const [brand] = useDocumentDataOnce("brands", productDoc?.brand);
  const [openEditor, toggleVisibility] = useModal();

  const productImages = productDoc?.images;

  const removeImages = async (image) => {
    await updateDoc({
      indexName: "products",
      id: objectID,
      hit: {
        images: FieldValue.arrayRemove(image),
      },
    });
  };

  const addImages = async (image) => {
    await updateDoc({
      indexName: "products",
      id: objectID,
      hit: {
        images: FieldValue.arrayUnion(image),
      },
    });
  };

  const [viewEdit, setViewEdit] = useState(false);

  const onDragEnd = ({ source, destination }) => {
    if (
      destination &&
      !(
        source.index === destination.index &&
        source.droppableId === destination.droppableId
      )
    ) {
      updateDoc({
        indexName: "products",
        id: objectID,
        hit: {
          images: reorder(productImages, source.index, destination.index),
        },
      });
    }
  };

  return (
    <Container>
      <Box
        display={"flex"}
        alignItems="center"
        gap={10}
        marginLeft={0}
        className={classes.productDetail}
      >
        <Typography>
          <h2>{productDoc?.name}</h2>
        </Typography>
        <Box
          display={"flex"}
          flexDirection="column"
          padding={1}
          paddingRight={0}
        >
          <Box
            className={classnames({
              [classes.badge]: true,
              [classes.badgeNoStock]: !!productDoc?.nostock,
              [classes.badgeStock]: !productDoc?.nostock,
            })}
          >
            {productDoc?.nostock === true ? "SIN STOCK" : "EN STOCK"}
          </Box>
          {!productDoc?.active ? (
            <Box className={classnames([classes.badgeNoStock, classes.badge])}>
              No Activo
            </Box>
          ) : (
            <Box className={classnames([classes.badgeActive, classes.badge])}>
              Activo
            </Box>
          )}
        </Box>
      </Box>

      <Box className={classes.containerInfo}>
        <Box className={classes.containerImage}>
          {!viewEdit ? (
            <>
              <Tooltip title="Editar">
                <Button>
                  <CreateIcon onClick={() => setViewEdit(true)} />
                </Button>
              </Tooltip>

              <div>
                {productDoc?.images?.length ? (
                  <Carousel>
                    {productDoc?.images?.map((image, i) => (
                      <img
                        key={image}
                        src={image}
                        alt={`${i}`}
                        className={classes.image}
                      />
                    ))}
                  </Carousel>
                ) : (
                  !productDoc?.images && (
                    <img src={productDoc?.image} className={classes.image} />
                  )
                )}
              </div>
            </>
          ) : (
            <>
              <Tooltip title="Volver atrás">
                <Button>
                  <KeyboardReturnIcon onClick={() => setViewEdit(false)} />
                </Button>
              </Tooltip>

              <div
                style={{
                  display: "flex",
                  maxWidth: 300,
                  width: 300,
                  justifyContent: "center",
                }}
              >
                <DragDropContext onDragEnd={onDragEnd}>
                  <Droppable droppableId="droppable">
                    {(droppableProvided) => (
                      <ul
                        {...droppableProvided.droppableProps}
                        ref={droppableProvided.innerRef}
                        style={{ listStyle: "none" }}
                      >
                        {
                          <>
                            {productDoc?.images?.map((image, i) => (
                              <Draggable
                                key={i}
                                draggableId={i.toString()}
                                index={i}
                              >
                                {(draggableProvided) => (
                                  <div
                                    className={classes.containerImagesEdit}
                                    ref={draggableProvided.innerRef}
                                    {...draggableProvided.draggableProps}
                                    {...draggableProvided.dragHandleProps}
                                  >
                                    <li>
                                      <img
                                        src={image}
                                        alt={i}
                                        className={classes.imageEdit}
                                      />
                                    </li>
                                    <Tooltip title="Eliminar imagen">
                                      <Button
                                        onClick={() => removeImages(image)}
                                      >
                                        <DeleteIcon />
                                      </Button>
                                    </Tooltip>
                                  </div>
                                )}
                              </Draggable>
                            ))}
                          </>
                        }
                        {droppableProvided.placeholder}
                      </ul>
                    )}
                  </Droppable>
                </DragDropContext>
              </div>

              <div
                style={{
                  marginBottom: 15,
                  marginLeft: 10,
                  marginTop: productDoc?.images?.length ? "-20px" : "10px",
                }}
              >
                <InputUpload
                  accept={"image/*"}
                  uploadTo={"firebase"}
                  onUploaded={(e) => addImages(e)}
                  fullPath={`products/${objectID}/${generarIdAlfanumerico()}`}
                  title={"Agregar Imagen"}
                />
              </div>
            </>
          )}

          <Box display="flex" alignItems={"center"}>
            <Typography style={{ fontWeight: "bold" }}>EAN:</Typography>{" "}
            {productDoc?.EAN}
          </Box>
        </Box>

        <Box className={classes.description}>
          <Box style={{ display: "flex" }}>
            <Box>
              <MarkdownEditor
                indexName={"products"}
                defaultData={productDoc?.description}
                fieldToUpdate={"description"}
                idToUpdate={objectID}
              />

              <WrapperBoxes productDoc={productDoc} />
            </Box>
            <Box></Box>
          </Box>
          <Grid container gap={2}>
            {EDITABLE_FIELDS.map(
              ({
                label,
                field,
                isArray,
                type,
                secondField,
                labels,
                types,
                withComma,
              }) => {
                const actualValue = (f) => {
                  if (field === "brand") {
                    return brand?.name || "";
                  }

                  if (secondField) {
                    return productDoc?.[field]?.[secondField];
                  }

                  if (fieldFormatted(field)?.length) {
                    return f
                      ? productDoc?.[f]
                      : fieldFormatted(field)?.map((ff) => productDoc?.[ff]);
                  }

                  return productDoc?.[field];
                };

                const actualField = () => {
                  if (secondField) {
                    return [`${field}.${secondField}`];
                  }

                  if (fieldFormatted(field)?.length) {
                    return fieldFormatted(field);
                  }
                  return [field];
                };

                return (
                  <EditableLabel
                    key={field}
                    label={label}
                    isArray={isArray}
                    labels={labels}
                    brandName={brand?.name}
                    field={actualField()}
                    types={types}
                    type={type}
                    withComma={withComma}
                    actualValue={actualValue}
                    productID={productDoc?.objectID}
                  />
                );
              },
            )}
          </Grid>
          {/*  edit specs */}
          <Button
            sx={{ marginY: 1 }}
            onClick={toggleVisibility}
            variant={openEditor ? "outlined" : "contained"}
            color={openEditor ? "secondary" : "primary"}
            startIcon={openEditor ? null : <EditIcon />}
          >
            {openEditor ? "Cancelar" : "Categoría google"}
          </Button>
          <CategorysEditor
            openEditor={openEditor}
            showDefaults={!openEditor}
            defaultSpecs={productDoc?.googleProductCategory}
            productID={productDoc?.objectID}
            onSuccess={toggleVisibility}
          />
          <ChangeCategory
            productID={productDoc?.objectID}
            defaultSpecs={productDoc?.specs}
          />
          <ChangeTags
            productID={productDoc?.objectID}
            defaultTags={productDoc?.tags}
          />
        </Box>
      </Box>
    </Container>
  );
};

function generarIdAlfanumerico() {
  const caracteres =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  const longitud = 6;

  let id = "";
  for (let i = 0; i < longitud; i++) {
    const indiceAleatorio = _.random(0, caracteres.length - 1);
    id += caracteres[indiceAleatorio];
  }

  return id;
}
export default ProductDescription;
