import { useEffect, useContext } from "react";
import { ProviderContext } from "../context/providerContext";
import { SellerContext } from "../context/sellerContext";
import { useLocation } from "react-router-dom";
import { useSearchParams } from "../hooks/useSearchParams";
export default function ParamPersistance({ children }) {
  const { deleteParam, pushParams, setParam, getParam } = useSearchParams();
  const location = useLocation();
  const { sellerSelected } = useContext(SellerContext);
  const { providerSelected } = useContext(ProviderContext);

  useEffect(() => {
    if(location.pathname === '/providers/productsCheck'){
      deleteParam('providerCompany');
      pushParams();
    }

    if (providerSelected && location.pathname !== '/providers/productsCheck') {
      setParam("providerCompany", providerSelected.value);
      pushParams();
    }
    if (!sellerSelected && !getParam('seller')) {
      deleteParam("seller");
      return pushParams();
    }
    if (sellerSelected) {
      setParam("seller", sellerSelected);
      return pushParams();
    }
  }, [providerSelected, sellerSelected, location.pathname]);
  return <>{children}</>;
}
