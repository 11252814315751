import {
  Card,
  CardContent,
  Grid,
  Stack,
  Typography,
  Divider,
} from "@mui/material";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import CircularProgress from "@mui/material/CircularProgress";

export function SimpleCard({ color = "#767c88", header, content }) {
  return (
    <Card
      style={{
        height: "100%",
        display: "flex",
        overflow: "hidden",
        alignItems: "center",
        borderLeft: "6px solid #64748b",
      }}
      variant="outlined"
    >
      <CardContent
        style={{
          width: "100%",
          height: "100%",
          alignItems: "center",
        }}
      >
        <Typography
          sx={{ fontSize: 16, fontWeight: 700, color }}
          color="text.secondary"
          component="h4"
          gutterBottom
        >
          {header}
        </Typography>
        <Typography
          component="h3"
          sx={{
            fontSize: 20,
            marginBottom: 0,
            fontWeight: 700,
            color: "#1f2937",
          }}
          color="text.secondary"
        >
          {content}
        </Typography>
      </CardContent>
    </Card>
  );
}
export function LargeCard({
  state,
  subheading,
  header,
  unitsDeclaredData,
  unitsProcessedData,
  progressBarValue,
  progressColor,
}) {
  const fontColor =
    state === "warning"
      ? "#f97316"
      : state === "success"
      ? "#22c55e"
      : state === "danger"
      ? "#ef4444"
      : null;
  return (
    <Card
      style={{
        height: "100%",
        display: "flex",
        overflow: "hidden",
        alignItems: "center",
        borderLeft: `6px solid ${fontColor}`,
      }}
      variant="outlined"
    >
      <CardContent
        style={{
          width: "100%",
          height: "100%",
          alignItems: "center",
        }}
      >
        <Typography style={{ color: fontColor, fontSize: 16, fontWeight: 700 }}>
          {subheading}
        </Typography>
        <Typography component="h2" style={{ fontSize: "24", marginBottom: 12 }}>
          {header}
        </Typography>
        <Stack flexWrap="wrap" direction="row" alignItems="center" gap={4}>
          {unitsDeclaredData.map((unit) => (
            <Stack key={unit.header}>
              <Typography
                style={{ fontWeight: 600, color: unit.color, fontSize: 22 }}
              >
                {unit.header}
              </Typography>
              <Typography>{unit.subHeader}</Typography>
            </Stack>
          ))}
          <ArrowForwardIosIcon />
          {unitsProcessedData && (
            <Stack>
              <Typography style={{ fontWeight: 700, fontSize: 22 }}>
                {unitsProcessedData.reduce(
                  (partial, a) => partial + Number(a.value),
                  0,
                )}
              </Typography>
              <Typography>Procesadas</Typography>
            </Stack>
          )}
          <CircularProgress
            sx={{
              "&.MuiCircularProgress-root": {
                color: progressColor,
              },
            }}
            variant="determinate"
            thickness={5}
            // color={progressColor}
            size={64}
            value={progressBarValue}
          />
          {unitsProcessedData &&
            unitsProcessedData.map((unit) => (
              <Stack key={unit.header}>
                <Typography
                  style={{
                    fontWeight: 700,
                    color: unit.colorState,
                    fontSize: 18,
                  }}
                >
                  {unit.value} {unit.u}
                </Typography>
                <Typography>{unit.subHeader}</Typography>
              </Stack>
            ))}
        </Stack>
      </CardContent>
    </Card>
  );
}
export function ListCard({ list }) {
  return (
    <Card
      style={{
        height: "100%",
        display: "flex",
        alignItems: "center",
      }}
      variant="outlined"
      // sx={{ display: "flex" }}
      // style={{
      //   backgroundColor: "#f8f8f8",
      //   fontFamily: "system-ui",
      //   overflow: "hidden",
      //   borderRadius: 3,
      //   borderBottom: "none",
      //   boxShadow:
      //     "rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px",
      // }}
    >
      <CardContent
        style={{
          width: "100%",
          height: "100%",
          display: "flex",
          alignItems: "center",
        }}
      >
        <Stack direction="column" gap={1} style={{ width: "100%" }}>
          {list.map((el) => (
            <>
              {el.divider && <Divider />}
              <Stack
                style={{ width: "100%" }}
                direction="row"
                gap={4}
                justifyContent="space-between"
                key={el}
              >
                <Typography
                  sx={{ fontSize: 16, fontWeight: 700, color: "#767c88" }}
                  color="text.secondary"
                  gutterBottom
                >
                  {el.header}
                </Typography>
                <Typography
                  sx={{ fontSize: 16, fontWeight: 700, color: "#1f2937" }}
                  color="text.secondary"
                  gutterBottom
                >
                  {el.content}
                </Typography>
              </Stack>
            </>
          ))}
        </Stack>
      </CardContent>
    </Card>
  );
}
export function RowCard({ rows }) {
  return (
    <Card
      style={{
        width: "inherit",
        // height: "100%",
        display: "flex",
        alignItems: "center",
      }}
      variant="outlined"
    >
      <CardContent
        style={{
          width: "100%",
          height: "100%",
          display: "flex",
          alignItems: "center",
        }}
      >
        <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
          {rows.map((row) => (
            <Grid key={row} item xs={6}>
              <Typography
                sx={{ fontSize: 16, fontWeight: 700, color: "#767c88" }}
                color="text.secondary"
                gutterBottom
              >
                {row.header}
              </Typography>
              <Typography
                sx={{ fontSize: 16, fontWeight: 700, color: "#1f2937" }}
                color="text.secondary"
                gutterBottom
              >
                {row.content}
              </Typography>
            </Grid>
          ))}
        </Grid>
      </CardContent>
    </Card>
  );
}
