/* eslint-disable no-mixed-operators */
/* eslint-disable react/prop-types */

import { Box, Button, Chip, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import IconButton from "@mui/material/IconButton";
import RefreshIcon from "@mui/icons-material/Refresh";
import _ from "lodash";
import DriveFilePrintButton from "../../../../components/Drive/DrivePrintButton";
import {
  newDoc,
  updateDoc,
  useCollection,
} from "../../../../firebase/database";
import { MenuApp } from "./MenuApp";

export default function Facturar({ orderID, order }) {
  const [facturas, loading] = useCollection({
    collection: "invoices",
    where: [["order", "==", orderID]],
    limit: 10,
  });
  const [refreshDisabled, setRefresh] = useState(false);
  const [dataTypes, setDataTypes] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);
  const types = Object.values(order.items || {}).reduce(
    (p, a) => ({ ...p, [a.data?.conservation]: 1 }),
    {},
  );

  const formatTypes = (typ) => {
    const newTypes = typ;

    const a = _.pickBy(order.items, (v) =>
      [
        "xuabpCgck1CmbGRl6PGO",
        "Lyr51nVaNxVsMGpsLOkI",
        "zw5AKTsPxEj8HZk2r53f",
        "g1T8ooUaciaatbxPZqxq",
        "Psia1wxJdNS7gOL29phc",
        "4e9kkpcoRJDJjFqJgUSP",
        "S6Vc8GTWvxfjNPB6o7QS",
      ].includes(v.data?.brand),
    );
    if (Object.keys(a || {})?.length) {
      newTypes.cosmetica = 1;
    }
    return newTypes;
  };
  const TYPES = formatTypes(types);

  useEffect(() => {
    let active = true;
    setTimeout(() => active && setRefresh(false), 5000);
    // eslint-disable-next-line no-return-assign
    return () => (active = false);
  }, [refreshDisabled]);

  const hit = {
    order: orderID,
    user: order.user?.objectID,
    store: order.store?.objectID || "",
    cuit: order.store?.cuit || order.user?.cuit || 0,
  };

  const hits = Object.entries(dataTypes || {})
    .map(([, a]) => ({
      hit: {
        ...hit,
        itemsIds: Object.keys(
          _.pickBy(
            order.items,
            (v) =>
              (a.includes("seco") &&
                v.data.conservation === "seco" &&
                ![
                  "xuabpCgck1CmbGRl6PGO",
                  "Lyr51nVaNxVsMGpsLOkI",
                  "zw5AKTsPxEj8HZk2r53f",
                  "g1T8ooUaciaatbxPZqxq",
                  "Psia1wxJdNS7gOL29phc",
                  "4e9kkpcoRJDJjFqJgUSP",
                  "S6Vc8GTWvxfjNPB6o7QS",
                ].includes(v.data.brand)) ||
              (a.includes("frio") && v.data.conservation === "frio") ||
              (a.includes("congelado") &&
                v.data.conservation === "congelado") ||
              (a.includes("cosmetica") &&
                [
                  "xuabpCgck1CmbGRl6PGO",
                  "Lyr51nVaNxVsMGpsLOkI",
                  "zw5AKTsPxEj8HZk2r53f",
                  "g1T8ooUaciaatbxPZqxq",
                  "Psia1wxJdNS7gOL29phc",
                  "4e9kkpcoRJDJjFqJgUSP",
                  "S6Vc8GTWvxfjNPB6o7QS",
                ].includes(v.data.brand)),
          ),
        ),
        type: a.join(";"),
      },
    }))
    .filter((x) => x?.hit?.itemsIds?.length);

  function crearFactura() {
    hits.forEach((h) => {
      newDoc({ indexName: "invoices", ...h });
    });
  }

  return (
    <>
      {!loading &&
        !facturas.length &&
        !order.invoiceDriveId &&
        (["checked", "assembled", "inBoxes"].includes(order.assemblyState) ||
          order.priceList?.objectID === "3BIVlHNzrxvskViGBZNq") && (
          // !(order.store?.cuit || order.user?.cuit) ? 'FALTA CUIT O DNI' :
          <>
            <Button
              id="basic-button"
              disabled={!order.algoliaUpdated}
              aria-controls="basic-menu"
              aria-haspopup="true"
              aria-expanded="true"
              onClick={(e) =>
                ["undefined", "6V5yXRnYLXMz97MgJQBw"].includes(
                  order.shipping?.objectID,
                )
                  ? setAnchorEl(e.currentTarget)
                  : crearFactura()
              }
            >
              Crear Factura
            </Button>
            <MenuApp
              // eslint-disable-next-line react/jsx-no-bind
              action={crearFactura}
              setDataTypes={setDataTypes}
              types={TYPES}
              anchorEl={anchorEl}
              isVisible={!!anchorEl}
              toggleVisibily={() => setAnchorEl(null)}
              options={Object.keys(TYPES || {})}
            />
          </>
        )}
      {facturas?.map((x, index) => (
        <Box key={index} sx={{ display: "flex", alignItems: "center" }}>
          {facturas.length > 1 && (
            <Typography>{x?.type ? x?.type : `factura${index + 1}`}</Typography>
          )}
          {!x?.invoiceDriveId?.length &&
            !order.invoiceDriveId && x.algoliaUpdated && (
              <IconButton
                onClick={() => {
                  updateDoc("invoices", x?.objectID, x?.idContabilium ? {} : {retries:(x?.retries||0)+1});
                  setRefresh(true);
                }}
                disabled={refreshDisabled || !x.algoliaUpdated}
              >
                <RefreshIcon />
                {!x?.idContabilium && <Chip size="small" label="Reintentar" color="success" />}
              </IconButton>
            )}
          {x?.contabilium?.Id &&
            x?.contabilium?.Modo === "E" &&
            !x?.contabilium?.Cae && (
              <Button
                target="_blank"
                href={`https://app.contabilium.com/comprobantese.aspx?ID=${x?.contabilium?.Id}`}
              >
                Emitir Factura
              </Button>
            )}
          {x?.contabilium?.Id && (
            <Button
              target="_blank"
              href={`https://app.contabilium.com/cobranzase.aspx?IDComprobante=${x?.contabilium?.Id}`}
            >
              Cobrar
            </Button>
          )}
          {x?.invoiceDriveId?.length && (
            <DriveFilePrintButton driveFileId={x?.invoiceDriveId} />
          )}
          {!(!loading && !facturas?.length && order.store?.cuit) &&
            !order.invoiceDriveId &&
            !facturas?.filter((f) => f.invoiceDriveId || f.algoliaUpdated)?.length && (
              <Chip size="small" label="Pendiente" color="error" />
            )}
        </Box>
      ))}
      {order.invoiceDriveId && !facturas?.[0]?.invoiceDriveId?.length && (
        <DriveFilePrintButton driveFileId={order?.invoiceDriveId} />
      )}
    </>
  );
}
