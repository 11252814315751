import moment from "moment";
import React from "react";

const momentConverter = (startDateTimestamp, endDateTimestamp) => {
  const evaluateTime = (time) =>
    time > 60
      ? moment(endDateTimestamp * 1000).diff(
          moment(startDateTimestamp * 1000),
          "hours"
        )
      : moment(endDateTimestamp * 1000).diff(
          moment(startDateTimestamp * 1000),
          "minutes"
        );

  const evaluateWord = () => {
    if (
      moment(endDateTimestamp * 1000).diff(
        moment(startDateTimestamp * 1000),
        "minutes"
      ) > 60
    ) {
      return "horas";
    }
    if (
      moment(endDateTimestamp * 1000).diff(
        moment(startDateTimestamp * 1000),
        "minutes"
      ) === 60
    ) {
      return "hora";
    }
    if (
      moment(endDateTimestamp * 1000).diff(
        moment(startDateTimestamp * 1000),
        "minutes"
      ) === 1
    ) {
      return "minuto";
    }
    if (
      moment(endDateTimestamp * 1000).diff(
        moment(startDateTimestamp * 1000),
        "minutes"
      ) < 1
    ) {
      return "";
    }

    return "minutos";
  };

  return (
    <>
      {startDateTimestamp &&
        `Comenzó el : ${moment(startDateTimestamp * 1000).format(
          "DD/MM/YYYY"
        )} a las: ${moment(startDateTimestamp * 1000).format("HH:mm")}`}
      {moment(endDateTimestamp * 1000).diff(
        moment(startDateTimestamp * 1000),
        "minutes"
      ) >= 1 &&
        `se tardó : ${evaluateTime(
          moment(endDateTimestamp * 1000).diff(
            moment(startDateTimestamp * 1000),
            "minutes"
          )
        )} ${evaluateWord()}`}
    </>
  );
};

export default momentConverter;
