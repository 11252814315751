/* eslint-disable react/prop-types */
import { ajax } from "can";
import printJS from "print-js";
import React, { useState } from "react";
import { CircularProgress, IconButton } from "@mui/material";
import PrintIcon from "@mui/icons-material/Print";

export default function DriveFilePrintButton({ driveFileId }) {
  const [loading, setLoading] = useState(false);

  const handleClick = async (e) => {
    e.stopPropagation();
    setLoading(true);

    await ajax({
      crossDomain: true,
      url: `https://script.google.com/macros/s/AKfycbyHDAvdN_yK5Ol-e13XVLzeOmMtQDncU1DFz3cYKwjROXr3KOS_d1e17bJr0ZW_3FK3/exec?id=${driveFileId}`,
      type: "GET",
    }).then(({ base64 }) =>
      printJS({ printable: base64, type: "pdf", base64: true })
    );

    setLoading(false);
  };

  return (
    <>
      <IconButton onClick={handleClick} aria-label="print" disabled={loading}>
        <PrintIcon />
      </IconButton>
      {loading && <CircularProgress />}
    </>
  );
}
