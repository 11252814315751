import { useUserData } from "../../context/UserContext";
import AccessDeniedPage from "../../components/AccessDeniedPage";

import { BanksProvider } from "./BanksContext";
import Consolidations from "./consolidations";

export default function Banks() {
  const { areas } = useUserData();

  return (
    <>
      {areas.includes("contabilidad") ? (
        <>
          <BanksProvider>
            <Consolidations />
          </BanksProvider>
        </>
      ) : (
        <AccessDeniedPage go_home={true} />
      )}
    </>
  );
}
