import React from "react";

export const useEvent = (callback) => {
  const ref = React.useRef(callback);

  React.useEffect(() => {
    ref.current = callback;
  }, [callback]);

  return React.useCallback((...args) => ref.current(...args), []);
};
