import { formatCountryCurrency } from "../../../../utils/formatCountryCurrency";
import { ProductCard } from "./ProductCard";
import { CardsSkeleton } from "../CardsSkeleton";
import imgurWebp from "../../../../components/Utils/Imgur/imgurWebp";
import { calculateWeight } from "../../utils/calculateWeight";
import { VirtualList } from "../VirtualList";
import { useMediaQuery } from "@mui/material";
import { useSearchParams } from "../../hooks/useSearchParams";
import Observer from "../Observer";
import { useEffect } from "react";
import { DEFAULT_LIMIT } from "../../sections/Products";
const ProductsList = ({ data, isLoading }) => {
  const { getParam, deleteParam, setParam, pushParams } = useSearchParams();
  let limitQuery = Number(getParam("limit"));
  let totalQuery = Number(getParam("total"));
  useEffect(() => {
    deleteParam("limit");
    pushParams();
  }, []);

  const DEFAULT_IVA = 0.21;
  const matches = useMediaQuery("(min-width:695px)");
  const observerCallback = () => {
    const nextLimit = limitQuery ? limitQuery + 100 : DEFAULT_LIMIT + 100;
    if (nextLimit > totalQuery) {
      setParam("limit", totalQuery);
      return pushParams();
    }
    if (nextLimit === totalQuery) return;

    setParam("limit", nextLimit);
    return pushParams();
  };
  return (
    <>
      {isLoading && <CardsSkeleton />}
      <VirtualList estimateSize={510} data={data}>
        {(
          {
            sellerCode,
            onbin,
            taste,
            active,
            grossWeight,
            available,
            brandName,
            name,
            image,
            quantity,
            unit,
            specs_data,
            pricelists_data,
            iva,
            price,
            purchasePrice,
          },
          index,
        ) => {
          const ivaPercentage =
            (iva ? iva * 100 : DEFAULT_IVA * 100).toString() + "%";
          return (
            <div>
              {index === data.length - 1 ? (
                <Observer
                  options={{ root: null, rootMargin: "0px", threshold: 0.4 }}
                  callback={observerCallback}
                >
                  <ProductCard
                    mediaQuery={matches}
                    onbin={onbin}
                    prices={
                      price
                        ? [
                            purchasePrice && {
                              title: "Compra",
                              value:
                                formatCountryCurrency(
                                  iva ? purchasePrice : purchasePrice,
                                ) +
                                (iva && iva !== DEFAULT_IVA
                                  ? ` + ${ivaPercentage} IVA`
                                  : ""),
                            },
                            ...price?.map((p) => {
                              const priceListData = pricelists_data
                                ?.map((pricelist) => pricelist.data)
                                ?.find(
                                  (pricelistData) =>
                                    pricelistData.objectID === p.k,
                                );
                              return {
                                title: priceListData.name,
                                value:
                                  formatCountryCurrency(iva ? p.v : p.v) +
                                  (iva && iva !== DEFAULT_IVA
                                    ? ` + ${ivaPercentage} IVA`
                                    : ""),
                              };
                            }),
                          ]
                        : []
                    }
                    iva={
                      iva
                        ? ivaPercentage
                        : price && price?.length <= 0
                        ? ""
                        : ivaPercentage
                    }
                    brandName={brandName}
                    unit={unit}
                    weight={grossWeight && calculateWeight(grossWeight)}
                    specs={specs_data.map((spec) => spec.data)}
                    active={active}
                    sellerCode={sellerCode}
                    imageUrl={imgurWebp(image)}
                    taste={taste}
                    available={available !== 0 ? available : null}
                    name={name}
                    quantity={quantity}
                  />
                </Observer>
              ) : (
                <ProductCard
                  mediaQuery={matches}
                  onbin={onbin}
                  prices={
                    price
                      ? [
                          purchasePrice && {
                            title: "Compra",
                            value:
                              formatCountryCurrency(
                                iva ? purchasePrice : purchasePrice,
                              ) +
                              (iva && iva !== DEFAULT_IVA
                                ? ` + ${ivaPercentage} IVA`
                                : ""),
                          },
                          ...price?.map((p) => {
                            const priceListData = pricelists_data
                              ?.map((pricelist) => pricelist.data)
                              ?.find(
                                (pricelistData) =>
                                  pricelistData.objectID === p.k,
                              );
                            return {
                              title: priceListData.name,
                              value:
                                formatCountryCurrency(iva ? p.v : p.v) +
                                (iva && iva !== DEFAULT_IVA
                                  ? ` + ${ivaPercentage} IVA`
                                  : ""),
                            };
                          }),
                        ]
                      : []
                  }
                  iva={
                    iva
                      ? ivaPercentage
                      : price && price?.length <= 0
                      ? ""
                      : ivaPercentage
                  }
                  brandName={brandName}
                  unit={unit}
                  weight={grossWeight && calculateWeight(grossWeight)}
                  specs={specs_data.map((spec) => spec.data)}
                  active={active}
                  sellerCode={sellerCode}
                  imageUrl={imgurWebp(image)}
                  taste={taste}
                  available={available !== 0 ? available : null}
                  name={name}
                  quantity={quantity}
                />
              )}
            </div>
          );
        }}
      </VirtualList>
    </>
  );
};

export default ProductsList;
