/* eslint-disable react/prop-types */
import React, { useEffect, useRef, useState } from "react";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faTimes } from "@fortawesome/free-solid-svg-icons";
import { useGoogleMapsApi } from "react-hook-google-maps";
import pinIcon from "./pin.svg";
import useStyles from "./styles";

export default function ConfirmNewAddress({ address, confirmAddress, cancel }) {
  const [details, setDetails] = useState(address.details || "");
  const classes = useStyles();
  const mapRef = useRef(null);
  const google = useGoogleMapsApi(
    // eslint-disable-next-line no-undef
    `${process.env.REACT_APP_MAPS_API}&libraries=places,geometry,drawings`,
  );

  useEffect(() => setDetails(address.details || ""), [address.details]);

  useEffect(() => {
    if (google) {
      const map = new google.maps.Map(mapRef.current, {
        center: { lat: -34.7833, lng: -58.4 }, // center {lat: -34.7729, lng: -58.3984},
        zoom: 1,
        mapTypeId: "roadmap",
      });

      const icon = {
        url: pinIcon,
        size: new google.maps.Size(71, 71),
        origin: new google.maps.Point(0, 0),
        anchor: new google.maps.Point(17.5, 35),
        scaledSize: new google.maps.Size(35, 35),
      };

      const markers = [];
      // Create a marker for each place.
      markers.push(
        new google.maps.Marker({
          map,
          icon,
          draggable: false,
          animation: google.maps.Animation.DROP,
          title: address.name,
          position: address.geometry.location,
        }),
      );
      const bounds = new google.maps.LatLngBounds();

      bounds.extend(address.geometry.location);

      map.fitBounds(bounds);

      // const searchBox = new window.google.maps.places.Autocomplete(
      //     document.getElementById('inpMap'), {
      //         componentRestrictions: {country: 'ar'}
      //     });
      //
      // searchBox.addListener('place_changed', function() {
      //
      //
      //     const place = searchBox.getPlace();
      //     const newAddress = JSON.parse(JSON.stringify(place));
      //     console.log(newAddress);
      //     setInpMapValue(newAddress.formatted_address);
      // })
    }
  }, [address, google]);

  return (
    <>
      {/* {GoogleMap} */}
      <div className={classes.map}>
        <div ref={mapRef} className={classes.googleMap} />
      </div>
      <div className={classes.address}>{address.formatted_address}</div>
      <TextField
        multiline
        label="Detalles"
        value={details}
        onChange={(e) => setDetails(e.target.value)}
        className={classes.detailsField}
        fullWidth
        onKeyPress={(e) => e.key === "Enter" && e.target.blur()}
        helperText="Piso, Depto, Local o Cualquier informacion que nos ayude a encontrar la dirección"
        autoFocus
        rowsMax="4"
        variant="outlined"
      />
      <div className={classes.confirmButton}>
        <Button
          startIcon={<FontAwesomeIcon icon={faTimes} />}
          onClick={cancel}
          variant="contained"
        >
          Cancelar
        </Button>
        <Button
          startIcon={<FontAwesomeIcon icon={faCheck} />}
          onClick={() => confirmAddress(details)}
          variant="contained"
          color="secondary"
        >
          Confirmar
        </Button>
      </div>
    </>
  );
}
