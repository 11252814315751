/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable no-restricted-globals */

import React, { useMemo } from "react";
import { Route, Switch } from "react-router-dom";
import classnames from "classnames";
import Tables from "../tables";
import OrdenesKoco from "./orders/ordenesKoco";
import ProductPage from "./products/ProductPage";
import Header from "../../components/Header";
import Sidebar from "../../components/Sidebar";
import useStyles from "./styles";
import { useLayoutState } from "../../context/LayoutContext";
import Cards from "./cards";
import AreasCard from "../areas/Card/AreasCard";
import PurchaseOrderCard from "./purchaseOrders";
import SpecsPage from "./specs/specsPage";
import { Box, Stack } from "@mui/material";

const cards = {
  orders: OrdenesKoco,
  products: ProductPage,
  // brands: BrandPage,
  // users: PageUsers,
  areas: AreasCard,
  purchaseOrders: PurchaseOrderCard,
  specs: SpecsPage,
};

export default function AppLayout() {
  const classes = useStyles();
  const layoutState = useLayoutState();

  const s = useMemo(() => <Switches />, []);

  return (
    <div
      className={classes.root}
      style={{ width: "100%", padding: 0, margin: 0 }}
    >
      <Header history={history} />
      <Sidebar />
      <div
        className={classnames(classes.content, {
          [classes.contentShift]: layoutState.isSidebarOpened,
        })}
      >
        <Stack direction="row" minWidth="0" gap={2}>
          <Box
            sx={{
              minWidth: layoutState.isSidebarOpened ? "240px" : "85px",
              width: layoutState.isSidebarOpened ? "240px" : "85px",
              height: "100vh",
              backgroundColor: "transparent",
            }}
            display={{ xs: "none", sm: "none", md: "block" }}
          ></Box>
          {s}
        </Stack>
      </div>
    </div>
  );
}

function Switches() {
  return (
    <Switch>
      <Route
        path="/app/:indexName"
        exact
        component={({ match }) => <Tables index={match?.params?.indexName} />}
      />
      <Route
        path="/app/:indexName/:objectID"
        exact
        render={({ match }) => (
          <>
            {cards[match?.params?.indexName] &&
              React.createElement(cards[match?.params?.indexName], {
                objectID: match?.params?.objectID,
              })}
            <Cards
              objectID={match?.params?.objectID}
              indexName={match?.params?.indexName}
            />
          </>
        )}
      />
    </Switch>
  );
}
