import React from "react";
import { makeStyles } from "@material-ui/styles";
import { Checkbox, FormControlLabel, FormGroup, Box } from "@mui/material";
import _ from "lodash";

const sorts = [
  {
    label: "VTO mas próximo",
    field: "expirationdate",
    value: 1,
  },
  {
    label: "Para Reponer en Picking",
    field: "commitedPickingPercentage",
    value: -1,
  },
];

export function CheckboxFilters({
  filters,
  data,
  setFacetFilters,
  facetFilters,
  sort,
  setSort,
}) {
  const classes = useStyles();

  return (
    <Box style={{ margin: "0 auto", zIndex: 1 }}>
      <Box className={classes.fixedBox}>
        <Box className={classes.checkboxBox}>
          <p
            style={{
              fontWeight: "bold",
              margin: 5,
              marginTop: 8,
              fontSize: "15px",
            }}
          >
            Orden
          </p>
          <FormGroup sx={{ gap: 2 }}>
            {sorts.map(({ label, field, value }) => (
              <FormControlLabel
                key={label}
                className={classes.checkboxContent}
                sx={{
                  color: "#536dfe",
                  "&.Mui-checked": {
                    color: "#536dfe",
                  },
                }}
                control={
                  <Checkbox
                    checked={sort[field] === value}
                    onClick={() =>
                      setSort((ff) =>
                        ff[field] === value
                          ? {}
                          : {
                              [field]: value,
                            },
                      )
                    }
                  />
                }
                label={
                  <Box className={classes.label}>
                    <span style={{ color: "#7C7C7C", display: "inline" }}>
                      {label}
                    </span>
                  </Box>
                }
              />
            ))}
          </FormGroup>
        </Box>
        {filters.map((f) => (
          <Box className={classes.checkboxBox} key={f.field}>
            <p
              style={{
                fontWeight: "bold",
                margin: 5,
                marginTop: 8,
                fontSize: "15px",
              }}
            >
              {f.label}
            </p>
            <FormGroup sx={{ gap: 2 }}>
              {Object.entries(f.options)
                .filter(([, value]) => data?.facets?.[f.field]?.[String(value)])
                .map(([text, value]) => (
                  <FormControlLabel
                    key={text}
                    sx={{
                      color: "#536dfe",
                      "&.Mui-checked": {
                        color: "#536dfe",
                      },
                    }}
                    className={classes.checkboxContent}
                    control={
                      <Checkbox
                        checked={facetFilters[f.field] === value}
                        onClick={() =>
                          setFacetFilters((ff) =>
                            _.pickBy(
                              {
                                ...ff,
                                [f.field]: ff[f.field] === value ? "" : value,
                              },
                              (a) => a !== "",
                            ),
                          )
                        }
                      />
                    }
                    label={
                      <Box className={classes.label}>
                        <span style={{ color: "#7C7C7C", display: "inline" }}>
                          {text}
                        </span>
                        <Box className={classes.filtersBadge}>
                          {data.facets?.[f.field]?.[String(value)]}
                        </Box>
                      </Box>
                    }
                  />
                ))}
            </FormGroup>
          </Box>
        ))}
      </Box>
    </Box>
  );
}

const useStyles = makeStyles((theme) => ({
  fixedBox: {
    margin: "60 0 0 10",
    padding: "25px",
    background: "#FAFAFA",
    borderRadius: 5,
    position: "fixed",
    left: 0,
    boxShadow: "1px 1px 1px 1px rgba(0,0,0,0.1)",
    [theme.breakpoints.down(1110)]: {
      display: "flex",
      position: "relative",
      margin: "0 auto",
      marginTop: "50px",
      justifyContent: "center",
      alignItems: "center",
    },
    [theme.breakpoints.down(663)]: {
      display: "block",
      position: "relative",
    },
  },
  checkboxBox: {
    display: "flex",
    flexDirection: "column",
    gap: 20,
    [theme.breakpoints.down(1110)]: {
      margin: "0 auto",
    },
  },
  checkboxContent: {
    display: "flex",
    gap: 8,
    alignItems: "center",
  },
  filtersBadge: {
    background: "#EDF0FF",
    padding: 2,
    borderRadius: 5,
    marginLeft: 10,
    width: "fit-content",
    fontSize: ".7rem",
  },
  label: {
    display: "flex",
  },
}));
