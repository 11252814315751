import { makeStyles } from "@material-ui/styles";

export default makeStyles((theme) => ({
  containerDataGrid: {
    background: "#fff",
  },
  containerDataGridActive: {
    background: "#b9f6ca",
  },
  containerDataGridNoActive: {
    background: "#ffccbc",
  },

  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    height: 56,
    background: "#fff",
    border: "1px solid #ccc",
    borderRadius: 5,
    padding: "16.5px 14px",
  },
  title: {
    fontWeight: 600,
    textAlign: "center",
    flex: 1,
    fontSize: "21px",
    marginBottom: "15px",
  },
  container: {
    background: "#fff",
    padding: "20px",
    boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
  },
  TypographyPrice: {
    display: "inline-block",
    marginBottom: "5px",
    textDecoration: "underline",
  },
  thypographyBrandName: {
    fontSize: 21,
    fontWeight: "bold",
    textDecorationLine: "underline",
    marginRight: 5,
  },
  rowActive: {
    background: "#b9f6ca",
    "&:hover": {
      backgroundColor: "rgba(185,246,202,0.4)!important",
    },
  },
  rowInActive: {
    background: "#ffcdd2",
    "&:hover": {
      background: "rgba(255,205,210,0.4)!important",
    },
  },
  orangeRow: {
    background: "#FCDC9D",
    "&:hover": {
      background: "rgba(252,220,157,0.4)!important",
    },
  },
  containerCuit: {
    border: "1px solid #ccc",
    borderRadius: "10px",
    padding: "10px",
    width: "50%",
    display: "flex",
    justifyContent: "space-around",
    textAlign: "center",
    height: "max-content",
  },
  cuit: {
    fontWeight: 600,
    display: "block",
    paddingBottom: 3,
  },
  buttonPDF: {
    cursor: "pointer",
    color: "#4fc3f7",
    fontWeight: 600,
    textTransform: "capitalize",
  },
  containerTextArea: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
  },
  buttonModificarDatos: {
    display: "flex",
    margin: "0 auto",
    color: "rgb(79, 195, 247)",
    border: "1px solid",
    marginTop: 5,
    fontWeight: 600,
  },
}));
