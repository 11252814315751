import { Box, Button } from "@material-ui/core";
import { updateDoc } from "../../../firebase/database";
import useStyles from "./styles";
import _ from "lodash";
import { useState } from "react";

const PasteTextContent = ({ products, id }) => {
  const classes = useStyles();
  const [data, setData] = useState("");

  const changeTextArea = (e) => setData(e.target.value);

  //MODIFICAR DATOS TABLA
  const modificarDatos = () => {
    const result = _.chain(data)
      .split("\n")
      .map((v) =>
        _.chain(v)
          .split("\t")
          .map((c, i) => (i === 1 ? Number(c) : _.trim(c)))
          .value()
      )
      .value();

    const ids = products.map((x) => x.sellerCode);
    const products_coincidences = result.map((subArray) =>
      ids.some((sellerCode) => sellerCode === subArray[0])
    );

    const arrCantidad = result.map((num) => num[1]);

    const obj = result.reduce((acc, key, index) => {
      const product = products?.find((x) => x.sellerCode === key[0]);

      acc[product?.objectID] = {
        ["q"]: arrCantidad[index],
        ["purchasePrice"]: product?.purchasePrice ?? 10000000,
        ["iva"]: product?.iva ?? 0.21,
        ["title"]: `${product?.brandName} ${product?.name} - ${
          product?.taste
        } ${product?.quantity ? product?.quantity + product?.unit : ""}`.trim(),
      };
      return acc;
    }, {});

    products_coincidences &&
      updateDoc({
        indexName: "purchaseOrders",
        id: id,
        hit: {
          ["items"]: { ...obj },
        },
      });
  };

  return (
    <Box className={classes.containerTextArea}>
      <Box>
        <div>
          <textarea
            style={{ width: "390px" }}
            rows="15"
            onChange={changeTextArea}
          ></textarea>
          <Button
            className={classes.buttonModificarDatos}
            onClick={modificarDatos}
          >
            Modificar datos
          </Button>
        </div>
      </Box>
    </Box>
  );
};

export default PasteTextContent;
