import { Box, Skeleton, Stack } from "@mui/material";

export function OrderShipmentSkeleton() {
  return (
    <Stack
      style={{ padding: 24, flexGrow: 1 }}
      component="main"
      sx={{ backgroundColor: "#f8fafc" }}
    >
      <Skeleton variant="text" height="40px" width={200} />
      <Stack
        flexWrap="wrap"
        direction="row"
        gap={2}
        marginTop={2}
        marginBottom={4}
      >
        <Skeleton
          animation="wave"
          variant="rectangular"
          height={220}
          width="51%"
        />
        <Skeleton
          animation="wave"
          variant="rectangular"
          height={220}
          width="30%"
        />
        <Skeleton
          animation="wave"
          variant="rectangular"
          height={220}
          width="30%"
        />
      </Stack>
      <Skeleton variant="text" height="40px" width={200} />
      <Stack direction="column" marginTop={2}>
        <Skeleton variant="rectangular" height={180} width="100%" />
        <Stack flexWrap="wrap" direction="row" gap={2} marginTop={2}>
          <Skeleton
            animation="wave"
            variant="rectangular"
            height={100}
            width="200px"
          />
          <Skeleton
            animation="wave"
            variant="rectangular"
            height={100}
            width="200px"
          />
          <Skeleton
            animation="wave"
            variant="rectangular"
            height={100}
            width="200px"
          />
        </Stack>
      </Stack>
      <Box marginTop={4}>
        <Skeleton
          animation="wave"
          variant="rectangular"
          height={420}
          width="100%"
        />
      </Box>
    </Stack>
  );
}
