import _ from "lodash";

const extractCategories = (data) => {
  const allCaterogiesFlat = data
    .map((product) => product.specs_data)
    .map((specs) => specs.map((spec) => spec.data))
    .flat();
  return _.uniqBy(allCaterogiesFlat, (spec) => spec.objectID).map((flat) => ({
    param: "spec",
    length: allCaterogiesFlat.filter((e) => flat.name === e.name).length,
    label: flat.name,
    value: flat.objectID,
  }));
};
const extractBrands = (data) => {
  const allBrandsFlat = data.map((product) => product.brandName).flat();
  return _.uniq(allBrandsFlat).map((data) => ({
    length: allBrandsFlat.filter((e) => data === e).length,
    param: "brandName",
    label: data,
    value: data,
  }));
};
const extractActive = (data) => {
  const allActiveFlat = data.map((product) => Boolean(product.active)).flat();

  const result = _.uniq(allActiveFlat).map((data) => ({
    length: allActiveFlat.filter((e) => data === e).length,
    param: "active",
    label: data ? "Activo" : "Desactivado",
    value: data ? "active" : "inactive",
  }));
  if (!result.find((val) => val.value === "active"))
    result.push({
      length: "0",
      param: "active",
      label: "Activo",
      value: "active",
    });
  if (!result.find((val) => val.value === "inactive"))
    result.push({
      length: "0",
      param: "active",
      label: "Desactivado",
      value: "inactive",
    });
  return result;
};
const extractAvailable = (data) => {
  const allAvailableFlat = data
    .map((product) => (Number(product.available) > 0 ? true : false))
    .flat();
  const result = _.uniq(allAvailableFlat).map((data) => ({
    length: allAvailableFlat.filter((e) => data === e).length,
    param: "available",
    label: data ? "Disponible" : "No disponible",
    value: data ? "available" : "unavailable",
  }));
  if (!result.find((val) => val.value === "available"))
    result.push({
      length: "0",
      param: "available",
      label: "Disponible",
      value: "available",
    });
  if (!result.find((val) => val.value === "unavailable"))
    result.push({
      length: "0",
      param: "available",
      label: "No disponible",
      value: "unavailable",
    });
  return result;
};

const extractSeller = (data) => {
  const allSellerFlat = data.map((product) => product.seller).flat();
  return _.uniq(allSellerFlat).map((data) => ({
    length: allSellerFlat.filter((e) => data === e).length,
    param: "seller",
    label: data,
    value: data,
  }));
};

export {
  extractSeller,
  extractActive,
  extractAvailable,
  extractBrands,
  extractCategories,
};
