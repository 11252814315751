import {
  Avatar,
  Badge,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import LocalShippingIcon from "@mui/icons-material/LocalShipping";
import { Sf } from "../../../../../components/Widget/SnowFlakes";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBox } from "@fortawesome/free-solid-svg-icons";
import moment from "moment";
import { styled } from "@material-ui/styles";
import { useMongoAggregate } from "../../../../../utils/mongoAggregate/useMongoAggregate";

export const ListPickedInfo = ({ order }) => {
  const shippingBoxes = order?.shippingBoxes;

  const shippingBoxesFormatted = Object.keys(order?.shippingBoxes || {}).sort(
    (a, b) => a.replace(/[^0-9.]/g, "") - b.replace(/[^0-9.]/g, ""),
  );

  const pickers = Object.values(shippingBoxes || {})?.map(
    (ship) => ship?.inTruck?.by,
  );

  const [data] = useMongoAggregate({
    index: "users",
    aggregate: [
      {
        $match: { "data.objectID": { $in: pickers } },
      },
      {
        $project: {
          name: "$data.name",
          image: "$data.image",
          objectID: "$data.objectID",
        },
      },
    ],
  });

  const users = data?.reduce((p, a) => ({ ...p, [a?.objectID]: a }), {});

  return (
    <List
      dense
      sx={{
        width: "100%",
        bgcolor: "background.paper",
        maxHeight: 300,
        overflowY: "auto",
      }}
    >
      {(shippingBoxesFormatted || [])?.map((box, index) => {
        const conservation =
          box.charAt(0) === "C" ? 2 : box.charAt(0) === "F" ? 1 : 0;

        const boxQ = shippingBoxes[box]?.q;
        const t = shippingBoxes[box]?.inTruck?.t
        const inTruckTimestamp = `${moment(t * 1000,
        ).format("DD/MM hh:mm")} ${moment().isSame(t * 1000,'year') ? '' : moment(t * 1000)?.format('YYYY')}`

        const loadedOnTruck = users?.[shippingBoxes[box].inTruck?.by];

        return (
          <ListItem key={index} disablePadding>
            <ListItemButton>
              <ListItemText
                id={box}
                primary={
                  <Stack direction={"row"} spacing={1} alignItems={"center"}>
                    <StyledBadge
                      anchorOrigin={{
                        vertical: "top",
                        horizontal: "left",
                      }}
                      badgeContent={boxQ}
                      color="primary"
                    >
                      <FontAwesomeIcon
                        style={{ marginRight: 2, fontSize: 20 }}
                        icon={faBox}
                      />
                    </StyledBadge>
                    <Typography>{`${box}`}</Typography>
                    <Sf q={conservation} />
                  </Stack>
                }
              />
              <Tooltip title={`Subido al camion por: ${loadedOnTruck?.name}`}>
                <ListItemText>
                  <Stack direction={"row"} spacing={1} alignItems={"center"}>
                    <Stack direction={"row"} spacing={1} alignItems={"center"}>
                      <Avatar
                        sx={{ width: 20, height: 20 }}
                        src={loadedOnTruck?.image}
                        alt={`${loadedOnTruck?.name}`}
                      />
                      <LocalShippingIcon style={{ fontSize: 22 }} />
                    </Stack>
                    <Typography sx={{ fontSize: 14 }}>
                      {inTruckTimestamp}
                    </Typography>
                  </Stack>
                </ListItemText>
              </Tooltip>
            </ListItemButton>
          </ListItem>
        );
      })}
    </List>
  );
};

const StyledBadge = styled(Badge)(({ theme }) => ({
  "& .MuiBadge-badge": {
    right: 5,
    border: `2px solid ${theme.palette.background.paper}`,
  },
}));
