import { AvatarGroup, Stack } from "@mui/material";
import { MainLayout } from "../../layouts/MainLayout";
import { Avatar, Chip, Typography } from "@material-ui/core";
import Button from "@mui/material/Button";
import Table from "../../../../components/Table/Table";
import { memo } from "react";
import moment from "moment";
import { NavLink } from "react-router-dom";
import { useBrandsObj, useProductsObj } from "../../hooks/useProductsObj";
export const fixDiscount = (discount) => Number(discount) > 0.9 ? Number(discount) * 0.01 : Number(discount)
export const getDiscountAsPercentage = (discount) => discount* 100
// Algunos descuentos vienen enteros y no decimales, la prop autofix detecta eso y lo corrige
export const DiscountMemoized = memo(function Items ({discount, autoFix = true}){
    const discountFixed = autoFix && fixDiscount(discount)
    let discountPercentage = discount && !isNaN(Number(discount)) && getDiscountAsPercentage(discountFixed || discount)

    return <>
        {discountPercentage &&
        <Chip color="primary" variant='default' size="small" label={`${discountPercentage}%`} />
        }
    </>
})
const ProductsMemoized = memo(function productsList ({items, itemsObj}){
          return <>
          {items && <AvatarGroup total={5}>
            {items?.map(item => {
              return <Avatar
                  key={itemsObj[item]?.id}
                  alt={itemsObj[item]?.description || itemsObj[item]?.name}
                  style={{ width: 32, height: 32 }}
                  src={itemsObj[item]?.image} />

            })}
          </AvatarGroup>
        }
</>
})
export default function Campaigns(){
  const [productsObj] = useProductsObj();
  const [brandsObj] = useBrandsObj();

    const CAMPAIGNS_COLUMNS = [
    {
      field: "name",
      headerName: "Nombre",
      flex: 1,
    },
    {
      field: "brand",
      flex: 1,
      headerName: "Marca",
      options: {
        quickFilter: true,
      },
      renderCell: (data) => {
        return brandsObj[data.row.brand]
      }
    },
    {
      flex: 1,
      field: "start_timestamp",
      headerName: "Fecha de comienzo",
      renderCell: (data) => {
        return <>{moment(data?.row?.start_timestamp * 1000).format(
          "DD/MM hh:mm",
        )}</>
      }
    },
    {
      flex: 1,
      field: "end_timestamp",
      headerName: "Fecha de culminación",
      renderCell: (data) => {
        return <>{moment(data?.row?.end_timestamp * 1000).format(
          "DD/MM hh:mm",
        )}</>
      }
    },
    {
      flex: 1,
      field: "items",
      headerName: "Productos",
      renderCell: (data) => <ProductsMemoized items={data.row.items} itemsObj={productsObj}/>
    },
    {
      flex: 1,
      field: "discount",
      headerName: "Descuento",
      options: {},
      renderCell: (data) => <DiscountMemoized discount={data.row.discount} />
    },
    {
      flex: 1,
      field: "action",
      headerName: "",
      sortable: false,
      renderCell: (data) => {

        return (
          <>
            <NavLink
              to={`/providers/campaigns/${data.row.objectID}?id=${data.row.objectID}`}
            >
              <Button
                variant="contained"
                sx={{ backgroundColor: "#3b82f6", fontSize: 10 }}
              >
                Ver detalles
              </Button>
            </NavLink>
          </>
        );
      },
    },
    // {
    //   flex: 1,
    //   field: "received.t",
    //   headerName: "Fecha de recepción",
    //   options: {},
    //   renderCell: (data) => {
    //     return (
    //       <>
    //         {data.row.received.t ? (
    //           <Typography fontSize={14}>
    //             {formatUnix(data.row.received.t)}
    //           </Typography>
    //         ) : (
    //           ""
    //         )}
    //       </>
    //     );
    //   },
    // },
    // {
    //   flex: 1,
    //   field: "date_timestamp",
    //   headerName: "Fecha de creación",
    //   options: {},
    //   renderCell: (data) => {
    //     return (
    //       <>
    //         <Typography fontSize={14}>
    //           {formatUnix(data.row.date_timestamp)}
    //         </Typography>
    //       </>
    //     );
    //   },
    // },
    // {
    //   flex: 1,
    //   field: "state",
    //   headerName: "Estado",

    //   options: {
    //     filter: true,
    //   },
    // },
    // {
    //   flex: 1,
    //   field: "action",
    //   headerName: "",
    //   sortable: false,
    //   renderCell: (data) => {
    //     const { getParam } = useSearchParams();
    //     const seller = getParam("seller");
    //     return (
    //       <>
    //         <NavLink
    //           to={
    //             seller
    //               ? `/providers/purchaseOrders/${data.row.name}?id=${data.id}&provider=${seller}`
    //               : `/providers/purchaseOrders/${data.row.name}?id=${data.id}`
    //           }
    //         >
    //           <Button
    //             variant="contained"
    //             sx={{ backgroundColor: "#3b82f6", fontSize: 10 }}
    //           >
    //             Ver detalles
    //           </Button>
    //         </NavLink>
    //       </>
    //     );
    //   },
    // },
  ];
    return <MainLayout>
    <Stack gap={2}>
      <Typography
        component="h3"
        style={{ fontWeight: 700, fontSize: "24px", color: "#1e293b" }}
      >
        Campañas
      </Typography>
      <Table
        style={{ borderColor: "transparent" }}
        minWidth="100%"
        height="700px"
        defaultFilters={{
          // seller: seller,
        //   ...(!providerCompany && {provider:{ $in: defaultProviders}}),
        //   ...(providerCompany && {provider:providerSelected?.value} ),

        }}
        sx={{
          ".MuiDataGrid-main": {
            marginTop: 2,
          },
          ".MuiBox-root": {
            flexDirection: "row-reverse",
            padding: 0,
          },
          ".MuiDataGrid-toolbarContainer": {
            padding: 0,
          },
          ".MuiGrid-root": {
            maxWidth: "100%",
          },
          ".MuiDataGrid-columnHeaders": {
            backgroundColor: "#e5e7eb",
          },
        }}
        index="campaigns"
        // defaultFilters={{ state: "" }}
        defaultSort={{ number: -1 }}
        columns={CAMPAIGNS_COLUMNS}
      />
    </Stack>
  </MainLayout>
}
