import { createContext, useEffect, useState } from "react";
import { useSearchParams } from "../hooks/useSearchParams";
import { useMongoAggregate } from "../../../utils/mongoAggregate/useMongoAggregate";
import { useUserData } from "../../../context/UserContext";
export const ProviderContext = createContext();

export default function ProviderFNProvider({ children }) {
  const [providerSelected, setSelectState] = useState();
  const { setParam, deleteParam, pushParams, getParam } = useSearchParams();
  const [productHasItems,setProductHasItems] = useState(null)
  const [providersState, setProviders] = useState([]);

  let providerCompany = getParam("providerCompany");
  let provider = getParam("seller");
  const { uid,roles } = useUserData();
  const [defaultBrands,setDefaultBrands] = useState()
  const [defaultProviders,setDefaultProviders] = useState()

  const [providersData, providersLoading] = useMongoAggregate({
    enabled: !!provider,
    index: "providers",
    refetchOnWindowFocus: false,
    aggregate: [
      {
        $match: {
          "data.sellers": provider,
          ...((!roles.includes("seller")) && ({"data.users":uid}))
        },
      },
      {
        $project: {
          "data.users":1,
          "data.name": 1,
          "data.objectID": 1,
          "data.brands": 1,
          "data.sellers": 1,
        },
      },
    ],
  });

  const handleSelectProvider = (val) => {
    if (!val) return handleDeleteProvider();

    setSelectState(providersState.find((e) => e.value === val));
    setParam("providerCompany", val);

    return pushParams();
  };

  const handleDeleteProvider = () => {
    setSelectState();
    deleteParam("providerCompany");
    return pushParams();
  };

  useEffect(() => {
    if (providersLoading) return;

    if (providersData && providersData.length > 0) {
      let data = providersData.map((s) => ({
        label: (s.data.name && s.data.name[0].toUpperCase() + s.data.name.slice(1)),
        value: s.data.objectID,
        brands: s.data.brands,
      })).sort((current,next) => current.label < next.label ? -1 : current.label > next.label ? 1 : 0);

      data.unshift({
        label: "Ninguno",
        value: null,
        brands: null,
      });

      setProviders(data);
    }
  }, [providersData,providersLoading]);

  useEffect(() => {
    setDefaultProviders(providersState.map(b => b.value).flat().filter(e => e))
  },[providersState])

  useEffect(() => {
    setDefaultBrands(providersState.map(b => b.brands).flat().filter(e => e))
  },[providersState])

  useEffect(() => {
    if (providerCompany) {

      return handleSelectProvider(providerCompany);
    }

  }, [providersState]);
  if(providersLoading) return;

  return (
    <ProviderContext.Provider
      value={{
        providerSelected,
        handleDeleteProvider,
        handleSelectProvider,
        productHasItems,
        setProductHasItems,
        defaultBrands,
        defaultProviders,
        providersState,
        userRoles:roles,
        setProviders,
      }}
    >
      {children}
    </ProviderContext.Provider>
  );
}
