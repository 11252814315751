import _ from "lodash";

export function fixTimestampFirestore(h) {
  const isArray = _.isArray(h);
  return typeof h !== "object"
    ? h
    : Object.entries(h || {}).reduce(
        (p, [f, v]) => {
          if (!v) return p;
          const nv =
            typeof v === "string" &&
            v.match(/^([12]\d{3}-(0[1-9]|1[0-2])-(0[1-9]|[12]\d|3[01]))$/g)
              ? (new Date(v + " GMT-0300") / 1000) | 0
              : typeof v.toDate === "function"
              ? (v.toDate() / 1000) | 0
              : h[f + "_timestamp"] && typeof v === "object"
              ? h[f + "_timestamp"]
              : typeof v === "object"
              ? fixTimestampFirestore(v)
              : v;

          return isArray
            ? [...p, nv]
            : {
                ...p,
                [f]: nv,
              };
        },
        isArray ? [] : {},
      );
}
