import {
  Avatar,
  Button,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  MenuItem,
  Modal,
  Select,
  Typography,
} from "@mui/material";
import {
  faArrowRight,
  faShoppingBasket,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useReducer, useState } from "react";
import { useMongoAggregate } from "../../../../utils/mongoAggregate/useMongoAggregate";
import _ from "lodash";
import { Sf } from "../../../../components/Widget/SnowFlakes";
import { FieldValue, updateDoc } from "../../../../firebase";
import { useUserData } from "../../../../context/UserContext";
import imgurWebp from "../../../../components/Utils/Imgur/imgurWebp";

export default function RemoveBasket({ orderId, basketId, orderData }) {
  const [openModal, setOpenModal] = useState(false);
  return (
    <>
      <IconButton
        sx={{
          height: 50,
          position: "absolute",
          right: "20px",
        }}
        aria-label="delete"
        onClick={(e) => {
          e.stopPropagation();
          setOpenModal(true);
        }}
      >
        <FontAwesomeIcon icon={faTrash} />
      </IconButton>
      {openModal && (
        <RemoveBasketModal
          orderId={orderId}
          basketId={basketId}
          orderData={orderData}
          onClose={() => {
            setOpenModal(false);
          }}
        />
      )}
    </>
  );
}

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 1,
  maxWidth: "100vw",
};

function RemoveBasketModal({ orderId, basketId, onClose, orderData }) {
  const [items] = useMongoAggregate({
    index: "orders",
    aggregate: [
      {
        $match: {
          "data.objectID": orderId,
        },
      },
      {
        $project: {
          item: { $objectToArray: "$data.items" },
        },
      },
      { $unwind: "$item" },
      {
        $match: {
          $or: [
            { "item.v.basket": basketId },
            { [`item.v.baskets.${basketId}.q`]: { $gt: 0 } },
          ],
        },
      },
      {
        $lookup: {
          from: "pproducts",
          localField: "item.k",
          foreignField: "data.objectID",
          as: "product",
        },
      },
      {
        $project: {
          q: {
            $cond: {
              if: { $gt: ["$item.v.tq", 0] },
              then: "$item.v.tq",
              else: "$item.v.q",
            },
          },
          basket: "$item.v.basket",
          id: "$item.k",
          name: { $first: "$product.data.name" },
          taste: { $first: "$product.data.taste" },
          quantity: {
            $first: "$product.data.quantity",
          },
          unit: { $first: "$product.data.unit" },
          image: { $first: "$product.data.image" },
        },
      },
    ],
  });

  const baskets = _.chain(orderData?.baskets)
    .entries()
    .filter(([id, b]) => b.q && id !== basketId && b.state !== "shippingBox")
    .sortBy([
      ([, v]) => -(v.c || 0),
      ([d]) => (d.match(/^\d/) ? Number(d.replace(/[^0-9]/g, "")) : d),
    ])
    .value();

  const { uid } = useUserData();

  const [defaultBasket, setDefaultBasket] = useState(baskets?.[0]?.[0]);
  const [newBasketsByItem, setNewBasketsByItem] = useReducer(
    (p, a) =>
      p[a] === defaultBasket ? _.omit(p, a) : { ...p, [a]: defaultBasket },
    {},
  );

  const handleConfirm = () => {
    const hit = items?.reduce(
      (p, a) => ({
        ...p,
        [`items.${a.id}.basket`]: newBasketsByItem[a.id] || defaultBasket,
        [`items.${a.id}.oBasket`]: basketId,
      }),
      {
        [`baskets.${basketId}.state`]: "divided",
        [`baskets.${basketId}.divided.t`]: FieldValue.serverTimestamp(),
        [`baskets.${basketId}.divided.by`]: uid,
        [`baskets.${basketId}.q`]: 0,
      },
    );

    updateDoc("orders", orderId, hit);

    onClose();
  };

  return (
    <Modal onClose={onClose} open={true} onClick={(e) => e.stopPropagation()}>
      <Grid sx={style} container>
        <Grid item xs={7}>
          <Typography variant={"body1"}>Vaciando</Typography>
          <Typography variant="h6" component="h2">
            <FontAwesomeIcon icon={faShoppingBasket} /> {basketId}{" "}
            <Sf q={orderData?.baskets?.[basketId]?.c} />{" "}
            <FontAwesomeIcon icon={faArrowRight} />
          </Typography>
        </Grid>
        <Grid item xs={5}>
          <Select
            fullWidth
            label={"Mover A"}
            value={defaultBasket}
            onChange={(e) => {
              setDefaultBasket(e.target.value);
            }}
          >
            {baskets?.map(([id, b]) => (
              <MenuItem value={id} key={id}>
                <FontAwesomeIcon icon={faShoppingBasket} /> {id} <Sf q={b.c} />
              </MenuItem>
            ))}
          </Select>
        </Grid>
        <List
          sx={{
            width: "100%",
            maxWidth: 400,
            bgcolor: "background.paper",
            maxHeight: "calc(100vh - 200px)",
            overflow: "scroll",
            mb: 2,
          }}
        >
          {items?.map((item) => (
            <ListItem key={item.id} disablePadding>
              <ListItemAvatar>
                <Avatar src={imgurWebp(item.image, 100)} variant="square" />
              </ListItemAvatar>
              <ListItemText
                primary={
                  <>
                    {item.q}x {item.name}
                  </>
                }
                secondary={
                  <>
                    {item.taste} | {item.quantity}
                    {item.unit}
                  </>
                }
              />
              <Button
                variant={newBasketsByItem[item.id] ? "contained" : "outlined"}
                startIcon={<FontAwesomeIcon icon={faShoppingBasket} />}
                edge={"end"}
                onClick={() => setNewBasketsByItem(item.id)}
              >
                {newBasketsByItem[item.id] || defaultBasket}
              </Button>
            </ListItem>
          ))}
        </List>
        <Grid item xs={6} padding={1}>
          <Button
            fullWidth
            variant={"contained"}
            color={"error"}
            onClick={onClose}
          >
            Cancelar
          </Button>
        </Grid>
        <Grid item xs={6} padding={1}>
          <Button
            fullWidth
            variant={"contained"}
            color={"success"}
            onClick={handleConfirm}
          >
            Confirmar
          </Button>
        </Grid>
      </Grid>
    </Modal>
  );
}
