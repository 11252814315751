import { Box, Container, Divider } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { DataGridPremium, GridToolbar } from "@mui/x-data-grid-premium";
import { useGetProductsExcel } from "./useGetProductsExcel";
import { Avatar, FormControlLabel, Radio, RadioGroup, Stack } from "@mui/material";
import { useDocs } from "../../algolia/getObjects";
import { getPrice, getProductDiscount } from "../../utils/prices/priceCalc";
import { useEffect, useState } from "react";
import _ from "lodash";
import { useMongoAggregate } from "../../utils/mongoAggregate/useMongoAggregate";
import Filters from "../providers/components/Filters";
import { useFilter } from "../providers/hooks/useFilter";
import { useSearchParams } from "../providers/hooks/useSearchParams";

const useStyles = makeStyles((theme) => ({
  container: {
    width: "100%",
    height: "95vh",
    paddingBottom: "87px",
    display: "flex",
    [theme.breakpoints.up("md")]: {
      maxWidth: "1400px",
    },
    //justifyContent:'space-around',
    marginTop: 15,
    border: "1px solid #ccc",
    borderRadius: 10,
  },
  containerCheckbox: {
    borderRight: "1px solid #ccc",
  },
}));

const ProductsExcel = () => {
  const { setParam, pushParams, getAllParams } = useSearchParams();
  useEffect(() =>{
    setParam("active", true)
    pushParams()

  }, [])
  const activeParam = getAllParams("active").map(param => JSON.parse(param.toLowerCase()))
  // const activeParamsBool = activeParam
  const {
    clearAllFiltersFromUrl,
    handleDeleteUrlFilter,
    handleOnUrlFilterChange,
  } = useFilter();
  const classes = useStyles();
  console.log(activeParam)
  const [priceListIdsd] = useMongoAggregate({
    index: "priceList",
    refetchOnWindowFocus: false,
    aggregate:[
      {
        $match: { "data.active": activeParam.length ? {$in: activeParam } : null}
      },
      {
        $project: {
          "data.active": { $ifNull: [ "$data.active", false ] },
          "data.objectID": 1
        }
      },

  ]
  })
  const priceListIds = priceListIdsd?.map(pricelist => pricelist.data.objectID) || []
  const [product, loading] = useGetProductsExcel();
  const priceList = useDocs({
    index: "priceList",
    ids: priceListIds,
  });

  const brands = useDocs({ index: "brands", ids: ["a"] });
  const specs = useDocs({ index: "specs", ids: ["a"] });
  const [selectedPricelist, setSelectedPricelist] = useState("d");
  const hasPackDiscount = undefined !== getProductDiscount({
    product,
    priceLists: priceList,
    priceListID: selectedPricelist,
    brand: brands[product?.brand],
    specs: Object.values(specs).filter((d) =>
      product?.specs?.includes(d.objectID)
    ),
    q: 1,
  })?.plusDiscounts?.packDiscount

  const [columnVisibilityModel, setColumnVisibilityModel] =
    useState({
      packDiscount:hasPackDiscount
    });

  useEffect(() => {
    setColumnVisibilityModel({
      packDiscount:hasPackDiscount
    })
  },[hasPackDiscount])

  let rows =
    Object.keys(priceList).length &&
    Object.keys(brands).length &&
    Object.keys(specs).length &&
    _.sortBy(product, [
      "data.brandRanking",
      "data.ranking",
      "data.name",
      "data.taste",
    ])?.map(({ data: product }) => {
      return {
        id: product?.objectID,
        name: product?.name,
        brandName: product.brandName,
        taste: product?.taste,
        thumbnail: product?.thumbnail,
        quantity: product?.quantity,
        price: getPrice({
          product,
          priceLists: priceList,
          priceListID: selectedPricelist,
          iva: priceList?.[selectedPricelist]?.iva_included,
        }),
        unit: product?.unit,
        code: product?.sellerCode,
        EAN: product?.EAN,
        discount:
          _.round(
            getProductDiscount({
              product,
              priceLists: priceList,
              priceListID: selectedPricelist,
              brand: brands[product?.brand],
              specs: Object.values(specs).filter((d) =>
                product?.specs?.includes(d.objectID)
              ),
              q: 1,
            }).discount,
            2
          ) *
            100 +
          "%",
        packDiscount:_.round(
          getProductDiscount({
            product,
            priceLists: priceList,
            priceListID: selectedPricelist,
            brand: brands[product?.brand],
            specs: Object.values(specs).filter((d) =>
              product?.specs?.includes(d.objectID)
            ),
            q: product?.pack,
          }).plusDiscounts.packDiscount,
          2
        ) *
          100 +
        "%",
      };
    });

  const columns = [
    {
      field: "thumbnail",
      headerName: "Imagen",
      renderCell: (params) => <Avatar src={params.value} />,
      disableExport: true,
    },
    { field: "code", headerName: "Codigo" },
    { field: "brandName", headerName: "Marca" },
    { field: "name", headerName: "Producto" },
    { field: "taste", headerName: "Sabor" },
    { field: "quantity", headerName: "Cantidad" },
    { field: "unit", headerName: "Unidad" },
    { field: "EAN", headerName: "EAN" },
    {
      field: "price",
      hide: true,
      headerName: `Precio ${
        priceList?.[selectedPricelist]?.iva_included ? "con iva" : "sin iva"
      }`,
    },
    { field: "discount", headerName: "Descuento" },
    { field:"packDiscount", headerName: "Descuento por bulto", flex:1 },
  ];
  // In the future add support for multiple filters
  const priceListFilters = [
    { 
      category:"Activa", 
      multiple:true,
      elements:
        [
          { param:"active", value:true, label:"Activo" }, 
        ]
    }
  ]
  useEffect(() => {
    try {
      if (product?.length) {
        setTimeout(() => {
          for (const e of document.querySelectorAll(".MuiDataGrid-main > div"))
            e.textContent === "MUI X Missing license key" &&
              (e.style.display = "none");
        }, 500);
      }
    } catch (e) {
      console.log(e);
    }
  }, [product]);

  return (
    <Container className={classes.container}>
      <Box style={{ width: "100%" }}>
        <Stack direction="column">
        <RadioGroup
          row
          value={selectedPricelist}
          onChange={(e) => setSelectedPricelist(e.target.value)}
        >
          {priceListIds.map((p) => (
            <FormControlLabel
              key={p}
              value={p}
              control={<Radio />}
              label={priceList?.[p]?.name}
            />
          ))}
        </RadioGroup>
        <Filters
          onApply={(el, close) => close()}
          onClear={clearAllFiltersFromUrl}
          onDeleteFilter={(filter) => handleDeleteUrlFilter(filter)}
          filters={priceListFilters} 
          onChange={handleOnUrlFilterChange}
        />
        <Divider style={{marginBottom: 16, marginTop: 16}} />
        </Stack>
        {rows?.length && (
          <DataGridPremium
            rows={rows}
            columnVisibilityModel={columnVisibilityModel}
            onColumnVisibilityModelChange={(newModel) =>
              setColumnVisibilityModel(newModel)
            }
            components={{ Toolbar: GridToolbar }}
            loading={loading}
            columns={columns}
          />
        )}
      </Box>
    </Container>
  );
};

export default ProductsExcel;
