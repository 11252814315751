import { Box, ListItem, ListItemText, Typography } from "@mui/material";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import { Link } from "react-router-dom";
import moment from "moment";
import LocalShippingIcon from "@mui/icons-material/LocalShipping";
import CheckIcon from "@mui/icons-material/Check";

export const CommitedOrderCard = ({ order }) => {
  const {
    q,
    tq,
    internalId_daily,
    timestamp,
    objectID,
    user,
    address,
    shippingDate,
    shipping,
    storeName,
    roadmap,
  } = order || {};

  if (!objectID) return null;

  const name = storeName || user || "";
  const shippingTitle = shipping?.[0]?.title;

  return (
    <ListItem
      as={Link}
      to={`/app/orders/${objectID}`}
      target="_blank"
      sx={{
        padding: 0,
        cursor: "pointer",
        "&:hover": {
          backgroundColor: "rgba(0,0,0,0.1)",
        },
      }}
    >
      <ListItemText
        primary={
          <Box sx={{ display: "flex", gap: 2 }}>
            <Typography sx={{ fontWeight: "bold", fontSize: 14 }}>
              {`${tq || q || 0}`}
            </Typography>
            <Box>
              <Typography sx={{ fontWeight: "bold" }}>{name}</Typography>
              <Typography sx={{ fontWeight: "bold" }}>
                {internalId_daily?.toUpperCase() || ""}
              </Typography>
            </Box>
            <OpenInNewIcon />
            {roadmap && <CheckIcon />}
          </Box>
        }
        secondary={
          <Box sx={{ display: "flex", flexDirection: "column" }}>
            <Typography>
              {moment(timestamp*1000)?.format("DD/MM/YYYY HH:mm")}
            </Typography>
            {!!shippingDate?.length && (
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: 2,
                  marginTop: 2,
                }}
              >
                <LocalShippingIcon />
                <Typography>
                  {moment(shippingDate)?.format("DD/MM/YYYY")}
                </Typography>
                <Typography sx={{ fontWeight: "bold" }}>
                  {shippingTitle || ""}
                </Typography>
              </Box>
            )}
            <Typography sx={{ maxWidth: 300, overflowX: "hidden" }}>
              {address || ""}
            </Typography>
          </Box>
        }
      />
    </ListItem>
  );
};
